import { Modal, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import "./notification.css";
import { BellOutlined } from "@ant-design/icons";

// const NotificationListItem = ({ listItems }) => {
//   return (
//     <>
//       {Array(4)
//         .fill()
//         .map((el, index) => (
//           <div key={index} className="listing_card">
//             <div className="listing_item_body">
//               <div className="listing_item_body_content">
//                 <div className="icon">
//                   <BellOutlined style={{ color: "#5A81FA", fontSize: 16 }} />
//                 </div>
//                 <div className="item_content">
//                   <p className="text_content">
//                     New Findings has been initiated
//                   </p>
//                   <p className="time_text">30 mins ago</p>
//                 </div>
//               </div>
//             </div>
//             <div className="ellipse_unread"></div>
//           </div>
//         ))}
//     </>
//   );
// };

const NotificationModal = ({
  className,
  isNotificationOpen,
  handleNotifiCancel,
}) => {
  const [activeTabKey, setActiveTabKey] = useState("all");
  const [notificationJsonData] = useState([
    {
      id: 1,
      notification_title: "New Findings has been initiated",
      approved_btn: false,
      reject_btn: false,
      read: false,
      module: "cloud",
    },
    {
      id: 2,
      notification_title: "New Findings has been initiated",
      approved_btn: true,
      reject_btn: true,
      read: false,
      module: "cloud",
    },
    {
      id: 3,
      notification_title: "New Findings has been initiated",
      approved_btn: false,
      reject_btn: false,
      read: false,
      module: "sca",
    },
    {
      id: 4,
      notification_title: "New Findings has been initiated",
      approved_btn: false,
      reject_btn: false,
      read: true,
      module: "infra",
    },
    {
      id: 5,
      notification_title: "New Findings has been initiated",
      approved_btn: false,
      reject_btn: false,
      read: true,
      module: "infra",
    },
    {
      id: 6,
      notification_title: "New Findings has been initiated",
      approved_btn: false,
      reject_btn: false,
      read: true,
      module: "sca",
    },
  ]);
  const [finalData, setFinalData] = useState(notificationJsonData);

  //   change key
  const onChange = (key) => {
    setActiveTabKey(key);
  };

  //   filter data on tab change
  useEffect(() => {
    if (activeTabKey === "all") {
      setFinalData(notificationJsonData);
    } else {
      const filterData = notificationJsonData.filter(
        (el) => el.module === activeTabKey
      );
      setFinalData(filterData);
    }
  }, [activeTabKey, notificationJsonData]);

  const items = [
    {
      key: "all",
      label: "All",
      //   children: <NotificationListItem />,
    },
    {
      key: "cloud",
      label: "Cloud",
      //   children: "Content of Tab Pane 2",
    },
    {
      key: "sca",
      label: "SCA",
      //   children: "Content of Tab Pane 3",
    },
    {
      key: "infra",
      label: "Infra",
      //   children: "Content of Tab Pane 4",
    },
    {
      key: "vendor",
      label: "Vendor",
      //   children: "Content of Tab Pane 5",
    },
  ];

  return (
    <Modal
      mask={false}
      className={className}
      open={isNotificationOpen}
      closable={false}
      footer={null}
      // onOk={handleProfileOk}
      onCancel={handleNotifiCancel}
    >
      <div className="notification_card_header_wrapper_div">
        <div className="notification_header_title">
          <p className="common_text_style mb-0">Notifications </p>
          <p className="common_text_style mb-0">Mark All As Read</p>
        </div>
        <div className="notification_tabs">
          <Tabs
            style={{ overflow: "auto" }}
            defaultActiveKey={activeTabKey}
            items={items}
            onChange={onChange}
          />
          <div className="archieved_notification">
            <button className="archieved_btn">Archived</button>
          </div>
        </div>
        <div className="notification_item_wrapper_container">
          {finalData.map((el, index) => (
            <div key={index} className="listing_card">
              <div className="listing_item_body">
                <div className="listing_item_body_content">
                  <div className="icon">
                    <BellOutlined style={{ color: "#5A81FA", fontSize: 16 }} />
                  </div>
                  <div className="item_content">
                    <p className="text_content">{el.notification_title}</p>
                    <p className="time_text">30 mins ago</p>
                    {el.approved_btn && el.reject_btn && (
                      <div className="approval_btn_parent">
                        <button className="btn approve_btn">Approve</button>
                        <button className="btn reject_btn">Reject</button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {!el.read && <div className="ellipse_unread"></div>}
            </div>
          ))}
        </div>
      </div>
    </Modal>
  );
};

export default NotificationModal;
