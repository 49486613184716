import { Card, Col, Row } from "antd";
import React from "react";
import "./CategoryWiseIssues.css";
const data = [
  { id: 1, countValue: 22, category: "POLICY & PEOPLE", color: "#FF9D01" },
  { id: 1, countValue: 36, category: "RISK MANAGEMENT", color: "#A9C901" },
  { id: 1, countValue: 60, category: "INCIDENT MANAGEMENT", color: "#0174DE" },
  { id: 1, countValue: 78, category: "physical", color: "#7BCF74" },
  { id: 1, countValue: 97, category: "Infrastructure", color: "#01B1BD" },
  { id: 1, countValue: 55, category: "access control", color: "#014EB4" },
  { id: 1, countValue: 49, category: "application security", color: "#4F25C7" },
  { id: 1, countValue: 32, category: "BCM", color: "#5A8DAA" },
];
const CategoryWiseIssues = () => {
  return (
    <div className="category_wise_issues">
          <div className="category_wise_issues_header">Category Wise Issues</div>
        <Row gutter={[8, 8]} className="content_wrapper">
          {data.map((el, index) => (
            <Col key={index}>
              <div className="wrapper_issue">
                <div className="issue_values" style={{ color: el.color }}>
                  {el.countValue}
                </div>
                <div className="issue_category" style={{ width: "85px" }}>
                  {el.category}
                </div>
              </div>
            </Col>
          ))}
         
        </Row>
      {/* </Card> */}
    </div>
  );
};

export default CategoryWiseIssues;
