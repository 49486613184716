import { AccountBookOutlined, BugOutlined, CloseOutlined, CloudOutlined, DownloadOutlined, FullscreenExitOutlined, LogoutOutlined, PlusOutlined, QuestionCircleOutlined, SearchOutlined, SyncOutlined, WarningOutlined } from '@ant-design/icons';
import { Card, Checkbox, Col, Drawer, Dropdown, Flex, Input, Menu, Pagination, Row, Space, Table } from 'antd'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import './ScanDetails.css'
import CustomTableVulnerabilities from '../../../../Common/CommanTable/CustomTableVulnerabilities';
import data from "../../../../../components/dashboard-options/Data";
import TableCard from './TableCard';
import ProjectNameCard from './ProjectNameCard';
import arming from "../../../../../images/icons/arming_countdown.png";
import HeaderNotation from '../../../../Common/grid-header-number-count/HeaderNotation';
import TopIssuesPage from './TopIssuesPage';
import TopIssuesCard from './TopIssuesCard';
import CardViewIcon from "../../../../../images/sidenavIcons/view_day.png";
import DataGridCard from '../../../../Common/data-grid-card-view/DataGridCard';
import SplitscreenOutlinedIcon from "@mui/icons-material/SplitscreenOutlined";
import RowSelectBtn from '../../../../Common/CommanTable/RowSelectBtn';
const { VulnerabilityTableData,TableData,ScanDetailData } = data;
const ScanDetails = () => {
    let history= useHistory();
    const handleClick = ()=>{
        history.push("/sca-dashboard");
    }
    const [filteredInfo, setFilteredInfo] = useState({});
    const [sortedInfo, setSortedInfo] = useState({});
    const handleChange = (pagination, filters, sorter) => {
        console.log("Various parameters", pagination, filters, sorter);
        setFilteredInfo(filters);
        setSortedInfo(sorter);
      };
    const data = [
        {
          key: "1",
          status: "Open",
          threat_category: "Third Part Vendor Risk",
          projectName: "Project 1",
          scanId: 'com.xbiz.Neo',
          Packages_Discovered: 'com.xbiz.Neo',
          Policies_checked:'Policy 1',
          Scan_date:'12/10/23',
          Issues_found:[
            {
              mainValue: 400,
              value: 30,
              initial: "C",
              color: "#9494AE",
              bgColor: "#E6E6E6",
            },
            {
              mainValue: 868,
              value: 80,
              initial: "H",
              color: "#F50000",
              bgColor: "#F500001F",
            },
            {
              mainValue: 208,
              value: 90,
              initial: "M",
              color: "#FE9706",
              bgColor: "#FE97061F",
            },
            {
              mainValue: 30,
              value: 30,
              initial: "L",
              color: "#02B583",
              bgColor: "#02B5831F",
            },
          ],
          title: "Weak cipher suites partnerbuddy.hdfcergo.com:443)",
          severity: "High",
          endOfLife: "20/01/2023",
          codeIssue_No: 400,
          vulnerability_No: 300,
          licence_No: 200,
          malicious_No: 500,
        },
        {
          key: "2",
          status: "Open",
          threat_category: "Cloud Risk",
          projectName: "Project 2",
          
          scanId: 'com.xbiz.Neo',
          Packages_Discovered: 'com.xbiz.Neo',
          Policies_checked:'Policy 1',
          Scan_date:'12/10/23',
          Issues_found:[
            {
              mainValue: 400,
              value: 30,
              initial: "C",
              color: "#9494AE",
              bgColor: "#E6E6E6",
            },
            {
              mainValue: 868,
              value: 80,
              initial: "H",
              color: "#F50000",
              bgColor: "#F500001F",
            },
            {
              mainValue: 208,
              value: 90,
              initial: "M",
              color: "#FE9706",
              bgColor: "#FE97061F",
            },
            {
              mainValue: 30,
              value: 30,
              initial: "L",
              color: "#02B583",
              bgColor: "#02B5831F",
            },
          ],
          title: "Weak cipher suites partnerbuddy.hdfcergo.com:443)",
          severity: "Medium",
          endOfLife: "20/01/2023",
          codeIssue_No: 400,
          vulnerability_No: 300,
          licence_No: 200,
          malicious_No: 500,
        },
        {
          key: "3",
          status: "Open",
          threat_category: "SCA Risk",
          projectName: "Project 3",
          
          scanId: 'com.xbiz.Neo',
          Packages_Discovered: 'com.xbiz.Neo',
          Policies_checked:'Policy 1',
          Scan_date:'12/10/23',
          Issues_found:[
            {
              mainValue: 400,
              value: 30,
              initial: "C",
              color: "#9494AE",
              bgColor: "#E6E6E6",
            },
            {
              mainValue: 868,
              value: 80,
              initial: "H",
              color: "#F50000",
              bgColor: "#F500001F",
            },
            {
              mainValue: 208,
              value: 90,
              initial: "M",
              color: "#FE9706",
              bgColor: "#FE97061F",
            },
            {
              mainValue: 30,
              value: 30,
              initial: "L",
              color: "#02B583",
              bgColor: "#02B5831F",
            },
          ],
          title: "Weak cipher suites partnerbuddy.hdfcergo.com:443)",
          // severity: <Button>Critical</Button>,
          severity: "Critical",
          identification_date: "20/01/2023",
          codeIssue_No: 400,
          vulnerability_No: 300,
          licence_No: 200,
          malicious_No: 500,
        },
        {
          key: "4",
          status: "Open",
          threat_category: "Infrastructural Risk",
          projectName: "Project 4",
          
          scanId: 'com.xbiz.Neo',
          Packages_Discovered: 'com.xbiz.Neo',
          Policies_checked:'Policy 1',
          Scan_date:'12/10/23',
          Issues_found:[
            {
              mainValue: 400,
              value: 30,
              initial: "C",
              color: "#9494AE",
              bgColor: "#E6E6E6",
            },
            {
              mainValue: 868,
              value: 80,
              initial: "H",
              color: "#F50000",
              bgColor: "#F500001F",
            },
            {
              mainValue: 208,
              value: 90,
              initial: "M",
              color: "#FE9706",
              bgColor: "#FE97061F",
            },
            {
              mainValue: 30,
              value: 30,
              initial: "L",
              color: "#02B583",
              bgColor: "#02B5831F",
            },
          ],
          title: "Weak cipher suites partnerbuddy.hdfcergo.com:443)",
          // severity: <Button>Low</Button>,
          severity: "Low",
          identification_date: "20/01/2023",
          codeIssue_No: 400,
          vulnerability_No: 300,
          licence_No: 200,
          malicious_No: 500,
        },
        {
          key: "5",
          status: "Open",
          threat_category: "Third Part Vendor Risk",
          projectName: "Project 5",
         
          scanId: 'com.xbiz.Neo',
          Packages_Discovered: 'com.xbiz.Neo',
          Policies_checked:'Policy 1',
          Scan_date:'12/10/23',
          Issues_found:[
            {
              mainValue: 400,
              value: 30,
              initial: "C",
              color: "#9494AE",
              bgColor: "#E6E6E6",
            },
            {
              mainValue: 868,
              value: 80,
              initial: "H",
              color: "#F50000",
              bgColor: "#F500001F",
            },
            {
              mainValue: 208,
              value: 90,
              initial: "M",
              color: "#FE9706",
              bgColor: "#FE97061F",
            },
            {
              mainValue: 30,
              value: 30,
              initial: "L",
              color: "#02B583",
              bgColor: "#02B5831F",
            },
          ],
          title: "Weak cipher suites partnerbuddy.hdfcergo.com:443)",
          // severity: <Button>High</Button>,
          severity: "High",
          identification_date: "20/01/2023",
          codeIssue_No: 400,
          vulnerability_No: 300,
          licence_No: 200,
          malicious_No: 500,
        },
      ];
    const columns = [
        {
          title: "Scan Id",
          dataIndex: "scanId",
          key: "scanId",
          sorter: (a, b) => a.scanId - b.scanId,
          sortOrder: sortedInfo.columnKey === "scanId" ? sortedInfo.order : null,
          ellipsis: true,
          filteredValue: filteredInfo.scanId || null,
        },
        {
          title:'Packages Discovered',
          dataIndex: "Packages_Discovered",
          key: "Packages_Discovered",
          sorter: (a, b) => a.Packages_Discovered - b.Packages_Discovered,
          sortOrder: sortedInfo.columnKey === "Packages_Discovered" ? sortedInfo.order : null,
          ellipsis: true,
          filteredValue: filteredInfo.Packages_Discovered || null,
          
          
        },
        {
          title: 'Issues found',
          dataIndex: "Issues_found",
          key: "Issues_found",
          sorter: (a, b) => a.Issues_found - b.Issues_found,
          sortOrder: sortedInfo.columnKey === "Issues_found" ? sortedInfo.order : null,
          ellipsis: true,
          filteredValue: filteredInfo.Issues_found || null,
          render: (event, data) => {
            console.log('event nirankar-->',event);
            return (
              <TableCard
                data={event}
                
              />
            );
          },
          
          
        },
        {
          title: 'Policies checked',
          dataIndex: "Policies_checked",
          key: "Policies_checked",
          sorter: (a, b) => a.Policies_checked - b.Policies_checked,
          sortOrder: sortedInfo.columnKey === "Policies_checked" ? sortedInfo.order : null,
          ellipsis: true,
          filteredValue: filteredInfo.Policies_checked || null,
        },
    
        {
          title:'Scan Date',
          dataIndex: "Scan_date",
          key: "Scan_date",
          sorter: (a, b) => a.Scan_date - b.Scan_date,
          sortOrder: sortedInfo.columnKey === "Scan_date" ? sortedInfo.order : null,
          ellipsis: true,
          filteredValue: filteredInfo.Scan_date || null,         
          
        },
      ];
      const [selectedRow, setSelectedRow] = useState(null);
      const [openDrawer, setOpenDrawer] = useState(false);
      const [open, setOpen] = useState(false);
      const showDrawerModal = () => {
        // setOpenDrawer(true);
        setOpen(true)
      };
      const onClose = () => {
        setOpen(false);
      };
      const headerNotationData = [
        { id: 1, count: 120, category: "Vulnerabilities" },
        { id: 2, count: 120, category: "End of Life" },
        { id: 3, count: 120, category: "License Violations" },
        // { id: 4, count: 120, category: "Malicious Components" },
        // { id: 4, count: 30, category: "Re- Validation" },
        // { id: 5, count: 10, category: "Rejected" },
        // { id: 6, count: 20, category: "False Positive" },
        // { id: 7, count: 30, category: "Need Justification" },
      ];
      const iconStyle = {
        position: "relative",
        top: "5px",
        fontSize: "18px",
        marginRight: "5px",
      };
      const topIssuesCardData = [
        {
          color: "#FE9706",
          icon: <LogoutOutlined style={{ ...iconStyle, color: "#FE9706" }} />,
          title: "'newtonsoft.json'",
          content: "Policy Violation (newtonsoft.json@12.0.3)",
          discription:
            "First detected 17 days ago, most recently seen 2 hours ago in dotnet-api",
        },
        {
          icon: (
            <FullscreenExitOutlined style={{ ...iconStyle, color: "#E10505" }} />
          ),
          title: "CVE-2024-21907",
          discription:
            "First detected 17 days ago, most recently seen 2 hours ago in dotnet-api",
          content: "Vulnerability (newtonsoft.json@12.0.3)",
        },
        {
          icon: (
            <QuestionCircleOutlined style={{ ...iconStyle, color: "#02B583" }} />
          ),
          title: "'at least 3 releases a year'",
          discription:
            "First detected 17 days ago, most recently seen 2 hours ago in dotnet-api",
          content: "Policy Violation (newtonsoft.json@12.0.3)",
        },
        {
          icon: <WarningOutlined style={{ ...iconStyle, color: "#FE9706" }} />,
          title: "CVE-2024-21907",
          discription:
            "First detected 17 days ago, most recently seen 2 hours ago in dotnet-api",
          content: "Vulnerability (newtonsoft.json@12.0.3)",
        },
        {
          icon: <QuestionCircleOutlined style={iconStyle} />,
          title: "'at least 3 releases a year'",
          discription:
            "First detected 17 days ago, most recently seen 2 hours ago in dotnet-api",
          content: "Policy Violation (newtonsoft.json@12.0.3)",
        },
      ];
      const [tableView, setTableView] = useState(false);
      const toggleTableView = () => {
        setTableView(!tableView);
      };
      const itemRender = (current, type, originalElement) => {
        if (type === "prev") {
          return <a>Previous</a>;
        }
        if (type === "next") {
          return <a>Next</a>;
        }
        return originalElement;
      };
      const [currentPage, setCurrentPage] = useState(1);
      const totalRecords = 100; // Total number of records in your table
      const pageSize = 10; // Number of records per page
    
      const startRecord = (currentPage - 1) * pageSize + 1;
      const endRecord = Math.min(currentPage * pageSize, totalRecords);
      const handlePageChange = (page) => {
        setCurrentPage(page);
      };
      
      const defaultCheckedList = columns.map((item) => item.key);
      const [checkedList, setCheckedList] = useState(defaultCheckedList);
      const options = columns.map(({ key, title }) => ({
        label: title,
        value: key,
      }));
      const newColumns = columns.map((item) => ({
        ...item,
        hidden: !checkedList.includes(item.key),
      }));
      const menu = (
        <Menu className="column_dropdown">
          {options.map(({ label, value, index }) => (
            <Menu.Item className="menu_item" key={index}>
              <Checkbox
                key={value}
                onClick={(e) => e.stopPropagation()}
                checked={checkedList.includes(value)}
                onChange={(e) => {
                  const isChecked = e.target.checked;
                  if (isChecked) {
                    setCheckedList([...checkedList, value]);
                  } else {
                    setCheckedList(checkedList.filter((item) => item !== value));
                  }
                }}
              >
                <span onClick={(e) => e.stopPropagation()}>{label}</span>
              </Checkbox>
            </Menu.Item>
          ))}
        </Menu>
      );
      
  return (
    
    <Card  className='scan-details' >
        <div style={{fontSize:'13px', color:'#5A81FA', fontWeight:400, paddingBottom:'10px'}} >
          Dashboard / <span onClick={handleClick} >SCA Dashboard / </span><span style={{color:'#63637D'}}>Scan Details</span>
        </div>
        <Flex className='d-flex' justify={'space-between'} >
          <div className="scan_details_title ">Scan Details</div>
          <div className="sca-scan-details"> <SyncOutlined style={{marginRight:'5px'}} />Sync Data</div>
          </Flex >
          <div className='table-row'>
            <div className='first-part d-flex'>
              <div className='first-data' >
              <Dropdown overlay={menu} trigger={["click"]}>
                  <a
                    className="column-icon"
                    onClick={(e) => e.stopPropagation()}
                  >
                    Columns
                    <SplitscreenOutlinedIcon
                      style={{ color: "#63637D", width: 18, fontSize: '18px' }}
                    />
                  </a>
                </Dropdown>
                <RowSelectBtn />
              
              </div>
            </div>
            <div className='second-part d-flex align-items-center'>
            <Input
              placeholder="Search here"
              prefix={<SearchOutlined />}
              style={{ width: 200,height:'30px' }}
            />
              <div className='second-data' onClick={toggleTableView} ><img src={CardViewIcon} alt="card_view" />card view</div>
              <div className='download' ><DownloadOutlined className='icon'/>Download Excel</div>
              <div className='scan-details-btn' ><PlusOutlined className='addIcon' />Add scan details</div>
            </div>
          </div>
          {/* <CustomTableVulnerabilities columns={columns} data={data}/> */}
          {!tableView ? (
            <Table
            className="scan-details"
            columns={columns}
            dataSource={data}
            style={{ width: "100%" }}
            pagination={false}
            onChange={handleChange}
            columns={newColumns.filter((column) => !column.hidden)}
            onRow={(record) => ({
              onClick: () => {
                setSelectedRow(record);
                showDrawerModal();
              },
            })}
          />
          ) :(
            <DataGridCard />
          )}
          <div
              style={{
                display: "flex",
                marginTop: "10px",
                justifyContent: "space-between",
                alignItems: "center",
                color: "#63637D",
                padding: "7px",
                fontWeight: 600,
                fontSize: "13px",
              }}
            >
              <div>
                Showing {startRecord}-{endRecord} entries out of {totalRecords}
              </div>
              <Pagination
                style={{
                  color: "#63637D",
                  padding: "5px",
                  fontWeight: 600,
                  fontSize: "12px",
                }}
                current={currentPage}
                pageSize={pageSize}
                total={totalRecords}
                itemRender={itemRender}
                onChange={handlePageChange}
                showSizeChanger={false}
              />
            </div>
          
            {open && (
            <Drawer
              title="Scan Summary"
              className="scan-detail-side-drawer"
              // placement={placement}
              width={500}
              onClose={onClose}
              closeIcon={null}
              
              open={open}
              extra={
                <Space>
                  <span
                    onClick={onClose}
                  >
                    <CloseOutlined />
                  </span>
                  {/* <Button type="primary" onClick={onClose}>
                  OK
                </Button> */}
                </Space>
              }
            >
                <div style={{padding:'10px',height:'50px'}} >
                    <HeaderNotation data={headerNotationData} customStyle='customStyle' />
                </div>
                <div className='issues'>
                 <TopIssuesPage />
                </div>
                
            </Drawer>
          )}

    </Card>
  )
}

export default ScanDetails