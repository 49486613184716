import React, { useState } from "react";
import TableSelectable from "../../Pages/Common/selectable-table/TableSelectable";
import { Button, Col, Pagination, Row } from "antd";
import { DownloadOutlined } from "@ant-design/icons";

const buttonStyle = {
  padding: "6px 14px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: "4px",
  borderRadius: "4px",
  opacity: "0px",
  background: "#5A81FA",
  boxShadow: "0px 2px 12px 0px #3F4BFF66",
  textDecoration: "none",
  color: "#FFFFFF",
  fontFamily: "Open Sans",
  fontSize: "12px",
  fontWeight: 600,
  lineHeight: "19.07px",
};
const headerTextStyle = {
  fontFamily: "Open Sans",
  fontSize: "14px",
  fontWeight: 400,
  lineHeight: "19.07px",
  color: "#232337",
};
const DocumentListTable = () => {
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      render: (text) => <a style={{ color: "#5A81FA" }}>{text}</a>,
    },
    {
      title: "Size",
      dataIndex: "size",
      render: (text) => (
        <span style={{ fontFamily: "Open Sans", fontWeight: 400, fontSize: 12, lineHeight: "19.07px", color: "#9494AE" }}>{text}</span>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      align: "center",
      render: (text, record) => (
        <a>
          <DownloadOutlined style={{ color: "#5A81FA" }} />
        </a>
      ),
    },
  ];

  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10; // Number of records per page

  const data = [];
  for (let i = 0; i < 46; i++) {
    data.push({
      key: i,
      name: `file-sample_200kb.Doc`,
      size: `20 kb`,
    });
  }

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const itemRender = (current, type, originalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }
    return originalElement;
  };
  const totalRecords = data.length; // Total number of records in your table
  const startRecord = (currentPage - 1) * pageSize + 1;
  const endRecord = Math.min(currentPage * pageSize, totalRecords);

  // Pagination data for the current page
  const currentPageData = data.slice((currentPage - 1) * pageSize, currentPage * pageSize);

  return (
    <Row>
      <Col md={10}>
        <div style={{ marginBottom: 12 }} className="document_table_header d-flex justify-content-between align-items-center">
          <div className="header_left" style={headerTextStyle}>List of Documents</div>
          <a style={buttonStyle} type="default" className="header_left">
            Download All
          </a>
        </div>
        <TableSelectable size="small" rowSelect={true} pagination={false} TableData={currentPageData} TableClomun={columns} />
        <div
          style={{
            display: "flex",
            marginTop: "10px",
            justifyContent: "space-between",
            alignItems: "center",
            color: "#63637D",
            padding: "7px",
            fontWeight: 600,
            fontSize: "13px",
          }}
        >
          <div>
            Showing {startRecord}-{endRecord} entries out of {totalRecords}
          </div>
          <Pagination
            style={{
              color: "#63637D",
              padding: "5px",
              fontWeight: 600,
              fontSize: "12px",
            }}
            current={currentPage}
            pageSize={pageSize}
            total={totalRecords}
            itemRender={itemRender}
            onChange={handlePageChange}
            showSizeChanger={false}
          />
        </div>
      </Col>
    </Row>
  );
};

export default DocumentListTable;
