import React, { useState } from "react";
import GlobalFilter from "../../../../Common/global-filter/GlobalFilter";
import { Card, Col, Flex, Row, Select } from "antd";
import "./Mobile.css";
import data from "../../../../../components/dashboard-options/Data";
import CustomTable from "../../../../Common/CommanTable/CustomTable";
import HeaderNotation from "../../../../Common/grid-header-number-count/HeaderNotation";
import replay from "../../../../../images/otherIcons/replay.svg"
import { SyncOutlined } from "@ant-design/icons";
const headerNotationData = [
  { id: 1, count: 120, category: "All", color: '#5A81FA' },
  { id: 2, count: 20, category: "Open", color: '#E10505' },
  { id: 3, count: 10, category: "Closed", color: '#02B583' },
  { id: 4, count: 30, category: "Re- Validation", color:'#FE9706' },
  { id: 5, count: 10, category: "Rejected", color: '#E10505' },
  { id: 6, count: 20, category: "False Positive", color: '#E10505' },
  { id: 7, count: 30, category: "Need Justification", color: '#FE9706' },
];
const Mobile = () => {
  const { TableData } = data;
  console.log("data===", TableData);
  const [showGlobalFilter, setShowGlobalFilter] = useState(true);
  return (
    <div className="mobile_grid">
      <Card bodyStyle={{ padding: 0 }} style={{ padding: "19px 16px" }}>
        <div className="card_body_wrapper">
        <Flex className="card_head" justify="space-between">
            <div className="header_title">Mobile</div>
            <a
              onClick={() => window.location.reload(false)}
              className="sync_data"
            >
              <SyncOutlined />
              <span>Sync Data</span>
            </a>
          </Flex>
          <HeaderNotation data={headerNotationData} />
          <div className="android_select">
            <p className="mb-1">Mobile Type</p>
            <Select
              defaultValue="Android"
              style={{
                width: 300,
              }}
            >
              <Select.Option value="Android">Android</Select.Option>
              <Select.Option value="iOS">iOS</Select.Option>
            </Select>
          </div>
          <CustomTable data={TableData} />
          
        </div>
      </Card>
    </div>
  );
};

export default Mobile;
