import React from "react";
import "./PdfDocumentStyle.css";
import X_biz_logo from "../../images/pdfImage/xbiz_logo.png";
import secureNexus from "../../images/pdfImage/secure_nexus_log0.png";
import secureNexusblack from "../../images/pdfImage/secure_nesux_black.png";
import Graphics from "../../images/pdfImage/Graphics.png";
import timeline from "../../images/pdfImage/timeline.png";
import timelineRed from "../../images/pdfImage/timeline_red.png";
import feedbackIcon from "../../images/pdfImage/feedback.png";
import assetsGroupImg from "../../images/pdfImage/assets_group_img.svg";
import DemoGouge from "../../components/charts/DemoGouge";
import { Button, Card, Col, Progress, Row, Table } from "antd";
import GougeCloudRishkChart from "../../components/charts/GougeCloudRishkChart";
import ViewSummaryChart from "../../components/charts/ViewSummaryChart";
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
} from "react-simple-maps";
import ThreatFoundChartPDF from "../../components/charts/ThreatFoundChartPDF";
import InformationExposureChartPDF from "../../components/charts/InformationExposureChartPDF";
import TrendSummaryChart from "../../components/charts/TrendSummaryChart";

const markers = [
  {
    markerOffset: -15,
    name: "UNITED KINGDOM",
    coordinates: [55.378051, -3.435973],
    code: "GB",
  },

  {
    markerOffset: 25,
    name: "AFGANISTAN",
    coordinates: [33.93911, 67.709953],
    code: "AF",
  },
  {
    markerOffset: 25,
    name: "ALBANIA",
    coordinates: [41.153332, 20.168331],
    code: "AL",
  },
  {
    markerOffset: 25,
    name: "AUSTRALIA",
    coordinates: [-25.274398, 133.775136],
    code: "AU",
  },
  {
    markerOffset: 25,
    name: "ANDORRA",
    coordinates: [42.546245, 1.601554],
    code: "AD",
  },
  {
    markerOffset: 25,
    name: "ARMENIA",
    coordinates: [40.069099, 45.038189],
    code: "AM",
  },
  {
    markerOffset: 25,
    name: "AUSTRIA",
    coordinates: [47.516231, 14.550072],
    code: "AT",
  },
  {
    markerOffset: 25,
    name: "BELGIUM",
    coordinates: [50.503887, 4.469936],
    code: "BE",
  },
  {
    markerOffset: 25,
    name: "BAHRAIN",
    coordinates: [25.930414, 50.637772],
    code: "BH",
  },
  {
    markerOffset: 25,
    name: "BELARUS",
    coordinates: [53.709807, 27.953389],
    code: "BY",
  },
];

const PdfDocumnet = () => {
  // ----------------------Table Data-----------
  const data = [
    {
      key: "1",
      status: "Open",
      threat_category: "Third Part Vendor Risk",
      threat_type: "SSL/TLS",
      title: "Weak cipher suites partnerbuddy.hdfcergo.com:443)",
      severity: "High",
      identification_date: "20/01/2023",
    },
    {
      key: "2",
      status: "Open",
      threat_category: "Cloud Risk",
      threat_type: "SSL/TLS",
      title: "Weak cipher suites partnerbuddy.hdfcergo.com:443)",
      severity: "Medium",
      identification_date: "20/01/2023",
    },
    {
      key: "3",
      status: "Open",
      threat_category: "SCA Risk",
      threat_type: "SSL/TLS",
      title: "Weak cipher suites partnerbuddy.hdfcergo.com:443)",
      // severity: <Button>Critical</Button>,
      severity: "Critical",
      identification_date: "20/01/2023",
    },
    {
      key: "4",
      status: "Open",
      threat_category: "Infrastructural Risk",
      threat_type: "SSL/TLS",
      title: "Weak cipher suites partnerbuddy.hdfcergo.com:443)",
      // severity: <Button>Low</Button>,
      severity: "Low",
      identification_date: "20/01/2023",
    },
    {
      key: "5",
      status: "Open",
      threat_category: "Third Part Vendor Risk",
      threat_type: "SSL/TLS",
      title: "Weak cipher suites partnerbuddy.hdfcergo.com:443)",
      // severity: <Button>High</Button>,
      severity: "High",
      identification_date: "20/01/2023",
    },
    {
      key: "6",
      status: "Open",
      threat_category: "Cloud Risk",
      threat_type: "SSL/TLS",
      title: "Weak cipher suites partnerbuddy.hdfcergo.com:443)",
      // severity: <Button>Medium</Button>,
      severity: "Medium",
      identification_date: "20/01/2023",
    },
    {
      key: "7",
      status: "Open",
      threat_category: "Third Part Vendor Risk",
      threat_type: "SSL/TLS",
      title: "Weak cipher suites partnerbuddy.hdfcergo.com:443)",
      // severity: <Button>Low</Button>,
      severity: "Low",
      identification_date: "20/01/2023",
    },
  ];
  const securityPostureData = [
    {
      key: 1,
      category: "Network Security",
      current_instances: 100,
      previous_instances: 200,
    },
    {
      key: 2,
      category: "Information Leakage",
      current_instances: 150,
      previous_instances: 50,
    },
    {
      key: 3,
      category: "Security Misconfigurations",
      current_instances: 200,
      previous_instances: 300,
    },
    {
      key: 4,
      category: "Social Engineering",
      current_instances: 100,
      previous_instances: 80,
    },
    {
      key: 5,
      category: "Information Leakage",
      current_instances: 400,
      previous_instances: 500,
    },
    {
      key: 6,
      category: "Network Security",
      current_instances: 150,
      previous_instances: 200,
    },
    {
      key: 7,
      category: "Security Misconfigurations",
      current_instances: 100,
      previous_instances: 90,
    },
    {
      key: 8,
      category: "Social Engineering",
      current_instances: 200,
      previous_instances: 300,
    },
  ];
  const ExecutiveSummaryData = [
    {
      key: 1,
      asset_type: "Web Applications",
      count: 100,
      describe_text: "Include subdomains and related services",
    },
    {
      key: 2,
      asset_type: "Network Devices",
      count: 250,
      describe_text: "Routers, switches, etc.",
    },
    {
      key: 3,
      asset_type: "APIs",
      count: 298,
      describe_text: "Public and internal APIs discovered",
    },
    {
      key: 4,
      asset_type: "Web Applications",
      count: 155,
      describe_text: "Routers, switches, etc.",
    },
    {
      key: 5,
      asset_type: "Network Devices",
      count: 235,
      describe_text: "Include subdomains and related services",
    },
  ];
  const AssetsDetailsData = [
    {
      key: 1,
      issues: "Domain Expiry",
      notes: "Failure to renew the domain registration leading to expiration",
    },
    {
      key: 2,
      issues: "DNS Issues",
      notes: "Incorrect DNS settings affecting website accessibility",
    },
    {
      key: 3,
      issues: "Domain Squatting",
      notes:
        "Unauthorized registration of similar domain names to exploit brand confusion",
    },
  ];
  const securityPostureColumns = [
    {
      title: "CATEGORY",
      dataIndex: "category",
      key: "category",
    },
    {
      title: "CURRENT INSTANCES",
      dataIndex: "current_instances",
      key: "current_instances",
      render: (text, record) => (
        <span className="column_render_text">
          {record.current_instances < record.previous_instances ? (
            <>
              <span>{text}</span>
              <img src={timeline} alt="timeline" />
            </>
          ) : (
            <>
              <span>{text}</span>
              <img src={timelineRed} alt="timelineRed" />
            </>
          )}
        </span>
      ),
    },

    {
      title: "PREVIOUS INSTANCES",
      dataIndex: "previous_instances",
      key: "previous_instances",
    },
  ];
  const ExecutiveSummaryColumns = [
    {
      title: "ASSET TYPE",
      dataIndex: "asset_type",
      key: "asset_type",
    },
    {
      title: "COUNT",
      dataIndex: "count",
      key: "count",
      render: (text, record) => (
        <span className="column_render_text">
          {record.count < 200 ? (
            <>
              <span>{text}</span>
              <img src={timeline} alt="timeline" />
            </>
          ) : (
            <>
              <span>{text}</span>
              <img src={timelineRed} alt="timelineRed" />
            </>
          )}
        </span>
      ),
    },

    {
      title: "-",
      dataIndex: "describe_text",
      key: "describe_text",
    },
  ];
  const AssetsDetailsColumns = [
    {
      title: "ISSUES",
      dataIndex: "issues",
      key: "issues",
    },

    {
      title: "NOTES",
      dataIndex: "notes",
      key: "notes",
    },
  ];
  const NetworkSecurityData = [
    {
      key: 1,
      risk: "Unsupported/EOL Products in use like IIS 7.5, Nginx 1.20.0, Apache 2.4.10, OpenSSL 1.0.2 etc.",
      recommandation:
        "Implement Best Practices for Secure Product Lifecycle Management",
      severity: "low",
    },
    {
      key: 2,
      risk: "Multiple SSL/TLS misconfigurations, including the use of weak ciphers.",
      recommandation: "Implement best practices to strengthen encryption",
      severity: "critical",
    },
    {
      key: 3,
      risk: "Unsupported/EOL Products in use like IIS 7.5, Nginx 1.20.0, Apache 2.4.10, OpenSSL 1.0.2 etc.",
      recommandation:
        "Implement Best Practices for Secure Product Lifecycle Management",
      severity: "high",
    },
    {
      key: 4,
      risk: "Unsupported/EOL Products in use like IIS 7.5, Nginx 1.20.0, Apache 2.4.10, OpenSSL 1.0.2 etc.",
      recommandation:
        "Implement Best Practices for Secure Product Lifecycle Management",
      severity: "medium",
    },
    {
      key: 5,
      risk: "Multiple SSL/TLS misconfigurations, including the use of weak ciphers.",
      recommandation: "Implement best practices to strengthen encryption",
      severity: "low",
    },
  ];
  const ThreatFOundData = [
    {
      key: 1,
      severity: "low",
      count: 100,
      open: 10,
      category:
        "SQL Injection, Remote Code Execution, Insecure Deserialization",
    },
    {
      key: 2,
      severity: "critical",
      count: 250,
      open: 9,
      category:
        "Misconfigurations, Exposure of Sensitive Data, Cross-Site Scripting",
    },
    {
      key: 3,
      severity: "medium",
      count: 298,
      open: 14,
      category:
        "Cross-Site Scripting, Insecure Deserialization, Misconfigurations",
    },
    {
      key: 4,
      severity: "high",
      count: 155,
      open: 63,
      category:
        "Insecure Deserialization, Minor Bugs, Exposure of Sensitive Data, Cross-Site Scripting",
    },
    {
      key: 5,
      severity: "low",
      count: 235,
      open: 98,
      category:
        "SQL Injection, Cross-Site Scripting, Misconfigurations, Insecure Deserialization, Exposure of Sensitive Data, Remote Code Execution",
    },
  ];
  const exposureTypeData = [
    {
      exposure_type: "Secrets Found",
      count: 34,
      note: "Hardcoded credentials, API keys",
    },
    {
      exposure_type: "Data Leaks Found",
      count: 12,
      note: "Sensitive customer information exposed",
    },
    {
      exposure_type: "Code Leaks Found",
      count: 14,
      note: "Source code repositories, snippets",
    },
  ];
  const domainNearingExpireData = [
    {
      domain: "kompass.hdfcergo.com",
      day_to_expiry: 2,
      registrar: "PDR Ltd. d/b/a PublicDomainRegistry.com",
    },
    {
      domain: "kompass.hdfcergo.com",
      day_to_expiry: 24,
      registrar: "PDR Ltd. d/b/a PublicDomainRegistry.com",
    },
    {
      domain: "kompass.hdfcergo.com",
      day_to_expiry: 30,
      registrar: "PDR Ltd. d/b/a PublicDomainRegistry.com",
    },
    {
      domain: "kompass.hdfcergo.com",
      day_to_expiry: 5,
      registrar: "PDR Ltd. d/b/a PublicDomainRegistry.com",
    },
  ];
  const certificateNearingExpireData = [
    {
      domain: "kompass.hdfcergo.com",
      day_to_expiry: 30,
      card_detail: "",
    },
    {
      domain: "kompass.hdfcergo.com",
      day_to_expiry: 5,
      card_detail: "",
    },
    {
      domain: "kompass.hdfcergo.com",
      day_to_expiry: 30,
      card_detail: "",
    },
    {
      domain: "kompass.hdfcergo.com",
      day_to_expiry: 5,
      card_detail: "",
    },
  ];
  const applicationSecurityData = [
    {
      issue_id: "ASM-AS-001",
      risk: "Lack of Web Application Firewalls (WAF).",
      affected_assets: "pie.hdfcergo.com",
      severity: "critical",
    },
    {
      issue_id: "ASM-AS-002",
      risk: "Applications lacking essential security headers potentially exposing vulnerabilities.",
      affected_assets: "pie.hdfcergo.com",
      severity: "critical",
    },
    {
      issue_id: "ASM-AS-003",
      risk: "Applications/APIs without Rate Limiting",
      affected_assets: "pie.hdfcergo.com  kompass.hdfcergo.com",
      severity: "high",
    },
    {
      issue_id: "ASM-AS-003",
      risk: "Applications lacking essential security headers potentially exposing vulnerabilities.",
      affected_assets: "pie.hdfcergo.com  kompass.hdfcergo.com",
      severity: "medium",
    },
    {
      issue_id: "ASM-AS-002",
      risk: "Applications lacking essential security headers potentially exposing vulnerabilities.",
      affected_assets: "pie.hdfcergo.com",
      severity: "high",
    },
  ];
  const securityMisConfigurationData = [
    {
      risk: "Default credentials present on the Camunda admin portal, posing a high-security risk.",
      affected_domain: "pie.hdfcergo.com",
      recommandation:
        "Update access controls. Eliminate the use of default credentials.",
      severity: "low",
    },
    {
      risk: "Default credentials present on the Camunda admin portal, posing a high-security risk.",
      affected_domain: "pie.hdfcergo.com",
      recommandation:
        "Update access controls. Eliminate the use of default credentials.",
      severity: "medium",
    },
    {
      risk: "Default credentials present on the Camunda admin portal, posing a high-security risk.",
      affected_domain: "pie.hdfcergo.com",
      recommandation:
        "Update access controls. Eliminate the use of default credentials.",
      severity: "critical",
    },
    {
      risk: "Default credentials present on the Camunda admin portal, posing a high-security risk.",
      affected_domain: "pie.hdfcergo.com",
      recommandation:
        "Update access controls. Eliminate the use of default credentials.",
      severity: "low",
    },
    {
      risk: "Default credentials present on the Camunda admin portal, posing a high-security risk.",
      affected_domain: "pie.hdfcergo.com",
      recommandation:
        "Update access controls. Eliminate the use of default credentials.",
      severity: "high",
    },
  ];
  //   -----------Table columns---------
  const columns = [
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Threat Type",
      dataIndex: "threat_type",
      key: "threat_type",
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Severity",
      dataIndex: "severity",
      key: "severity",

      render: (text, record) => {
        let buttonColor = "";

        switch (record.severity.toLowerCase()) {
          case "high":
            buttonColor = "#F50000";
            break;
          case "medium":
            buttonColor = "#FE9706";
            break;
          case "critical":
            buttonColor = "#F50000";
            break;
          case "low":
            buttonColor = "#02B583";
            break;
          default:
            buttonColor = "#000";
            break;
        }

        return (
          <Button
            className="table_btn_severity"
            style={{
              borderColor: buttonColor,
              color: buttonColor,
            }}
          >
            {record.severity}
          </Button>
        );
      },
    },

    {
      title: "Identification Date",
      dataIndex: "identification_date",
      key: "identification_date",
    },
  ];
  const NetworkSecuritycolumns = [
    {
      title: "RISK",
      dataIndex: "risk",
      key: "risk",
    },
    {
      title: "RECOMMANDATION",
      dataIndex: "recommandation",
      key: "recommandation",
    },

    {
      title: "Severity",
      dataIndex: "severity",
      key: "severity",
      render: (text, record) => {
        let buttonColor = "";
        switch (record.severity.toLowerCase()) {
          case "high":
            buttonColor = "#F50000";
            break;
          case "medium":
            buttonColor = "#FE9706";
            break;
          case "critical":
            buttonColor = "#F50000";
            break;
          case "low":
            buttonColor = "#02B583";
            break;
          default:
            buttonColor = "#000";
            break;
        }

        return (
          <Button
            className="table_btn_severity"
            style={{
              borderColor: buttonColor,
              color: buttonColor,
              borderRadius: 16,
            }}
          >
            {record.severity}
          </Button>
        );
      },
    },
  ];
  const ThreatFoundcolumns = [
    {
      title: "SEVERITY",
      dataIndex: "severity",
      key: "severity",
      render: (text, record) => {
        let buttonColor = "";
        switch (record.severity.toLowerCase()) {
          case "high":
            buttonColor = "#F50000";
            break;
          case "medium":
            buttonColor = "#FE9706";
            break;
          case "critical":
            buttonColor = "#F50000";
            break;
          case "low":
            buttonColor = "#02B583";
            break;
          default:
            buttonColor = "#000";
            break;
        }

        return (
          <Button
            className="table_btn_severity"
            style={{
              borderColor: buttonColor,
              color: buttonColor,
              borderRadius: 16,
            }}
          >
            {record.severity}
          </Button>
        );
      },
    },
    {
      title: "COUNT",
      dataIndex: "count",
      key: "count",
    },
    {
      title: "OPEN",
      dataIndex: "open",
      key: "open",
    },
    {
      title: "CATEGORY",
      dataIndex: "category",
      key: "category",
    },
  ];
  const exposureTypeColumns = [
    {
      title: "EXPOSURE TYPE",
      dataIndex: "exposure_type",
      key: "exposure_type",
    },
    {
      title: "COUNT",
      dataIndex: "count",
      key: "count",
    },
    {
      title: "NOTE",
      dataIndex: "note",
      key: "note",
    },
  ];
  const domainNearingExpireColumn = [
    { title: "Domain", dataIndex: "domain", key: "domain", width: "30%" },
    {
      title: "DAY TO EXPIRY",
      dataIndex: "day_to_expiry",
      key: "day_to_expiry",
      render: (text, record) => (
        <span
          style={{
            borderRadius: 6,
            padding: "2px 6px",
            background: record.day_to_expiry < 10 ? "#F14336" : "#FE9706",
            color: "#fff",
          }}
        >
          {text} days
        </span>
      ),
    },
    { title: "REGISTRAR", dataIndex: "registrar", key: "registrar" },
  ];
  const CetificateNearingExpireColumn = [
    { title: "Domain", dataIndex: "domain", key: "domain", width: "30%" },
    {
      title: "DAY TO EXPIRY",
      dataIndex: "day_to_expiry",
      key: "day_to_expiry",
      render: (text, record) => (
        <span
          style={{
            borderRadius: 6,
            padding: "2px 6px",
            background: record.day_to_expiry < 10 ? "#F14336" : "#FE9706",
            color: "#fff",
          }}
        >
          {text} days
        </span>
      ),
    },
    {
      title: "CARD DETAIL",
      dataIndex: "card_detail",
      key: "card_detail",

      render: (text, record) => (
        <span>{record.card_detail ? record.card_detail : "NA"}</span>
      ),
    },
  ];
  const ApplicationSecurityColumn = [
    {
      title: "ISSUE ID",
      dataIndex: "issue_id",
      key: "issue_id",
      width: 200,
    },
    {
      title: "RISK",
      dataIndex: "risk",
      key: "risk",
    },
    {
      title: "AFFECTED ASSETS(S)",
      dataIndex: "affected_assets",
      key: "affected_assets",
    },
    {
      title: "SEVERITY",
      dataIndex: "severity",
      key: "severity",
      render: (text, record) => {
        let buttonColor = "";
        switch (record.severity.toLowerCase()) {
          case "high":
            buttonColor = "#F50000";
            break;
          case "medium":
            buttonColor = "#FE9706";
            break;
          case "critical":
            buttonColor = "#F50000";
            break;
          case "low":
            buttonColor = "#02B583";
            break;
          default:
            buttonColor = "#000";
            break;
        }

        return (
          <Button
            className="table_btn_severity"
            style={{
              borderColor: buttonColor,
              color: buttonColor,
              borderRadius: 16,
            }}
          >
            {record.severity}
          </Button>
        );
      },
    },
  ];
  const SecurityMisConfigurationColumn = [
    {
      title: "RISK",
      dataIndex: "risk",
      key: "risk",
    },
    {
      title: "AFFECTED DOMAIN/SUBDOMAIN",
      dataIndex: "affected_domain",
      key: "affected_domain",
      width: "30%",
    },
    {
      title: "RECOMMANDATION",
      dataIndex: "recommandation",
      key: "recommandation",
    },
    {
      title: "SEVERITY",
      dataIndex: "severity",
      key: "severity",
      render: (text, record) => {
        let buttonColor = "";
        switch (record.severity.toLowerCase()) {
          case "high":
            buttonColor = "#F50000";
            break;
          case "medium":
            buttonColor = "#FE9706";
            break;
          case "critical":
            buttonColor = "#F50000";
            break;
          case "low":
            buttonColor = "#02B583";
            break;
          default:
            buttonColor = "#000";
            break;
        }

        return (
          <Button
            className="table_btn_severity"
            style={{
              borderColor: buttonColor,
              color: buttonColor,
              borderRadius: 16,
            }}
          >
            {record.severity}
          </Button>
        );
      },
    },
  ];
  return (
    <div className="pdf_content">
      <section className="banner_page">
        <div className="header_logo">
          <img src={X_biz_logo} alt="" srcset="" />
        </div>
        <div className="content_box">
          <img src={secureNexus} alt="" />
          <p className="content_box_text">
            Organization-wide
            <br />
            Domain Report (ASM REPORT)
          </p>
          <div className="report_details">
            <div className="flex">
              <div className="prepared_by_label">Prepared By</div>
              <div className="prepared_by_name">Sunil Yadav</div>
            </div>
            <div className="flex">
              <div className="report_label">REPORT ID</div>
              <div className="report_id">SN-ADR-123723</div>
            </div>
            <div className="flex">
              <div className="date_label">Date</div>
              <div className="date_val">16th February 2024</div>
            </div>
          </div>
        </div>
      </section>
      <section className="attact_surface_report">
        <div className="surface_attack_hero_sec">
          <div className="heading">Attack Surface Monitoring Report</div>
          <div className="wrapper_img_table">
            <div className="left_head_img">
              <img src={Graphics} alt="" srcset="" />
            </div>
            <div className="right_head_table">
              <table className="table mb-0">
                <tr className="header_row">
                  <td className="header_title" colspan="2">
                    Organisation Details
                  </td>
                </tr>
                <tr>
                  <td className="td td_label">Company Name</td>
                  <td className="td">HDFC Ergo General Insurance</td>
                </tr>
                <tr>
                  <td className="td td_label">location</td>
                  <td className="td">Mumbai, Maharashtra, India</td>
                </tr>
                <tr>
                  <td className="td td_label">Primary Domain</td>
                  <td className="td">hdfcergo.com</td>
                </tr>
                <tr>
                  <td className="td td_label">Industry</td>
                  <td className="td">General Insurance</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <div className="surface_attact_content_wrapper">
          <p className="page_head">Overall Risk Score</p>
          <div className="chart_gouge">
            <Row justify={"center"} className="mb-3">
              <Col xxl={8}>
                <GougeCloudRishkChart
                  width={500}
                  height={300}
                  iRs={160}
                  oRs={200}
                />
                <div className="d-flex justify-content-center gap-3">
                  <div className="d-flex align-items-center gap-2">
                    <span
                      className="dot"
                      style={{
                        width: 12,
                        height: 12,
                        borderRadius: "50%",
                        background: "red",
                      }}
                    ></span>
                    <span>HIgh</span>
                  </div>
                  <div className="d-flex align-items-center gap-2">
                    <span
                      style={{
                        width: 12,
                        height: 12,
                        borderRadius: "50%",
                        background: "#FE9706",
                      }}
                      className="dot"
                    ></span>
                    <span>Medium</span>
                  </div>
                  <div className="d-flex align-items-center gap-2">
                    <span
                      //   style={{ background: "#02B583" }}
                      style={{
                        width: 12,
                        height: 12,
                        borderRadius: "50%",
                        background: "#02B583",
                      }}
                      className="dot"
                    ></span>
                    <span>Low</span>
                  </div>
                </div>
              </Col>
            </Row>
            <p>
              This approach note aims to cover the aspects that need to be
              relooked to give an enhanced claims experience. In addition to the
              subject matter expertise of a claims assessor, which only enhances
              with passing time, the other aspects of the process including
              interventions to engage a claimant, technology to improve
              efficiencies as well as detect frauds and the use of data and
              analytics, too play a critical role in this journey.
            </p>
            <ul>
              <li>
                This approach note aims to cover the aspects that need to be
                relooked to give an enhanced claims experience.
              </li>
              <li>
                In addition to the subject matter expertise of a claims
                assessor, which only enhances with passing time, the other
                aspects.
              </li>
              <li>
                The process including interventions to engage a claimant,
                technology to improve efficiencies as well as detect frauds and
                the use of data and analytics, too play a critical role in this
                journey.
              </li>
            </ul>
          </div>
        </div>
      </section>
      <section className="recent_discover_issue_section">
        <div className="section_common_header">
          <div className="x_biz_logo">
            <img style={{ width: "100%" }} src={X_biz_logo} alt="xbiz" />
          </div>
          <div className="nexus_logo">
            <img style={{ width: "100%" }} src={secureNexusblack} alt="nexus" />
          </div>
        </div>
        <div className="dicover_issue_content">
          <div className="header">
            <p className="head_text">
              Recent Discovered Issues (top 10 entries)
            </p>
            <span className="head_des">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat.
            </span>
          </div>
          <Table
            className="summary_chart"
            columns={columns}
            dataSource={data}
            style={{ width: "100%" }}
            scroll={{ x: 600 }}
            pagination={false}
            size="small"
          />
        </div>
      </section>
      <section className="security_posture_section">
        <div className="section_common_header">
          <div className="x_biz_logo">
            <img style={{ width: "100%" }} src={X_biz_logo} alt="xbiz" />
          </div>
          <div className="nexus_logo">
            <img style={{ width: "100%" }} src={secureNexusblack} alt="nexus" />
          </div>
        </div>
        <div className="security_posture_content">
          <div className="header">
            <p className="head_text">Security Posture Across Key Domains</p>
            <span className="head_des">
              This approach note aims to cover the aspects that need to be
              relooked to give an enhanced claims experience. In addition to the
              subject matter expertise of a claims assessor, which only enhances
              with passing time, the other aspects of the process including
              interventions to engage a claimant, technology to improve
              efficiencies as well as detect frauds and the use of data and
              analytics, too play a critical role in this journey.
            </span>
          </div>
          <div className="security_posture_chart">
            <Card className="d-flex justify-content-center">
              <ViewSummaryChart />
            </Card>
          </div>
          <div className="threat_text">
            <p className="threat_head">Threat Category List</p>
            <span className="threat_head_desc">
              This approach note aims to cover the aspects that need to be
              relooked to give an enhanced claims experience. In addition to the
              subject matter expertise of a claims assessor, which only enhances
              with passing time, the other aspects of the process including
              interventions to engage a claimant, technology to improve
              efficiencies as well as detect frauds and the use of data and
              analytics, too play a critical role in this journey.
            </span>
          </div>
          <div className="table_threats">
            <Table
              className="summary_chart"
              columns={securityPostureColumns}
              dataSource={securityPostureData}
              style={{ width: "100%" }}
              scroll={{ x: 600 }}
              pagination={false}
              size="small"
            />
          </div>
        </div>
      </section>
      <section className="executive_summary_section">
        <div className="section_common_header">
          <div className="x_biz_logo">
            <img style={{ width: "100%" }} src={X_biz_logo} alt="xbiz" />
          </div>
          <div className="nexus_logo">
            <img style={{ width: "100%" }} src={secureNexusblack} alt="nexus" />
          </div>
        </div>
        <div className="executive_summary_content">
          <div className="header">
            <p className="head_text">Executive Summary</p>
            <span className="head_des">
              This ASM report reveals critical and high-severity vulnerabilities
              across SSL/TLS configurations, security misconfigurations, network
              security, DNS health, and social engineering vectors. Immediate
              attention to SSL/TLS and security misconfigurations is
              recommended.
            </span>
          </div>
          <div className="assets_discover_charts">
            <div className="chart_head_wrap">
              <div className="chart_head">Assets Discovered (last 30 days)</div>
              <div className="chart_description">
                This approach note aims to cover the aspects that need to be
                relooked to give an enhanced claims experience.
              </div>
            </div>
            <div style={{ width: 600, margin: "auto" }} className="map_chart">
              <div className="asset_distribution_map">
                <ComposableMap>
                  <Geographies geography="/features.json">
                    {({ geographies }) =>
                      geographies.map((geo) => (
                        <Geography
                          fill="#cacaf3"
                          key={geo.rsmKey}
                          geography={geo}
                        />
                      ))
                    }
                  </Geographies>
                  {markers.map(({ name, coordinates, markerOffset }) => (
                    <Marker key={name} coordinates={coordinates}>
                      <circle
                        r={10}
                        fill="#F00"
                        stroke="#fff"
                        strokeWidth={2}
                      />
                      <text
                        textAnchor="middle"
                        y={markerOffset}
                        style={{ fontFamily: "system-ui", fill: "#5D5A6D" }}
                      >
                        {name}
                      </text>
                    </Marker>
                  ))}
                </ComposableMap>
              </div>
            </div>
            <div className="asset_type_table">
              <Table
                className="summary_chart"
                columns={ExecutiveSummaryColumns}
                dataSource={ExecutiveSummaryData}
                style={{ width: "100%" }}
                scroll={{ x: 600 }}
                pagination={false}
                size="small"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="network_security_section">
        <div className="section_common_header">
          <div className="x_biz_logo">
            <img style={{ width: "100%" }} src={X_biz_logo} alt="xbiz" />
          </div>
          <div className="nexus_logo">
            <img style={{ width: "100%" }} src={secureNexusblack} alt="nexus" />
          </div>
        </div>
        <div className="network_security_content">
          <div className="head_wrap">
            <div className="heading">Network Security</div>
            <div className="head_sec_wrap">
              <p className="sub_head">Risk:</p>
              <span className="sub_head_desc">
                Networks are the backbone of modern organizations, connecting
                critical systems and facilitating essential data flow. However,
                unsecured networks present a significant attack surface, leaving
                organizations vulnerable to unauthorized access, data breaches,
                and disruptions. These vulnerabilities can stem from weak
                perimeter defenses, unpatched devices, insecure protocols, or
                misconfigured network settings.
              </span>
            </div>
          </div>
          <div className="impact_content">
            <div className="header_impact">Impact:</div>
            <span className="impact_des">
              Network security breaches can have a devastating impact on
              organizations, including:
            </span>
            <ul>
              <li>
                <b>Data Breaches:</b> Sensitive information like financial data,
                customer records, or intellectual property can be stolen,
                leading to financial losses, regulatory fines, and reputational
                damage.
              </li>
              <li>
                <b>Malware Infections:</b> Malicious actors can exploit network
                vulnerabilities to gain access to systems and deploy malware,
                disrupting operations, stealing data, or launching further
                attacks.
              </li>
              <li>
                <b>Denial-of-Service Attacks:</b> Attackers can overwhelm
                network resources with malicious traffic, causing service
                outages and financial losses.
              </li>
              <li>
                <b>Operational Disruptions:</b> Network breaches can disrupt
                essential services and operations, impacting productivity,
                revenue, and customer satisfaction.
              </li>
            </ul>
          </div>
          <div className="recommandation">
            <div className="recommandation_header">
              <img src={feedbackIcon} alt="feedback_icon" />
              <div className="heading">Recommendations</div>
            </div>
            <ul>
              <li>
                Financial Loss: Theft of credentials, account details, or trade
                secrets can lead to significant financial losses for individuals
                and organizations.
              </li>
              <li>
                Data Breaches: Social engineering can be used to gain access to
                sensitive data, compromising individual privacy and
                organizational integrity.
              </li>
              <li>
                Operational Disruption: Malicious actors can manipulate
                individuals to disrupt critical systems, causing downtime,
                productivity loss, and reputational damage.
              </li>
            </ul>
          </div>
          <div className="network_security_table">
            <Table
              columns={NetworkSecuritycolumns}
              dataSource={NetworkSecurityData}
              style={{ width: "100%" }}
              scroll={{ x: 600 }}
              pagination={false}
              size="small"
            />
          </div>
        </div>
      </section>
      <section className="threat_found_section">
        <div className="section_common_header">
          <div className="x_biz_logo">
            <img style={{ width: "100%" }} src={X_biz_logo} alt="xbiz" />
          </div>
          <div className="nexus_logo">
            <img style={{ width: "100%" }} src={secureNexusblack} alt="nexus" />
          </div>
        </div>
        <div className="threat_fount_content">
          <div className="header_content_wrap">
            <h3 className="heading">Threats Found (last 30 days)</h3>
            <p className="heading_para">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat.
            </p>
          </div>
          <div className="thread_found_chart">
            <ThreatFoundChartPDF width={600} height={400} />
          </div>
          <div className="thread_found_table">
            <Table
              columns={ThreatFoundcolumns}
              dataSource={ThreatFOundData}
              style={{ width: "100%" }}
              scroll={{ x: 600 }}
              pagination={false}
              size="small"
            />
          </div>
        </div>
      </section>
      <section className="information_exposure_section">
        <div className="section_common_header">
          <div className="x_biz_logo">
            <img style={{ width: "100%" }} src={X_biz_logo} alt="xbiz" />
          </div>
          <div className="nexus_logo">
            <img style={{ width: "100%" }} src={secureNexusblack} alt="nexus" />
          </div>
        </div>
        <div className="information_exposure_content">
          <div className="header_content_wrap">
            <h3 className="heading">Information Exposures (last 30 days)</h3>
            <p className="heading_para">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat.
            </p>
          </div>
          <div className="info_exposure_chart">
            <InformationExposureChartPDF isShowNotation={true} />
          </div>
          <div className="exposure_type_table">
            <Table
              columns={exposureTypeColumns}
              dataSource={exposureTypeData}
              style={{ width: "100%" }}
              scroll={{ x: 600 }}
              pagination={false}
              size="small"
            />
          </div>
          <div className="domain_nearing_expiry_table">
            <h2 className="domain_nearing_expiry_heading">
              Domains nearing expiry
            </h2>
            <Table
              columns={domainNearingExpireColumn}
              dataSource={domainNearingExpireData}
              style={{ width: "100%" }}
              scroll={{ x: 600 }}
              pagination={false}
              size="small"
            />
          </div>
          <div className="certificate_nearing_expiry_table">
            <h2 className="certificate_nearing_expirys_heading">
              Certificates nearing expiry
            </h2>
            <Table
              columns={CetificateNearingExpireColumn}
              dataSource={certificateNearingExpireData}
              style={{ width: "100%" }}
              scroll={{ x: 600 }}
              pagination={false}
              size="small"
            />
          </div>
        </div>
      </section>
      <section className="security_rating_section">
        <div className="section_common_header">
          <div className="x_biz_logo">
            <img style={{ width: "100%" }} src={X_biz_logo} alt="xbiz" />
          </div>
          <div className="nexus_logo">
            <img style={{ width: "100%" }} src={secureNexusblack} alt="nexus" />
          </div>
        </div>
        <div className="security_rating_content">
          <div className="header_content_wrap">
            <h3 className="heading">Security Ratings: Monthly Trend</h3>
            <p className="heading_para">
              This approach note aims to cover the aspects that need to be
              relooked to give an enhanced claims experience. In addition to the
              subject matter expertise of a claims assessor, which only enhances
              with passing time, the other aspects of the process including
              interventions to engage a claimant, technology to improve
              efficiencies as well as detect frauds and the use of data and
              analytics, too play a critical role in this journey.
            </p>
          </div>
          <h3 className="chart_heading">
            Vulnerabilities Trend for the year 2023
          </h3>
          <div className="security_rating_chart">
            <TrendSummaryChart width={600} />
          </div>
          <div className="label_indication_div">
            <div className="critical_label">
              3 Critical application security risks identified{" "}
            </div>
            <div className="normal_label">2 of them remediated.</div>
          </div>
        </div>
      </section>
      <section className="summary_section">
        <div className="section_common_header">
          <div className="x_biz_logo">
            <img style={{ width: "100%" }} src={X_biz_logo} alt="xbiz" />
          </div>
          <div className="nexus_logo">
            <img style={{ width: "100%" }} src={secureNexusblack} alt="nexus" />
          </div>
        </div>
        <div className="summary_section_content">
          <div className="header_content_wrap">
            <h3 className="summary_heading">Summary</h3>
            <p className="heading_para">
              This approach note aims to cover the aspects that need to be
              relooked to give an enhanced claims experience. In addition to the
              subject matter expertise of a claims assessor, which only enhances
              with passing time.
            </p>
          </div>
          <div className="application_security_table_wrap">
            <div className="application_sec_table_header_wrap">
              <div className="table_header">Application Security</div>
              <p className="subHeader">
                This approach note aims to cover the aspects that need to be
                relooked to give an enhanced claims experience.
              </p>
              <div className="application_security_table">
                <Table
                  columns={ApplicationSecurityColumn}
                  dataSource={applicationSecurityData}
                  style={{ width: "100%" }}
                  scroll={{ x: 600 }}
                  pagination={false}
                  size="small"
                />
              </div>
            </div>

            <div className="application_sec_table_header_wrap">
              <div className="table_header">Security Misconfigurations</div>
              <p className="subHeader">
                This approach note aims to cover the aspects that need to be
                relooked to give an enhanced claims experience.
              </p>
              <div className="application_security_table">
                <Table
                  columns={SecurityMisConfigurationColumn}
                  dataSource={securityMisConfigurationData}
                  style={{ width: "100%" }}
                  scroll={{ x: 600 }}
                  pagination={false}
                  size="small"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="assets_details">
        <div className="surface_attack_hero_sec">
          <div className="heading">Asset Details</div>
          <div className="wrapper_img_table">
            <div className="left_head_img">
              <img src={assetsGroupImg} alt="assets-group" />
            </div>
            <div className="right_head_table">
              <table className="table mb-0">
                <tr className="header_row">
                  <td className="header_title" colspan="2">
                    pie.hdfcergo.com
                  </td>
                </tr>
                <tr>
                  <td className="td td_label">Domain</td>
                  <td className="td">hdfcergo.com</td>
                </tr>
                <tr>
                  <td className="td td_label">Registrar</td>
                  <td className="td">
                    PDR Ltd. d/b/a PublicDomainRegistry.com
                  </td>
                </tr>
                <tr>
                  <td className="td td_label">Creation Date</td>
                  <td className="td">26-07-2007</td>
                </tr>
                <tr>
                  <td className="td td_label">Expiry Date</td>
                  <td className="td">26-07-2025</td>
                </tr>
                <tr>
                  <td className="td td_label">CNAME</td>
                  <td className="td">foo.securenexus.io</td>
                </tr>
                <tr>
                  <td className="td td_label">Nameserver 1</td>
                  <td className="td">dns1.tatacommunicationsdns.com</td>
                </tr>
                <tr>
                  <td className="td td_label">Nameserver 2</td>
                  <td className="td">dns2.tatacommunicationsdns.com</td>
                </tr>
                <tr>
                  <td className="td td_label">Nameserver 3</td>
                  <td className="td">dns3.tatacommunicationsdns.com</td>
                </tr>
                <tr>
                  <td className="td td_label">Nameserver 4</td>
                  <td className="td">dns4.tatacommunicationsdns.com</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <div className="assets_details_section_content">
          <div className="execution_summary_content">
            <h2 className="header">Executive Summary</h2>
            <div className="content_description">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat.
            </div>
            <div className="progress_domain_health w-100">
              <label className="progre_label">Need Attention !</label>
              <Progress
                strokeWidth={30}
                style={{ width: "100%" }}
                strokeLinecap="rounded"
                strokeColor={"#E10505"}
                percent={10}
              />
              <div className="overall_text_label">Overall Domain Health</div>
            </div>
            <div className="table_content">
              <h3 className="table_header">Critical Issues</h3>
              <p className="table_head_des">
                Regular monitoring, proactive management, and swift resolution
                of these critical issues are essential to maintaining a healthy
                and secure domain environment.
              </p>
            </div>
            <div className="asset_type_table">
              <Table
                className="summary_chart"
                columns={AssetsDetailsColumns}
                dataSource={AssetsDetailsData}
                style={{ width: "100%" }}
                scroll={{ x: 600 }}
                pagination={false}
                size="small"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="domain_health_section">
        <div className="section_common_header">
          <div className="x_biz_logo">
            <img style={{ width: "100%" }} src={X_biz_logo} alt="xbiz" />
          </div>
          <div className="nexus_logo">
            <img style={{ width: "100%" }} src={secureNexusblack} alt="nexus" />
          </div>
        </div>
        <div className="domain_health_content">
          <div className="header_content_wrap">
            <h1 className="heading">Domain Health</h1>
            <p className="heading_para">
              This approach note aims to cover the aspects that need to be
              relooked to give an enhanced claims experience. In addition to the
              subject matter expertise of a claims assessor, which only enhances
              with passing time, the other aspects of the process including
              interventions to engage a claimant, technology to improve
              efficiencies as well as detect frauds and the use of data and
              analytics, too play a critical role in this journey.
            </p>
          </div>
          <div className="card_type_wrapper d-flex gap-2">
            <Card
              className="dns_progration"
              title="DNS Propagation"
              extra={
                <a size="large" className="domain_health_card_btn">
                  Consistent
                </a>
              }
              style={{
                width: 372,
              }}
            >
              <p className="card_body_text">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.{" "}
              </p>
            </Card>
            <Card
              className="blaklisted_status"
              title="Blacklisted Status"
              extra={
                <a
                  style={{
                    background: "#E10505",
                    boxShadow: "0px 4px 12px 0px #E105054D",
                  }}
                  size="large"
                  className="domain_health_card_btn"
                >
                  Consistent
                </a>
              }
              style={{
                width: 372,
              }}
            >
              <div className="d-flex justify-content-between ">
                <div className="label_text">IP Address</div>
                <div className="label_value">68.82.40.124</div>
              </div>
              <div className="d-flex justify-content-between ">
                <div className="label_text">Scan for</div>
                <div className="label_value">http://example.om</div>
              </div>
              <div className="d-flex justify-content-between ">
                <div className="label_text">IP Address</div>
                <div className="label_value">68.82.40.124</div>
              </div>
            </Card>
            <Card
              size="small"
              className="dns_record"
              title="DNS Records"
              style={{
                width: 372,
              }}
            >
              <div className="d-flex justify-content-between border-bottom">
                <div className="label_text">A Records</div>
                <div className="label_value">Tech Support</div>
              </div>
              <div className="d-flex justify-content-between border-bottom">
                <div className="label_text">MX Records</div>
                <div className="label_value">xbiz@gmail.com</div>
              </div>
              <div className="d-flex justify-content-between border-bottom">
                <div className="label_text">TXT Records</div>
                <div className="label_value">SPF, DKIM</div>
              </div>
              <div className="d-flex justify-content-between">
                <div className="label_text">Other</div>
                <div className="label_value">CNAME</div>
              </div>
            </Card>
          </div>
        </div>
      </section>
    </div>
  );
};

export default PdfDocumnet;
