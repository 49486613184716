// Header.js
import React, { useState } from "react";
import "./Header.css";
import { Dropdown, Input, Layout, Switch, Modal, Select } from "antd";
import {
  AppstoreOutlined,
  BellOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import SettingsIcon from "@mui/icons-material/Settings";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import LogoutIcon from "@mui/icons-material/Logout";
import NotificationModal from "../notification/NotificationModal";
import DashboardOptionModal from "../dashboardOptionModal/DashboardOptionModal";
import supportAgent from "../../images/icons/support_agent.png"

const { Header } = Layout;

const HeaderComponent = () => {
  //--------------------profile modal start ---------------------------------//
  const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const showProfileModal = () => {
    setIsProfileModalOpen(!isProfileModalOpen);
  };
  // const handleProfileOk = () => {
  //   setIsProfileModalOpen(false);
  // };
  const handleProfileCancel = () => {
    setIsProfileModalOpen(false);
  };

  // profile modal start
  const ProfileModal = () => {
    return (
      <Modal
        mask={false}
        className="profile_modal"
        open={isProfileModalOpen}
        closable={false}
        footer={null}
        // onOk={handleProfileOk}
        onCancel={handleProfileCancel}
      >
        <div className="profile_card_content">
          <div className="user_info position-relative">
            <EditOutlinedIcon
              style={{ position: "absolute", top: 10, right: 10 }}
            />
            <div className="user_name">Admin_user</div>
            <p className="user_role">Admin</p>
            <p className="login_time">Last Login : 01/04/2024 05:08:50 </p>
          </div>
          <div className="change_password d-flex align-items-center gap-2">
            <div
              className="lock_icon"
              style={{ background: "#E6EEFD", padding: 4, borderRadius: 8 }}
            >
              <LockOutlinedIcon style={{ color: "#63637D" }} />
            </div>
            <div className="text_change_pass common_style">Change Password</div>
          </div>
          <div className="settings border-bottom">
            <div className="setting_wrap">
              <div
                className="settings_icon"
                style={{ background: "#E6EEFD", padding: 4, borderRadius: 8 }}
              >
                <SettingsIcon style={{ color: "#63637D" }} />
              </div>
              <div className="text_setting common_style">Settings</div>
            </div>
            <div className="select_dashboard">
              <label className="select_dash_label">
                Select Defoult dashboard
              </label>
              <Select
                size="large"
                defaultValue="Admin Panel"
                style={{
                  width: "100%",
                }}
              >
                <Select.Option value="Admin Panel">Admin Panel</Select.Option>
                <Select.Option value="Vulnerability Management">
                  Vulnerability Management
                </Select.Option>
                <Select.Option value="Security & Compliance">
                  Security & Compliance
                </Select.Option>
                <Select.Option value="Cloud Security Posture Management">
                  Cloud Security Posture Management
                </Select.Option>
                <Select.Option value="Template Builder">
                  Template Builder
                </Select.Option>
                <Select.Option value="Cyber Insurance">
                  Cyber Insurance
                </Select.Option>
                <Select.Option value="Exception Management">
                  Exception Management
                </Select.Option>
                <Select.Option value="Third Party Risk Management">
                  Third Party Risk Management
                </Select.Option>
              </Select>
            </div>
          </div>
          <div className="help d-flex align-items-center gap-2">
            <div
              className="help_icon"
              style={{ background: "#E6EEFD", padding: 4, borderRadius: 8 }}
            >
              <HelpOutlineIcon style={{ color: "#63637D" }} />
            </div>
            <div className="text_change_pass common_style">Help</div>
          </div>
          <div className="log_out d-flex align-items-center gap-2">
            <a href="/login" className="text-decoration-none">
              <button className="logout_btn">
                <LogoutIcon style={{ color: "#63637D" }} />
                <span className="common_style">Logout</span>
              </button>
            </a>
          </div>
        </div>
      </Modal>
    );
  };
  // profile modal end

  //--------------------notification modal ---------------------------------//
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);
  const showNotificationModal = () => {
    setIsNotificationOpen(!isNotificationOpen);
  };
  const handleNotifiCancel = () => {
    setIsNotificationOpen(false);
  };
  //--------------------notification modal end ---------------------------------//

  // -------dashboard options modal start--------
  const [openDashboardOptions, setOpenDashboardOptions] = useState(false);
  const showDashboardOption = () => {
    setOpenDashboardOptions(true);
  };
  const onCloseDashboardOption = () => {
    setOpenDashboardOptions(false);
  };
  // -------dashboard options modal end--------
  return (
    <Header
      className="xbitz_header"
      style={{ background: "#fff", padding: "0 20px", height: 60 }}
    >
      <div className="header_wrapper">
        <div className="dashboard_name_title" >SECURITY & COMPLIANCE</div>
        <div className="header_right_panel">
          <div className="search_input">
          <Input
            style={{ borderRadius: 10,height: '30px' }}
            prefix={<SearchOutlined />}
            width={200}
            placeholder="Search here"
          />
          </div>
          <div className="position-relative header_icom_wrap">
            <AppstoreOutlined
              onClick={showDashboardOption}
              style={{ fontSize: 24, color: "#63637D" }}
            />
            
          </div>
          <div className="position-relative header_icom_wrap">
            <BellOutlined
              onClick={showNotificationModal}
              style={{ fontSize: 24, color: "#63637D" }}
            />
            <NotificationModal
              showNotificationModal={showNotificationModal}
              isNotificationOpen={isNotificationOpen}
              handleNotifiCancel={handleNotifiCancel}
              className="notification_modal"
              // shown={showModal}
              // close={() => setShowModal(false)}
            />
          </div>
          <div className="support_agent header_icom_wrap" style={{width: 36, height: 36}}>
            <img src={supportAgent} alt="agent" />
           </div>
         
          <div onClick={showProfileModal} className="profile_img_div">
            <img className="profile_img" src="./account_circle.png" />
          </div>
        </div>
      </div>
      <ProfileModal />
      <DashboardOptionModal
              className="dashboard_option_modal"
              openDashboardOptions={openDashboardOptions}
              onCloseDashboardOption={onCloseDashboardOption}
            />
    </Header>
  );
};

export default HeaderComponent;
