import React from "react";
import { ResponsiveContainer, PieChart, Pie, Cell } from "recharts";

const GougeCloudRishkChart = ({
  fillPercentage = 60,
  startRange = 0,
  endRange = 300,
  width,
  height,
  iRs,
  oRs,
}) => {
  const RADIAN = Math.PI / 180;
  const data = [
    { name: "A", value: fillPercentage, color: "#02b583" },
    { name: "B", value: fillPercentage, color: "#7febd3" },
    { name: "B", value: fillPercentage, color: "#45ad50" },
    { name: "B", value: fillPercentage, color: "#d8e670" },
    { name: "B", value: fillPercentage, color: "#f2e22c" },
    { name: "G", value: 100 - fillPercentage, color: "#F1F1F1" },
    { name: "G", value: 100 - fillPercentage, color: "#F1F1F1" },
    { name: "G", value: 100 - fillPercentage, color: "#F1F1F1" },
    { name: "G", value: 100 - fillPercentage, color: "#F1F1F1" },
    { name: "G", value: 100 - fillPercentage, color: "#F1F1F1" },
    { name: "G", value: 100 - fillPercentage, color: "#F1F1F1" },
    { name: "G", value: 100 - fillPercentage, color: "#F1F1F1" },
  ];

  const cx = "50%";
  const cy = "80%";
  const iR = iRs ? iRs : 75;
  const oR = oRs ? oRs : 90;
  const value = 50;

  return (
    <ResponsiveContainer
      className="ehsan_responsive_container m-auto px-3"
      width={width ? width : 180}
      height={height ? height : 150}
    >
      <PieChart width={width ? width : 180} height={height ? height : 150}>
        <Pie
          dataKey="value"
          startAngle={180}
          endAngle={0}
          data={data}
          cx={cx}
          cy={cy}
          innerRadius={iR}
          outerRadius={oR}
          paddingAngle={5}
          fill="url(#colorGradient)"
          stroke="none"
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.color} />
          ))}
        </Pie>
        <svg>
          <defs>
            <linearGradient
              id="colorGradient"
              x1="0%"
              y1="0%"
              x2="100%"
              y2="0%"
            >
              <stop
                offset="10%"
                style={{ stopColor: "#02b583", stopOpacity: 1 }}
              />
              <stop
                offset="20%"
                style={{ stopColor: "#71cbbe", stopOpacity: 1 }}
              />
            </linearGradient>
          </defs>
        </svg>

        <text x="0%" y="90%" textAnchor="start" fontSize="16" fill="#D9D9D9">
          {startRange}
        </text>
        <text x="100%" y="90%" textAnchor="end" fontSize="16" fill="#D9D9D9">
          {endRange}
        </text>

        <text
          x="50%"
          y="65%"
          textAnchor="middle"
          alignmentBaseline="middle"
          fontSize="50"
          fontWeight={600}
          style={{lineHeight: '65.37px'}}
          fill="#fe9706"
        >
          {`${data[0].value}`}
        </text>
        <text
          x="50%"
          y="90%"
          textAnchor="middle"
          alignmentBaseline="middle"
          fontSize="12"
          fontWeight={600}
          fill="#fe9706"
          lineHeight="16.34px"
        >
          {"POINTS"}
        </text>
        {/* {needle(value, data, cx, cy, iR, oR, "#d0d000")} */}
      </PieChart>
    </ResponsiveContainer>
  );
};

export default GougeCloudRishkChart;
