import React, { startTransition, useState } from "react";
import "./SCA_dashboard.css";
import { AccountTree, WarningOutlined } from "@mui/icons-material";
import DonutChart from "../../../../components/charts/MainChart";
import AntDoughChart from "../../../../components/charts/AntDoughChart";
import arming from "../../../../images/icons/arming_countdown.png";
import amend from "../../../../images/icons/amend.png";
import assured from "../../../../images/icons/assured_workload.png";
import Tab from "./components/Tabs";
import {
  LogoutOutlined,
  FullscreenExitOutlined,
  QuestionCircleOutlined,
  ArrowDownOutlined,
  ClockCircleOutlined,
  DeleteOutlined,
  ExpandOutlined,
  EyeOutlined,
  FormOutlined,
  LineChartOutlined,
  MoreOutlined,
  RiseOutlined,
  SnippetsOutlined,
  PieChartOutlined,
  BugOutlined,
  CloudOutlined,
} from "@ant-design/icons";
import antIcons from "@ant-design/icons";
import accountTree from '../../../../images/icons/account_tree.png'
import arming2 from '../../../../images/icons/arming_countdown2.png'
import workLoad from '../../../../images/icons/assured_workload2.png'
import CardGraph from "./components/CardGraph";
import DemoGouge from "../../../../components/charts/DemoGouge";
import { Card, Col, Dropdown, Flex, Row, Select, Space } from "antd";
import ScaPieChart from "../../../../components/charts/SCA_PieChart";
import ScaBarGhraph from "../../../../components/charts/SCA_BarGraph";
import GougeThirdPartyVendorChart from "../../../../components/charts/GougeThirdPartyVendorChart";
import GougeSCAChart from "../../../../components/charts/GougeSCAChart";
import GougeInfrastrChart from "../../../../components/charts/GougeInfrastrChart";
import AreaChartDomain from "../../../../components/charts/AreaChartDomain";
import AreaChartSubDomain from "../../../../components/charts/AreaChartSubDomain";
import AreaChartIPS from "../../../../components/charts/AreaChartIPS";
import AreaChartCertificate from "../../../../components/charts/AreaChartCertificate";
import AreaChartSecret from "../../../../components/charts/AreaChartSecret";
import AreaThirdParty from "../../../../components/charts/AreaThirdParty";
  import SummaryChart from "../../../../components/charts/SummaryChart";
import PolicySummary from "../../../../components/charts/PolicySummary";
import ProjectTable from "../../../../components/charts/ProjectTable.js";
import TrendSummary from "./TrendSummary";
import ViewSummary from "./ViewSummary";
import { useHistory } from "react-router-dom";
import TopIssuesCard from "./components/TopIssuesCard.js";
import CommonDashboardHeader from "../../../../components/dashboard/CommonDashboardHeader.js";

const SCADashboard = () => {
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [openViewSummary, setOpenViewSummary] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const showDrawerSummary = () => {
    setOpenViewSummary(true);
  };
  const closeSummaryView = () => {
    setOpenViewSummary(false);
  };
  const onClose = () => {
    setOpen(false);
  };

  const items = [
    {
      label: (
        <a
          onClick={showDrawer}
          className="view_trend_summary"
          href="#"
        >
          <EyeOutlined />
          View Trend Summary
        </a>
      ),
      key: "0",
    },
    {
      label: (
        <a
          className="view_details"
          href="#"
        >
          <FormOutlined />
          View Details
        </a>
      ),
      key: "1",
    },
  ];
  const [activeTabs, setActiveTabs] = useState("all_details");
  const callback = (activekey) => {
    setActiveTabs(activekey);
    console.log("active-key ==", activekey);
  };
  const barDataSCA = [
    {
      name: "Python",
      uv: 4000,
      pv: 2400,
      amt: 2400,
      colors: '#0F548C',
    },
    {
      name: "Javascript",
      uv: 3000,
      pv: 1398,
      amt: 2210,
      colors: '#96C6FA',
    },
    {
      name: "Java",
      uv: 2000,
      pv: 8,
      // amt: 2290,
      colors: '#0F6CBD',
    },
    {
      name: "Pascal",
      uv: 2780,
      pv: 3908,
      amt: 2000,
      colors: '#1A8CF0',
    },
    {
      name: "Basic",
      uv: 18,
      pv: 4800,
      amt: 2181,
      colors: '#093357',
    },
    {
      name: "Kotlin",
      uv: 2390,
      pv: 3800,
      amt: 2500,
      colors: '#1290FF',
    },
    {
      name: "PHP",
      uv: 3490,
      pv: 4300,
      amt: 2100,
      colors: '#3E6587',
    },
  ];
  let color = "#1A8CF0";
  const tabsMenu = [
    {
      key: "all_details",
      label: "Approved/Un- approved Packages",
      children: <ScaPieChart />,
    },
    {
      key: "documnet",
      label: "Language wise Packages",
      children: (
        <ScaBarGhraph
          width={450}
          height={245}
          data={barDataSCA}
          color={color}
        />
      ),
    },
  ];

  let cardData = [
    {
      title: "Vulnerabilities",
      detail: 1.2,
      percentage: 28,
      color: "red",
      icon: <img
        src={arming}
        alt="Icon"
        style={{
          width: "16px",
          height: "16px",
          position: "relative",
          top: "-2px",
          left: "-2px",
        }}
      />,
      graphData: [
        { name: "Page A", uv: 1220, pv: 1600, amt: 1600 },
        { name: "Page B", uv: 1100, pv: 1398, amt: 2210 },
        { name: "Page C", uv: 1100, pv: 3000, amt: 2290 },
        { name: "Page D", uv: 1100, pv: 2008, amt: 2000 },
        { name: "Page E", uv: 1300, pv: 2000, amt: 2181 },
        { name: "Page F", uv: 1000, pv: 3000, amt: 2500 },
        { name: "Page G", uv: 1020, pv: 2000, amt: 2100 },
        { name: "Page H", uv: 1000, pv: 2000, amt: 2100 },
        { name: "Page I", uv: 1050, pv: 2000, amt: 2100 },
        { name: "Page I", uv: 1150, pv: 2000, amt: 2100 },
      ],
      startColor:'#FFBCB4',
      stopColor:'#FF6653',
      stroke: "#E10505",
    },
    {
      title: "End of life",
      detail: 572,
      percentage: 8,
      color: "green",
      stroke: "#02B583",
      startColor:'#FFFFFF',
      stopColor:'#57CCA7',
      icon: <img
        src={amend}
        alt="Icon"
        style={{
          width: "16px",
          height: "16px",
          position: "relative",
          top: "-2px",
          left: "-2px",
        }}
      />,
      graphData: [
        { name: "Page A", uv: 1220, pv: 1600, amt: 1600 },
        { name: "Page B", uv: 1900, pv: 9398, amt: 22 },
        { name: "Page C", uv: 1100, pv: 3000, amt: 2290 },
        { name: "Page D", uv: 1100, pv: 2008, amt: 2000 },
        { name: "Page E", uv: 130, pv: 2000, amt: 2181 },
        { name: "Page F", uv: 1000, pv: 300, amt: 2500 },
        { name: "Page G", uv: 1020, pv: 2000, amt: 200 },
        { name: "Page H", uv: 1000, pv: 2000, amt: 2100 },
        { name: "Page I", uv: 1050, pv: 2000, amt: 2100 },
        { name: "Page I", uv: 1150, pv: 2000, amt: 2100 },
      ],
    },
    {
      title: "License Violations",
      detail: 98,
      percentage: 8,
      stroke: "#02B583",
      startColor:'#FFFFFF',
      stopColor:'#57CCA7',
      icon: <img
        src={assured}
        alt="Icon"
        style={{
          width: "16px",
          height: "16px",
          position: "relative",
          top: "-2px",
          left: "-2px",
        }}
      />,
      graphData: [
        { name: "Page A", uv: 1220, pv: 1600, amt: 1600 },
        { name: "Page B", uv: 1100, pv: 1398, amt: 2210 },
        { name: "Page C", uv: 1100, pv: 3000, amt: 2290 },
        { name: "Page D", uv: 1100, pv: 2008, amt: 2000 },
        { name: "Page E", uv: 1300, pv: 2000, amt: 2181 },
        { name: "Page F", uv: 1000, pv: 3000, amt: 2500 },
        { name: "Page G", uv: 1020, pv: 2000, amt: 2100 },
        { name: "Page H", uv: 1000, pv: 2000, amt: 2100 },
        { name: "Page I", uv: 1050, pv: 2000, amt: 2100 },
        { name: "Page I", uv: 1150, pv: 2000, amt: 2100 },
      ],
    },
    {
      title: "Malicious Components",
      detail: 1,
      percentage: 38,
      stroke: "#E10505",
      startColor:'#FFBCB4',
      stopColor:'#FF6653',
      icon: <img
      src={arming}
      alt="Icon"
      style={{
        width: "16px",
        height: "16px",
        position: "relative",
        top: "-2px",
        left: "-2px",
      }}
    />,
      graphData: [
        { name: "Page A", uv: 1220, pv: 1600, amt: 1600 },
        { name: "Page B", uv: 1100, pv: 1398, amt: 2210 },
        { name: "Page C", uv: 110, pv: 3000, amt: 2290 },
        { name: "Page D", uv: 1100, pv: 2008, amt: 2000 },
        { name: "Page E", uv: 1300, pv: 2000, amt: 2181 },
        { name: "Page F", uv: 1000, pv: 3000, amt: 2500 },
        { name: "Page G", uv: 120, pv: 2000, amt: 100 },
        { name: "Page H", uv: 1000, pv: 2000, amt: 2100 },
        { name: "Page I", uv: 1050, pv: 200, amt: 2100 },
        { name: "Page I", uv: 1150, pv: 2000, amt: 2100 },
      ],
    },
  ];
  const iconStyle = {  position:'relative',top:'7px' ,fontSize: "18px", marginRight: "8px",height:'14px' };
  const topIssuesCardData = [
    {
      color: "#FE970614",
      // icon: <LogoutOutlined style={{ ...iconStyle, color: "#FE9706" }} />,
      icon: <img src={accountTree} alt="Icon" style={{ ...iconStyle, color: "#FE9706" }} />,
      title: "'newtonsoft.json'",
      content: "Policy Violation (newtonsoft.json@12.0.3)",
      discription:
        "First detected 17 days ago, most recently seen 2 hours ago in dotnet-api",
    },
    {
      color: "#E105050F",
      icon: (
        <img src={arming2} alt="Icon" style={{ ...iconStyle, height:'17px' }} />
      ),
      title: "CVE-2024-21907",
      discription:
        "First detected 17 days ago, most recently seen 2 hours ago in dotnet-api",
      content: "Vulnerability (newtonsoft.json@12.0.3)",
    },
    {
      color: '#02B5830F',
      icon: (
        <img src={workLoad} alt="Icon" style={{ ...iconStyle,height:'17px' }} />
      ),
      title: "'at least 3 releases a year'",
      discription:
        "First detected 17 days ago, most recently seen 2 hours ago in dotnet-api",
      content: "Policy Violation (newtonsoft.json@12.0.3)",
    },
    {
      color: "#E105050F",
      icon: <img src={accountTree} alt="Icon" style={{ ...iconStyle, color: "#FE9706" }} />,
      title: "CVE-2024-21907",
      discription:
        "First detected 17 days ago, most recently seen 2 hours ago in dotnet-api",
      content: "Vulnerability (newtonsoft.json@12.0.3)",
    },
    {
      color: "#FE970614",
      icon: <img src={arming2} alt="Icon" style={{...iconStyle, height:'17px'}} />,
      title: "'at least 3 releases a year'",
      discription:
        "First detected 17 days ago, most recently seen 2 hours ago in dotnet-api",
      content: "Policy Violation (newtonsoft.json@12.0.3)",
    },
  ];
  const viewMoreBtn = () => {
    startTransition(() => {
      console.log("viewMoreBtn--->", history);
      history.push("/sca-project-list");
    })
  };
  return (
    <>
      <div className="sca-dashboard">
        <div className="dashboard_head_des_parent d-flex justify-content-between">
          {/* <div className="welcome-to-tata-container  ">
            <div className="title_with_wrap_des">
              <div>
                <span className="welcome-to">Welcome to </span>
                <b className="tata-group">Tata Group</b>
                <span className="welcome-to"> SCA Dashboard</span>
              </div>
              <div className="stay_ahead_of">
                stay ahead of emerging threats through real-time monitoring
              </div>
            </div>
            <div className="showing_sync_ver_as_on_parent">
              <div className="showing_sync_ver">
                <i> Showing Sync ver. as on:</i>
                <i className="th_jan_2024">5th Jan 2024</i>
                <ClockCircleOutlined style={{ fontSize: 16, color: "red" }} />
              </div>
            </div>
          </div> */}
          <CommonDashboardHeader
            name="Tata Group"
            subName='SCA'
            desc="stay ahead of emerging threats through real-time monitoring"
          />

          <div className="scan_history">
            <div className="company_select">
              <Select
                defaultValue="Tata motors"
                style={{
                  width: "100%",
                }}
              >
                <Select.Option
                  selected
                  value="Tata motors"
                >
                  Tata motors
                </Select.Option>
                <Select.Option value="Tata steel ltd">
                  Tata steel ltd
                </Select.Option>
                <Select.Option value="Tata capital">Tata capital</Select.Option>
              </Select>
            </div>
            <div className="insight_report">
              <EyeOutlined style={{ fontSize: 14, color: "#5a81fa" }} />
              insight report
            </div>
          </div>
        </div>
       <Row
          gutter={[16, 16]}
          justify="center"
        >
          <Col
            xs={24}
            sm={8}
            md={8}
            lg={8}
          >
            <Row
              gutter={[10, 10, { xs: 8, sm: 8, md: 8, lg: 8 }]}
              style={{height: '100%'}}
            >
              {cardData.map((item, index) =>{ 
                const uniqueIndex = index
                console.log('index--->',index);
              return(
                <Col
                  key={index}
                  xs={24}
                  sm={12}
                  md={12}
                  lg={12}
                >
                  <CardGraph
                    data={item}
                    key={uniqueIndex}
                    style={{width: '100%'}}
                  />
                </Col>
              )})}
            </Row>
          </Col>
          <Col
            xs={24}
            sm={8}
            md={8}
            lg={8}
          >
            {/* <Card style={{ padding: 16,width:'100%' }}> */}
              <div className="discovered_assets package_representation h-100" >
                <div className="header_label commo_chat_header d-flex align-items-center gap-2">
                  <span> Package Representation</span>
                </div>
                <Tab
                  callback={callback}
                  menu={tabsMenu}
                />
              </div>
            {/* </Card> */}
          </Col>
          <Col
            xs={24}
            sm={8}
            md={8}
            lg={8}
          >
            {/* <Card style={{ padding: 15 }}> */}
              <div className="discovered_assets" style={{background:'#fff',padding:'7px 12px 12px 12px',borderRadius:'10px',gap:'12px'}}>
                <div className="header_title d-flex justify-content-between">
                  <div className="header_label commo_chat_header d-flex align-items-center gap-2">
                    <span>Top Issues</span>
                    <span className="discovered_count">10</span>
                  </div>
                </div>
                {/* <div className="list"> */}
                  <TopIssuesCard topIssuesCardData={topIssuesCardData} />
                {/* </div> */}
              </div>
            {/* </Card> */}
          </Col>
        </Row>
        <Row gutter={[8, { xs: 16, sm: 16, md: 8, lg: 8 }]}>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={24}
          >
            <Card style={{ padding: 16 }}>
              <Flex
                className="d-flex"
                justify={"space-between"}
              >
                <div className="threat_summary_title ">Project List</div>
                <div
                  style={{
                    fontSize: "13px",
                    fontWeight:600,
                    textDecoration: "underline",
                    color: "#5A81FA",
                    cursor:'pointer'
                  }}
                  onClick={() => viewMoreBtn()}
                >
                  View More
                </div>
              </Flex>
              <ProjectTable />
            </Card>
          </Col>
        </Row>

        {/* <Row gutter={[8, { xs: 16, sm: 16, md: 8, lg: 8 }]}>
          <Col xs={24} sm={24} md={24} lg={24}> */}
        {/* <SummaryChart /> */}
        <PolicySummary />
        {/* </Col>
        </Row> */}
      </div>
      {/* <TrendSummary open={open} onClose={onClose} /> */}
      {/* <ViewSummary open={openViewSummary} onClose={closeSummaryView} /> */}
    </>
  );
};

export default SCADashboard;
