import { CheckCircleFilled, CheckSquareFilled, DeleteOutlined, MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { Checkbox, Col, Collapse, Form, Input, Row, Select, Switch } from "antd";
import React, { useState } from "react";
import "./AssessmentPreview.css";
import complianceEnfoIcon from "../../../../images/otherIcons/complianceEnforcement.png";

const {Option} = Select
const AssessmentPreview = () => {
  const customExpandIcon = (panelProps) => {
    const { isActive } = panelProps;
    return isActive ? (
      <MinusOutlined style={{ fontSize: 14 }} />
    ) : (
      <PlusOutlined style={{ fontSize: 14 }} />
    );
  };

  const [checkedBtn, setCheckedBtn] = useState({
    compliance_enforcement: false,
  });

  const handleBtnClick = (btnName) => {
    setCheckedBtn((prevState) => ({
      ...prevState,
      [btnName]: !prevState[btnName],
    }));
  };

  const optionsSelect = [
    { id: 1, label: "AWS Codebuild", value: "AWS Codebuild" },
    { id: 2, label: "Bitbucket", value: "Bitbucket" },
    { id: 2, label: "CircleCI", value: "CircleCI" },
  ];

  const [checked, setChecked] = useState(true);
  const onChange = (e) => {
    setChecked(e.target.checked);
    console.log(`checked = ${e.target.checked}`);
  };

  const CollapsePanelHeader = (keyId, deletefun) => {
    return (
      <div className="d-flex justify-content-between">
        <div className="left_header_item">Rules {keyId}</div>
        <div
          onClick={(e) => {
            e.stopPropagation();
            // deletefun(keyId);
          }}
          className="right_header_item d-flex align-items-center gap-2"
        >
          <Switch
            size="small"
            defaultChecked
            onChange={(check, e) => {
              // Prevent Collapse panel from closing
              console.log("switch check collapse--->", check);
              e.stopPropagation();
            }}
          />
        </div>
      </div>
    );
  };

  return (
    <div className="onboarding_preview">
      <p className="heading_form_1 mb-0 text-center">Preview Assessment Summary</p>
      <Collapse
        bordered={false}
        borderRadius={0}
        expandIcon={customExpandIcon}
        expandIconPosition="end"
      >
        <Collapse.Panel
          style={{ background: "#DCDDE7", marginBottom: 10 }}
          header="Identification"
          key="1"
        >
            <Row gutter={[16,16]} align={'middle'} className="d-flex justify-content-between">
                <Col sm={24} md={12} lg={12}>
              <div className="policy_type_label">Vendor Name</div>
              </Col>
              <Col sm={24} md={12} lg={12}>
              <div className="selected_checkbox">
               Vendor 1
              </div>
              </Col>
              <Col sm={24} md={12} lg={12}>
              <div className="policy_name">Assessment Name</div>
              </Col>
              <Col sm={24} md={12} lg={12}>
              <div className="policy_name">Assessment</div>
              </Col>
              
            </Row>
        </Collapse.Panel>
        <Collapse.Panel
          style={{ background: "#DCDDE7", marginBottom: 10 }}
          header="Assessment Definition"
          key="2"
        >
            {Array(7).fill().map((_,i)=>(
          <div className="assessment_definition">
          {/* <CheckSquareFilled style={{color: '#9494AE',fontSize: 16}} /> <span className="selected_policy_definition_text">OBSD {i}</span> */}
          <Checkbox className="form_checkbox" checked={checked} onChange={onChange}>Form {i}</Checkbox>
          </div>
          ))}
        </Collapse.Panel>
        <Collapse.Panel
          style={{ background: "#DCDDE7", marginBottom: 10 }}
          header="Digital Assessment"
          key="3"
        >
          <Form layout="vertical" >
          <div className="form_wrapper">
            <div className="row_label">
              Organization Details
            </div>
            <Row gutter={[16, 16]} className="m-0">
              <Col xs={24} sm={24} md={12} lg={12}>
                <Form.Item
                  style={{ marginBottom: "0px" }}
                  name="Include Organization"
                  label={
                    <span className="custom_label_class">
                      Include Organization
                    </span>
                  }
                  rules={[
                    {
                      required: true,
                      message: "Please enter Include Organization",
                    },
                  ]}
                >
                  <Input size="large" placeholder="Enter" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12}>
                <Form.Item
                  style={{ marginBottom: "0px" }}
                  name="Exclude Organization"
                  label={
                    <span className="custom_label_class">
                      Exclude Organization
                    </span>
                  }
                  rules={[
                    {
                      required: true,
                      message: "Please enter Exclude Organization",
                    },
                  ]}
                >
                  <Input size="large" placeholder="Enter" />
                </Form.Item>
              </Col>
            </Row>

              <div className="row_label">Domain Details</div>
              <Row gutter={[16, 16]} className="m-0">
                <Col xs={24} sm={24} md={12} lg={12}>
                  <Form.Item
                    style={{ marginBottom: "0px" }}
                    name="Include Domains*"
                    label={
                      <span className="custom_label_class">
                        Include Domains*
                      </span>
                    }
                    rules={[
                      {
                        required: true,
                        message: "Please enter Include Domains*",
                      },
                    ]}
                  >
                    <Input size="large" placeholder="Enter" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                  <Form.Item
                    style={{ marginBottom: "0px" }}
                    name="Exclude Domains"
                    label={
                      <span className="custom_label_class">
                        Exclude Domains
                      </span>
                    }
                    rules={[
                      {
                        required: true,
                        message: "Please enter Exclude Domains",
                      },
                    ]}
                  >
                    <Input size="large" placeholder="Enter" />
                  </Form.Item>
                </Col>
              </Row>
              <div className="row_label">Subdomains</div>
              <Row gutter={[16, 16]} className="m-0">
                <Col xs={24} sm={24} md={12} lg={12}>
                  <Form.Item
                    style={{ marginBottom: "0px" }}
                    name="Include subdomains"
                    label={
                      <span className="custom_label_class">
                        Include subdomains
                      </span>
                    }
                    rules={[
                      {
                        required: true,
                        message: "Please enter Include subdomains",
                      },
                    ]}
                  >
                    <Input size="large" placeholder="Enter" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                  <Form.Item
                    style={{ marginBottom: "0px" }}
                    name="Exclude Subdomains"
                    label={
                      <span className="custom_label_class">
                        Exclude Subdomains
                      </span>
                    }
                    rules={[
                      {
                        required: true,
                        message: "Please enter Exclude Subdomains",
                      },
                    ]}
                  >
                    <Input size="large" placeholder="Enter" />
                  </Form.Item>
                </Col>
              </Row>
              <div className="row_label">Hosts</div>
              <Row gutter={[16, 16]} className="m-0">
                <Col xs={24} sm={24} md={12} lg={12}>
                  <Form.Item
                    style={{ marginBottom: "0px" }}
                    name="Include Hosts"
                    label={
                      <span className="custom_label_class">
                        Include Hosts
                      </span>
                    }
                    rules={[
                      {
                        required: true,
                        message: "Please enter Include Hosts",
                      },
                    ]}
                  >
                    <Input size="large" placeholder="Enter" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                  <Form.Item
                    style={{ marginBottom: "0px" }}
                    name="Exclude Hosts"
                    label={
                      <span className="custom_label_class">
                        Exclude Hosts
                      </span>
                    }
                    rules={[
                      {
                        required: true,
                        message: "Please enter Exclude Hosts",
                      },
                    ]}
                  >
                    <Input size="large" placeholder="Enter" />
                  </Form.Item>
                </Col>
              </Row>
              <div className="row_label">IP Address</div>
              <Row gutter={[16, 16]} className="m-0">
                <Col xs={24} sm={24} md={12} lg={12}>
                  <Form.Item
                    style={{ marginBottom: "0px" }}
                    name="Include IP Address"
                    label={
                      <span className="custom_label_class">
                        Include IP Address
                      </span>
                    }
                    rules={[
                      {
                        required: true,
                        message: "Please enter Include IP Address",
                      },
                    ]}
                  >
                    <Input size="large" placeholder="Enter" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                  <Form.Item
                    style={{ marginBottom: "0px" }}
                    name="Exclude IP Address"
                    label={
                      <span className="custom_label_class">
                        Exclude IP Address
                      </span>
                    }
                    rules={[
                      {
                        required: true,
                        message: "Please enter Exclude IP Address",
                      },
                    ]}
                  >
                    <Input size="large" placeholder="Enter" />
                  </Form.Item>
                </Col>
              </Row>
              <div className="row_label">ASNs</div>
              <Row gutter={[16, 16]} className="m-0">
                <Col xs={24} sm={24} md={12} lg={12}>
                  <Form.Item
                    style={{ marginBottom: "0px" }}
                    name="Include ASNs"
                    label={
                      <span className="custom_label_class">
                        Include ASNs
                      </span>
                    }
                    rules={[
                      {
                        required: true,
                        message: "Please enter Include ASNs",
                      },
                    ]}
                  >
                    <Input size="large" placeholder="Enter" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                  <Form.Item
                    style={{ marginBottom: "0px" }}
                    name="Exclude ASNs"
                    label={
                      <span className="custom_label_class">
                        Exclude ASNs
                      </span>
                    }
                    rules={[
                      {
                        required: true,
                        message: "Please enter Exclude ASNs",
                      },
                    ]}
                  >
                    <Input size="large" placeholder="Enter" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                  <Form.Item
                    style={{ marginBottom: "0px" }}
                    name="Include SSL TLS"
                    label={
                      <span className="custom_label_class">
                        Include SSL TLS
                      </span>
                    }
                    rules={[
                      {
                        required: true,
                        message: "Please enter Include SSL TLS",
                      },
                    ]}
                  >
                    <Input size="large" placeholder="Enter" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                  <Form.Item
                    style={{ marginBottom: "0px" }}
                    name="Exclude SSL TLS"
                    label={
                      <span className="custom_label_class">
                        Exclude SSL TLS
                      </span>
                    }
                    rules={[
                      {
                        required: true,
                        message: "Please enter Exclude SSL TLS",
                      },
                    ]}
                  >
                    <Input size="large" placeholder="Enter" />
                  </Form.Item>
                </Col>
              </Row>
          </div>
        </Form>
        </Collapse.Panel>
        <Collapse.Panel
          style={{ background: "#DCDDE7", marginBottom: 10 }}
          header={CollapsePanelHeader("1")}
          key="4"
        >
         <div className="panel_content">
              <Form layout="vertical">
                <Form.Item
                  style={{ marginBottom: "12px" }}
                  name="rule_type"
                  label={<span className="custom_label_class">Rule Type</span>}
                  rules={[{ required: false }]}
                >
                  <Select size="large" placeholder="Select Rule Type">
                    {Array(4)
                      .fill()
                      .map((_, i) => (
                        <Option key={i} value={`Rule ${i + 1}`}>
                          {`Rule ${i + 1}`}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  style={{ marginBottom: "12px" }}
                  name="description"
                  label={
                    <span className="custom_label_class">Description</span>
                  }
                  rules={[{ required: false }]}
                >
                  <Input.TextArea
                    placeholder="write description here..."
                    showCount
                    maxLength={150}
                  />
                </Form.Item>
                <Form.Item
                  style={{ marginBottom: "12px" }}
                  name="app"
                  label={
                    <div className="custom_label_class">
                      App
                      <span className="text-danger">
                        (min one app is required)
                      </span>
                    </div>
                  }
                  rules={[{ required: false }]}
                >
                  <Select
                    size="large"
                    mode="multiple"
                    style={{
                      width: "100%",
                      fontSize: 16,
                      height: "40px",
                    }}
                    placeholder="Please select"
                    defaultValue={["AWS Codebuild", "Bitbucket", "CircleCI"]}
                    options={optionsSelect}
                  />
                </Form.Item>
                
              </Form>
            </div>
        </Collapse.Panel>
      </Collapse>
    </div>
  );
};

export default AssessmentPreview;
