import React, { useState } from "react";
import "./dashboard.css";
import DonutChart from "../charts/MainChart";
import AntDoughChart from "../charts/AntDoughChart";
import {
  ArrowDownOutlined,
  CheckCircleFilled,
  ClockCircleOutlined,
  DeleteOutlined,
  ExpandOutlined,
  EyeOutlined,
  FormOutlined,
  MoreOutlined,
  RiseOutlined,
} from "@ant-design/icons";
import DemoGouge from "../charts/DemoGouge";
import {
  Button,
  Card,
  Col,
  Dropdown,
  Radio,
  Row,
  Select,
  Space,
  DatePicker,
} from "antd";
import GougeCloudRishkChart from "../charts/GougeCloudRishkChart";
import GougeThirdPartyVendorChart from "../charts/GougeThirdPartyVendorChart";
import GougeSCAChart from "../charts/GougeSCAChart";
import GougeInfrastrChart from "../charts/GougeInfrastrChart";
import AreaChartDomain from "../charts/AreaChartDomain";
import AreaChartSubDomain from "../charts/AreaChartSubDomain";
import AreaChartIPS from "../charts/AreaChartIPS";
import AreaChartCertificate from "../charts/AreaChartCertificate";
import AreaChartSecret from "../charts/AreaChartSecret";
import AreaThirdParty from "../charts/AreaThirdParty";
import ThreatsAcc from "../charts/ThreatsAcc";
import SummaryChart from "../charts/SummaryChart";
import TrendSummary from "./TrendSummary";
import ViewSummary from "./ViewSummary";
import SmallModal from "../../Pages/Common/small-pop-modal/SmallModal";
import fileIcon from "../../images/otherIcons/fileIcon.svg";
import CommonDashboardHeader from "./CommonDashboardHeader";
import cloudIcon from "../../images/chart-header-icon/cloud.png";
import domainIcon from "../../images/chart-header-icon/domain.png";
import desktopIcon from "../../images/chart-header-icon/screen_search_desktop.png";
import settingIcon from "../../images/chart-header-icon/settings_alert.png";
import OpenInNewOutlinedIcon from "@mui/icons-material/OpenInNewOutlined";
const { RangePicker } = DatePicker;

const Dashboard = () => {
  const [open, setOpen] = useState(false);
  const [openViewSummary, setOpenViewSummary] = useState(false);
  const [reportType, setReportType] = useState("");
  // inside report modal state
  const [openInsiderepModal, setOpenInsideRepModal] = useState(false);
  const [reportDateRange, setReportDateRange] = useState(undefined);
  const [active , setActive]=useState(false)
  const OpenInsideRepoModal = () => {
    setOpenInsideRepModal(true);
  };
  console.log("===", reportType);
  const handleCloseInsideRepModal = () => {
    setOpenInsideRepModal(false);
  };

  const generateHandleReport = () => {
    console.log("rep:", reportType, "date-range:", reportDateRange);
    handleCloseInsideRepModal();
  };
  // inside report modal state end

  const showDrawer = () => {
    setOpen(true);
  };
  const showDrawerSummary = () => {
    setOpenViewSummary(true);
  };
  const closeSummaryView = () => {
    setOpenViewSummary(false);
  };
  const onClose = () => {
    setOpen(false);
  };

  const items = [
    {
      label: (
        <a onClick={showDrawer} className="view_trend_summary" href="#">
          <EyeOutlined />
          View Trend Summary
        </a>
      ),
      key: "0",
    },
    {
      label: (
        <a className="view_details" href="#">
          <FormOutlined />
          View Details
        </a>
      ),
      key: "1",
    },
  ];
  // scan history dropdown menu
  const items1 = [
    {
      label: <a href="#">5th Jan 2024</a>,
      key: "0",
    },
    {
      label: <a href="#">2nd menu item</a>,
      key: "1",
    },
    {
      label: <a href="#">2nd menu item</a>,
      key: "1",
    },
    {
      label: <a href="#">2nd menu item</a>,
      key: "1",
    },
    {
      label: <a href="#">2nd menu item</a>,
      key: "1",
    },
  ];
  return (
    <>
      <div className="dashboard">
        <div className="dashboard_head_des_parent d-flex justify-content-between">
          <CommonDashboardHeader
            name="Tata Group"
            desc="stay ahead of emerging threats through real-time monitoring"
          />
          <div className="scan_history">
            <div className="company_select">
              <Select
                // defaultValue="Tata motors"
                allowClear
                placeholder="Tata Motors"
                style={{
                  width: "100%",
                }}
              >
                <Select.Option value="Tata motors">Tata motors</Select.Option>
                <Select.Option value="Tata steel ltd">
                  Tata steel ltd
                </Select.Option>
                <Select.Option value="Tata capital">Tata capital</Select.Option>
              </Select>
            </div>
            <div onClick={OpenInsideRepoModal} className="insight_report">
              <EyeOutlined style={{ fontSize: 14, color: "#5a81fa" }} />
              insight report
            </div>
          </div>
        </div>
        <Col>
          <Row gutter={[8, { xs: 16, sm: 16, md: 8, lg: 8 }]}>
            <Col lg={8}>
              <Card
                className="main_chart1 pb-2"
                style={{ padding: 12, width: "100%", height: "100%" }}
              >
                <div className="head_wrapper">
                  <div
                    className="commo_chat_header p-0"
                    style={{ fontSize: 14 }}
                  >
                    Over All Risk Rating
                  </div>
                  <div className="over_all_risk_description fsm_text_ellipse">
                    Lower the risk score by addressing what needs to be
                    remediated. How is it calculated?
                  </div>
                </div>
                <DemoGouge />
                <div className="custom_legend_overall_risk_gauge d-flex justify-content-center gap-3">
                  <div className="d-flex align-items-center gap-1">
                    <span className="dot"></span>
                    <span className="dot_text" style={{color: "#63637D", fontSize: 11}}>HIgh</span>
                  </div>
                  <div className="d-flex align-items-center gap-1">
                    <span
                      style={{ background: "#FE9706" }}
                      className="dot"
                    ></span>
                    <span lassName="dot_text" style={{color: "#63637D", fontSize: 11}}>Medium</span>
                  </div>
                  <div className="d-flex align-items-center gap-1">
                    <span
                      style={{ background: "#02B583" }}
                      className="dot"
                    ></span>
                    <span lassName="dot_text" style={{color: "#63637D", fontSize: 11}}>Low</span>
                  </div>
                </div>
              </Card>
            </Col>

            <Col xxl={4} lg={4}>
              <Card className="main_chart2 pb-2 h-100" style={{ padding: 12 }}>
                <div className="commo_chat_header">
                  <div
                    className="header_icon"
                    style={{
                      width: "20px",
                      display: "inline-flex",
                      alignItems: "center",
                    }}
                  >
                    <img className="w-100" src={cloudIcon} alt="cloud" />
                  </div>
                  <span>Cloud Risk</span>
                </div>
                <GougeCloudRishkChart className="border-bottom" />
                <div
                  onClick={showDrawerSummary}
                  className="view_summary"
                >
                  <span>View Summary</span>
                  <OpenInNewOutlinedIcon
                    style={{ color: "#63637D", fontSize: 12 }}
                  />
                </div>
              </Card>
            </Col>

            <Col xxl={4} lg={4}>
              <Card className="main_chart3 pb-2 h-100" style={{ padding: 12 }}>
                <div className="commo_chat_header">
                  <div
                    className="header_icon"
                    style={{ width: "22px", margin: 0 }}
                  >
                    <img className="w-100" src={domainIcon} alt="domain" />
                  </div>
                  <span className="fsm_text_ellipse mt-0">
                    Third Party Vendor Risk
                  </span>
                </div>
                <GougeThirdPartyVendorChart />
                <div className="view_summary">
                  <span>View Summary</span>
                  <OpenInNewOutlinedIcon
                    style={{ color: "#63637D", fontSize: 12 }}
                  />
                </div>
              </Card>
            </Col>

            <Col xxl={4} lg={4}>
              <Card className="main_chart4 pb-2 h-100" style={{ padding: 12 }}>
                <div className="commo_chat_header">
                  <div
                    className="header_icon"
                    style={{
                      width: "20px",
                      display: "inline-flex",
                      alignItems: "center",
                    }}
                  >
                    <img className="w-100" src={desktopIcon} alt="desktop" />
                  </div>
                  <span className="fsm_text_ellipse mt-0">SCA Risk</span>
                </div>
                <GougeSCAChart />
                <div className="view_summary">
                  <span>View Summary</span>
                  <OpenInNewOutlinedIcon
                    style={{ color: "#63637D", fontSize: 12 }}
                  />
                </div>
              </Card>
            </Col>

            <Col xxl={4} lg={4}>
              <Card className="main_chart5 pb-2 h-100" style={{ padding: 12 }}>
                <div className="commo_chat_header">
                  <div
                    className="header_icon"
                    style={{
                      width: "20px",
                      display: "inline-flex",
                      alignItems: "center",
                    }}
                  >
                    <img className="w-100" src={settingIcon} alt="setting" />
                  </div>
                  <span className="fsm_text_ellipse mt-0">
                    Infrastructural Risk
                  </span>
                </div>
                <GougeInfrastrChart />
                <div className="view_summary">
                  <span>View Summary</span>
                  <OpenInNewOutlinedIcon
                    style={{ color: "#63637D", fontSize: 12 }}
                  />
                </div>
              </Card>
            </Col>
          </Row>
        </Col>
        <div className="assets">Assets</div>
        <Row gutter={[8, { xs: 16, sm: 16, md: 8, lg: 8 }]}>
          <Col xxl={4} lg={4}>
            <Card className="" style={{ padding: 0 }}>
              <div className="area_chat_wrapper">
                <div
                  className="commo_chat_header d-flex justify-content-between"
                  style={{ padding: 10 }}
                >
                  <span className="fsm_text_ellipse"> Domain</span>
                  {/* <a href="#"> */}
                  <Dropdown
                    menu={{
                      items,
                    }}
                    placement="bottomRight"
                    arrow={{ pointAtCenter: true }}
                    trigger={["click"]}
                  >
                    <MoreOutlined style={{ color: "#63637D" }} />
                  </Dropdown>
                  {/* </a> */}
                </div>
                <div className="area_chat_content d-flex justify-content-between">
                  <div className="chart_card_wrapper d-flex align-items-center gap-1">
                    <div className="count_content">163</div>
                    <div className="percent_with_label">
                      <div className="d-flex justify-content-end">
                        <ArrowDownOutlined style={{ color: "green" }} />
                        <div className="percentage">8%</div>
                      </div>
                      <div className="month_label">Past Month</div>
                    </div>
                  </div>
                </div>
                <AreaChartDomain />
              </div>
            </Card>
          </Col>
          <Col xxl={4} lg={4}>
            <Card className="" style={{ padding: 0 }}>
              <div className="area_chat_wrapper">
                <div
                  className="commo_chat_header d-flex justify-content-between"
                  style={{ padding: 10 }}
                >
                  <span className="fsm_text_ellipse"> Sub Domain</span>
                  <a href="#">
                    <MoreOutlined style={{ color: "#63637D" }} />
                  </a>
                </div>
                <div className="area_chat_content d-flex gap-2 justify-content-between">
                  <div className="chart_card_wrapper d-flex align-items-center gap-1">
                    <div className="count_content">163</div>
                    <div className="percent_with_label">
                      <div className="d-flex justify-content-end">
                        <ArrowDownOutlined style={{ color: "green" }} />
                        <div className="percentage">8%</div>
                      </div>
                      <div className="month_label">Past Month</div>
                    </div>
                  </div>
                </div>
                <AreaChartSubDomain />
              </div>
            </Card>
          </Col>
          <Col xxl={4} lg={4}>
            <Card className="" style={{ padding: 0 }}>
              <div className="area_chat_wrapper">
                <div
                  className="commo_chat_header d-flex justify-content-between"
                  style={{ padding: 10 }}
                >
                  <span className="fsm_text_ellipse"> IPS</span>
                  <a href="#">
                    <MoreOutlined style={{ color: "#63637D" }} />
                  </a>
                </div>
                <div className="area_chat_content d-flex gap-2 justify-content-between">
                  <div className="chart_card_wrapper d-flex align-items-center gap-1">
                    <div className="count_content">163</div>
                    <div className="percent_with_label">
                      <div className="d-flex justify-content-end">
                        <ArrowDownOutlined style={{ color: "green" }} />
                        <div className="percentage">8%</div>
                      </div>
                      <div className="month_label">Past Month</div>
                    </div>
                  </div>
                </div>
                <AreaChartIPS />
              </div>
            </Card>
          </Col>
          <Col xxl={4} lg={4}>
            <Card className="" style={{ padding: 0 }}>
              <div className="area_chat_wrapper">
                <div
                  className="commo_chat_header d-flex justify-content-between"
                  style={{ padding: 10 }}
                >
                  <span className="fsm_text_ellipse"> Certificates</span>
                  <a href="#">
                    <MoreOutlined style={{ color: "#63637D" }} />
                  </a>
                </div>
                <div className="area_chat_content d-flex gap-2 justify-content-between">
                  <div className="chart_card_wrapper d-flex align-items-center gap-1">
                    <div className="count_content">299</div>
                    <div className="percent_with_label">
                      <div className="d-flex justify-content-end">
                        <ArrowDownOutlined style={{ color: "green" }} />
                        <div className="percentage">8%</div>
                      </div>
                      <div className="month_label">Past Month</div>
                    </div>
                  </div>
                  <div className="instances">
                    <div className="instance_count">155</div>
                    <div className="instance_label">Instances</div>
                  </div>
                </div>
                <AreaChartCertificate />
              </div>
            </Card>
          </Col>
          <Col xxl={4} lg={4}>
            <Card className="" style={{ padding: 0 }}>
              <div className="area_chat_wrapper">
                <div
                  className="commo_chat_header d-flex justify-content-between"
                  style={{ padding: 10 }}
                >
                  <span className="fsm_text_ellipse"> Secrets</span>
                  <a href="#">
                    <MoreOutlined style={{ color: "#63637D" }} />
                  </a>
                </div>
                <div className="area_chat_content d-flex gap-2 justify-content-between">
                  <div className="chart_card_wrapper d-flex align-items-center gap-1">
                    <div className="count_content">163</div>
                    <div className="percent_with_label">
                      <div className="d-flex justify-content-end">
                        <ArrowDownOutlined style={{ color: "green" }} />
                        <div className="percentage">8%</div>
                      </div>
                      <div className="month_label">Past Month</div>
                    </div>
                  </div>
                </div>
                <AreaChartSecret />
              </div>
            </Card>
          </Col>

          <Col xxl={4} lg={4}>
            <Card className="" style={{ padding: 0 }}>
              <div className="area_chat_wrapper">
                <div className="commo_chat_header d-flex justify-content-between" style={{ padding: 10 }}>
                  <span className="fsm_text_ellipse">
                    {" "}
                    Third Party Componets
                  </span>
                  <a href="#">
                    <MoreOutlined style={{ color: "#63637D" }} />
                  </a>
                </div>
                <div className="area_chat_content d-flex gap-2 justify-content-between">
                  <div className="chart_card_wrapper d-flex align-items-center gap-1">
                    <div className="count_content">163</div>
                    <div className="percent_with_label">
                      <div className="d-flex justify-content-end">
                        <ArrowDownOutlined style={{ color: "green" }} />
                        <div className="percentage">8%</div>
                      </div>
                      <div className="month_label">Past Month</div>
                    </div>
                  </div>
                </div>
                <AreaThirdParty />
              </div>
            </Card>
          </Col>
        </Row>
        <Row gutter={[8, { xs: 16, sm: 16, md: 8, lg: 8 }]}>
          <Col xs={24} sm={24} md={24} lg={16}>
            <Card style={{ padding: 16 }}>
              <div className="d-flex flex-column gap-3">
                <div className="assets_header d-flex justify-content-between">
                  <div className="assets_title d-inline-flex gap-2 align-items-center">
                    Asset Distribution <RiseOutlined style={{ color: "red" }} />
                  </div>
                  <ExpandOutlined />
                </div>
                <Row gutter={[8, { xs: 16, sm: 16, md: 8, lg: 8 }]}>
                  {/* <Col xs={24} sm={24} md={24} lg={4}></Col> */}
                  <Col xs={24} sm={24} md={24} lg={24}>
                    <ThreatsAcc />
                  </Col>
                </Row>
              </div>
            </Card>
          </Col>
          <Col xs={24} sm={24} md={24} lg={8}>
            <Card className="h-100" style={{ padding: 16 }}>
              <div className="discovered_assets">
                <div className="header_title d-flex justify-content-between">
                  <div className="header_label commo_chat_header d-flex align-items-center gap-2">
                    <span> Recently Discovered Assets</span>
                    <span className="discovered_count">10</span>
                  </div>
                  <a className="view_more_or_delete">
                    <span className="view"> View More</span>
                  </a>
                </div>
                <div className="list">
                  {Array(4)
                    .fill()
                    .map((el) => (
                      <>
                        <div className="list_card_item">
                          <div className="head">Securenexus.io</div>
                          <Row className="w-100">
                            <Col span={8}>
                              <div className="item">SSL certificate</div>
                              <div className="item_label">Asset Type</div>
                            </Col>
                            <Col span={8}>
                              <div className="item">Certificate</div>
                              <div className="item_label">Category</div>
                            </Col>
                            <Col span={8}>
                              <div className="item">08th Jan 2024</div>
                              <div className="item_label">Discovery date</div>
                            </Col>
                          </Row>
                        </div>
                      </>
                    ))}
                </div>
              </div>
            </Card>
          </Col>
        </Row>

        {/* <Row gutter={[8, { xs: 16, sm: 16, md: 8, lg: 8 }]}>
          <Col xs={24} sm={24} md={24} lg={24}> */}
        <SummaryChart />
        {/* </Col>
        </Row> */}
      </div>
      <TrendSummary open={open} onClose={onClose} />
      <ViewSummary open={openViewSummary} onClose={closeSummaryView} />
      <SmallModal
        width={700}
        open={openInsiderepModal}
        className="inside_report_modal"
        CloseModal={handleCloseInsideRepModal}
        title="Generate Insight Report"
        closable={true}
      >
        <div className="modal_content_wrap d-flex flex-column gap-3">
          <p className="label_header">
            Select the format below to generate the report
          </p>
          <div className="report_type_content" id="report-type">
            <label className="report_type_label" htmlFor="report-type">
              Report Type <span className="required_mark text-danger">*</span>
            </label>
            <div className="d-flex gap-3">
              <Button
                size="large"
                className={`${reportType === "summary" ?  "active_btn d-flex align-items-center justify-content-between" : "d-flex align-items-center justify-content-between"}`}
                onClick={() => setReportType("summary")}
                block
                type="default"
              >
                <span className="d-flex gap-1">
                <img width={30} src={fileIcon} alt="file" />
                <span>summary</span>
                </span>
                {reportType === "summary" && (
                <CheckCircleFilled
                    style={{ fontSize: 18, color: "#5A81FA" }}
                  />
                )}
              </Button>
              <Button
                size="large"
                className={`${reportType === "trendsummary" ?  "active_btn d-flex align-items-center justify-content-between" : "d-flex align-items-center justify-content-between"}`}
                onClick={() => setReportType("trendsummary")}
                block
                type="default"
              >
                <span className="d-flex gap-1">
                <img width={30} src={fileIcon} alt="file" />
                <span>TrendSummary</span>
                </span>
                {reportType === "trendsummary" && (
                <CheckCircleFilled
                    style={{ fontSize: 18, color: "#5A81FA" }}
                  />
                  )}
              </Button>
            </div>
          </div>
          <div className="date_range w-100">
            <label className="report_type_label" htmlFor="report-type">
              Date Range <span className="required_mark text-danger">*</span>
            </label>
            <RangePicker
              onChange={(date) => setReportDateRange(date)}
              style={{ width: "100%" }}
              block
              size="large"
            />
          </div>
          <div className="button_submit_groug d-flex justify-content-end gap-3">
            <Button
              onClick={handleCloseInsideRepModal}
              size="large"
              type="default"
            >
              Cancel
            </Button>
            <Button onClick={generateHandleReport} size="large" type="primary">
              Generate Report
            </Button>
          </div>
        </div>
      </SmallModal>
    </>
  );
};

export default Dashboard;
