import { Card, Flex, Select } from 'antd'
import React from 'react'
import './PopularityTabs.css'
import HeaderNotation from '../../../../Common/grid-header-number-count/HeaderNotation'
import PopularityChart from './PopularityChart.js'

const PopularityTabs = () => {
    const headerNotationData = [
        { id: 1, count: 120, category: "Vulnerabilities",color:'#5A81FA' },
        { id: 2, count: 120, category: "End of Life" ,color:'#FE9706'},
        { id: 3, count: 120, category: "License Violations",color:'#E10505' },
      ];
      const handleChange = (value) => {
        console.log(`selected ${value}`);
      };
  return (
    <>
    <Card className='popularity' >
    <Flex justify='space-between' className='main'>
        <div className='popularity-title' >Popularity</div>
        <div className='popularity-sub-title'>Key Project ecosystem</div>
    </Flex>
    <HeaderNotation data={headerNotationData} popularityStyle='popularityStyle' />
    <Flex justify='space-between' className='secondary'>
        <div className='secondary-title' >Weekly Downloads (1,210,320)</div>
        <div >
        <Select
        className='secondary-sub-title'
            defaultValue="Download Trend"
            onChange={handleChange}
            options={[
              {
                value: "Critical",
                label: "Critical",
              },
              {
                value: "High",
                label: "High",
              },
              {
                value: "Medium",
                label: "Medium",
              },
              {
                value: "low",
                label: "low",
              },
            ]}
          />
        </div>
    </Flex>
    <PopularityChart />
    </Card>
    </>
  )
}

export default PopularityTabs