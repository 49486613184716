import { Card } from "antd";
import React from "react";
import "./CardOption.css";

const dataValue = [
  { id: 1, grade: "A+", scan_url: "Kidsweek.ru" },
  { id: 1, grade: "A", scan_url: "Kidsweek.ru" },
  { id: 1, grade: "B", scan_url: "Kidsweek.ru" },
  { id: 1, grade: "C", scan_url: "Kidsweek.ru" },
  { id: 1, grade: "D", scan_url: "Kidsweek.ru" },
  { id: 1, grade: "E", scan_url: "Kidsweek.ru" },
  { id: 1, grade: "F", scan_url: "Kidsweek.ru" },
  { id: 1, grade: "R", scan_url: "Kidsweek.ru" },
];
const CardOption4 = () => {
  return (
    <div className="card_option_4">
      <Card
        headStyle={{ background: "#ECECF6" }}
        style={{ width: 300 }}
        title="Grand Totals"
      >
        {dataValue.map((item) => (
          <div className="grade_wrapper d-flex justify-content-between border-bottom py-1">
            <div className="scan_url">{item.scan_url}</div>
            <div
              className="grade"
              style={{
                background:
                  item.grade === "A+"
                    ? "#02B583"
                    : item.grade === "A"
                    ? "#02B554"
                    : item.grade === "B"
                    ? "#FBCC53"
                    : item.grade === "C"
                    ? "#FEBB3A"
                    : item.grade === "D"
                    ? "#FEAD3A"
                    : item.grade === "E"
                    ? "#FE9706"
                    : item.grade === "F"
                    ? "#D90000"
                    : item.grade === "R"
                    ? "#9494AE"
                    : "",
              }}
            >
              {item.grade}
            </div>
          </div>
        ))}
      </Card>
    </div>
  );
};

export default CardOption4;
