import React from "react";
import "./DataGridCard.css";
import { Col, Row } from "antd";
import data from "../../../components/dashboard-options/Data"
const DataGridCard = () => {
  const {TableData} = data
  console.log('data-grid==',TableData)
  return (
    <Row gutter={[8, 8]}>
      {TableData
        .map((item, index) => (
          <Col lg={8}>
            <div className="data_grid_card">
              <div className="card_header">
                <div className="header_title">{item.Name ? item.Name :'SSL/TLS'}</div>
                <div className="status">{item.Status ? item.Status : 'Low'}</div>
              </div>
              <div className="title_wrap">
                <div className="title_text">
                  {item["Operating System"]}
                </div>
                <div className="title_label">Title</div>
              </div>
              <div className="summary">
                <div className="summary_text">
                  {item["Security Group Names"]}
                </div>
                <div className="summary_label">Summary</div>
              </div>
              <div className="dateindicarion_wrap d-flex flex-wrap justify-content-between">
                <div className="identificatio_date d-flex flex-column">
                  <span className="identificatio_date_value">{item["Storage Attach Times"]}</span>
                  <span className="identificatio_date_label">
                    Identification Date
                  </span>
                </div>
                <div className="confidence d-flex flex-column">
                  <span className="confidence_value">{item["Instance Type"]}</span>
                  <span className="confidence_label">Confidence</span>
                </div>
                <div className="status d-flex flex-column">
                  <span className="status_value">{item.Status}</span>
                  <span className="status_label">Status</span>
                </div>
              </div>
              <div className="recommandation_div">
                <div className="recommandation_steps">
                  {item.Architecture}
                </div>
                <div className="recommandation_label">Remediation Steps</div>
              </div>
              <div className="tags">
                <div className="tags_value">
                 {item["Tag Values"]}
                </div>
                <div className="tags_label">Tags</div>
              </div>
            </div>
          </Col>
        ))}
    </Row>
  );
};

export default DataGridCard;
