import React from 'react'
import { Card, Flex, Select } from 'antd'
import HeaderNotation from '../../../../Common/grid-header-number-count/HeaderNotation'
import ScaBarGhraph from '../../../../../components/charts/SCA_BarGraph'

const MaintenanceTabs = () => {
    const barDataSCA = [
        {
          name: "Page A",
          uv: 4000,
          pv: 2400,
          amt: 2400,
        },
        {
          name: "Page B",
          uv: 3000,
          pv: 1398,
          amt: 2210,
        },
        {
          name: "Page C",
          uv: 2000,
          pv: 8,
          // amt: 2290,
        },
        {
          name: "Page D",
          uv: 2780,
          pv: 3908,
          amt: 2000,
        },
        {
          name: "Page E",
          uv: 18,
          pv: 4800,
          amt: 2181,
        },
        {
          name: "Page F",
          uv: 2390,
          pv: 3800,
          amt: 2500,
        },
        
      ];
    const headerNotationData = [
        { id: 1, count: 71, category: "Open Issues",color:'#E10505' },
        { id: 2, count: 20, category: "Open PR" ,color:'#FE9706'},
        { id: 3, count: '4 months ago', category: "Last Release",color:'#02B583' },
        { id: 4, count: '2 months ago', category: "Last Commit",color:'#FE9706' },
      ];
      const handleChange = (value) => {
        console.log(`selected ${value}`);
      };
      const  gradientColors= [
        { offset: '14.56%', stopColor: '#E11326' },
        { offset: '44.58%', stopColor: '#FF3344' },
        { offset: '90.5%', stopColor: '#FF7D59' }
      ]
  return (
    <Card className='maintenance' >
    <Flex justify='space-between' className='main'>
        <div className='maintenance-title' >Maintenance</div>
        <div className='maintenance-sub-title'>Healthy</div>
    </Flex>
    <HeaderNotation data={headerNotationData} popularityStyle='popularityStyle' />
    <Flex justify='space-between' className='secondary'>
        <div className='secondary-title' >Commit Frequency</div>
    </Flex>
    <ScaBarGhraph
          width={450}
          height={300}
          data={barDataSCA}
          gradientColors={gradientColors}
          borderRadius={5}
          color={''}
        />
    </Card>
  )
}

export default MaintenanceTabs