import { Flex } from "antd";
import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  LabelList,
  ResponsiveContainer,
  Cell,
} from "recharts";

const ScaBarGraph = (props) => {
  console.log("props.color-->", props);
  const { width, height, data, gradientColors, color } = props;
  const barWidth = 40;
  const barGap = 10;

  return (
    <Flex>
      {gradientColors ? (
        <ResponsiveContainer width="100%" height={height}>
        <BarChart
          width={width}
          height={height}
          barSize={10}
          data={data}
          margin={{
            top: 20,
            right: 20,
            left: -10,
            bottom: 15,
          }}
          // barGap={10}
        >
          {/* <CartesianGrid strokeDasharray="1" /> */}
          <XAxis fontSize={12} dataKey="name" tickLine={false} interval={0} 
           label={{
            value: "POLICIES",
            offset: 0,
            position: "bottom",
            style: { textAnchor: "middle", fontWeight: 500 },
          }}
          />
          <YAxis fontSize={12} tickLine={false} 
          label={{
            value: "Non compliant instance count",
            style: { textAnchor: "middle", fontWeight: 500 },
            angle: -90,
            position: "left",
            offset: -15,
          }}
          />
          <defs>
            <linearGradient id="colorGradient" x1="0" y1="0" x2="0" y2="1">
              {gradientColors?.map((color, index) => (
                <stop
                  key={index}
                  offset={color.offset}
                  stopColor={color.stopColor}
                />
              ))}
            </linearGradient>
          </defs>
          <Bar
            radius={[4,4,0,0]}
            barSize={28}
            dataKey="pv"
            fill={gradientColors ? "url(#colorGradient)" : color}
            minPointSize={5}
            // barSize={barWidth}
            barGap={20}
          ></Bar>
        </BarChart>
        </ResponsiveContainer>
      ) : (
        <ResponsiveContainer width="100%" height={height}>
          <BarChart
            width={width}
            height={height}
            data={data}
            margin={{
              top: 5,
              right: 15,
              left: -10,
              bottom: 5,
            }}
          >
            <CartesianGrid horizontal={true} vertical={false} />
            <XAxis interval={0} fontSize={10} dataKey="name" tickLine={false} />
            <YAxis tickFormatter={(value) => `${value / 1000}k`} fontSize={12} tickLine={false} />
            <defs></defs>
            <Bar
              radius={[8, 8, 0, 0]}
              barSize={28}
              dataKey="pv"
              minPointSize={5}
            >
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={entry.colors} />
              ))}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      )}
    </Flex>
  );
};

export default ScaBarGraph;
