import React from "react";
import DynamicTable from "../SelectComponent/DynamicTable";
import data from "../dashboard-options/Data";

const AssetInventoryList = () => {
  return (
    <>
      <div>AssetInventoryList</div>
      <DynamicTable data={data} />
    </>
  );
};

export default AssetInventoryList;
