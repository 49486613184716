import React, { useState } from "react";
import "./DashboardOption.css";
import { Col, Row } from "antd";
import { useHistory } from "react-router-dom";
import CustomSelect from "../SelectComponent/CustomSelect";
const DashboardOptions = () => {
  const history = useHistory();
  const [defoultDashboard, setDefoultDashboard] = useState("");
  const handleContinue = () => {
    const defDash = localStorage.getItem("defoultDashboard");
    console.log("defDash===", defDash);
    history.push("/dashboard");
  };
  const HandleDefoultDash = (defoultDash) => {
    console.log("defoult-dash====", defoultDash);
    localStorage.setItem("defoultDashboard", JSON.stringify(defoultDash));
    setDefoultDashboard(defoultDash);
  };
  return (
    <div className="dashboard_options">
      <header>
        <div className="logo">
          <img src="./x_biz_logo_blue.png" alt="logo" />
        </div>
        <button className="log_out_btn">Log Out</button>
      </header>

      {/* <CustomSelect data={JsonData} /> */}

      <div className="main_content_body">
        <div className="top_header_content">
          <div className="header_title">Welcome Sanket Patil</div>
          <div className="header_sub_title">
            Please select default application from list below
          </div>
          <div className="header_description">
            You can always change default application from settings in User
            Pannel Menu.
          </div>
        </div>
        <div className="card_option_wrapper">
          <Row
            className="mb-3"
            gutter={[16, { xs: 16, sm: 16, md: 16, lg: 16 }]}
          >
            <Col xs={24} sm={24} md={8} lg={6}>
              <div
                onClick={() => HandleDefoultDash("Admin Panel")}
                className={`${
                  defoultDashboard === "Admin Panel"
                    ? "defoult_dashboard card_option"
                    : "card_option"
                } d-flex justify-content-center align-items-center`}
              >
                <div className="card_content">
                  <div className="icon_parent position-relative">
                    <img src="./admin_panel_icon.png" alt="panel" />
                    <div className="title_name">Admin Panel</div>
                  </div>
                </div>
              </div>
            </Col>
            <Col xs={24} sm={24} md={8} lg={6}>
              <div
                onClick={() => HandleDefoultDash("Vulnerability Management")}
                className={`${
                  defoultDashboard === "Vulnerability Management"
                    ? "defoult_dashboard card_option"
                    : "card_option"
                } d-flex justify-content-center align-items-center`}
              >
                <div className="card_content">
                  <div className="icon_parent position-relative">
                    <img src="./Vulnerability _mgt_icon.png" alt="panel" />
                    <div className="title_name">Vulnerability Management</div>
                  </div>
                </div>
              </div>
            </Col>
            <Col xs={24} sm={24} md={8} lg={6}>
              <div
                onClick={() => HandleDefoultDash("Cyber Insurance")}
                className={`${
                  defoultDashboard === "Cyber Insurance"
                    ? "defoult_dashboard card_option"
                    : "card_option"
                } d-flex justify-content-center align-items-center`}
              >
                <div className="card_content">
                  <div className="icon_parent position-relative">
                    <img src="./Cyber_Insurance_icon.png" alt="panel" />
                    <div className="title_name">Cyber Insurance</div>
                  </div>
                </div>
              </div>
            </Col>
            <Col xs={24} sm={24} md={8} lg={6}>
              <div
                onClick={() => HandleDefoultDash("Security & Compliance")}
                className={`${
                  defoultDashboard === "Security & Compliance"
                    ? "defoult_dashboard card_option"
                    : "card_option"
                } d-flex justify-content-center align-items-center`}
              >
                <div className="card_content">
                  <div className="icon_parent position-relative">
                    <img src="./Security_Compliance_icon.png" alt="panel" />
                    <div className="title_name">Security & Compliance</div>
                  </div>
                </div>
              </div>
            </Col>
            <Col xs={24} sm={24} md={8} lg={6}>
              <div
                onClick={() => HandleDefoultDash("Exception Management")}
                className={`${
                  defoultDashboard === "Exception Management"
                    ? "defoult_dashboard card_option"
                    : "card_option"
                } d-flex justify-content-center align-items-center`}
              >
                <div className="card_content">
                  <div className="icon_parent position-relative">
                    <img src="./Exception_Management_icon.png" alt="panel" />
                    <div className="title_name">Exception Management</div>
                  </div>
                </div>
              </div>
            </Col>
            <Col xs={24} sm={24} md={8} lg={6}>
              <div
                onClick={() => HandleDefoultDash("Template Builder")}
                className={`${
                  defoultDashboard === "Template Builder"
                    ? "defoult_dashboard card_option"
                    : "card_option"
                } d-flex justify-content-center align-items-center`}
              >
                <div className="card_content">
                  <div className="icon_parent position-relative">
                    <img src="./Template_Builder_icon.png" alt="panel" />
                    <div className="title_name">Template Builder</div>
                  </div>
                </div>
              </div>
            </Col>
            <Col xs={24} sm={24} md={8} lg={6}>
              <div
                onClick={() =>
                  HandleDefoultDash(" Cloud Security Posture Management")
                }
                className={`${
                  defoultDashboard === " Cloud Security Posture Management"
                    ? "defoult_dashboard card_option"
                    : "card_option"
                } d-flex justify-content-center align-items-center`}
              >
                <div className="card_content">
                  <div className="icon_parent position-relative">
                    <img src="./Cloud_Security_icon.png" alt="panel" />
                    <div className="title_name">
                      Cloud Security Posture Management
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col xs={24} sm={24} md={8} lg={6}>
              <div
                onClick={() => HandleDefoultDash("Third Party Risk Management")}
                className={`${
                  defoultDashboard === "Third Party Risk Management"
                    ? "defoult_dashboard card_option"
                    : "card_option"
                } d-flex justify-content-center align-items-center`}
              >
                <div className="card_content">
                  <div className="icon_parent position-relative">
                    <img
                      src="./Third_Party_Risk_Management_icon.png"
                      alt="panel"
                    />
                    <div className="title_name">
                      Third Party Risk Management
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row justify={"end"}>
            <button onClick={handleContinue} className="continue_btn">
              continue
            </button>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default DashboardOptions;
