import React from 'react';
import { Avatar, Card, Flex, Tag } from "antd";


const ProjectNameCard = (props) => {
    // console.log('projectName-->',props);
    return (
        // <Card style={{ width: 170 }} bordered={false}>
        <div className='flex'  >
          <h1 style={{
            color: '#232337',
            fontSize: '14px',
            fontWeight:600,
          }}>{props.projectName}</h1>
            
            <div >
              <div style={{fontStyle:'italic',fontSize:'11px',color:'#63637D'}}>Source</div>
            <Flex >
            <div style={{paddingInlineEnd:'10px',fontWeight:600,fontSize:'10px',color:'#9494AE' }} >File</div>
            <div style={{paddingInlineEnd:'10px',fontWeight:600,fontSize:'10px',color:'#9494AE' }} >Image</div>
            <div style={{paddingInlineEnd:'10px',fontWeight:600,fontSize:'10px',color:'#9494AE' }} >Code</div>
            <div style={{paddingInlineEnd:'10px',fontWeight:600,fontSize:'10px',color:'#9494AE' }} >Web</div>
                {/* {props.data.map((item, index) => (
                    <div style={{paddingInlineEnd:'10px'}} >File</div>
                    
                    ))} */}
            </Flex>
            </div>
        </div>
        // </Card>
      )
}

export default ProjectNameCard