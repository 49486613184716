import { ClockCircleOutlined } from '@ant-design/icons'
import { Dropdown, Space } from 'antd'
import React, { useState } from 'react'
import "./CommonDashboardHeader.css"

const CommonDashboardHeader = ({ name,desc,subName}) => {
    const [selectedItem, setSelectedItem] = useState(null);

    const handleItemClick = (item) => {
      setSelectedItem(item);
      console.log('item==',item)
    };

    const items3 = [
        {
          date:"5th Jan 2024",
          time:'at 12:30 pm'
        },
        {
          date:"6th Jan 2024",
          time:'at 12:30 pm'
        },
        {
          date:"7th Jan 2024",
          time:'at 12:30 pm'
        },
        {
          date:"8th Jan 2024",
          time:'at 12:30 pm'
        },
         ];
    const dropdownMenu = (
        <div className="scan_list_dropdown">
          {items3.map((item, index) => (
            <div
            className={`parent_div_menu_item ${
              selectedItem && selectedItem.date === item.date ? "selected" : ""
            }`}
            key={index}
            onClick={() => handleItemClick(item)}
          >
              <div className="d-flex flex-column gap-1">
                <div className="history_date">{item.date}</div>
                <div className="history_time">{item.time}</div>
              </div>
            </div>
          ))}
        </div>
      );
  return (
    <>
        <div className="welcome-to-tata-container  ">
            <div className="title_with_wrap_des">
              <div>
                <span className="welcome-to">Welcome to </span>
                <b className="tata-group">{name ? name : 'Tata Group'}</b>
                <span className="welcome-to">{' '}{ subName ? subName+' Dashboard' : 'Dashboard'} </span>
              </div>
              <div className="stay_ahead_of">
                {desc ? desc : 'stay ahead of emerging threats through real-time monitoring'}
              </div>
            </div>
            <div className="showing_sync_ver_as_on_parent">
              <div className="showing_sync_ver">
                <i>Showing Sync ver. as on:</i>

                <Dropdown
                  className="scan_history_dropdown"
                  overlay={dropdownMenu}
                  trigger={["click"]}
                >
                  <a onClick={(e) => e.preventDefault()}>
                    <Space>
                      <i className="th_jan_2024">{selectedItem ? selectedItem.date : '5th Jan 2024'}</i>
                      <ClockCircleOutlined
                        style={{ fontSize: 14, color: "red" }}
                      />
                    </Space>
                  </a>
                </Dropdown>
              </div>
            </div>
          </div>
    </>
  )
}

export default CommonDashboardHeader