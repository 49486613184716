import React from "react";
import { PieChart, Pie, Cell, ResponsiveContainer, Polygon } from "recharts";

const DemoGouge = ({
  fillPercentage = 60,
  startRange = 0,
  endRange = 100,
  width,
  height,
}) => {
  const data = [
    {
      name: "A",
      value: fillPercentage,
      color: "url(#colorGradient)",
    },
    { name: "B", value: 100 - fillPercentage, color: "#e0e0e0" },
  ];

  const cx = "50%";
  const cy = "80%";
  const iR = 110;
  const oR = 125;
  const arrowSize = 8; // Size of the arrow

  // Calculate the coordinates for the arrow
  const arrowX = cx + Math.cos(Math.PI / 6) * (oR - 20 - arrowSize);
  const arrowY = cy + Math.sin(Math.PI / 6) * (oR - 20 - arrowSize);
  return (
    <ResponsiveContainer
      className="responsive_overall_risk_container m-auto"
      width={width ? width : 250}
      height={height ? height : 150}
    >
      <PieChart width={180}>
        <Pie
          dataKey="value"
          startAngle={180}
          endAngle={0}
          data={data}
          cx={cx}
          cy={cy}
          innerRadius={iR}
          outerRadius={oR}
          fill="url(#colorGradient)"
          stroke="none"
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.color} />
          ))}
        </Pie>
        <Pie
          data={[{ value: fillPercentage }]}
          cx={cx}
          cy={cy}
          startAngle={180}
          endAngle={0}
          innerRadius={iR - 12}
          outerRadius={oR - 25}
          fill="#F1F1F1" 
          stroke="none"
        >
          <Cell fill="#e0e0e0" />
        </Pie>
        {data.map((entry, index) => (
        <Polygon key={`cell-${index}`}
      points={`${arrowX},${arrowY} ${arrowX + arrowSize},${arrowY} ${arrowX},${arrowY + arrowSize / 2}`}
      fill="#000" 
    />
        ))}
       
        <svg>
          <defs>
            <linearGradient
              id="colorGradient"
              x1="0%"
              y1="0%"
              x2="100%"
              y2="0%"
            >
              <stop
                offset="10%"
                style={{ stopColor: "#0fd850", stopOpacity: 1 }}
              />
              <stop
                offset="20%"
                style={{ stopColor: "#f9f047", stopOpacity: 1 }}
              />
              <stop
                offset="50%"
                style={{ stopColor: "#ff930f", stopOpacity: 1 }}
              />
            </linearGradient>
          </defs>
        </svg>

        <text x="0%" y="90%" textAnchor="start" fontSize="16" fill="#D9D9D9">
          {startRange}
        </text>
        <text x="100%" y="90%" textAnchor="end" fontSize="16" fill="#D9D9D9">
          {endRange}
        </text>

        <text
          x="50%"
          y="60%"
          textAnchor="middle"
          alignmentBaseline="middle"
          fontSize="70"
          style={{lineHeight: '87.16px'}}
          fontWeight={600}
          fill="#fe9706"
        >
          {`${data[0].value}`}
        </text>
        <text
          x="50%"
          y="90%"
          textAnchor="middle"
          alignmentBaseline="middle"
          fontSize="14"
          fontWeight={600}
          lineHeight="19.07px"
          fill="#fe9706"
        >
          {"Medium"}
        </text>
      </PieChart>
    </ResponsiveContainer>
  );
};

export default DemoGouge;
