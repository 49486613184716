import React from "react";
import { ResponsiveContainer, PieChart, Pie, Cell } from "recharts";

const GougeInfrastrChart = ({
  fillPercentage = 200,
  endRange = 300,
  startRange = 0,
}) => {
  const RADIAN = Math.PI / 180;
  const data = [
    { name: "A", value: fillPercentage, color: "#02B583" },
    { name: "B", value: fillPercentage, color: "#0BB56B" },
    { name: "C", value: fillPercentage, color: "#7CBE2A" },
    { name: "D", value: fillPercentage, color: "#CAD03C" },
    { name: "E", value: fillPercentage, color: "#EBC43B" },
    { name: "F", value: fillPercentage, color: "#FF9D2A" },
    { name: "G", value: fillPercentage, color: "#EE6C0E" },
    { name: "H", value: fillPercentage, color: "#EE6C0E" },
    { name: "I", value: fillPercentage, color: "#FE6E06" },
    { name: "J", value: fillPercentage, color: "#EF4F1C" },
    { name: "K", value: fillPercentage, color: "#D72700" },
    { name: "L", value: fillPercentage, color: "#BC2200" },
  ];

  const cx = "50%";
  const cy = "80%";
  const iR = 75;
  const oR = 90;
  const value = 50;

  //   const needle = (value, data, cx, cy, iR, oR, color) => {
  //     let total = 0;
  //     data.forEach((v) => {
  //       total += v.value;
  //     });
  //     const ang = 180.0 * (1 - value / total);
  //     const length = (iR + 2 * oR) / 3;
  //     const sin = Math.sin(-RADIAN * ang);
  //     const cos = Math.cos(-RADIAN * ang);
  //     const r = 5;
  //     const x0 = cx + 5;
  //     const y0 = cy + 5;
  //     const xba = x0 + r * sin;
  //     const yba = y0 - r * cos;
  //     const xbb = x0 - r * sin;
  //     const ybb = y0 + r * cos;
  //     const xp = x0 + length * cos;
  //     const yp = y0 + length * sin;

  //     return [
  //       <circle cx={x0} cy={y0} r={r} fill={color} stroke="none" />,
  //       <path
  //         d={`M${xba} ${yba}L${xbb} ${ybb} L${xp} ${yp} L${xba} ${yba}`}
  //         stroke="#none"
  //         fill={color}
  //       />,
  //     ];
  //   };

  return (
    <ResponsiveContainer className="ehsan_responsive_container m-auto px-3" width={180} height={150}>
      <PieChart width="100%" height={150}>
        <Pie
          dataKey="value"
          startAngle={180}
          endAngle={0}
          data={data}
          cx={cx}
          cy={cy}
          innerRadius={iR}
          outerRadius={oR}
          paddingAngle={5}
          fill="url(#colorGradient)"
          stroke="none"
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.color} />
          ))}
        </Pie>
        <svg>
          <defs>
            <linearGradient
              id="colorGradient"
              x1="0%"
              y1="0%"
              x2="100%"
              y2="0%"
            >
              <stop
                offset="0%"
                style={{ stopColor: "#e0e0e0", stopOpacity: 1 }}
              />
              <stop
                offset={`${fillPercentage}%`}
                style={{ stopColor: "#e0e0e0", stopOpacity: 1 }}
              />
              <stop
                offset={`${fillPercentage + 1}%`}
                style={{ stopColor: "#02b583", stopOpacity: 1 }}
              />
              <stop
                offset="100%"
                style={{ stopColor: "#02b583", stopOpacity: 1 }}
              />
            </linearGradient>
          </defs>
        </svg>
        <text x="0%" y="90%" textAnchor="start" fontSize="16" fill="#D9D9D9">
          {startRange}
        </text>
        <text x="100%" y="90%" textAnchor="end" fontSize="16" fill="#D9D9D9">
          {endRange}
        </text>
        <text
          x="50%"
          y="65%"
          textAnchor="middle"
          alignmentBaseline="middle"
          fontSize="50"
          fontWeight={600}
          style={{lineHeight: '65.37px'}}
          fill="#BC2200"
        >
          {`${data[0].value}`}
        </text>
        <text
          x="50%"
          y="90%"
          textAnchor="middle"
          alignmentBaseline="middle"
          fontSize="12"
          fontWeight={600}
          fill="#BC2200"
          lineHeight="16.34px"
        >
          {"POINTS"}
        </text>
        {/* {needle(value, data, cx, cy, iR, oR, "#d0d000")} */}
      </PieChart>
    </ResponsiveContainer>
  );
};

export default GougeInfrastrChart;
