import React, { useCallback, useState } from "react";
import { PieChart, Pie, Sector, Legend, Line } from "recharts";

const data = [
  { name: "Tier 1", value: 400, fill: "#67CC48" },
  { name: "Tier 2", value: 300, fill: "#484AFB" },
  { name: "Tier 3", value: 300, fill: "#D73212" },
];

const renderActiveShape = (props) => {
  console.log("props=", props);

  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
    value,
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? "start" : "end";
  const totalValue = data.reduce(
    (accumulator, currentValue) => accumulator + currentValue.value,
    0
  );

  const gradientId = `gradient-${payload.name
    .replace(/\s+/g, "-")
    .toLowerCase()}`;
  const gradientColor = `url(#${gradientId})`;

  return (
    <g>
      <defs>
        <linearGradient id={gradientId} x1="0%" y1="0%" x2="100%" y2="100%">
          <stop offset="30%" stopColor={fill} />
          <stop offset="100%" stopColor={`${fill}99`} />
        </linearGradient>
      </defs>
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
        {totalValue}
      </text>
      <text x={cx} y={cy} dy={25} textAnchor="middle" fill={fill}>
        Total
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={gradientColor}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle + 5}
        endAngle={endAngle - 5}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={gradientColor}
      />
      <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        stroke={fill}
        fill="none"
      />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        textAnchor={textAnchor}
        fill="#333"
      >{`${value}`}</text>
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        dy={18}
        textAnchor={textAnchor}
        fill="#999"
      >
        {`${(percent * 100).toFixed(2)}%`}
      </text>
    </g>
  );
};
const InformationExposureChartPDF = ({
  isShowNotation,
  width,
  height,
  showLegend,
}) => {
  const [activeIndex, setActiveIndex] = useState(
    Array.from(Array(data.length).keys())
  );
  console.log("data=", data);
  const onPieEnter = useCallback(
    (_, index) => {
      setActiveIndex(index);
    },
    [setActiveIndex]
  );

  return (
    <div
      className="chart_container"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <PieChart width={width ? width : 400} height={height ? height : 400}>
        <Pie
          activeIndex={activeIndex}
          activeShape={renderActiveShape}
          data={data}
          cx="50%"
          cy="50%"
          innerRadius={60}
          outerRadius={100}
          fill="#8884d8"
          dataKey="value"
          // onMouseEnter={onPieEnter}
        />
        {showLegend && (
          <>
            <Legend
              iconType={"circle"}
              iconSize={10}
              align="center"
              verticalAlign="bottom"
              height={20}
              chartWidth={20}
              width={"100%"}
            />
            <Line type="monotone" dataKey="value" stroke="red" name="tier" />
            <Line type="monotone" dataKey="value" stroke="green" name="tier" />
            <Line type="monotone" dataKey="value" stroke="blue" name="tier" />
          </>
        )}
      </PieChart>
      {isShowNotation && (
        <div
          className="notation_div"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: 400,
          }}
        >
          {data?.map((el, index) => (
            <div key={index} className="notation_wrap">
              <div
                style={{
                  fontSize: 44,
                  lineHeight: "44px",
                  fontWeight: 700,
                  color: el.fill ? el.fill : "#000",
                }}
                className="value_lable"
              >
                {el.value}
              </div>
              <div
                style={{
                  lineHeight: "21.79px",
                  fontSize: 16,
                  fontWeight: 400,
                  color: "#63637D",
                  fontFamily: "Open Sans",
                }}
                className="name_label"
              >
                {el.name}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default InformationExposureChartPDF;
