import React from "react";
import "./CustomProgressBar.css"; // Import CSS for styling

const CustomProgressBar = ({ percent,filledColor }) => {
  const filledStyle = {
    width: `${percent}%`,
    backgroundColor: filledColor
  };

  return (
    <div className="custom-progress-container">
      <div className="custom-progress-bar">
        <div className="custom-progress-filled" style={filledStyle} >
        <div className="custom-progress-text" style={{background: filledColor}}>{percent}%</div>
        </div>
        
      </div>
    </div>
  );
};

export default CustomProgressBar;
