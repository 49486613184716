import React from "react";
import Flags from "country-flag-icons/react/3x2";
const FlagIcon = ({ flagNationCode }) => {
  const Flag = Flags[flagNationCode];
  return (
    <>
      <Flag width={24} />
    </>
  );
};

export default FlagIcon;
