import React from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Label,
} from "recharts";

const data = [
  {
    name: "Oct 22",
    uv: 112,
    pv: 240,
    amt: 240,
  },
  {
    name: "Oct 24",
    uv: 400,
    pv: 138,
    amt: 2210,
  },
  {
    name: "Nov 2",
    uv: 150,
    pv: 9800,
    amt: 2290,
  },
  {
    name: "Nov 2",
    uv: 700,
    pv: 3908,
    amt: 2000,
  },
  {
    name: "Page E",
    uv: 260,
    pv: 4800,
    amt: 2181,
  },
  {
    name: "Page F",
    uv: 230,
    pv: 3800,
    amt: 2500,
  },
  {
    name: "Nov 17",
    uv: 780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: "Nov 24",
    uv: 70,
    pv: 800,
    amt: 2100,
  },
  {
    name: "Page I",
    uv: 890,
    pv: 2468,
    amt: 2100,
  },
  {
    name: "Nov 24",
    uv: 390,
    pv: 18180,
    amt: 2100,
  },
  {
    name: "Page K",
    uv: 800,
    pv: 1798,
    amt: 210,
  },
  {
    name: "Dec 3",
    uv: 390,
    pv: 9315,
    amt: 2100,
  },
  {
    name: "Dec 4",
    uv: 1090,
    pv: 6970,
    amt: 2100,
  },
  {
    name: "Page N",
    uv: 800,
    pv: 7598,
    amt: 2100,
  },
  {
    name: "Page O",
    uv: 1500,
    pv: 5970,
    amt: 2100,
  },
  {
    name: "Dec 27",
    uv: 980,
    pv: 8765,
    amt: 2100,
  },
  {
    name: "Page Q",
    uv: 1700,
    pv: 5877,
    amt: 2100,
  },
  {
    name: "Page R",
    uv: 1700,
    pv: 10765,
    amt: 2100,
  },
  {
    name: "Jan 5",
    uv: 890,
    pv: 8675,
    amt: 2100,
  },
  {
    name: "Page T",
    uv: 989,
    pv: 12875,
    amt: 2100,
  },
  {
    name: "Page U",
    uv: 132,
    pv: 8762,
    amt: 2100,
  },
  {
    name: "Jan 14",
    uv: 2341,
    pv: 62,
    amt: 2100,
  },
];
const customYAxisTicks = [0, 200, 400, 600, 800, 1000, 1200, 1400, 1600, 1800];

const PopularityChart = () => {
  const generateGradientId = (index) => `areaGradient`;
  return (
    <AreaChart
      width={400}
      height={300}
      data={data}
      margin={{
        top: 10,
        right: 30,
        left: 0,
        bottom: 0,
      }}
    >
      <defs>
        <linearGradient
          id="chartGradient"
          x1="0"
          y1="0"
          x2="0"
          y2="1"
        >
          <stop
            offset="0%"
            stopColor="#5A81FA"
            stopOpacity={0.2}
          />
          <stop
            offset="96%"
            stopColor="#D2DDFF"
          />
        </linearGradient>
      </defs>
      <CartesianGrid strokeDasharray="0 3" />
      <XAxis dataKey="name"
      tick={{ fontSize: 11,margin: 10, fill: "#63637D" }} >
         {/* <Label value="Downloads count" angle={-90} position="bottom" offset={0} /> */}
      </XAxis>
      <YAxis ticks={customYAxisTicks} 
      tick={{ fontSize: 12,margin: 10, fill: "#9494AE" }} >
         {/* <Label value="Months" angle={-90} position="insideLeft" /> */}
      </YAxis>
      
      {/* <Tooltip /> */}
      <Area
        type="monotone"
        dataKey="uv"
        stroke="#5A81FA"
        fill="url(#chartGradient)"
        strokeWidth={1.5}
      />
    </AreaChart>
  );
};

export default PopularityChart;
