import React, { useState } from "react";
import DynamicTable from "../../../../components/SelectComponent/DynamicTable";
// import data from "../../../../components/dashboard-options/Data";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Dropdown,
  Flex,
  Menu,
  Pagination,
  Row,
  Switch,
  Table,
} from "antd";
import "./Onboarding.css";
import GlobalFilter from "../../../Common/global-filter/GlobalFilter";
import {
  CloseOutlined,
  DownloadOutlined,
  PlusOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import SplitscreenOutlinedIcon from "@mui/icons-material/SplitscreenOutlined";
import RowSelectBtn from "../../../../Pages/Common/CommanTable/RowSelectBtn";
import CardViewIcon from "../../../../images/sidenavIcons/view_day.png";
import SmallModal from "../../../Common/small-pop-modal/SmallModal";
import FormStep from "../../../Common/steps/FormStep";
import Identification from "../stepper-identificaton/Identification";
import AssignAssessment from "../assign-assessment/AssignAssessment";
import DigitalAssessment from "../digital-assessment/DigitalAssessment";
import AdditionalRulesForm from "../../SC/sca-dashboard/components/AdditionalRulesForm";
import AssessmentPreview from "../assessment-preview/AssessmentPreview";
import CommonBottomDrawer from "../../../Common/commonBottomDrawer/CommonBottomDrawer";
import VendorDashboard from "../vendor-dashboard/VendorDashboard";
const Onboarding = () => {
  const columns = [
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      ellipsis: true,
      sorter: true,
      width: 200,
    },
    {
      title: "Profile",
      dataIndex: "profile",
      key: "profile",
      ellipsis: true,
      sorter: true,
      width: 200,
    },
    {
      title: "Rank",
      dataIndex: "rank",
      key: "rank",
      ellipsis: true,
      sorter: true,
      width: 200,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      ellipsis: true,
      sorter: true,
      width: 200,
      sorter: (a, b) => a.status.localeCompare(b.status),
      render: (text, record) => (
        <span
          style={{
            color:
              record.status === "Secteam Confirmed"
                ? "#02B583"
                : record.status === "Infosecteam Reviewed" ||
                  record.status === "Legalteam Reviewed"
                ? "#FE9706"
                : record.status === "Initiated"
                ? "#5A81FA"
                : "",
          }}
        >
          {text || "-"}
        </span>
      ),
    },
    {
      title: "Company name",
      dataIndex: "company_name",
      key: "company_name",
      ellipsis: true,
      sorter: (a, b) => a.company_name.localeCompare(b.company_name),
      width: 200,
    },
    {
      title: "Vendor Email",
      dataIndex: "vendor_email",
      key: "vendor_email",
      ellipsis: true,
      sorter: true,
      width: 200,
    },
    {
      title: "Vendor Contact No",
      dataIndex: "vendor_contact_no",
      key: "vendor_contact_no",
      ellipsis: true,
      sorter: true,
      width: 200,
    },
    {
      title: "Operational State",
      dataIndex: "operational_state",
      key: "operational_state",
      ellipsis: true,
      sorter: true,
      width: 200,
    },
    {
      title: "Operational City",
      dataIndex: "operational_city",
      key: "operational_city",
      ellipsis: true,
      sorter: true,
      width: 200,
    },
    {
      title: "Head Quarter",
      dataIndex: "head_quarter",
      key: "head_quarter",
      ellipsis: true,
      sorter: true,
      width: 200,
      render: (text, record) => <span>{text || "NA"}</span>,
    },
    {
      title: "Spoc Name",
      dataIndex: "spoc_name",
      key: "spoc_name",
      ellipsis: true,
      sorter: true,
      width: 200,
    },
  ];

  const data = [
    {
      key: "1",
      status: "Secteam Confirmed",
      company_name: "Velocious Solutions Pvt. Ltd.",
      vendor_email: "Vendor@gmail.com",
      vendor_contact_no: "9860179162",
      operational_state: "Maharashtra",
      operational_city: "Mumbai",
      head_quarter: "",
      spoc_name: "xyz",
    },
    {
      key: "2",
      status: "Infosecteam Reviewed",
      company_name: "Deloitte",
      vendor_email: "Deloitte@gmail.com",
      vendor_contact_no: "8860179168",
      operational_state: "Maharashtra",
      operational_city: "Mumbai",
      head_quarter: "",
      spoc_name: "xyz",
    },
    {
      key: "3",
      status: "Initiated",
      company_name: "Xbiz Techventures Pvt Ltd.",
      vendor_email: "Xbiz@gmail.com",
      vendor_contact_no: "7960379160",
      operational_state: "Maharashtra",
      operational_city: "Mumbai",
      head_quarter: "",
      spoc_name: "xyz",
    },
    {
      key: "4",
      status: "Secteam Confirmed",
      company_name: "Velocious Solutions Pvt. Ltd.",
      vendor_email: "Vendor@gmail.com",
      vendor_contact_no: "9860179162",
      operational_state: "Maharashtra",
      operational_city: "Mumbai",
      head_quarter: "",
      spoc_name: "xyz",
    },
    {
      key: "5",
      status: "Infosecteam Reviewed",
      company_name: "Deloitte",
      vendor_email: "Deloitte@gmail.com",
      vendor_contact_no: "8860179168",
      operational_state: "Maharashtra",
      operational_city: "Mumbai",
      head_quarter: "",
      spoc_name: "xyz",
    },
    {
      key: "6",
      status: "Initiated",
      company_name: "Xbiz Techventures Pvt Ltd.",
      vendor_email: "Xbiz@gmail.com",
      vendor_contact_no: "7960379160",
      operational_state: "Maharashtra",
      operational_city: "Mumbai",
      head_quarter: "",
      spoc_name: "xyz",
    },
    {
      key: "7",
      status: "Legalteam Reviewed",
      company_name: "Deloitte",
      vendor_email: "Vendor@gmail.com",
      vendor_contact_no: "9780112184",
      operational_state: "Maharashtra",
      operational_city: "Mumbai",
      head_quarter: "",
      spoc_name: "xyz",
    },
    {
      key: "8",
      status: "Secteam Confirmed",
      company_name: "Velocious Solutions Pvt. Ltd.",
      vendor_email: "Velocious@gmail.com",
      vendor_contact_no: "9860179162",
      operational_state: "Maharashtra",
      operational_city: "Mumbai",
      head_quarter: "",
      spoc_name: "xyz",
    },
    {
      key: "9",
      status: "Initiated",
      company_name: "Xbiz Techventures Pvt Ltd.",
      vendor_email: "Xbiz@gmail.com",
      vendor_contact_no: "8860179168",
      operational_state: "Maharashtra",
      operational_city: "Mumbai",
      head_quarter: "",
      spoc_name: "xyz",
    },
    {
      key: "10",
      status: "Infosecteam Reviewed",
      company_name: "Velocious Solutions Pvt. Ltd.",
      vendor_email: "Velocious@gmail.com",
      vendor_contact_no: "9860179162",
      operational_state: "Maharashtra",
      operational_city: "Mumbai",
      head_quarter: "",
      spoc_name: "xyz",
    },
    {
      key: "11",
      status: "Legalteam Reviewed",
      company_name: "Deloitte",
      vendor_email: "Vendor@gmail.com",
      vendor_contact_no: "9860179162",
      operational_state: "Maharashtra",
      operational_city: "Mumbai",
      head_quarter: "",
      spoc_name: "xyz",
    },
    {
      key: "12",
      status: "Secteam Confirmed",
      company_name: "Velocious Solutions Pvt. Ltd.",
      vendor_email: "Velocious@gmail.com",
      vendor_contact_no: "9860179162",
      operational_state: "Maharashtra",
      operational_city: "Mumbai",
      head_quarter: "",
      spoc_name: "xyz",
    },
    {
      key: "13",
      status: "Legalteam Reviewed",
      company_name: "Velocious Solutions Pvt. Ltd.",
      vendor_email: "Vendor@gmail.com",
      vendor_contact_no: "8860179168",
      operational_state: "Maharashtra",
      operational_city: "Mumbai",
      head_quarter: "",
      spoc_name: "xyz",
    },
    {
      key: "14",
      status: "Initiated",
      company_name: "Xbiz Techventures Pvt Ltd.",
      vendor_email: "Vendor@gmail.com",
      vendor_contact_no: "9860179162",
      operational_state: "Maharashtra",
      operational_city: "Mumbai",
      head_quarter: "",
      spoc_name: "xyz",
    },
    {
      key: "15",
      status: "Legalteam Reviewed",
      company_name: "Deloitte",
      vendor_email: "Deloitte@gmail.com",
      vendor_contact_no: "9860179162",
      operational_state: "Maharashtra",
      operational_city: "Mumbai",
      head_quarter: "",
      spoc_name: "xyz",
    },
    {
      key: "16",
      status: "Secteam Confirmed",
      company_name: "Velocious Solutions Pvt. Ltd.",
      vendor_email: "Vendor@gmail.com",
      vendor_contact_no: "8860179168",
      operational_state: "Maharashtra",
      operational_city: "Mumbai",
      head_quarter: "",
      spoc_name: "xyz",
    },
    {
      key: "17",
      status: "Infosecteam Reviewed",
      company_name: "Xbiz Techventures Pvt Ltd.",
      vendor_email: "Xbiz@gmail.com",
      vendor_contact_no: "7960379160",
      operational_state: "Maharashtra",
      operational_city: "Mumbai",
      head_quarter: "",
      spoc_name: "xyz",
    },
    {
      key: "18",
      status: "Initiated",
      company_name: "Velocious Solutions Pvt. Ltd.",
      vendor_email: "Vendor@gmail.com",
      vendor_contact_no: "9860179162",
      operational_state: "Maharashtra",
      operational_city: "Mumbai",
      head_quarter: "",
      spoc_name: "xyz",
    },
  ];
  const [showGlobalFilter, setShowGlobalFilter] = useState(true);
  // modal policy start
  const [open, setOpen] = useState(false);
  const openModalPopUp = () => {
    setOpen(true);
  };
  const closeModalPopUp = () => {
    setOpen(false);
  };

  // modal policy end
  //stepper form start
  const steps = [
    {
      title: "Identification",
      content: <Identification />,
    },
    {
      title: "Assign Assessment",
      content: <AssignAssessment />,
    },
    {
      title: "Digital Assessment",
      content: <DigitalAssessment />,
    },
    {
      title: "Additional Rules",
      content: <AdditionalRulesForm />,
    },
    {
      title: "Preview",
      content: <AssessmentPreview />,
    },
  ];
  //   stepper form end
  const handleSwitchFilter = (checked) => {
    setShowGlobalFilter(checked);
  };

  // pagination start

  const itemRender = (current, type, originalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }
    return originalElement;
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const [currentPage, setCurrentPage] = useState(1);
  const totalRecords = data ? data.length : 100; // Total number of records in your table
  const pageSize = 15; // Number of records per page

  const startRecord = (currentPage - 1) * pageSize + 1;
  const endRecord = Math.min(currentPage * pageSize, totalRecords);

  // Pagination data for the current page
  const currentPageData = data.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );
  // pagination end

  //bottom drawer start
  const [bottomDrawer, setBottomDrawer] = useState(false);

  const openBottomDrawer = () => {
    setBottomDrawer(true);
  };
  const closeBottomDrawer = () => {
    setBottomDrawer(false);
  };
  //bottom drawer end

  const defaultCheckedList = columns.slice(3).map((item) => item.key);

  const [checkedList, setCheckedList] = useState(defaultCheckedList);
  const options = columns.map(({ key, title }) => ({
    label: title,
    value: key,
  }));
  const newColumns = columns.map((item) => ({
    ...item,
    hidden: !checkedList.includes(item.key),
  }));

  //column menu start
  const menu = (
    <Menu className="column_dropdown">
      {options.map(({ label, value, index }) => (
        <Menu.Item className="menu_item" key={index}>
          <Checkbox
            key={value}
            onClick={(e) => e.stopPropagation()}
            checked={checkedList.includes(value)}
            onChange={(e) => {
              const isChecked = e.target.checked;
              if (isChecked) {
                setCheckedList([...checkedList, value]);
              } else {
                setCheckedList(checkedList.filter((item) => item !== value));
              }
            }}
          >
            <span onClick={(e) => e.stopPropagation()}>{label}</span>
          </Checkbox>
        </Menu.Item>
      ))}
    </Menu>
  );

  //column menu end

  return (
    <div className="custom_table">
      <Card
        className="tprm_onboarding"
        style={{ borderRadius: 12 }}
        bodyStyle={{ padding: 16 }}
      >
        <div className="card_wrapper">
          <Flex className="card_head" justify="space-between">
            <div className="header_title">Onboarding</div>
            <a
              onClick={() => window.location.reload(false)}
              className="sync_data"
            >
              <SyncOutlined />
              <span>Sync Data</span>
            </a>
          </Flex>
          <div>
            {/* <div className="btn_above_table"></div> */}
            <Row gutter={[16, 16]}>
              {showGlobalFilter ? (
                <Col
                  xs={showGlobalFilter ? 24 : 0}
                  sm={showGlobalFilter ? 24 : 0}
                  md={showGlobalFilter ? 5 : 0}
                >
                  <GlobalFilter />
                </Col>
              ) : null}
              <Col xs={24} sm={24} md={showGlobalFilter ? 19 : 24}>
                <div className="table_row d-flex justify-content-between align-items-center mb-2">
                  <div
                    className="wrapper_left"
                    style={{
                      display: "inline-flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <Dropdown overlay={menu} trigger={["click"]}>
                      <a
                        className="column-icon"
                        onClick={(e) => e.stopPropagation()}
                      >
                        Columns
                        <SplitscreenOutlinedIcon
                          style={{ color: "#63637D", width: 15, fontSize: 13 }}
                        />
                      </a>
                    </Dropdown>
                    <RowSelectBtn />
                    {!showGlobalFilter ? (
                      <a className="filter_label_btn">
                        <span className="filter_label_btn_btn_text">
                          {" "}
                          Filter
                        </span>
                        <CloseOutlined style={{ color: "#fff" }} />
                      </a>
                    ) : null}
                  </div>
                  <div
                    className="right_div"
                    style={{
                      display: "inline-flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <div className="filter_switch">
                      <Switch
                        size="small"
                        className="custom_switch"
                        defaultChecked
                        onChange={handleSwitchFilter}
                      />
                      <span className="ms-1">Filter</span>
                    </div>
                    <div className="card_view">
                      <button className="card_view_btn">
                        <img src={CardViewIcon} alt="card_view" />
                        <span className="card_view_text"> Card View</span>
                      </button>
                    </div>
                    <div className="download_excel d-flex align-items-center">
                      <button
                        // icon={<DownloadOutlined style={{fontSize: 15}} />}
                        style={{ borderColor: "#5A81FA" }}
                        className="btn download_excel_btn btn-outlined  d-flex align-items-center "
                      >
                        <DownloadOutlined style={{ fontSize: 15 }} />
                        Download Excel
                      </button>
                    </div>
                    <div className="add_andriod">
                      <button
                        className="add_andriod_btn"
                        onClick={openModalPopUp}
                        // icon={<PlusOutlined />}
                        type="primary"
                      >
                        <PlusOutlined
                          style={{ color: "#FFFFFF", fontSize: 15 }}
                        />
                        Add Onboarding
                      </button>
                    </div>
                  </div>
                </div>
                <Table
                  size="small"
                  scroll={{ x: 600 }}
                  className="tprm_onboarding"
                  columns={newColumns.filter((column) => !column.hidden)}
                  onRow={(record, index) => ({
                    onClick: () => {
                      openBottomDrawer();
                      console.log("selected-row===>", record);
                    },
                  })}
                  dataSource={currentPageData}
                  style={{
                    marginTop: 24,
                  }}
                  pagination={false}
                />
                <div
                  style={{
                    display: "flex",
                    marginTop: "10px",
                    justifyContent: "space-between",
                    alignItems: "center",
                    color: "#63637D",
                    padding: "7px",
                    fontWeight: 600,
                    fontSize: "13px",
                  }}
                >
                  <div>
                    Showing {startRecord}-{endRecord} entries out of{" "}
                    {totalRecords}
                  </div>
                  <Pagination
                    style={{
                      color: "#63637D",
                      padding: "5px",
                      fontWeight: 600,
                      fontSize: "12px",
                    }}
                    current={currentPage}
                    pageSize={pageSize}
                    total={totalRecords}
                    itemRender={itemRender}
                    onChange={handlePageChange}
                    showSizeChanger={false}
                  />
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Card>

      <SmallModal
        title="Add a Onboarding"
        className="add_onboarding_popup"
        open={open}
        CloseModal={closeModalPopUp}
        closable={true}
        width={700}
      >
        <FormStep
          onCloseModal={closeModalPopUp}
          stepsMenu={steps}
          next_btn_text="Next"
        />
      </SmallModal>

      <CommonBottomDrawer
        open={bottomDrawer}
        onClose={closeBottomDrawer}
        title="Velocious Solutions Pvt. Ltd."
      >
        <VendorDashboard />
      </CommonBottomDrawer>
    </div>
  );
};

export default Onboarding;
