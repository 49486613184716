import { Modal } from "antd";
import React from "react";

const SmallModal = ({ CloseModal, open, title, children, width, closable }) => {
  return (
    <Modal
      closable={closable ? closable : false}
      width={width ? width : 500}
      title={title}
      open={open}
      onCancel={CloseModal}
      footer={null}
    >
      {children}
    </Modal>
  );
};

export default SmallModal;
