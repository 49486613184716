import React from 'react'
import './QuestionDetails.css'
import { padding } from '@mui/system'
const QuestionDetails = () => {
  return (
    <div className="question_details w-100">
        {/* {Array(7).fill().map((item)=>(
    <div className='row question_row'>
       <div className="col-md-8">
        <li className="question">
         Confirm whether any ui level or frontend changes are applicable for this project
        </li>
       </div>
       <div className="col-md-4">
        <div className="answer_respect_question">No</div>
       </div>
    </div>
    ))} */}
    <ol >
    {Array(4).fill().map((el)=>(
     <li className='question_row' >
        <div className='d-flex align-items-center'>
        <div className="question" style={{width: '60%'}}>
           Confirm whether any ui level or frontend changes are applicable for this project
        </div>
        <div className="answer_respect_question" style={{width: '40%'}}>No</div>
        </div>
     </li>
    ))}
    </ol>
    </div>
  )
}

export default QuestionDetails