import React from "react";
import HeaderNotation from "../../Pages/Common/grid-header-number-count/HeaderNotation";
import CustomTable from "../../Pages/Common/CommanTable/CustomTable";
import data from "../dashboard-options/Data";
const headerNotationData = [
  { id: 1, count: 120, category: "All", color: '#5A81FA' },
  { id: 2, count: 20, category: "Open", color: '#E10505' },
  { id: 3, count: 10, category: "Closed", color: '#02B583' },
  { id: 4, count: 30, category: "Re- Validation", color:'#FE9706' },
  { id: 5, count: 10, category: "Rejected", color: '#E10505' },
  { id: 6, count: 20, category: "False Positive", color: '#E10505' },
  { id: 7, count: 30, category: "Need Justification", color: '#FE9706' },
];

const Findings = () => {
  const { TableData } = data;
  console.log("data-line-17====", TableData);
  return (
    <div className="d-flex flex-column gap-5">
      <HeaderNotation data={headerNotationData} />
      <CustomTable data={TableData} />
    </div>
  );
};

export default Findings;
