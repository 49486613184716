import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { Card, Col, Collapse, Row, Table } from "antd";
import React from "react";
import "./OverAllDetailsTab.css";
import OverAllRiskRatingChart from "../over-all-risk-rating-chart/OverAllRiskRatingChart";
import ThreatSummaryLineChart from "../threat-categoy-summary-chart/ThreatSummaryLineChart";
import ScoreTrendChart from "../score-trend-chart/ScoreTrendChart";

const OverallDetailsTab = () => {
  //custom collapse icon
  const customExpandIcon = (panelProps) => {
    const { isActive } = panelProps;
    return isActive ? (
      <MinusOutlined style={{ fontSize: 14 }} />
    ) : (
      <PlusOutlined style={{ fontSize: 14 }} />
    );
  };
  //custom collapse icon end
  const categoryColumn = [
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      width: 210,
      ellipsis: true,
    },
    {
      title: "Instances",
      dataIndex: "instances",
      key: "instances",
      render: (text, record) => (
        <span
          className="tag_label"
          style={{
            background:
              record.category === "Information Leakage"
                ? "#FF9D01"
                : record.category === "Network Security"
                ? "#E10505"
                : record.category === "Security Misconfigurations"
                ? "#02B583"
                : record.category === "Social Engineering"
                ? "#014EB4"
                : record.category === "Network Security 2"
                ? "#4F25C7"
                : record.category === "Information Leakage 2"
                ? "#01B1BD"
                : record.category === "Security Misconfigurations 2"
                ? "#5A8DAA"
                : "",
          }}
        >
          {text || "-"}
        </span>
      ),
    },
  ];

  const categoryData = [
    {
      key: "1",
      category: "Information Leakage",
      instances: 120,
    },
    {
      key: "2",
      category: "Network Security",
      instances: 164,
    },
    {
      key: "3",
      category: "Security Misconfigurations",
      instances: 213,
    },
    {
      key: "4",
      category: "Social Engineering",
      instances: 178,
    },
    {
      key: "5",
      category: "Network Security 2",
      instances: 178,
    },
    {
      key: "6",
      category: "Information Leakage 2",
      instances: 178,
    },
    {
      key: "7",
      category: "Security Misconfigurations 2",
      instances: 178,
    },
    {
      key: "8",
      category: "Social Engineering",
      instances: 178,
    },
    {
      key: "9",
      category: "Network Security",
      instances: 164,
    },
    {
      key: "10",
      category: "Information Leakage",
      instances: 120,
    },
  ];

  //ASM Score Table Column & Data start
  const ASMColumn =[
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
    //   width: "50%"
    },
    {
      title: "Rating",
      dataIndex: "rating",
      key: "rating",
      width: 100,
      render: (text, record) => (
        <span
          className="tag_label"
          style={{
            background:
              record.rating < 5
                ? "#02B583"
                : record.rating > 5 && record.rating < 6
                ? "#E10505"
                : record.rating > 6
                ? "#FF9D01"
                : "",
          }}
        >
          {text || "-"}
        </span>
      ),
    },
    {
      title: "Trend",
      dataIndex: "trend",
      key: "trend",
      width: 150,
      render: (text, record) => {
        return <ScoreTrendChart data={record.trendData} chartBg={record.chartBg} strokeColor={record.strokeColor} />;
      },
    },
  ]

  const ASMData =[
    {
      id: 1,
      category: "Application Security Posture",
      rating: 4.5,
      strokeColor: "#5E6BFF",
      chartBg: "#7976F6",
      height: 40,
      trendData:[
        { name: "Page A", uv: 1220, pv: 1600, amt: 1600 },
        { name: "Page B", uv: 800, pv: 1398, amt: 2210 },
        { name: "Page C", uv: 1100, pv: 3000, amt: 2290 },
        { name: "Page D", uv: 1000, pv: 2008, amt: 2000 },
        { name: "Page E", uv: 400, pv: 2000, amt: 2181 },
        { name: "Page F", uv: 700, pv: 3000, amt: 2500 },
        { name: "Page G", uv: 820, pv: 2000, amt: 2100 },
        { name: "Page H", uv: 750, pv: 2000, amt: 2100 },
        { name: "Page I", uv: 850, pv: 2000, amt: 2100 },
        { name: "Page J", uv: 1150, pv: 2000, amt: 2100 },
      ]
    },
    {
      id: 2,
      category: "DNS Health and Security",
      rating: 5.6,
      strokeColor: "#5E6BFF",
      chartBg: "#57CCA7",
      height: 40,
      trendData:[
        { name: "Page A", uv: 1150, pv: 1600, amt: 1600 },
        { name: "Page B", uv: 850, pv: 1398, amt: 2210 },
        { name: "Page C", uv: 750, pv: 3000, amt: 2290 },
        { name: "Page D", uv: 820, pv: 2008, amt: 2000 },
        { name: "Page E", uv: 700, pv: 2000, amt: 2181 },
        { name: "Page F", uv: 400, pv: 3000, amt: 2500 },
        { name: "Page G", uv: 1000, pv: 2000, amt: 2100 },
        { name: "Page H", uv: 1100, pv: 2000, amt: 2100 },
        { name: "Page I", uv: 800, pv: 2000, amt: 2100 },
        { name: "Page J", uv: 1220, pv: 2000, amt: 2100 },
      ]
    },
    {
      id: 3,
      category: "Threat Intelligence",
      rating: 4.5,
      strokeColor: "#5E6BFF",
      chartBg: "#FF6653",
      height: 40,
      trendData:[
        { name: "Page A", uv: 1220, pv: 1600, amt: 1600 },
        { name: "Page B", uv: 800, pv: 1398, amt: 2210 },
        { name: "Page C", uv: 1100, pv: 3000, amt: 2290 },
        { name: "Page D", uv: 1000, pv: 2008, amt: 2000 },
        { name: "Page E", uv: 400, pv: 2000, amt: 2181 },
        { name: "Page F", uv: 700, pv: 3000, amt: 2500 },
        { name: "Page G", uv: 820, pv: 2000, amt: 2100 },
        { name: "Page H", uv: 750, pv: 2000, amt: 2100 },
        { name: "Page I", uv: 850, pv: 2000, amt: 2100 },
        { name: "Page J", uv: 1150, pv: 2000, amt: 2100 },
      ]
    },
    {
      id: 4,
      category: "Secure Configuration and Default Settings",
      rating: 5.6,
      strokeColor: "#5E6BFF",
      chartBg: "#7976F6",
      height: 40,
      trendData:[
        { name: "Page A", uv: 1150, pv: 1600, amt: 1600 },
        { name: "Page B", uv: 850, pv: 1398, amt: 2210 },
        { name: "Page C", uv: 750, pv: 3000, amt: 2290 },
        { name: "Page D", uv: 820, pv: 2008, amt: 2000 },
        { name: "Page E", uv: 700, pv: 2000, amt: 2181 },
        { name: "Page F", uv: 400, pv: 3000, amt: 2500 },
        { name: "Page G", uv: 1000, pv: 2000, amt: 2100 },
        { name: "Page H", uv: 1100, pv: 2000, amt: 2100 },
        { name: "Page I", uv: 800, pv: 2000, amt: 2100 },
        { name: "Page J", uv: 1220, pv: 2000, amt: 2100 },
      ]
    },
    {
      id: 5,
      category: "Encryption Standards and Implementation",
      rating: 5.6,
      strokeColor: "#5E6BFF",
      chartBg: "#57CCA7",
      height: 40,
      trendData:[
        { name: "Page A", uv: 1220, pv: 1600, amt: 1600 },
        { name: "Page B", uv: 800, pv: 1398, amt: 2210 },
        { name: "Page C", uv: 1100, pv: 3000, amt: 2290 },
        { name: "Page D", uv: 1000, pv: 2008, amt: 2000 },
        { name: "Page E", uv: 400, pv: 2000, amt: 2181 },
        { name: "Page F", uv: 700, pv: 3000, amt: 2500 },
        { name: "Page G", uv: 820, pv: 2000, amt: 2100 },
        { name: "Page H", uv: 750, pv: 2000, amt: 2100 },
        { name: "Page I", uv: 850, pv: 2000, amt: 2100 },
        { name: "Page J", uv: 1150, pv: 2000, amt: 2100 },
      ]
    },
    {
      id: 6,
      category: "Application Security Posture",
      rating: 4.5,
      strokeColor: "#5E6BFF",
      chartBg: "#FF6653",
      height: 40,
      trendData:[
        { name: "Page A", uv: 1150, pv: 1600, amt: 1600 },
        { name: "Page B", uv: 850, pv: 1398, amt: 2210 },
        { name: "Page C", uv: 750, pv: 3000, amt: 2290 },
        { name: "Page D", uv: 820, pv: 2008, amt: 2000 },
        { name: "Page E", uv: 700, pv: 2000, amt: 2181 },
        { name: "Page F", uv: 400, pv: 3000, amt: 2500 },
        { name: "Page G", uv: 1000, pv: 2000, amt: 2100 },
        { name: "Page H", uv: 1100, pv: 2000, amt: 2100 },
        { name: "Page I", uv: 800, pv: 2000, amt: 2100 },
        { name: "Page J", uv: 1220, pv: 2000, amt: 2100 },
      ]
    },
    {
      id: 7,
      category: "Application Security Posture",
      rating: 6.2,
      strokeColor: "#5E6BFF",
      chartBg: "#57CCA7",
      height: 40,
      trendData:[
        { name: "Page A", uv: 1220, pv: 1600, amt: 1600 },
        { name: "Page B", uv: 800, pv: 1398, amt: 2210 },
        { name: "Page C", uv: 1100, pv: 3000, amt: 2290 },
        { name: "Page D", uv: 1000, pv: 2008, amt: 2000 },
        { name: "Page E", uv: 400, pv: 2000, amt: 2181 },
        { name: "Page F", uv: 700, pv: 3000, amt: 2500 },
        { name: "Page G", uv: 820, pv: 2000, amt: 2100 },
        { name: "Page H", uv: 750, pv: 2000, amt: 2100 },
        { name: "Page I", uv: 850, pv: 2000, amt: 2100 },
        { name: "Page J", uv: 1150, pv: 2000, amt: 2100 },
      ]
    },
    {
      id: 8,
      category: "Application Security Posture",
      rating: 8.2,
      strokeColor: "#5E6BFF",
      chartBg: "#7976F6",
      height: 40,
      trendData:[
        { name: "Page A", uv: 1150, pv: 1600, amt: 1600 },
        { name: "Page B", uv: 850, pv: 1398, amt: 2210 },
        { name: "Page C", uv: 750, pv: 3000, amt: 2290 },
        { name: "Page D", uv: 820, pv: 2008, amt: 2000 },
        { name: "Page E", uv: 700, pv: 2000, amt: 2181 },
        { name: "Page F", uv: 400, pv: 3000, amt: 2500 },
        { name: "Page G", uv: 1000, pv: 2000, amt: 2100 },
        { name: "Page H", uv: 1100, pv: 2000, amt: 2100 },
        { name: "Page I", uv: 800, pv: 2000, amt: 2100 },
        { name: "Page J", uv: 1220, pv: 2000, amt: 2100 },
      ]
    },
  ]
//ASM Score Table Column & Data end

  return (
    <div className="overall_dedetail_Tab">
      <Collapse
        bordered={false}
        borderRadius={0}
        expandIcon={customExpandIcon}
        expandIconPosition="end"
        defaultActiveKey={["1"]}
      >
        <Collapse.Panel
          style={{ background: "#DCDDE7", marginBottom: 10 }}
          header="Overall  Details"
          key="1"
        >
          <Row gutter={[16, 16]} className="mt-3">
            <Col xs={24} sm={24} md={6} lg={6}>
              <Card
                bodyStyle={{ padding: 0 }}
                className=""
                style={{ padding: 0 }}
              >
                <div className="area_chat_wrapper">
                  <div
                    className="commo_chat_header d-flex justify-content-between"
                    style={{ padding: 8 }}
                  >
                    <span className="fsm_text_ellipse">
                      Over All Risk Rating
                    </span>
                  </div>
                  <OverAllRiskRatingChart width={290} height={260} />
                </div>
              </Card>
            </Col>
            <Col xs={24} sm={24} md={18} lg={18}>
              <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={16} lg={16}>
                  <Card
                    bodyStyle={{ padding: 0 }}
                    className=""
                    style={{ padding: 0 }}
                  >
                    <div className="area_chat_wrapper">
                      <div
                        className="commo_chat_header d-flex justify-content-between"
                        style={{ padding: 8 }}
                      >
                        <span className="fsm_text_ellipse">
                          Threat Category summary
                        </span>
                      </div>
                      <ThreatSummaryLineChart />
                    </div>
                  </Card>
                </Col>
                <Col xs={24} sm={24} md={8} lg={8}>
                  <div className="table_wrapp">
                    <Table
                      size="small"
                      // scroll={{y: true}}
                      className="category_table"
                      columns={categoryColumn}
                      dataSource={categoryData}
                      pagination={false}
                    />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row gutter={[16, 16]} className="mt-4">
            <Col xs={24} sm={24} md={12} lg={12}>
              <Card
                bodyStyle={{ padding: 0 }}
                className=""
                style={{ padding: 0 }}
              >
                <div className="area_chat_wrapper">
                  <div
                    className="commo_chat_header d-flex justify-content-between"
                    style={{ padding: 8 }}
                  >
                    <span className="fsm_text_ellipse">ASM Score</span>
                  </div>
                </div>
                <div className="asm_score_table_wrapper" style={{padding:'8px'}}> 
                <Table
                      size="small"
                      scroll={{y: 300}}
                      className="asm_score_table"
                      columns={ASMColumn}
                      dataSource={ASMData}
                      pagination={false}
                    />
                </div>
              </Card>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12}>
              <Card
               height={350}
                bodyStyle={{ padding: 0 }}
                className=""
                style={{ padding: 0 }}
              >
                <div className="area_chat_wrapper">
                  <div
                    className="commo_chat_header d-flex justify-content-between"
                    style={{ padding: 8 }}
                  >
                    <span className="fsm_text_ellipse">Questionnaire</span>
                  </div>
                </div>
                <div className="questionnaire_table_wrapper" style={{padding:'8px'}}>
                <Table
                      size="small"
                      scroll={{y: 300}}
                      className="category_table"
                      columns={ASMColumn}
                      dataSource={ASMData}
                      pagination={false}
                    />
                </div>
              </Card>
            </Col>
          </Row>
        </Collapse.Panel>
      </Collapse>
    </div>
  );
};

export default OverallDetailsTab;
