import React, { useEffect, useRef, useState } from "react";
import { Input, Space, Tag, Tooltip } from "antd";

const AutoCompleteChips = ({ value, onChange }) => {
  const inputRef = useRef(null);
  const [inputValue, setInputValue] = useState("");
  const [chipValue, setChipValue] = useState("");
  const [onEnter, setOnEnter]=useState(false)

  useEffect(() => {
    setInputValue(value || "");
    setChipValue(value || "");
    inputRef.current?.focus();
  }, [value]);

  const tagPlusStyle = {
    height: 22,
    borderStyle: "dashed",
    position: 'absolute',
    top: '38%',
    zIndex: 1,
    bottom: '50%',
    right: '10px'
  };

  const tagInputStyle = {
    width: "100%",
    marginInlineEnd: 8,
    verticalAlign: "top",
  };

  const handleInputChange = (e) => {
    const newValue = e.target.value;
    setInputValue(newValue);
    onChange(newValue);
  };

  const handleInputConfirm = () => {
    if (inputValue.trim()) {
      setChipValue(inputValue.trim());
      setInputValue("");
      setOnEnter(true)
    }
  };

  const handleCloseChip = () => {
    setChipValue("");
  };

  return (
    <div className="d-flex flex-column">
      <Space direction="vertical">
        {chipValue && onEnter && (
          <Tag closable onClose={handleCloseChip} style={tagPlusStyle}>
            <Tooltip title={chipValue}>{chipValue}</Tooltip>
          </Tag>
        )}
        <Input
          ref={inputRef}
          type="text"
          size="large"
          style={tagInputStyle}
          value={inputValue}
          onChange={handleInputChange}
          onPressEnter={handleInputConfirm}
          onBlur={handleInputConfirm}
          placeholder="Enter value"
        />
      </Space>
    </div>
  );
};

export default AutoCompleteChips;
