import React from 'react'
import { Tabs } from "antd";
import "./TabsWithExtraBtn.css";


    const Tab = ({ menu, callback }) => {
        const handleActiveTabs = (e) => {
          // console.log("active tabs ===", e);
          callback(e);
        };
      
        return (
          <Tabs
            className="tabs_with_btn"
            onChange={handleActiveTabs}
            items={menu}
            style={{ '--tabs-text-color': '#9494AE' }}
          />
        );
      };
export default Tab;

