import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { Collapse } from 'antd';
import React from 'react'

const References = () => {
    const customExpandIcon = (panelProps) => {
        const { isActive } = panelProps;
        return isActive ? <MinusOutlined /> : <PlusOutlined />;
      };
  return (
    <Collapse
    defaultActiveKey={"1"}
    expandIconPosition="end"
    expandIcon={customExpandIcon}
  >
    <Collapse.Panel
      style={{ background: "#DCDDE7", marginBottom: 8, fontSize:'13px',fontWeight:600 }}
      header="References"
      key="1"
    >
      <div>
        <div className="basic-details">              
              <p className="reference-para">
              NOTE: This product uses the NVD API but is not endorsed or certified by the NVD.: This product uses the NVD API but is not endorsed or certified by the NVD</p>
        </div>
        <div className='reference-detail'>
            <div className='redirect' ><i class="material-icons">open_in_new</i>About X Biz Vulnerabilities</div>
            <div className='chips' >X Biz</div>
        </div>
        <div className='reference-detail'>
            <div className='redirect' ><i class="material-icons">open_in_new</i>alephsecurity.com</div>
            <div className='chips' >X Biz</div>
        </div>
        <div className='reference-detail'>
            <div className='redirect' ><i class="material-icons">open_in_new</i>github.com</div>
            <div className='chips' >X Biz</div>
        </div>
        <div className='reference-detail'>
            <div className='redirect' ><i class="material-icons">open_in_new</i>About X Biz Vulnerabilities</div>
            <div className='chips' >X Biz</div>
        </div>
        <div className='reference-detail'>
            <div className='redirect' ><i class="material-icons">open_in_new</i>About X Biz Vulnerabilities</div>
            <div className='chips' >X Biz</div>
        </div>
      </div>
    </Collapse.Panel>
  </Collapse>
  )
}

export default References