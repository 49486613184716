import {
  AccountBookOutlined,
  ClockCircleOutlined,
  CloseOutlined,
  CloudOutlined,
  ExpandOutlined,
  EyeOutlined,
  MinusOutlined,
  PlusOutlined,
  RiseOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Drawer,
  Flex,
  Row,
  Select,
  Collapse,
  Table,
  Progress,
} from "antd";
import "./TPRMDashboard.css";
import React, { useState } from "react";
import InformationExposureChartPDF from "../../../components/charts/InformationExposureChartPDF";
import StatisticCard from "../../Common/statistic-card/StatisticCard";
import CategoryWiseIssues from "./category-wise-issues/CategoryWiseIssues";
import CategoryWiseIssueBarChart from "../../../components/charts/CategoryWiseIssueBarChart";
import TableSelectable from "../../Common/selectable-table/TableSelectable";
import ThreatsAcc from "../../../components/charts/ThreatsAcc";
import SmallModal from "../../Common/small-pop-modal/SmallModal";
import fileIcon from "../../../images/otherIcons/fileIcon.svg";
import FormStep from "../../Common/steps/FormStep";
import Identification from "./assessments-componnets/Identification";
import ProjectTable from "../../../components/charts/ProjectTable";
import ForceDirectedTreeChart from "./tree-chart/ForceDirectedTreeChart";
import TableCard from "../SC/sca-dashboard/components/TableCard";
import arming from "../../../images/icons/arming_countdown.png";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CardGraph from "../SC/sca-dashboard/components/CardGraph";
import { Area, AreaChart, ResponsiveContainer } from "recharts";
import ScoreTrendChart from "./score-trend-chart/ScoreTrendChart";
import HeaderNotation from "../../Common/grid-header-number-count/HeaderNotation";
import HeatmapChart from "./heatmap-chart/HeatmapChart";
import CustomProgressBar from "./custom-progressbar/CustomProgressBar";

const { RangePicker } = DatePicker;
const TableClomun = [
  {
    title: "Vendor Name",
    dataIndex: "vendor_name",
    key: "vendor_name",
    ellipsis: true,
  },
  {
    title: "Contract",
    dataIndex: "contract",
    key: "contract",
    ellipsis: true,
  },
];
const TableData = [
  {
    id: 1,
    vendor_name: "xbizventures pvt. ltd.",
    contract: "Tech Support agreement",
  },
  {
    id: 2,
    vendor_name: "Dion benoit Pvt. Ltd.",
    contract: "Claims Processing Agreerement",
  },
  {
    id: 3,
    vendor_name: "Huel Sons LLP",
    contract: "Claims Management Agreement",
  },
  {
    id: 4,
    vendor_name: "Dion benoit Pvt. Ltd.",
    contract: "Claims Processing Agreerement",
  },
  {
    id: 5,
    vendor_name: "xbizventures pvt. ltd.",
    contract: "Tech Support agreement",
  },
  {
    id: 6,
    vendor_name: "Huel Sons LLP",
    contract: "Claims Management Agreement",
  },
  {
    id: 7,
    vendor_name: "xbizventures pvt. ltd.",
    contract: "Tech Support agreement",
  },
];

//
const cardData = [
  {
    title: "All",
    percentage: 18,
    percentage_label: "Since last Month",
    count_value: 650,
    percenColor: "#E10505",
    countValueColor: "#2B318A",
    chartData: [
      {
        name: "Page A",
        uv: 4000,
        pv: 100,
        amt: 2400,
      },
      {
        name: "Page B",
        uv: 3000,
        pv: 200,
        amt: 2210,
      },
      {
        name: "Page C",
        uv: 2000,
        pv: 190,
        amt: 2290,
      },
      {
        name: "Page D",
        uv: 2780,
        pv: 400,
        amt: 2000,
      },
      {
        name: "Page E",
        uv: 1890,
        pv: 350,
        amt: 2181,
      },
      {
        name: "Page F",
        uv: 2390,
        pv: 210,
        amt: 2500,
      },
      {
        name: "Page G",
        uv: 3490,
        pv: 240,
        amt: 2100,
      },
      {
        name: "Page G",
        uv: 3490,
        pv: 180,
        amt: 2100,
      },
      {
        name: "Page G",
        uv: 3490,
        pv: 230,
        amt: 2100,
      },
    ],
  },
  {
    title: "Open",
    percentage: 16,
    percentage_label: "Since last Month",
    count_value: 185,
    percenColor: "#E10505",
    countValueColor: "#5A81FA",
    chartData: [
      {
        name: "Page A",
        uv: 4000,
        pv: 100,
        amt: 2400,
      },
      {
        name: "Page B",
        uv: 3000,
        pv: 200,
        amt: 2210,
      },
      {
        name: "Page C",
        uv: 2000,
        pv: 190,
        amt: 2290,
      },
      {
        name: "Page D",
        uv: 2780,
        pv: 400,
        amt: 2000,
      },
      {
        name: "Page E",
        uv: 1890,
        pv: 350,
        amt: 2181,
      },
      {
        name: "Page F",
        uv: 2390,
        pv: 210,
        amt: 2500,
      },
      {
        name: "Page G",
        uv: 3490,
        pv: 240,
        amt: 2100,
      },
      {
        name: "Page G",
        uv: 3490,
        pv: 180,
        amt: 2100,
      },
      {
        name: "Page G",
        uv: 3490,
        pv: 230,
        amt: 2100,
      },
    ],
  },
  {
    title: "Delayed",
    percentage: 24,
    percentage_label: "Since last Month",
    count_value: 159,
    percenColor: "#02B583",
    countValueColor: "#FE9706",
    chartData: [
      {
        name: "Page A",
        uv: 4000,
        pv: 100,
        amt: 2400,
      },
      {
        name: "Page B",
        uv: 3000,
        pv: 200,
        amt: 2210,
      },
      {
        name: "Page C",
        uv: 2000,
        pv: 190,
        amt: 2290,
      },
      {
        name: "Page D",
        uv: 2780,
        pv: 400,
        amt: 2000,
      },
      {
        name: "Page E",
        uv: 1890,
        pv: 350,
        amt: 2181,
      },
      {
        name: "Page F",
        uv: 2390,
        pv: 210,
        amt: 2500,
      },
      {
        name: "Page G",
        uv: 3490,
        pv: 240,
        amt: 2100,
      },
      {
        name: "Page G",
        uv: 3490,
        pv: 180,
        amt: 2100,
      },
      {
        name: "Page G",
        uv: 3490,
        pv: 230,
        amt: 2100,
      },
    ],
  },
  {
    title: "Onboarded",
    percentage: 12,
    percentage_label: "Since last Month",
    count_value: 254,
    percenColor: "#E10505",
    countValueColor: "#02B583",
    chartData: [
      {
        name: "Page A",
        uv: 4000,
        pv: 100,
        amt: 2400,
      },
      {
        name: "Page B",
        uv: 3000,
        pv: 200,
        amt: 2210,
      },
      {
        name: "Page C",
        uv: 2000,
        pv: 190,
        amt: 2290,
      },
      {
        name: "Page D",
        uv: 2780,
        pv: 400,
        amt: 2000,
      },
      {
        name: "Page E",
        uv: 1890,
        pv: 350,
        amt: 2181,
      },
      {
        name: "Page F",
        uv: 2390,
        pv: 210,
        amt: 2500,
      },
      {
        name: "Page G",
        uv: 3490,
        pv: 240,
        amt: 2100,
      },
      {
        name: "Page G",
        uv: 3490,
        pv: 180,
        amt: 2100,
      },
      {
        name: "Page G",
        uv: 3490,
        pv: 230,
        amt: 2100,
      },
    ],
  },
];
// statitcs card data end
const percent =45;
const filledStyle = {
  width: `${percent}%`
};
const drawerTableColumns = [
  {
    title: "Name",
    dataIndex: "name",
    width: "50%",
    render: (text) => <a>{text}</a>,
  },
  
  {
    title: "Score",
    dataIndex: "score",
    // width: "50%",
    render: (text, record) => ( <CustomProgressBar percent={record.score} />),
  },
];
const drawerTableData = [
  {
    key: "1",
    name: "Assessment 1",
    score: 40,
  },
  {
    key: "2",
    name: "Assessment 2",
    score: 50,
  },
  {
    key: "3",
    name: "Assessment 3",
    score: 70,
  },
  {
    key: "4",
    name: "Assessment 4",
    score: 60,
  },
  {
    key: "5",
    name: "Assessment 5",
    score: 30,
  },
  
];
const TPRMDashboard = () => {
  // project table column & data start

  const projectTableData = [
    {
      key: "1",
      status: "Open",
      threat_category: "Third Part Vendor Risk",
      vendor_name: "Velocious Solutions Pvt. Ltd.",
      completetion_date: "10/01/2023",
      // projectName: "Project 1",
      projectData: [
        {
          value: 30,
          initial: "C",
          color: "#9494AE",
          bgColor: "#E6E6E6",
        },
        {
          projectName: "Project 2",
          value: 80,
          initial: "H",
          color: "#F50000",
          bgColor: "#F500001F",
        },
        {
          projectName: "Project 3",
          value: 90,
          initial: "M",
          color: "#FE9706",
          bgColor: "#FE97061F",
        },
        {
          projectName: "Project 4",
          value: 30,
          initial: "L",
          color: "#02B583",
          bgColor: "#02B5831F",
        },
      ],
      code_issue: [
        {
          mainValue: 400,
          value: 30,
          initial: "C",
          color: "#9494AE",
          bgColor: "#E6E6E6",
        },
        {
          mainValue: 868,
          value: 80,
          initial: "H",
          color: "#F50000",
          bgColor: "#F500001F",
        },
        {
          mainValue: 208,
          value: 90,
          initial: "M",
          color: "#FE9706",
          bgColor: "#FE97061F",
        },
        {
          mainValue: 30,
          value: 30,
          initial: "L",
          color: "#02B583",
          bgColor: "#02B5831F",
        },
      ],
      chartBg: "#FF665347",
      endBg: "#FFBCB403",
      strokeColor: "#E10505",
      graphData: [
        { name: "Page A", uv: 620, pv: 1600, amt: 1600 },
        { name: "Page B", uv: 1200, pv: 1398, amt: 2210 },
        { name: "Page C", uv: 7000, pv: 3000, amt: 2290 },
        { name: "Page D", uv: 2400, pv: 2008, amt: 2000 },
        { name: "Page E", uv: 5500, pv: 2000, amt: 2181 },
        { name: "Page F", uv: 1100, pv: 3000, amt: 2500 },
        { name: "Page G", uv: 6020, pv: 2000, amt: 2100 },
        { name: "Page H", uv: 100, pv: 2000, amt: 2100 },
        { name: "Page I", uv: 8050, pv: 2000, amt: 2100 },
        { name: "Page I", uv: 1150, pv: 2000, amt: 2100 },
      ],
      title: "Weak cipher suites partnerbuddy.hdfcergo.com:443)",
      severity: "High",
      endOfLife: "20/01/2023",
      codeIssue_No: 400,
      vulnerability_No: 300,
      licence_No: 200,
      malicious_No: 500,
    },
    {
      key: "2",
      status: "Open",
      threat_category: "Cloud Risk",
      vendor_name: "Deloitte",
      completetion_date: "08/02/2023",
      // projectName: "Project 2",
      strokeColor: "#5E6BFF",
      chartBg: "#7976F69E",
      graphData: [
        {
          name: "Page A",
          uv: 60,
          pv: 60,
          amt: 40,
        },
        {
          name: "Page B",
          uv: 60,
          pv: 73,
          amt: 38,
        },
        {
          name: "Page C",
          uv: 90,
          pv: 56,
          amt: 76,
        },
        {
          name: "Page D",
          uv: 20,
          pv: 89,
          amt: 56,
        },
        {
          name: "Page E",
          uv: 40,
          pv: 100,
          amt: 58,
        },
        {
          name: "Page F",
          uv: 30,
          pv: 67,
          amt: 89,
        },
        {
          name: "Page G",
          uv: 87,
          pv: 98,
          amt: 67,
        },
        {
          name: "Page G",
          uv: 30,
          pv: 30,
          amt: 40,
        },
        {
          name: "Page G",
          uv: 50,
          pv: 40,
          amt: 40,
        },
      ],
      projectData: [
        {
          projectName: "Project 1",
          value: 30,
          initial: "C",
          color: "#9494AE",
          bgColor: "#E6E6E6",
        },
        {
          projectName: "Project 2",
          value: 80,
          initial: "H",
          color: "#F50000",
          bgColor: "#F500001F",
        },
        {
          projectName: "Project 3",
          value: 90,
          initial: "M",
          color: "#FE9706",
          bgColor: "#FE97061F",
        },
        {
          projectName: "Project 4",
          value: 30,
          initial: "L",
          color: "#02B583",
          bgColor: "#02B5831F",
        },
      ],
      code_issue: [
        {
          mainValue: 400,
          value: 30,
          initial: "C",
          color: "#9494AE",
          bgColor: "#E6E6E6",
        },
        {
          mainValue: 868,
          value: 80,
          initial: "H",
          color: "#F50000",
          bgColor: "#F500001F",
        },
        {
          mainValue: 208,
          value: 90,
          initial: "M",
          color: "#FE9706",
          bgColor: "#FE97061F",
        },
        {
          mainValue: 30,
          value: 30,
          initial: "L",
          color: "#02B583",
          bgColor: "#02B5831F",
        },
      ],
      title: "Weak cipher suites partnerbuddy.hdfcergo.com:443)",
      severity: "Medium",
      endOfLife: "20/01/2023",
      codeIssue_No: 400,
      vulnerability_No: 300,
      licence_No: 200,
      malicious_No: 500,
    },
    {
      key: "3",
      status: "Open",
      threat_category: "SCA Risk",
      vendor_name: "Xbiz Techventures Pvt Ltd.",
      completetion_date: "23/11/2023",
      projectName: "Project 3",
      chartBg: "#57CCA7",
      strokeColor: "#02B583",
      graphData: [
        {
          name: "Page A",
          uv: 2000,
          pv: 2400,
          amt: 2400,
        },
        {
          name: "Page B",
          uv: 5000,
          pv: 1398,
          amt: 2210,
        },
        {
          name: "Page C",
          uv: 3000,
          pv: 9800,
          amt: 2290,
        },
        {
          name: "Page D",
          uv: 3780,
          pv: 3908,
          amt: 2000,
        },
        {
          name: "Page E",
          uv: 1990,
          pv: 4800,
          amt: 2181,
        },
        {
          name: "Page F",
          uv: 5090,
          pv: 3800,
          amt: 2500,
        },
        {
          name: "Page G",
          uv: 3490,
          pv: 4300,
          amt: 2100,
        },
        {
          name: "Page G",
          uv: 4490,
          pv: 4300,
          amt: 2100,
        },
        {
          name: "Page G",
          uv: 4490,
          pv: 4300,
          amt: 2100,
        },
      ],
      projectData: [
        {
          projectName: "Project 1",
          value: 30,
          initial: "C",
          color: "#9494AE",
          bgColor: "#E6E6E6",
        },
        {
          projectName: "Project 2",
          value: 80,
          initial: "H",
          color: "#F50000",
          bgColor: "#F500001F",
        },
        {
          projectName: "Project 3",
          value: 90,
          initial: "M",
          color: "#FE9706",
          bgColor: "#FE97061F",
        },
        {
          projectName: "Project 4",
          value: 30,
          initial: "L",
          color: "#02B583",
          bgColor: "#02B5831F",
        },
      ],
      code_issue: [
        {
          mainValue: 400,
          value: 30,
          initial: "C",
          color: "#9494AE",
          bgColor: "#E6E6E6",
        },
        {
          mainValue: 868,
          value: 80,
          initial: "H",
          color: "#F50000",
          bgColor: "#F500001F",
        },
        {
          mainValue: 208,
          value: 90,
          initial: "M",
          color: "#FE9706",
          bgColor: "#FE97061F",
        },
        {
          mainValue: 30,
          value: 30,
          initial: "L",
          color: "#02B583",
          bgColor: "#02B5831F",
        },
      ],
      title: "Weak cipher suites partnerbuddy.hdfcergo.com:443)",
      // severity: <Button>Critical</Button>,
      severity: "Critical",
      identification_date: "20/01/2023",
      codeIssue_No: 400,
      vulnerability_No: 300,
      licence_No: 200,
      malicious_No: 500,
    },
    {
      key: "4",
      status: "Open",
      threat_category: "Infrastructural Risk",
      vendor_name: "Patron Info Tech Pvt Ltd",
      completetion_date: "23/11/2023",
      projectName: "Project 4",
      chartBg: "#FF665347",
      strokeColor: "#E10505",
      graphData: [
        {
          name: "Page A",
          uv: 60,
          pv: 60,
          amt: 40,
        },
        {
          name: "Page B",
          uv: 60,
          pv: 73,
          amt: 38,
        },
        {
          name: "Page C",
          uv: 90,
          pv: 56,
          amt: 76,
        },
        {
          name: "Page D",
          uv: 20,
          pv: 89,
          amt: 56,
        },
        {
          name: "Page E",
          uv: 40,
          pv: 100,
          amt: 58,
        },
        {
          name: "Page F",
          uv: 30,
          pv: 67,
          amt: 89,
        },
        {
          name: "Page G",
          uv: 87,
          pv: 98,
          amt: 67,
        },
        {
          name: "Page G",
          uv: 30,
          pv: 30,
          amt: 40,
        },
        {
          name: "Page G",
          uv: 50,
          pv: 40,
          amt: 40,
        },
      ],
      projectData: [
        {
          projectName: "Project 1",
          value: 30,
          initial: "C",
          color: "#9494AE",
          bgColor: "#E6E6E6",
        },
        {
          projectName: "Project 2",
          value: 80,
          initial: "H",
          color: "#F50000",
          bgColor: "#F500001F",
        },
        {
          projectName: "Project 3",
          value: 90,
          initial: "M",
          color: "#FE9706",
          bgColor: "#FE97061F",
        },
        {
          projectName: "Project 4",
          value: 30,
          initial: "L",
          color: "#02B583",
          bgColor: "#02B5831F",
        },
      ],
      code_issue: [
        {
          mainValue: 400,
          value: 30,
          initial: "C",
          color: "#9494AE",
          bgColor: "#E6E6E6",
        },
        {
          mainValue: 868,
          value: 80,
          initial: "H",
          color: "#F50000",
          bgColor: "#F500001F",
        },
        {
          mainValue: 208,
          value: 90,
          initial: "M",
          color: "#FE9706",
          bgColor: "#FE97061F",
        },
        {
          mainValue: 30,
          value: 30,
          initial: "L",
          color: "#02B583",
          bgColor: "#02B5831F",
        },
      ],
      title: "Weak cipher suites partnerbuddy.hdfcergo.com:443)",
      // severity: <Button>Low</Button>,
      severity: "Low",
      identification_date: "20/01/2023",
      codeIssue_No: 400,
      vulnerability_No: 300,
      licence_No: 200,
      malicious_No: 500,
    },
    {
      key: "5",
      status: "Open",
      threat_category: "Third Part Vendor Risk",
      vendor_name: "Vendor 5",
      completetion_date: "15/02/2023",
      projectName: "Project 5",
      strokeColor: "#5E6BFF",
      chartBg: "#7976F69E",
      graphData: [
        { name: "Page A", uv: 1220, pv: 1600, amt: 1600 },
        { name: "Page B", uv: 800, pv: 1398, amt: 2210 },
        { name: "Page C", uv: 1100, pv: 3000, amt: 2290 },
        { name: "Page D", uv: 1000, pv: 2008, amt: 2000 },
        { name: "Page E", uv: 400, pv: 2000, amt: 2181 },
        { name: "Page F", uv: 700, pv: 3000, amt: 2500 },
        { name: "Page G", uv: 920, pv: 2000, amt: 2100 },
        { name: "Page H", uv: 1100, pv: 2000, amt: 2100 },
        { name: "Page I", uv: 1150, pv: 2000, amt: 2100 },
        { name: "Page I", uv: 1350, pv: 2000, amt: 2100 },
      ],
      projectData: [
        {
          projectName: "Project 1",
          value: 30,
          initial: "C",
          color: "#9494AE",
          bgColor: "#E6E6E6",
        },
        {
          projectName: "Project 2",
          value: 80,
          initial: "H",
          color: "#F50000",
          bgColor: "#F500001F",
        },
        {
          projectName: "Project 3",
          value: 90,
          initial: "M",
          color: "#FE9706",
          bgColor: "#FE97061F",
        },
        {
          projectName: "Project 4",
          value: 30,
          initial: "L",
          color: "#02B583",
          bgColor: "#02B5831F",
        },
      ],
      code_issue: [
        {
          mainValue: 400,
          value: 30,
          initial: "C",
          color: "#9494AE",
          bgColor: "#E6E6E6",
        },
        {
          mainValue: 868,
          value: 80,
          initial: "H",
          color: "#F50000",
          bgColor: "#F500001F",
        },
        {
          mainValue: 208,
          value: 90,
          initial: "M",
          color: "#FE9706",
          bgColor: "#FE97061F",
        },
        {
          mainValue: 30,
          value: 30,
          initial: "L",
          color: "#02B583",
          bgColor: "#02B5831F",
        },
      ],
      title: "Weak cipher suites partnerbuddy.hdfcergo.com:443)",
      // severity: <Button>High</Button>,
      severity: "High",
      identification_date: "20/01/2023",
      codeIssue_No: 400,
      vulnerability_No: 300,
      licence_No: 200,
      malicious_No: 500,
    },
  ];

  const projectTableColumns = [
    {
      title: "Vendor Name",
      dataIndex: "vendor_details",
      key: "vendor_details",
      responsive: ["lg"],
      width: "25%",
      ellipsis: true,
      render: (event, data) => {
        console.log("vendor-name--->", data);
        return (
          <div className="vendor_details_info">
            <div className="vendor_comp">{data.vendor_name}</div>
            <div
              style={{ display: "flex", flexDirection: "column", gap: "2px" }}
            >
              <span className="vendor_schedule_completion_label">
                Scheduled completion date
              </span>
              <span className="vendor_schedule_completion_date">
                {data.completetion_date}
              </span>
            </div>
          </div>
          // <ProjectNameCard
          //   data={event}
          //   projectName={data.projectName}
          // />
        );
      },
    },
    {
      title: (
        <span style={{ whiteSpace: "nowrap" }}>
          <CloudOutlined style={{ marginRight: "4px", color: "#5A81FA" }} />
          Compliance Score
        </span>
      ),
      dataIndex: "code_issue",
      key: "code_issue",
      responsive: ["lg"],
      width: "20%",
      render: (event, data) => {
        return <TableCard data={event} mainvalue={data.codeIssue_No} />;
      },
    },
    {
      title: (
        <span style={{ whiteSpace: "nowrap" }}>
          <img
            src={arming}
            alt="Icon"
            style={{
              width: "14px",
              height: "14px",
              position: "relative",
              top: "-2px",
              left: "-2px",
            }}
          />
          Questionnaire Score
        </span>
      ),
      dataIndex: "code_issue",
      key: "code_issue",
      responsive: ["lg"],
      width: "20%",
      render: (event, data) => {
        return <TableCard data={event} mainvalue={data.codeIssue_No} />;
      },
    },
    {
      title: (
        <span style={{ whiteSpace: "nowrap" }}>
          <AccountBookOutlined
            style={{ marginRight: "4px", color: "#5A81FA" }}
          />
          assessment score
        </span>
      ),
      dataIndex: "code_issue",
      key: "code_issue",

      responsive: ["lg"],
      width: "20%",
      render: (event, data) => {
        return <TableCard data={event} mainvalue={data.licence_No} />;
      },
    },
    {
      title: (
        <span style={{ whiteSpace: "nowrap" }}>
          <WarningOutlined style={{ marginRight: "4px", color: "#5A81FA" }} />
          Score trend
        </span>
      ),
      dataIndex: "chart_data",
      key: "chart_data",
      responsive: ["lg"],
      width: "20%",
      render: (event, data) => {
        console.log("event--->", data);
        return (
          <div className="w-75">
            <ScoreTrendChart
              data={data.graphData}
              chartBg={data.chartBg}
              strokeColor={data.strokeColor}
            />
          </div>
        );
      },
    },

    {
      title: "Action",
      dataIndex: "endOfLife",
      key: "endOfLife",
      responsive: ["lg"],
      width: "20%",
      render: (event, data) => {
        return (
          <Row className="mt-3">
            <Col span={12}>
              <Flex  gap="small" align="center" style={{ color: "#5A81FA",cursor: 'pointer' }}>
                <span onClick={handleDrawer}>
                  <VisibilityIcon
                    style={{
                      fontSize: "18px",
                      // top: "-2px",
                      position: "relative",
                      color: "#5A81FA",
                      marginRight: "6px",
                      
                    }}
                  />
                  Summary
                </span>
              </Flex>
            </Col>
          </Row>
        );
      },
    },
  ];
  // project table column & data end

  
  // summary drawer start
  const [openDrawer, setOpenDrawer] = useState(false);
  const handleDrawer = () => {
    setOpenDrawer(true);
  };
  const handleCloseDrawer = () => {
    setOpenDrawer(false);
  };
  // summary drawer end
  // inside report modal state
  const [openInsiderepModal, setOpenInsideRepModal] = useState(false);
  const [reportDateRange, setReportDateRange] = useState(undefined);
  const [reportType, setReportType] = useState("summary");
  const OpenInsideRepoModal = () => {
    setOpenInsideRepModal(true);
  };
  //   console.log("===", reportType);
  const handleCloseInsideRepModal = () => {
    setOpenInsideRepModal(false);
  };

  const generateHandleReport = () => {
    // console.log("rep:", reportType, "date-range:", reportDateRange);
    handleCloseInsideRepModal();
  };
  // inside report modal state end

  //form steps modal
  const [IsAddAssessments, setIsAddAssessments] = useState(false);
  const openAssessmentsModal = () => {
    setIsAddAssessments(true);
  };
  const handleCloseAssessments = () => {
    setIsAddAssessments(false);
  };

  const stepMenuCom = [
    {
      title: "Identification",
      content: <Identification />,
    },
    {
      title: "Assign Assessments",
      content: <div>hello 2</div>,
    },
    {
      title: "Digital Assessments",
      content: <div>hello 3</div>,
    },
    {
      title: "Additional Rules",
      content: <div>hello 4</div>,
    },
    {
      title: "Preview",
      content: <div>hello 5</div>,
    },
  ];

  //   form step modal

  // drawer card vontent
  const DrawerCardContent = [
    { id: 1, label: "Vendor Email", value: "Vendor@gmail.com" },
    { id: 2, label: "Contact no", value: "9860179162" },
    { id: 3, label: "Company name", value: "ABC ltd" },
    { id: 4, label: "Operational state", value: "Maharashtra" },
    { id: 5, label: "Operational City", value: "Mumbai" },
    { id: 6, label: "Head Quarter", value: "NA" },
    { id: 7, label: "Spoc Name", value: "xyz" },
    { id: 8, label: "Spoc Email", value: "asdf@gmail.com" },
    { id: 9, label: "Spoc Number", value: 85 },
    { id: 10, label: "Business Spoc", value: "Business Spoc" },
    { id: 11, label: "Manager NT ID", value: "NA" },
    { id: 12, label: "Manager Name", value: "Sanket Jain" },
    { id: 13, label: "Upload File", value: "12,210" },
    { id: 14, label: "Auto Generated View", value: "NA" },
    { id: 15, label: "Criticality", value: "Medium", color: "#FE9706" },
  ];
  // drawer end
  // header notation data start
   const HeaderNotData = [
    {id: 1, count: '05', color: "#5A81FA", category: "Total"},
    {id: 1, count: '01', color: "#02B583", category: "Completed"},
    {id: 1, count: '04', color: "#FE9706", category: "Ongoing"},
   ]
  // header notation data end

  const customExpandIcon = (panelProps) => {
    const { isActive } = panelProps;
    return isActive ? (
      <MinusOutlined style={{ fontSize: 14 }} />
    ) : (
      <PlusOutlined style={{ fontSize: 14 }} />
    );
  };
  return (
    <>
      <div className="third_party_mngmnt_dashbord">
        <div className="dashboard_head_des_parent d-flex justify-content-between">
          <div className="welcome-to-tata-container  ">
            <div className="title_with_wrap_des">
              <div>
                <span className="welcome-to">Welcome to </span>
                <b className="tata-group">Tata Group</b>
                <span className="welcome-to"> Dashboard</span>
              </div>
              <div className="stay_ahead_of">
                stay ahead of emerging threats through real-time monitoring
              </div>
            </div>
            <div className="showing_sync_ver_as_on_parent">
              <div className="showing_sync_ver">
                <i> Showing Sync ver. as on:</i>
                <i className="th_jan_2024">5th Jan 2024</i>
                <ClockCircleOutlined style={{ fontSize: 16, color: "red" }} />
              </div>
            </div>
          </div>

          <div className="scan_history align-items-end">
            <div className="company_select d-flex gap-3">
              <Select
                //   defaultValue=""
                placeholder="Select Source"
                allowClear
                style={{
                  width: "100%",
                  minWidth: 150,
                }}
              >
                <Select.Option value="Assessment">Assessment</Select.Option>
                <Select.Option value="Infosec Assessment">
                  Infosec Assessment
                </Select.Option>
                <Select.Option value="Onboarding">Onboarding</Select.Option>
              </Select>

              <Select
                defaultValue="Tata motors"
                allowClear
                placeholder="Tata Groups"
                style={{
                  width: "100%",
                  minWidth: 150,
                }}
              >
                <Select.Option value="Tata motors">Tata motors</Select.Option>
                <Select.Option value="Tata steel ltd">
                  Tata steel ltd
                </Select.Option>
                <Select.Option value="Tata capital">Tata capital</Select.Option>
              </Select>
            </div>
            <div onClick={OpenInsideRepoModal} className="insight_report">
              <EyeOutlined style={{ fontSize: 14, color: "#5a81fa" }} />
              insight report
            </div>
          </div>
        </div>
        <Row gutter={[16, 16]}>
          <Col md={8} lg={8}>
            <Card className="main_pichart h-100" style={{ padding: 0 }}>
              <div className="head_wrapper px-3 py-2">
                <div className="commo_chat_header">Venders Distribution</div>
              </div>
              <InformationExposureChartPDF
                width={600}
                height={300}
                showLegend={true}
                showNotation={false}
              />
            </Card>
          </Col>
          <Col md={16} lg={16}>
            <Card className="risk_assessment_card h-100" style={{ padding: 0 }}>
              <div className="commo_chat_header d-flex align-items-center gap-1 px-3 py-2">
                <span>Risk Assessment Summary</span>
              </div>
              <Row gutter={[16, 16]} style={{ padding: 10 }}>
                <Col md={18}>
                  <StatisticCard propsData={cardData} />
                </Col>
                <Col md={6}>
                  <button
                    onClick={openAssessmentsModal}
                    className="start_assessment_btn"
                    type="button"
                    style={{background: '#f2f6f5'}}
                  >
                    {<PlusOutlined />}
                    <div
                      style={{
                        marginTop: 8,
                      }}
                    >
                      Start Assessment
                    </div>
                  </button>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <Row gutter={[10, 16]}>
          <Col md={17} lg={15}>
            <Card className="h-100" style={{ padding: 0 }} bodyStyle={{height: '100%'}}>
              <Row>
                <Col md={10}>
                  <CategoryWiseIssues />
                </Col>
                <Col className="p-2" md={14}>
                  <div className="d-flex gap-2 justify-content-end">
                    <div className="vendor_Select">
                      <Select
                        //   defaultValue=""
                        placeholder="All Vendor"
                        allowClear
                        style={{
                          width: "100%",
                          minWidth: 150,
                        }}
                      >
                        <Select.Option value="Assessment">
                          Assessment
                        </Select.Option>
                        <Select.Option value="Infosec Assessment">
                          Infosec Assessment
                        </Select.Option>
                        <Select.Option value="Onboarding">
                          Onboarding
                        </Select.Option>
                      </Select>
                    </div>
                    <div className="vendor_Select">
                      <Select
                        //   defaultValue=""
                        placeholder="All Tier"
                        allowClear
                        style={{
                          width: "100%",
                          minWidth: 100,
                        }}
                      >
                        <Select.Option value="Assessment">
                          Assessment
                        </Select.Option>
                        <Select.Option value="Infosec Assessment">
                          Infosec Assessment
                        </Select.Option>
                        <Select.Option value="Onboarding">
                          Onboarding
                        </Select.Option>
                      </Select>
                    </div>
                  </div>
                  <CategoryWiseIssueBarChart />
                </Col>
              </Row>
            </Card>
          </Col>
          <Col md={7} lg={9}>
            <Card
              className="h-100"
              headStyle={{ padding: "8px 12px", borderBottom: "none",fontSize: 14, fontWeight: 600, fontFamily:'Open Sans',lineHeight:'19px',color:'#232337' }}
              title="Expiring Contracts"
              bodyStyle={{ padding: "0 12px" }}
            >
              <TableSelectable
                TableClomun={TableClomun}
                TableData={TableData}
                size="small"
              />
            </Card>
          </Col>
        </Row>
        {/* vendor destribution */}
        <Row gutter={[16, { xs: 16, sm: 16, md: 8, lg: 8 }]}>
          <Col xs={24} sm={24} md={24} lg={15}>
            <Card style={{ padding: 16 }}>
              <div className="d-flex flex-column gap-3">
                <div className="assets_header d-flex justify-content-between">
                  <div style={{fontSize:14}} className="assets_title d-inline-flex gap-2 align-items-center">
                    Global Vendor Distribution{" "}
                    <RiseOutlined style={{ color: "red" }} />
                  </div>
                  <ExpandOutlined />
                </div>
                <Row gutter={[8, { xs: 16, sm: 16, md: 8, lg: 8 }]}>
                  <Col xs={24} sm={24} md={24} lg={24}>
                    <ThreatsAcc threadTitle="TOP VENDORS" />
                  </Col>
                </Row>
              </div>
            </Card>
          </Col>
          <Col xs={24} sm={24} md={24} lg={9}>
            <Card style={{ padding: 16 }}>
              <div className="discovered_assets">
                <div className="header_title d-flex justify-content-between">
                  <div className="header_label commo_chat_header d-flex align-items-center gap-2">
                    <span> Vendor Security Ratings by Business Impact</span>
                  </div>
                </div>
              </div>
              <HeatmapChart /> 
            </Card>
          </Col>
        </Row>
        <Row gutter={[8, { xs: 16, sm: 16, md: 8, lg: 8 }]}>
          <Col sm={24} md={24} lg={24}>
            <Card style={{ padding: 16 }}>
              <div className="vendor_list_card_wrapper">
                <div className="vendor_list_header d-flex justify-content-between align-items-center">
                  <div className="vendor_list_title">Vendor List</div>
                  <div className="filter_and_view_btn">
                  <Select size="small" placeholder="Filter By">
                    <Select.Option value="Filter 1">Filter 1</Select.Option>
                    <Select.Option value="Filter 2">Filter 2</Select.Option>
                  </Select>
                  <a className="view_more">View More</a>
                </div>
                </div>
            <ProjectTable
              tableColumn={projectTableColumns}
              tableData={projectTableData}
            />
            </div>
            </Card>
          </Col>
        </Row>
        <Card className="main_pichart h-100" style={{ padding: 0 }}>
          <div className="head_wrapper px-3 py-2">
            <div className="commo_chat_header">Venders Distribution</div>
          </div>
          <ForceDirectedTreeChart />
        </Card>
      </div>
      {/* inside report modal  */}
      <SmallModal
        width={700}
        open={openInsiderepModal}
        CloseModal={handleCloseInsideRepModal}
        title="Generate Insight Report"
        closable={true}
      >
        <div className="modal_content_wrap d-flex flex-column gap-3">
          <p className="label_header">
            Select the format below to generate the report
          </p>
          <div className="report_type_content" id="report-type">
            <label className="report_type_label" htmlFor="report-type">
              Report Type <span className="required_mark text-danger">*</span>
            </label>
            <div className="d-flex gap-3">
              <Button
                size="large"
                className="d-flex align-items-center"
                icon={<img width={30} src={fileIcon} alt="file" />}
                onClick={() => setReportType("summary")}
                block
                type="default"
              >
                summary
              </Button>
              <Button
                size="large"
                className="d-flex align-items-center"
                icon={<img width={30} src={fileIcon} alt="file" />}
                onClick={() => setReportType("trendsummary")}
                block
                type="default"
              >
                TrendSummary
              </Button>
            </div>
          </div>
          <div className="date_range w-100">
            <label className="report_type_label" htmlFor="report-type">
              Date Range <span className="required_mark text-danger">*</span>
            </label>
            <RangePicker
              onChange={(date) => setReportDateRange(date)}
              style={{ width: "100%" }}
              block
              size="large"
            />
          </div>
          <div className="button_submit_groug d-flex justify-content-end gap-3">
            <Button
              onClick={handleCloseInsideRepModal}
              size="large"
              type="default"
            >
              Cancel
            </Button>
            <Button onClick={generateHandleReport} size="large" type="primary">
              Generate Report
            </Button>
          </div>
        </div>
      </SmallModal>
      {/* inside report modal end  */}
      {/* assessment modal start*/}
      <SmallModal
        width={700}
        open={IsAddAssessments}
        CloseModal={handleCloseAssessments}
        closable={true}
        title="Add Assessments"
      >
        <FormStep
          stepsMenu={stepMenuCom}
          onCloseModal={handleCloseAssessments}
        />
      </SmallModal>
      {/* assessment modal end*/}

      {/* drawer summary start */}
      <Drawer
        className="summary_drawer"
        // title="Velocious Solutions Pvt. Ltd Summary"
        placement={"right"}
        height={"100vh"}
        width={500}
        footer={null}
        closable={false}
        onClose={handleCloseDrawer}
        bodyStyle={{ background: "#FFFFFF" }}
        visible={openDrawer}
      >
        <div className="drawer_wrapper_container d-flex flex-column gap-3">
          <div className="header_drawer d-flex justify-content-between">
            <div className="drawer_title">
              Velocious Solutions Pvt. Ltd Summary
            </div>
            <CloseOutlined
              onClick={handleCloseDrawer}
              style={{ color: "#000" }}
            />
          </div>
          <div className="text-center d-flex justify-content-center">
            <div className="group_circle">
              <div className="ellipse_circle">
                <span className="secteam_confirm">secteam CONFIRMED</span>
              </div>
            </div>
          </div>
          <div className="card_drawer">
            <div className="cart_item_wrap">
              {DrawerCardContent.map((el) => (
                <div className="item_wrap d-flex">
                  <div className="item_label w-50">{el.label}</div>
                  <div className="item_value w-50" style={{ color: el.color }}>
                    {el.value}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <Collapse
            bordered={false}
            borderRadius={0}
            expandIcon={customExpandIcon}
            expandIconPosition="end"
            style={{background: '#FFF'}}
            defaultActiveKey="1"
            className="drawer_collapse"
          >
            <Collapse.Panel
              style={{ background: "#DCDDE7", marginBottom: 10 }}
              header="Assessment Details"
              key="1"
            >
              <div className="d-flex flex-column gap-3" style={{background: "#fff"}}>
              <HeaderNotation data={HeaderNotData} />
              <Table
                size="small"
                columns={drawerTableColumns}
                dataSource={drawerTableData}
                bordered
                pagination={false}
              />
              </div>
            </Collapse.Panel>
          </Collapse>

          <div className="cta_btn_view_more">
            <button className="btn view_more_btn">
              <EyeOutlined style={{ fontSize: 21, color: "#5A81FA" }} /> View
              More Details
            </button>
          </div>
        </div>
      </Drawer>
      {/* drawer summary end */}
    </>
  );
};

export default TPRMDashboard;
