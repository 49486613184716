import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Label,
} from "recharts";

const data = [
  {
    name: "SQL Injection",
    critical: 4000,
    high: 2400,
    medium: 2000,
    low: 4000,
    amt: 2400,
  },
  {
    name: "Remote Code Execution",
    critical: 3000,
    high: 1398,
    medium: 1200,
    low: 1200,
    amt: 2210,
  },
  {
    name: "Cross-Site Scripting",
    critical: 2000,
    high: 9800,
    medium: 7000,
    low: 8000,
    amt: 2290,
  },
  {
    name: "Insecure Deserialization",
    critical: 2780,
    high: 3908,
    medium: 5300,
    low: 4000,
    amt: 2000,
  },
  {
    name: "Misconfigurations",
    critical: 1890,
    high: 4800,
    medium: 2300,
    low: 5000,
    amt: 2181,
  },
  {
    name: "Exposure of Sensitive Data",
    critical: 2390,
    high: 3800,
    medium: 3300,
    low: 4100,
    amt: 2500,
  },
  {
    name: "Minor Bugs",
    critical: 3490,
    high: 4300,
    medium: 2300,
    low: 4700,
    amt: 2100,
  },
];

const ThreatFoundChartPDF = ({ width, height }) => {
  return (
    <div className="threat_found_chart_for_pdf">
      <BarChart
        width={width ? width : 500}
        height={height ? height : 300}
        data={data}
        barSize={30}
      >
        <CartesianGrid stroke="none" />
        <XAxis type="category" dataKey="name">
          <Label
            value="Category"
            startOffset={0}
            position="bottom"
            style={{
              textAnchor: "middle",
              fontWeight: "bold",
            }}
          />
        </XAxis>
        <YAxis>
          <Label
            value="Risk Count"
            angle={-90}
            position="insideLeft"
            style={{
              textAnchor: "middle",
              fontWeight: "bold",
            }}
          />
        </YAxis>
        <Tooltip />
        <Legend
          wrapperStyle={{
            position: "relative",
          }}
          className="ehsan"
          style={{ position: "relative" }}
        />
        <Bar dataKey="critical" stackId="a" fill="#C50202" />
        <Bar dataKey="high" stackId="a" fill="#FC3E3E" />
        <Bar dataKey="medium" stackId="a" fill="#FA9F1E" />
        <Bar dataKey="low" stackId="a" fill="#02A678" />
      </BarChart>
    </div>
  );
};

export default ThreatFoundChartPDF;
