import { CopyOutlined, MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Collapse, Input, Select } from 'antd'
import TextArea from 'antd/es/input/TextArea';
import React from 'react'


const { Option } = Select;

const SolutionFix = () => {
    const customExpandIcon = (panelProps) => {
        const { isActive } = panelProps;
        return isActive ? <MinusOutlined /> : <PlusOutlined />;
      };
      const handleCopyText = () => {
        // Logic to copy text from text field
        const textFieldValue = document.getElementById('textField').value;
        navigator.clipboard.writeText(textFieldValue);
      };
  return (
    <Collapse
    defaultActiveKey={"1"}
    expandIconPosition="end"
    expandIcon={customExpandIcon}
  >
    <Collapse.Panel
      style={{ background: "#DCDDE7",marginBottom: 8, fontSize:'13px',fontWeight:600 }}
      header="Solution Fix"
      key="1"
    >
      <div>
        <div className="basic-details">
              <h6 className="solution-heading" >Solution</h6>              
              <p className="solution-para">
              Remove or update the package(s) and version(s) which are causing this vulnerability</p>
        </div>
        <div className="basic-details">
              <h6 className="solution-heading" >Fix</h6>              
              <p className="solution-para">
              Update to a vulnerability-free version of the package 'newtonsoft.json' in your manifest to ensure that the vulnerable version is no longer used, even if it is still referenced in your dependency tree</p>
        </div>
        <div className="dropdown-container">
      <div className="dropdown-column">
        <h2 className='solution-dropdown' >Dropdown 1</h2>
        <Select defaultValue="Option 1" style={{ width: 200 }}>
          <Option value="Option 1">Option 1</Option>
          <Option value="Option 2">Option 2</Option>
          <Option value="Option 3">Option 3</Option>
        </Select>
      </div>
      <div className="dropdown-column">
        <h2 className='solution-dropdown' >Dropdown 2</h2>
        <Select defaultValue="Option 1" style={{ width: 200 }}>
          <Option value="Option 1">Option 1</Option>
          <Option value="Option 2">Option 2</Option>
          <Option value="Option 3">Option 3</Option>
        </Select>
      </div>
      <div className="dropdown-column">
        <h2 className='solution-dropdown' >Dropdown 3</h2>
        <Select defaultValue="Option 1" style={{ width: 200 }}>
          <Option value="Option 1">Option 1</Option>
          <Option value="Option 2">Option 2</Option>
          <Option value="Option 3">Option 3</Option>
        </Select>
      </div>
      <TextArea id="textArea" rows={2} style={{ width: 200,height:'30px' }} defaultValue="Some default text..." disabled />
      {/* <Input id="textField" style={{ width: 200 }} icon={<CopyOutlined />}  /> */}
        {/* <Button onClick={handleCopyText} icon={<CopyOutlined />} /> */}
    </div>
      </div>
    </Collapse.Panel>
  </Collapse>
  )
}

export default SolutionFix