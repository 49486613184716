import { Select } from "antd";
import React from "react";
const { Option } = Select;

const CustomSelect = ({ data, size, onChange, mode, placeholder }) => {
  const handleOnchange = (value) => {
    onChange(value);
  };

  return (
    <div>
      <Select
        size={size}
        mode={mode}
        style={{ width: "100%" }}
        id={data.field_id}
        name={data.field_id}
        onChange={handleOnchange}
        placeholder={placeholder || "Select"}
      >
        {/* <Option value="">select</Option> */}
        {data.dropdownvalues.map(({ key, value }) => (
          <Option key={key} value={key}>
            {value}
          </Option>
        ))}
      </Select>
    </div>
  );
};

export default CustomSelect;
