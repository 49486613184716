import { DeleteOutlined } from "@ant-design/icons";
import { Button, Card, Col, Flex, Pagination, Row, Space, Table, Tabs } from "antd";
import React, { useState } from "react";
import "./PolicySummary.css";
import ScaBarGraph from "./SCA_BarGraph";

const pieChartData = [
  {
    type: "new",
    value: 40,
  },
  {
    type: "evaluating",
    value: 25,
  },
  {
    type: "ongoing",
    value: 22,
  },
  {
    type: "finished",
    value: 22,
  },
  {
    type: "archived",
    value: 10,
  },
];

const config = {
  appendPadding: 10,
  data: pieChartData,
  angleField: "value",
  colorField: "type",
  radius: 1,
  innerRadius: 0.5,
  label: {
    type: "inner",
    offset: "-50%",
    content: "{value}",
    style: {
      textAlign: "center",
      fontSize: 14,
    },
  },
  interactions: [{ type: "element-selected" }, { type: "element-active" }],
  statistic: {
    title: false,
    content: {
      style: {
        whiteSpace: "pre-wrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
      formatter: function formatter() {
        return `total\n134`;
      },
    },
  },
};

function PolicySummary() {
  const onChange = (key) => {
    console.log(key);
  };
  const items = [
    {
      key: "1",
      label: "All(100)",
      //   children: 'Content of Tab Pane 1',
    },
    {
      key: "2",
      label: "Cloud Risk(25)",
      //   children: 'Content of Tab Pane 2',
    },
    {
      key: "3",
      label: "Third Party Vendor Risk(20)",
      //   children: 'Content of Tab Pane 3',
    },
    {
      key: "4",
      label: "SCA Risk(30)",
      //   children: 'Content of Tab Pane 3',
    },
    {
      key: "5",
      label: "Infrastructural Risk(25)",
      //   children: 'Content of Tab Pane 3',
    },
  ];

  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});
  const handleChange = (pagination, filters, sorter) => {
    console.log("Various parameters", pagination, filters, sorter);
    setFilteredInfo(filters);
    setSortedInfo(sorter);
  };
  const clearFilters = () => {
    setFilteredInfo({});
  };
  const clearAll = () => {
    setFilteredInfo({});
    setSortedInfo({});
  };
  const setAgeSort = () => {
    setSortedInfo({
      order: "descend",
      columnKey: "age",
    });
  };

  const data = [
    {
      key: "1",
      status: "Open",
      threat_category: "Third Part Vendor Risk",
      threat_type: "SSL/TLS",
      title: "Weak cipher suites partnerbuddy.hdfcergo.com:443)",
      severity: "High",
      identification_date: "20/01/2023",
    },
    {
      key: "2",
      status: "Open",
      threat_category: "Cloud Risk",
      threat_type: "SSL/TLS",
      title: "Weak cipher suites partnerbuddy.hdfcergo.com:443)",
      severity: "Medium",
      identification_date: "20/01/2023",
    },
    {
      key: "3",
      status: "Open",
      threat_category: "SCA Risk",
      threat_type: "SSL/TLS",
      title: "Weak cipher suites partnerbuddy.hdfcergo.com:443)",
      // severity: <Button>Critical</Button>,
      severity: "Critical",
      identification_date: "20/01/2023",
    },
    {
      key: "4",
      status: "Open",
      threat_category: "Infrastructural Risk",
      threat_type: "SSL/TLS",
      title: "Weak cipher suites partnerbuddy.hdfcergo.com:443)",
      // severity: <Button>Low</Button>,
      severity: "Low",
      identification_date: "20/01/2023",
    },
    {
      key: "5",
      status: "Open",
      threat_category: "Third Part Vendor Risk",
      threat_type: "SSL/TLS",
      title: "Weak cipher suites partnerbuddy.hdfcergo.com:443)",
      // severity: <Button>High</Button>,
      severity: "High",
      identification_date: "20/01/2023",
    },
    {
      key: "6",
      status: "Open",
      threat_category: "Cloud Risk",
      threat_type: "SSL/TLS",
      title: "Weak cipher suites partnerbuddy.hdfcergo.com:443)",
      // severity: <Button>Medium</Button>,
      severity: "Medium",
      identification_date: "20/01/2023",
    },
    {
      key: "7",
      status: "Open",
      threat_category: "Third Part Vendor Risk",
      threat_type: "SSL/TLS",
      title: "Weak cipher suites partnerbuddy.hdfcergo.com:443)",
      // severity: <Button>Low</Button>,
      severity: "Low",
      identification_date: "20/01/2023",
    },
  ];

  const columns = [
    {
      title: "Policy Name",
      dataIndex: "status",
      key: "status",
      width: "15%",
      filteredValue: filteredInfo.status || null,
      
      ellipsis: true,
    },
    {
      title: "Policy Type",
      dataIndex: "threat_type",
      key: "threat_type",
      width: "15%",
      filteredValue: filteredInfo.threat_type || null,
      onFilter: (value, record) => record.threat_type.includes(value),
      sorter: (a, b) => a.threat_type.length - b.threat_type.length,
      ellipsis: true,
      sortOrder:
        sortedInfo.columnKey === "threat_type" ? sortedInfo.order : null,
    },
    {
      title: "Description",
      dataIndex: "title",
      key: "title",
      sorter: (a, b) => a.title - b.title,
      sortOrder: sortedInfo.columnKey === "title" ? sortedInfo.order : null,
      ellipsis: true,
      width: "35%",
    },
    {
        title: "Violation Count",
        dataIndex: "threat_type",
        key: "threat_type",
        width: "20%",
        filteredValue: filteredInfo.threat_type || null,
        onFilter: (value, record) => record.threat_type.includes(value),
        sorter: (a, b) => a.threat_type.length - b.threat_type.length,
        ellipsis: true,
        sortOrder:
          sortedInfo.columnKey === "threat_type" ? sortedInfo.order : null,
      },
    {
      title: "Severity",
      dataIndex: "severity",
      key: "severity",
      sorter: (a, b) => a.severity - b.severity,
      sortOrder: sortedInfo.columnKey === "severity" ? sortedInfo.order : null,
      ellipsis: true,
      width: "15%",
      render: (text, record) => {
        let buttonColor = "";

        switch (record.severity.toLowerCase()) {
          case "high":
            buttonColor = "#F50000";
            break;
          case "medium":
            buttonColor = "#FE9706";
            break;
          case "critical":
            buttonColor = "#F50000";
            break;
          case "low":
            buttonColor = "#02B583";
            break;
          default:
            buttonColor = "#000";
            break;
        }

        return (
          <Button
            size="small"
            className="table_btn_severity"
            style={{
              borderColor: buttonColor,
              color: buttonColor,
            }}
          >
            {record.severity}
          </Button>
        );
      },
    },

    // {
    //   title: "Identification Date",
    //   dataIndex: "identification_date",
    //   key: "identification_date",
    //   width: "15%",
    //   sorter: (a, b) => a.identification_date - b.identification_date,
    //   sortOrder:
    //     sortedInfo.columnKey === "identification_date"
    //       ? sortedInfo.order
    //       : null,
    //   ellipsis: true,
    // },
  ];
  const BarGraphdata = [
    {
      name: "Policy 1",
      uv: 4000,
      pv: 2400,
      amt: 2400,
    },
    {
      name: "Policy 1",
      uv: 3000,
      pv: 6798,
      amt: 2210,
    },
    {
      name: "Policy 1",
      uv: 2000,
      pv: 5000,
      // amt: 2290,
    },
    {
      name: "Policy 1",
      uv: 2780,
      pv: 6008,
      amt: 2000,
    },
    {
      name: "Policy 1",
      uv: 18,
      pv: 4800,
      amt: 2181,
    },
    {
      name: "Policy 1",
      uv: 2390,
      pv: 3500,
      amt: 2500,
    },
    {
      name: "Policy 1",
      uv: 3490,
      pv: 6000,
      amt: 2100,
    },
    {
      name: "Policy 1",
      uv: 3890,
      pv: 3000,
      amt: 2100,
    },
    {
      name: "Policy 1",
      uv: 5090,
      pv: 6400,
      amt: 2100,
    },
    {
      name: "Policy 1",
      uv: 3790,
      pv: 4900,
      amt: 2100,
    },
    {
      name: "Policy 1",
      uv: 2690,
      pv: 5000,
      amt: 2100,
    },
    
  ];
  let gradientColors = [
    { offset: '0%', stopColor: '#E11326' },
    { offset: '50%', stopColor: '#FF3344' },
    { offset: '100%', stopColor: '#FF7D59' }
  ];
  const itemRender = (current, type, originalElement) => {
    if (type === 'prev') {
      return <a>Previous</a>;
    }
    if (type === 'next') {
      return <a>Next</a>;
    }
    return originalElement;
  };
    const [currentPage, setCurrentPage] = useState(1);
    const totalRecords = 50; // Total number of records in your table
    const pageSize = 10; // Number of records per page

    const startRecord = (currentPage - 1) * pageSize + 1;
    const endRecord = Math.min(currentPage * pageSize, totalRecords);

    const handlePageChange = (page) => {
      setCurrentPage(page);
    };
  return (
    <div className="policy_summary_chart">
      <Card style={{ padding: 16 }}>
        <Flex justify='space-between'>
        <div className="threat_summary_title">Policy Summary</div>
        <div
                  style={{
                    fontSize: "13px",
                    fontWeight:600,
                    textDecoration: "underline",
                    color: "#5A81FA",
                    cursor:'pointer'
                  }}
                  // onClick={() => viewMoreBtn()}
                >
                  View More
                </div>
          </Flex>
        <Row gutter={[16, { xs: 16, sm: 16, md: 8, lg: 8 }]}>
          <Col xs={24} sm={24} md={24} lg={12}>
          <ScaBarGraph gradientColors={gradientColors} data={BarGraphdata} width="100%" height={400} color={''} />
            
          </Col>
          <Col xs={24} sm={24} md={24} lg={12}>
            {/* <Tabs defaultActiveKey="1" items={items} onChange={onChange} /> */}
           
            <Table
               size="small"
                className="summary_chart"
                columns={columns}
                dataSource={data}
                style={{ width: "100%" }}
                // scroll={{ x: 600 }}
                onChange={handleChange}
                pagination={false} 
              />
              <div style={{ display: 'flex', marginTop: '10px',  justifyContent: 'space-between', alignItems: 'center', color:'#63637D', padding:'7px', fontWeight:600, fontSize:'13px' }} > 
                <div>
                  Showing {startRecord}-{endRecord} entries out of {totalRecords}
                </div>
                <Pagination
                style={{color:'#63637D', padding:'5px', fontWeight:600, fontSize:'12px' }}
                current={currentPage}
                pageSize={pageSize}
                total={totalRecords}
                itemRender={itemRender}
                onChange={handlePageChange}
                showSizeChanger={false} 
                
                />
              </div>
              
          </Col>
        </Row>
      </Card>
    </div>
  );
}

export default PolicySummary;
