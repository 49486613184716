import { Col, Row, Statistic } from "antd";
import React from "react";
import "./StaticsCard.css";
import { ArrowDownOutlined } from "@ant-design/icons";
import { Line, LineChart } from "recharts";
// const data = [
//   {
//     name: "Page A",
//     uv: 4000,
//     pv: 100,
//     amt: 2400,
//   },
//   {
//     name: "Page B",
//     uv: 3000,
//     pv: 200,
//     amt: 2210,
//   },
//   {
//     name: "Page C",
//     uv: 2000,
//     pv: 190,
//     amt: 2290,
//   },
//   {
//     name: "Page D",
//     uv: 2780,
//     pv: 400,
//     amt: 2000,
//   },
//   {
//     name: "Page E",
//     uv: 1890,
//     pv: 350,
//     amt: 2181,
//   },
//   {
//     name: "Page F",
//     uv: 2390,
//     pv: 210,
//     amt: 2500,
//   },
//   {
//     name: "Page G",
//     uv: 3490,
//     pv: 240,
//     amt: 2100,
//   },
//   {
//     name: "Page G",
//     uv: 3490,
//     pv: 180,
//     amt: 2100,
//   },
//   {
//     name: "Page G",
//     uv: 3490,
//     pv: 230,
//     amt: 2100,
//   },
// ];

const StatisticCard = ({propsData,openStatisticModal}) => {
   console.log('propsData-->',propsData)
  return (
    <>
      {/* <div className="d-flex flex-wrap gap-2"> */}
      <Row gutter={[8,8]} >
        {propsData?.map((item) => (
          <Col sm={24} md={12} lg={12}>
          <div onClick={openStatisticModal} className="statistic_card" style={{background: item.background}}>
            <div className="title_label" style={{color : item.titleColor}}>{item.title}</div>
            <div className="content_wrapper">
              <div className="statics">
                <div className="statics_with_title d-flex gap-2">
                  <Statistic
                    // title="Since last Month"
                    value={item.percentage}
                    precision={2}
                    valueStyle={{
                      color: item.percenColor ? item.percenColor : "#cf1322",
                      fontSize: "14px",
                      minWidth: "70px",
                    }}
                    prefix={<ArrowDownOutlined />}
                    suffix="%"
                  />
                  <span className="statics_title">{item.percentage_label}</span>
                </div>
                <div
                  className="count_number"
                  style={{ color: item.countValueColor }}
                >
                  {item.count_value}
                </div>
              </div>
              <div className="statics_chart">
                <LineChart width={100} height={100} data={item.chartData} style={{ filter: `drop-shadow(2px 2px 4px ${item.countValueColor ? item.countValueColor : "rgba(0,0,0,0.3)"})` }}>
                  <Line
                    dot={false}
                    type="monotone"
                    dataKey="pv"
                    stroke={
                      item.countValueColor ? item.countValueColor : "#8884d8"
                    }
                    strokeWidth={2}
                  />
                </LineChart>
              </div>
            </div>
          </div>
          </Col>
        ))}
        </Row>
      {/* </div> */}
    </>
  );
};

export default StatisticCard;
