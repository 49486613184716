import React from "react";
import { AreaChart, Area, ResponsiveContainer } from "recharts";

const AreaChartSecret = () => {
  const data = [
    { name: "Page A", uv: 900, pv: 1600, amt: 1600 },
    { name: "Page B", uv: 880, pv: 1398, amt: 2210 },
    { name: "Page C", uv: 1200, pv: 3000, amt: 2290 },
    { name: "Page D", uv: 1000, pv: 2008, amt: 2000 },
    { name: "Page E", uv: 800, pv: 2000, amt: 2181 },
    { name: "Page F", uv: 1050, pv: 3000, amt: 2500 },
    { name: "Page G", uv: 700, pv: 2000, amt: 2100 },
    { name: "Page H", uv: 1100, pv: 2000, amt: 2100 },
    { name: "Page I", uv: 1190, pv: 2000, amt: 2100 },
  ];

  return (
    <ResponsiveContainer width="100%" height={60}>
      <AreaChart
        className="svg_wrapper"
        // width={200}
        // height={80}
        data={data}
        margin={{
          top: 5,
          right: 0,
          left: 0,
        }}
      >
        <defs>
          <linearGradient
            id="areaGradient_secret"
            x1="127.5"
            y1="-6.12359"
            x2="127"
            y2="147.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.0001" stopColor="white" stopOpacity="0" />
            <stop offset="0.0002" stopColor="white" />
            <stop offset="1" stopColor="#7976F7" />
          </linearGradient>
        </defs>
        <Area
          type="monotone"
          dataKey="uv"
          stroke="#7976F7"
          fill="url(#areaGradient_secret)"
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default AreaChartSecret;
