import { Col, Row } from "antd";
import React from "react";
import "./CardView.css";

const CardView = () => {
  return (
    <div className="card_view">
      <Row gutter={[8, 8]}>
        <Col xs={12} sm={12} md={6} lg={6}>
          <div className="value">Open</div>
          <label className="text_label">status</label>
        </Col>
        <Col xs={12} sm={12} md={6} lg={6}>
          <div className="value">NA</div>
          <label className="text_label">TAT</label>
        </Col>
        <Col xs={12} sm={12} md={6} lg={6}>
          <div className="value">https:/securityheaders.com/</div>
          <label className="text_label">Affected URL</label>
        </Col>
        <Col xs={12} sm={12} md={6} lg={6}>
          <div className="value">Temporary Directory Found</div>
          <label className="text_label">Description</label>
        </Col>
        <Col xs={12} sm={12} md={6} lg={6}>
          <div className="value">Temporary director found</div>
          <label className="text_label">Title</label>
        </Col>
        <Col xs={12} sm={12} md={6} lg={6}>
          <div className="value">New</div>
          <label className="text_label">Type</label>
        </Col>
        <Col xs={12} sm={12} md={6} lg={6}>
          <div className="value">Valis SIS (Vulnerability Assessment)</div>
          <label className="text_label">Asset</label>
        </Col>
        <Col xs={12} sm={12} md={6} lg={6}>
          <div className="value" style={{ color: "#FE9706" }}>
            Medium
          </div>
          <label className="text_label">Severity Status</label>
        </Col>
        <Col xs={12} sm={12} md={6} lg={6}>
          <div className="value">Temporary director found</div>
          <label className="text_label">Vulnerability List</label>
        </Col>
        <Col xs={12} sm={12} md={6} lg={6}>
          <div className="value">NA</div>
          <label className="text_label">Final Severity</label>
        </Col>
      </Row>
    </div>
  );
};

export default CardView;
