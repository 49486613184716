import { DeleteOutlined } from "@ant-design/icons";
import { Button, Card, Col, Pagination, Row, Space, Table, Tabs } from "antd";
import React, { useState } from "react";
import "./SummaryChat.css";
import SummaryPieChart from "./SummaryPieChart";

const pieChartData = [
  {
    type: "new",
    value: 40,
  },
  {
    type: "evaluating",
    value: 25,
  },
  {
    type: "ongoing",
    value: 22,
  },
  {
    type: "finished",
    value: 22,
  },
  {
    type: "archived",
    value: 10,
  },
];

const config = {
  appendPadding: 10,
  data: pieChartData,
  angleField: "value",
  colorField: "type",
  radius: 1,
  innerRadius: 0.5,
  label: {
    type: "inner",
    offset: "-50%",
    content: "{value}",
    style: {
      textAlign: "center",
      fontSize: 14,
    },
  },
  interactions: [{ type: "element-selected" }, { type: "element-active" }],
  statistic: {
    title: false,
    content: {
      style: {
        whiteSpace: "pre-wrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
      formatter: function formatter() {
        return `total\n134`;
      },
    },
  },
};

function SummaryChart() {
  const onChange = (key) => {
    console.log(key);
  };
  const items = [
    {
      key: "1",
      label: "All(100)",
      //   children: 'Content of Tab Pane 1',
    },
    {
      key: "2",
      label: "Cloud Risk(25)",
      //   children: 'Content of Tab Pane 2',
    },
    {
      key: "3",
      label: "Third Party Vendor Risk(20)",
      //   children: 'Content of Tab Pane 3',
    },
    {
      key: "4",
      label: "SCA Risk(30)",
      //   children: 'Content of Tab Pane 3',
    },
    {
      key: "5",
      label: "Infrastructural Risk(25)",
      //   children: 'Content of Tab Pane 3',
    },
  ];

  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});
  const handleChange = (pagination, filters, sorter) => {
    console.log("Various parameters", pagination, filters, sorter);
    setFilteredInfo(filters);
    setSortedInfo(sorter);
  };

  // pagination start

  const itemRender = (current, type, originalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }
    return originalElement;
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const [currentPage, setCurrentPage] = useState(1);
  const totalRecords = 100; // Total number of records in your table
  const pageSize = 10; // Number of records per page

  const startRecord = (currentPage - 1) * pageSize + 1;
  const endRecord = Math.min(currentPage * pageSize, totalRecords);
  // pagination end

  const clearFilters = () => {
    setFilteredInfo({});
  };
  const clearAll = () => {
    setFilteredInfo({});
    setSortedInfo({});
  };
  const setAgeSort = () => {
    setSortedInfo({
      order: "descend",
      columnKey: "age",
    });
  };

  const data = [
    {
      key: "1",
      status: "Open",
      threat_category: "Third Part Vendor Risk",
      threat_type: "SSL/TLS",
      title: "Weak cipher suites partnerbuddy.hdfcergo.com:443)",
      severity: "High",
      identification_date: "20/01/2023",
    },
    {
      key: "2",
      status: "Open",
      threat_category: "Cloud Risk",
      threat_type: "SSL/TLS",
      title: "Weak cipher suites partnerbuddy.hdfcergo.com:443)",
      severity: "Medium",
      identification_date: "20/01/2023",
    },
    {
      key: "3",
      status: "Open",
      threat_category: "SCA Risk",
      threat_type: "SSL/TLS",
      title: "Weak cipher suites partnerbuddy.hdfcergo.com:443)",
      // severity: <Button>Critical</Button>,
      severity: "Critical",
      identification_date: "20/01/2023",
    },
    {
      key: "4",
      status: "Open",
      threat_category: "Infrastructural Risk",
      threat_type: "SSL/TLS",
      title: "Weak cipher suites partnerbuddy.hdfcergo.com:443)",
      // severity: <Button>Low</Button>,
      severity: "Low",
      identification_date: "20/01/2023",
    },
    {
      key: "5",
      status: "Open",
      threat_category: "Third Part Vendor Risk",
      threat_type: "SSL/TLS",
      title: "Weak cipher suites partnerbuddy.hdfcergo.com:443)",
      // severity: <Button>High</Button>,
      severity: "High",
      identification_date: "20/01/2023",
    },
    {
      key: "6",
      status: "Open",
      threat_category: "Cloud Risk",
      threat_type: "SSL/TLS",
      title: "Weak cipher suites partnerbuddy.hdfcergo.com:443)",
      // severity: <Button>Medium</Button>,
      severity: "Medium",
      identification_date: "20/01/2023",
    },
    {
      key: "7",
      status: "Open",
      threat_category: "Third Part Vendor Risk",
      threat_type: "SSL/TLS",
      title: "Weak cipher suites partnerbuddy.hdfcergo.com:443)",
      // severity: <Button>Low</Button>,
      severity: "Low",
      identification_date: "20/01/2023",
    },
  ];

  const columns = [
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: "16%",
      render: (text)=>(<span style={{color: '#E10505'}}>{text}</span>),
      filteredValue: filteredInfo.status || null,
      onFilter: (value, record) => record.status.includes(value),
      sorter: (a, b) => a.status.length - b.status.length,
      sortOrder: sortedInfo.columnKey === "status" ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: "Threat Type",
      dataIndex: "threat_type",
      key: "threat_type",
      width: "16%",
      filteredValue: filteredInfo.threat_type || null,
      onFilter: (value, record) => record.threat_type.includes(value),
      sorter: (a, b) => a.threat_type.length - b.threat_type.length,
      sortOrder:
        sortedInfo.columnKey === "threat_type" ? sortedInfo.order : null,
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      sorter: (a, b) => a.title - b.title,
      sortOrder: sortedInfo.columnKey === "title" ? sortedInfo.order : null,
      ellipsis: true,
      width: "40",
    },
    {
      title: "Severity",
      dataIndex: "severity",
      key: "severity",
      sorter: (a, b) => a.severity - b.severity,
      sortOrder: sortedInfo.columnKey === "severity" ? sortedInfo.order : null,
      ellipsis: true,
      width: "15%",
      render: (text, record) => {
        let buttonColor = "";

        switch (record.severity.toLowerCase()) {
          case "high":
            buttonColor = "#F50000";
            break;
          case "medium":
            buttonColor = "#FE9706";
            break;
          case "critical":
            buttonColor = "#F50000";
            break;
          case "low":
            buttonColor = "#02B583";
            break;
          default:
            buttonColor = "#000";
            break;
        }

        return (
          <Button
           size="small"
            className="table_btn_severity"
            style={{
              borderColor: buttonColor,
              color: buttonColor,
            }}
          >
            {record.severity}
          </Button>
        );
      },
    },

    {
      title: "Identification Date",
      dataIndex: "identification_date",
      key: "identification_date",
      width: "15%",
      sorter: (a, b) => a.identification_date - b.identification_date,
      sortOrder:
        sortedInfo.columnKey === "identification_date"
          ? sortedInfo.order
          : null,
      ellipsis: true,
    },
  ];
  return (
    <div className="summary_chart_wrapper">
      <Card style={{ padding: 16 }}>
        {/* <div className="threat_summary_title">Threat Summary Severity Wise</div> */}
        <div className="header_title d-flex justify-content-between align-items-center">
                  <div className="threat_summary_title mb-0">
                  Threat Summary Severity Wise
                  </div>
                  <a className="view_more_or_delete">
                    <span className="view"> View More</span>
                  </a>
                </div>
        <Row gutter={[16, { xs: 16, sm: 16, md: 8, lg: 8 }]}>
          <Col xs={24} sm={24} md={24} lg={7}>
            <SummaryPieChart />
            <div className="d-flex justify-content-center gap-3">
              <div className="d-flex align-items-center gap-2">
                <span className="dot"></span>
                <span>Open Threats</span>
              </div>

              <div className="d-flex align-items-center gap-2">
                <span style={{ background: "#02B583" }} className="dot"></span>
                <span>Close Threats</span>
              </div>
            </div>
          </Col>
          <Col xs={24} sm={24} md={24} lg={17}>
            <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
            <Card className="summary_chart_table">
              <Table
               size="small"
                className="summary_chart"
                columns={columns}
                dataSource={data}
                style={{ width: "100%", fontFamily: 'Open Sans' }}
                scroll={{ x: 600 }}
                onChange={handleChange}
                pagination={false}
              />
                <div
              style={{
                display: "flex",
                marginTop: "10px",
                justifyContent: "space-between",
                alignItems: "center",
                color: "#63637D",
                padding: "7px",
                fontWeight: 600,
                fontSize: "13px",
              }}
            >
              <div>
                Showing {startRecord}-{endRecord} entries out of {totalRecords}
              </div>
              <Pagination
                style={{
                  color: "#63637D",
                  padding: "5px",
                  fontWeight: 600,
                  fontSize: "12px",
                }}
                current={currentPage}
                pageSize={pageSize}
                total={totalRecords}
                itemRender={itemRender}
                onChange={handlePageChange}
                showSizeChanger={false}
              />
            </div>
            </Card>
          </Col>
        </Row>
      </Card>
    </div>
  );
}

export default SummaryChart;
