import { Collapse, Flex, Col } from "antd";
import React from "react";
import { ReactComponent as ExclamationIcon } from "../../../../../images/icons/exclamation.svg";
import { ReactComponent as CheckIcon } from "../../../../../images/icons/done.svg";
import { ReactComponent as CrossIcon } from "../../../../../images/icons/close.svg";
import "./DiscoveredLicenseDrawer.css";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";

const DiscoveredLicenseDrawer = () => {
  const customExpandIcon = (panelProps) => {
    const { isActive } = panelProps;
    return isActive ? <MinusOutlined /> : <PlusOutlined />;
  };
  return (
    <div className="collapse-discovered-license">
      <Collapse
        defaultActiveKey={"1"}
        expandIconPosition="end"
        expandIcon={customExpandIcon}
      >
        <Collapse.Panel
          style={{ background: "#DCDDE7", marginBottom: 8, fontSize:'13px',fontWeight:600 }}
          header="Basic Details"
          key="1"
        >
          <Flex>
            <Col
              xs={24}
              sm={10}
              md={10}
              lg={10}
            >
              <div className="sub-detail">ShielderSec/webtech is licensed under the</div>
              <div className="detail">GNU General Public License v2.3</div>
              <p className="sub-detail-para">Permissions of this strong copyleft license are conditioned on making available complete source code of licensed works and modifications, which include larger works using a licensed work, under the same license. Copyright and license notices must be preserved. Contributors provide an express grant of patent rights.</p>
            </Col>
            <Col
              xs={24}
              sm={14}
              md={14}
              lg={14}
              className="discovered-icons"
            >
                <Flex className="flex-style">
                    <Col
                    xs={24}
                    sm={4}
                    md={4}
                    lg={4}
                    >
                        <div className="discovered-title">Permissions</div>
                        <div className="discovered-icon-detail">
                            <ExclamationIcon
                                className="discovered-exclamation-icon-style"
                                style={{ color: "#FE9706", backgroundColor: "#ffeacd" }}
                            />{" "}
                            Commercial Use
                        </div>
                        <div className="discovered-icon-detail">
                            <CheckIcon
                                className="discovered-exclamation-icon-style"
                                style={{color:'#02B583',backgroundColor:'#e6f8f3'}}
                            />{" "}
                            Private Use
                        </div>
                        <div className="discovered-icon-detail">
                            <ExclamationIcon
                                className="discovered-exclamation-icon-style"
                                style={{ color: "#FE9706", backgroundColor: "#ffeacd" }}
                            />{" "}
                            Distribution
                        </div>
                        <div className="discovered-icon-detail">
                            <CheckIcon
                                className="discovered-exclamation-icon-style"
                                style={{color:'#02B583',backgroundColor:'#e6f8f3'}}
                            />{" "}
                            State Changing
                        </div>
                    </Col>
                    <Col
                    xs={24}
                    sm={4}
                    md={4}
                    lg={4}
                    >
                        <div className="discovered-title">Limitations</div>
                        <div className="discovered-icon-detail">
                            <CrossIcon
                                className="discovered-exclamation-icon-style"
                                style={{color:'#02B583',backgroundColor:'#e6f8f3'}}
                            />{" "}
                            Warranty
                        </div>
                        <div className="discovered-icon-detail">
                            <CrossIcon
                                className="discovered-exclamation-icon-style"
                                style={{color:'#02B583',backgroundColor:'#e6f8f3'}}
                            />{" "}
                            Liability
                        </div>
                    </Col>
                    <Col
                    xs={24}
                    sm={4}
                    md={4}
                    lg={4}
                    >
                        <div className="discovered-title">Conditions</div>
                        <div className="discovered-icon-detail">
                            <ExclamationIcon
                                className="discovered-exclamation-icon-style"
                                style={{ color: "#FE9706", backgroundColor: "#ffeacd" }}
                            />{" "}
                            Commercial Use
                        </div>
                        <div className="discovered-icon-detail">
                            <CheckIcon
                                className="discovered-exclamation-icon-style"
                                style={{color:'#02B583',backgroundColor:'#e6f8f3'}}
                            />{" "}
                            Private Use
                        </div>
                        <div className="discovered-icon-detail">
                            <ExclamationIcon
                                className="discovered-exclamation-icon-style"
                                style={{ color: "#FE9706", backgroundColor: "#ffeacd" }}
                            />{" "}
                            Distribution
                        </div>
                        <div className="discovered-icon-detail">
                            <CheckIcon
                                className="discovered-exclamation-icon-style"
                                style={{color:'#02B583',backgroundColor:'#e6f8f3'}}
                            />{" "}
                            State Changing
                        </div>
                    </Col>
                </Flex>
            </Col>
          </Flex>
        </Collapse.Panel>
      </Collapse>
    </div>
  );
};

export default DiscoveredLicenseDrawer;
