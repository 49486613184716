import { Card } from "antd";
import React from "react";
import "./CardOption.css";

const dataValue = [
  { id: 1, grade: "A+", grade_val: 1209266 },
  { id: 1, grade: "A", grade_val: 1209266 },
  { id: 1, grade: "B", grade_val: 1209266 },
  { id: 1, grade: "C", grade_val: 1209266 },
  { id: 1, grade: "D", grade_val: 1209266 },
  { id: 1, grade: "E", grade_val: 1209266 },
  { id: 1, grade: "F", grade_val: 1209266 },
  { id: 1, grade: "R", grade_val: 1209266 },
];
const CardOption1 = () => {
  return (
    <div className="card_option_1">
      <Card
        headStyle={{ background: "#ECECF6" }}
        style={{ width: 300 }}
        title="Grand Totals"
      >
        {dataValue.map((item) => (
          <div className="grade_wrapper d-flex justify-content-between border-bottom py-1">
            <div
              className="grade"
              style={{
                background:
                  item.grade === "A+"
                    ? "#02B583"
                    : item.grade === "A"
                    ? "#02B554"
                    : item.grade === "B"
                    ? "#FBCC53"
                    : item.grade === "C"
                    ? "#FEBB3A"
                    : item.grade === "D"
                    ? "#FEAD3A"
                    : item.grade === "E"
                    ? "#FE9706"
                    : item.grade === "F"
                    ? "#D90000"
                    : item.grade === "R"
                    ? "#9494AE"
                    : "",
              }}
            >
              {item.grade}
            </div>
            <div className="grade_val">{item.grade_val.toLocaleString()}</div>
          </div>
        ))}

        <div className="total_footer d-flex justify-content-between py-1">
          <div className="total_text">Total</div>
          <div className="total_value">
            {dataValue
              .reduce((acc, obj) => acc + obj.grade_val, 0)
              .toLocaleString()}
          </div>
        </div>
      </Card>
    </div>
  );
};

export default CardOption1;
