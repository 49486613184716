// Footer.js
import React from "react";
import { Layout } from "antd";

const { Footer } = Layout;

const FooterComponent = () => {
  return (
    <Footer
      style={{
        textAlign: "center",
        padding: 0,
        background: "#000",
        color: "#fff",
      }}
    >
      {/* Your footer content goes here */}
      <p>Footer</p>
    </Footer>
  );
};

export default FooterComponent;
