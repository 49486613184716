import React from "react";
import { AreaChart, Area, ResponsiveContainer } from "recharts";

const AreaChartCertificate = () => {
  const data = [
    { name: "Page A", uv: 1220, pv: 1600, amt: 1600 },
    { name: "Page B", uv: 1100, pv: 1398, amt: 2210 },
    { name: "Page C", uv: 1100, pv: 3000, amt: 2290 },
    { name: "Page D", uv: 1100, pv: 2008, amt: 2000 },
    { name: "Page E", uv: 1300, pv: 2000, amt: 2181 },
    { name: "Page F", uv: 1000, pv: 3000, amt: 2500 },
    { name: "Page G", uv: 1020, pv: 2000, amt: 2100 },
    { name: "Page H", uv: 1000, pv: 2000, amt: 2100 },
    { name: "Page I", uv: 1050, pv: 2000, amt: 2100 },
    { name: "Page I", uv: 1150, pv: 2000, amt: 2100 },
    { name: "Page I", uv: 1200, pv: 2000, amt: 2100 },
  ];

  return (
    <ResponsiveContainer width="100%" height={60}>
      <AreaChart
        className="svg_wrapper"
        data={data}
        margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
      >
        <defs>
          <linearGradient
            id="areaGradient_certificate"
            x1="0"
            y1="0"
            x2="0"
            y2="1"
          >
            <stop offset="0%" stopColor="#FFBCB4" stopOpacity={0.2} />
            <stop offset="96%" stopColor="#FF6653" />
          </linearGradient>
        </defs>
        <Area
          type="basis"
          dataKey="uv"
          stroke="#FF6653"
          fill="url(#areaGradient_certificate)"
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default AreaChartCertificate;
