import React from "react";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Collapse,
  Divider,
  Dropdown,
  Flex,
  Menu,
  Row,
  Switch,
  Table,
  Tag,
  Typography,
} from "antd";
import FilterListOutlinedIcon, {
  DownloadOutlined,
  MinusOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import './CollapseTopIssues.css'
import CommanPieChart from "./CommanPieChart.js";
const { Title, Text } = Typography;
const CollapseTopIssues = () => {
  const cardData = [
    {
      title: "Base Score",
      value: "4.6 HIGH",
      bgColor:'#FF3043',
    },
    {
      title: "Impact Score",
      value: "3.6 Low",
      bgColor:'rgba(2, 181, 131, 1)'
    },
    {
      title: "Exploitability Score",
      value: "3.6 Medium",
      bgColor:'rgba(254, 151, 6, 1)'
    },
    {
      title: "Attack Vector",
      value: "Network",
      color:'#232337'
    },
    {
      title: "Attack Complexity",
      value: "Low",
      color:"#02B583",
    },
    {
      title: "Availability Impact",
      value: "High",
      color: "#E10505"
    },
    {
      title: "Confidentiality Impact",
      value: "None",
      color:"#232337"
    },
    {
      title: "Integrity Impact",
      value: "None",
      color: "#232337"
    },
    {
      title: "Privileges Required",
      value: "None",
      color: "#232337"
    },
    {
      title: "Scope",
      value: "Unchanged",
      color : "#FE9706"
    },
  ];
  const customExpandIcon = (panelProps) => {
    const { isActive } = panelProps;
    return isActive ? <MinusOutlined /> : <PlusOutlined />;
  };
  const data = [
    { name: "Group A", value: 3870 },
    { name: "Group B", value: 9860 },
  ];

  const colors = ["#f54343", "#f5a643", "#43f58b"];
  return (
    <Collapse
      defaultActiveKey={"1"}
      expandIconPosition="end"
      expandIcon={customExpandIcon}
    >
      <Collapse.Panel
        style={{ background: "#DCDDE7",marginBottom: 8, fontSize:'13px',fontWeight:600 }}
        header="Basic Details"
        key="1"
      >
        {/* <Row
          gutter={[35, 35]}
          justify="center"
        > */}
        <Flex>
          <Col
            xs={24}
            sm={8}
            md={8}
            lg={8}
            style={{backgroundColor:'rgba(250, 250, 250, 1)'}}
          >
            <div className='chart-overlay'>
              {/* <OverAllRiskRatingChart width={230} height={220} img={true} fillPercentage={79} /> */}
              {/* <ProjectListPieChart width={230} height={220} img={true} fillPercentage={10} /> */}
              <CommanPieChart isProjectTable={true} score={45} />
            </div>
                <Card className="min-200" style={{ padding: 0 }}>
                    {cardData.map((item, index) => (
                        <div key={index}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center',padding:'10px 16px 12px 0px'}}>
                            <div style={{ fontWeight: index < 3 ? 'bold' : 'normal',fontSize:'13px' }}>{item.title}</div>
                            {index < 3 ? (
                              <Tag
                              key={index}
                              style={{
                                display: 'flex',
                                alignItems: 'center', // Align items vertically in the center
                                justifyContent: 'center', 
                                fontWeight: 600,
                                fontSize: '13px',
                                width: '84px',
                                height: '27px',
                                borderRadius: '18px',
                                borderColor:'transparent',
                                backgroundColor: item.bgColor,
                                color: item.color,
                                cursor: 'pointer',
                                padding: '5px',
                                marginRight: '8px',
                              }}
                              ><span style={{color:'#FFFFFF'}} >
                              {item.value}
                              </span>
                            </Tag>
                            // <Tag color="blue">{item.value}</Tag>
                            ) : (
                            <div className="card-value" style={{position:'relative',right:'25px',color:item.color}} >{item.value}</div>
                            )}
                        </div>
                        {index < cardData.length - 1 && <Divider plain style={{ borderTop: '1px solid #d9d9d9', margin: '2px' }} />}
                        </div>
                    ))}
                 </Card>

          </Col>
          <Col
            xs={24}
            sm={14}
            md={14}
            lg={14}
          >
            <div className="basic-details">
              <h6 className="heading" >Overview</h6>
              <p className="overview">jquery is a package that makes things like HTML document traversal and manipulation, event handling, animation, and Ajax much simpler with an easy-to-use API that works across a multitude of browsers.</p>
              <p className="overview">
                Affected versions of this package are vulnerable to Cross-site Scripting (XSS). Passing HTML from untrusted sources - even after sanitizing it - to one of jQuery's DOM manipulation methods (i.e. .html(), .append(), and others) may execute untrusted code.</p>
            </div>
            <div className="basic-details">
              <h6 className="heading" >Description</h6>
              <p className="overview">jquery is a package that makes things like HTML document traversal and manipulation, event handling, animation, and Ajax much simpler with an easy-to-use API that works across a multitude of browsers.</p>
              <p className="overview">
                Affected versions of this package are vulnerable to Cross-site Scripting (XSS). Passing HTML from untrusted sources - even after sanitizing it - to one of jQuery's DOM manipulation methods (i.e. .html(), .append(), and others) may execute untrusted code.</p>
            </div>
            <div className="second-component" >
              <Col
                sm={12}
                md={12}
                lg={12}
              >
                <div className="title" >NA</div>
                <div className="sub-title" >CVSS 3</div>
                <div className="title" >CVSS:3.1/AV:PR:N/UI:N/S:U/C:H/1:H/A:H</div>
                <div className="sub-title"  >CVSS 3</div>
                <div className="title" > 0.003970000</div>
                <div className="sub-title" >SCAN ID</div>
                <div className="title">Temporary director found</div>
                <div className="sub-title"  >CVSS 3</div>
                <div className="title">NA</div>
                <div className="sub-title"  >CVSS 3</div>
                <div className="title">NA</div>
                <div className="sub-title"  >CVSS 3</div>
              </Col>
              <Col
                sm={12}
                md={12}
                lg={12}
              >
                <div className="title" >NA</div>
                <div className="sub-title" >CVSS 3</div>
                <div className="title" >CVSS:3.1/AV:PR:N/UI:N/S:U/C:H/1:H/A:H</div>
                <div className="sub-title"  >CVSS 3</div>
                <div className="title" > 0.003970000</div>
                <div className="sub-title" >SCAN ID</div>
                <div className="title">Temporary director found</div>
                <div className="sub-title"  >CVSS 3</div>
                <div className="title">NA</div>
                <div className="sub-title"  >CVSS 3</div>
                <div className="title">NA</div>
                <div className="sub-title"  >CVSS 3</div>
              </Col>
            </div>
          </Col>
        </Flex>
        {/* </Row> */}
      </Collapse.Panel>
    </Collapse>
  );
};

export default CollapseTopIssues;
