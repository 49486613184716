import { DeleteOutlined, DownOutlined, EditOutlined, EyeTwoTone, SearchOutlined } from '@ant-design/icons'
import { Button, Card, Input, Tree } from 'antd'
import React from 'react'
import './DependencyTree.css';
import CommonBottomDrawer from '../../../../Common/commonBottomDrawer/CommonBottomDrawer';
import TabsWithExtraBtn from '../../../../Common/tabs-with-extra-content/TabsWithExtraBtn';
import { useState } from 'react';
import CollapseDependencyTree from './CollapseDependencyTree.js';

const DependencyTree = (props) => {
    const onSelect = (selectedKeys, info) => {
      // console.log('Hello--->', selectedKeys[0]);
      if(selectedKeys[0] === "0-0-0-0"){
        console.log('inside proper key!!!');
        showDrawerModal()
      }
    };
    const tabsMenu = [
      {
        key: "all_details",
        label: "All Details",
        children: <CollapseDependencyTree />,
      },
      
    ];
    const operations = (
      <div className="extra_btn">
        <Button
          // onClick={handleOpenSmallModal}
          icon={<DeleteOutlined />}
          style={{ borderColor: "#1677ff", color: "#1677ff" }}
        >
          Delete
        </Button>
        <Button
          icon={<EditOutlined />}
          style={{ borderColor: "#1677ff", color: "#FFF", background: "#5A81FA" }}
        >
          Edit
        </Button>
      </div>
    );
    const [activeTabs, setActiveTabs] = useState("Vulnerability Details");
    const callback = (activekey) => {
      setActiveTabs(activekey);
      console.log("active-key ==", activekey);
    };
    const [openDrawer, setOpenDrawer] = useState(false);
    const showDrawerModal = ()=>{
      console.log('onclick drawerModal');
      setOpenDrawer(true);
    }
  return (
    <>
    {/* <div className='top-issues'>DependencyTree</div> */}
    <div className='top-issues'><Input
      placeholder="Search packages"
      prefix={<SearchOutlined />}
      style={{ width: '-webkit-fill-available' }} 
    /></div>
    <Tree
    showLine
    switcherIcon={<DownOutlined />}
    defaultExpandedKeys={['0-0-0']}
    onSelect={onSelect}
    treeData={props.treeData}
    style={{
        fontSize: '14px',
        color: '#333',
        backgroundColor: '#fff',
        borderRadius: '4px',
        // border: '1px solid #ddd',
    }}
    />
    <div className="view-more"  ><EyeTwoTone style={{fontSize:'18px',marginRight:'5px'}} /> View More Details</div>
    {/* Drawer */}
    <CommonBottomDrawer
        title="conan-library@5.0.2"
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
      >
        <Card>
          <TabsWithExtraBtn
            callback={callback}
            menu={tabsMenu}
            extraBtn={operations}
          />
        </Card>
    </CommonBottomDrawer>
    </>

  )
}

export default DependencyTree