import React from "react";
import { PieChart, Pie, Cell, ResponsiveContainer } from "recharts";

const SummaryPieChart = () => {
  const data = [
    { name: "A", value: 35, color: "url(#gradientA)" },
    { name: "B", value: 65, color: "url(#gradientB)" },
  ];

  const cx = "50%";
  const cy = 200;
  const iR = 70;
  const oR = 110;

  return (
    <div>
      <ResponsiveContainer width={250} height={400} className="m-auto">
        <PieChart width={200} height={400} position="relative">
          <defs>
            <linearGradient id="gradientA" x1="0%" y1="0%" x2="0%" y2="100%">
              <stop offset="30%" stopColor="#63E09E" />
              <stop offset="50%" stopColor="#63E09E" />
              <stop offset="100%" stopColor="#63E09E" />
            </linearGradient>
            <linearGradient id="gradientB" x1="0%" y1="0%" x2="0%" y2="100%">
              <stop offset="30%" stopColor="#FF3043" />
              <stop offset="50%" stopColor="#FF3043" />
              <stop offset="100%" stopColor="#FF3043" />
            </linearGradient>
          </defs>
          <Pie
            data={data}
            dataKey="value"
            innerRadius={iR}
            outerRadius={oR}
            stroke="none"
            labelLine={false}
            label={({ cx, cy, midAngle, outerRadius, percent, index }) => {
              const entry = data[index];
              const radius = outerRadius * 1.2;
              const x = cx + radius * Math.cos(-midAngle * (Math.PI / 180));
              const y = cy + radius * Math.sin(-midAngle * (Math.PI / 180));

              return (
                <text
                  x={x}
                  y={y}
                  fill={entry.color}
                  fontSize={16}
                  textAnchor={x > cx ? "start" : "end"}
                  dominantBaseline="central"
                >
                  {`${(percent * 100).toFixed(0)}%`}
                </text>
              );
            }}
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.color} />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
};

export default SummaryPieChart;
