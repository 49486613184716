import { Button, Card, Col, Steps, message } from "antd";
import React, { useState } from "react";
// const steps = [
//   {
//     title: "Application Information",
//     content: <DynamicForm data={applicationInfoFormData} />,
//   },
//   {
//     title: "Developer Information",
//     content: <DynamicForm data={applicationInfoFormData} />,
//   },
//   {
//     title: "Other Information",
//     content: <DynamicForm data={applicationInfoFormData} />,
//   },
// ];
const FormStep = ({ onCloseModal, stepsMenu, next_btn_text }) => {
  const [current, setCurrent] = useState(0);
  const next = () => {
    setCurrent(current + 1);
  };
  const prev = () => {
    setCurrent(current - 1);
  };

  const submitClick = (e) => {
    console.log("click submit", e);
    setCurrent(0);
    onCloseModal();
  };

  // const items = steps.map((item) => ({
  //   key: item.title,
  //   title: item.title,
  // }));

  const stepMenuItems = stepsMenu?.map((item) => ({
    key: item.title,
    title: item.title,
  }));

  return (
    <div>
      {/* <Col className="m-auto" span={16}>
        <Card> */}
      <Steps size="small" progressDot current={current} items={stepMenuItems} />
      <h4>{stepsMenu[current].content}</h4>
      <div
        className="d-flex justify-content-between"
        style={{
          marginTop: 24,
        }}
      >
        <div className="cancel_prev_btn">
          {current > 0 && (
            <Button
              style={{
                margin: "0 8px",
              }}
              onClick={() => prev()}
            >
              Previous
            </Button>
          )}
          {current === 0 && (
            <Button
              style={{
                margin: "0 8px",
              }}
              onClick={onCloseModal}
            >
              Cancel
            </Button>
          )}
        </div>
        <div className="next_submit_btn">
          {current < stepsMenu.length - 1 && (
            <Button type="primary" onClick={() => next()}>
              {next_btn_text ? next_btn_text : "Save & Next"}
            </Button>
          )}
          {current === stepsMenu.length - 1 && (
            <Button type="primary" onClick={submitClick}>
              Submit
            </Button>
          )}
        </div>
      </div>
      {/* </Card>
      </Col> */}
    </div>
  );
};

export default FormStep;
