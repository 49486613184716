import React from "react";
import { SmileOutlined } from "@ant-design/icons";
import { Timeline, Card, Tag, Col, Flex } from "antd";
import "./HistoryList.css";
const { Meta } = Card;

const HistoryList = (props) => {
  console.log("inside historyList-->", props);
  const { timeLineData, projectData } = props;
  return (
    <div className="container-fluid">
      <Timeline>
        {timeLineData.map((item, index) => (
          <Timeline.Item color="#5A81FA">
            <Card className="history-card">
              <Meta
                //   title="Solve initial network problems"
                description={
                  <>
                    <p
                      style={{
                        fontStyle: "italic",
                        color: "rgba(90, 129, 250, 1)",
                        fontSize:'11px'
                      }}
                    >
                      Latest, 1st Feb 2024 by recruting test
                    </p>
                  </>
                }
              />
              <div style={{ display: "flex", color: "#63637D" }}>
                <Col
                  xs={24}
                  sm={8}
                  md={8}
                  lg={8}
                  style={{paddingLeft:'0px'}}
                >
                  <div className="card-details">
                    Dependencies <span className="span-details">120</span>
                  </div>
                </Col>
                <Col
                  xs={24}
                  sm={6}
                  md={6}
                  lg={6}
                >
                  <div className="card-details">
                    Issues <span className="span-details">80</span>
                  </div>
                </Col>
                <Col
                  xs={24}
                  sm={10}
                  md={10}
                  lg={10}
                >
                  <Flex>
                    {projectData.map((item, index) => {
                      return (
                        <Tag
                          key={index}
                          style={{
                            display: "flex",
                            alignItems: "center", // Align items vertically in the center
                            justifyContent: "center",
                            fontWeight: 700,
                            fontSize: "10px",
                            width: "40px",
                            height: "24px",
                            borderRadius: "1px",
                            borderColor: "transparent",
                            backgroundColor: item.bgColor,
                            color: item.color,
                            cursor: "pointer",
                            padding: "5px",
                            marginRight: "8px",
                          }}
                        >
                          {item.value}
                          {item.initial}
                        </Tag>
                      );
                    })}
                  </Flex>
                  {/* <Tag
                    key={index}
                    style={{
                    display: 'flex',
                    alignItems: 'center', // Align items vertically in the center
                    justifyContent: 'center', 
                    fontWeight: 700,
                    fontSize: '10px',
                    width: '40px',
                    height: '24px',
                    borderRadius: '1px',
                    borderColor:'transparent',
                    backgroundColor: item.bgColor,
                    color: item.color,
                    cursor: 'pointer',
                    padding: '5px',
                    marginRight: '8px',
                    }}
                >
                    {item.value}{item.initial}
                </Tag> */}
                  {/* <Tag color="geekblue">Chip 1</Tag>
                    <Tag color="geekblue">Chip 2</Tag>
                    <Tag color="geekblue">Chip 3</Tag>
                    <Tag color="geekblue">Chip 4</Tag> */}
                </Col>
              </div>
            </Card>
          </Timeline.Item>
        ))}
      </Timeline>
    </div>
  );
};

export default HistoryList;
