import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const data = [
  {
    name: "MON",
    uv: 280,
    mv: 250,
    pv: 200,
    cv: 210,
    amt: 200,
  },
  {
    name: "TUE",
    uv: 300,
    pv: 198,
    mv: 210,
    cv: 140,
    amt: 210,
  },
  {
    name: "WED",
    uv: 200,
    cv: 180,
    mv: 280,
    pv: 300,
    amt: 290,
  },
  {
    name: "THU",
    uv: 280,
    cv: 270,
    mv: 190,
    pv: 308,
    amt: 200,
  },
  {
    name: "FRI",
    uv: 190,
    cv: 240,
    mv: 200,
    pv: 400,
    amt: 281,
  },
  {
    name: "SAT",
    uv: 290,
    cv: 390,
    mv: 160,
    pv: 300,
    amt: 200,
  },
  {
    name: "SUN",
    uv: 390,
    cv: 250,
    mv: 120,
    pv: 400,
    amt: 200,
  },
];

export default function ThreatSummaryLineChart({ width, height }) {
  return (
    <ResponsiveContainer
      width={width ? width : "100%"}
      height={height ? height : 260}
    >
      <LineChart
        width={width ? width : "100%"}
        height={height ? height : 260}
        data={data}
        margin={{
          top: 20,
          right: 20,
          left: 20,
          bottom: 20,
        }}
      >
        <CartesianGrid strokeDasharray="0" stroke="none" />
        <XAxis
          dataKey="name"
          tickLine={false}
          label={{
            value: "Days",
            offset: 0,
            position: "bottom",
            style: {
              textAnchor: "middle",
              fontWeight: "bold",
              fontSize: 12,
              fontWeight: 600,
              color: "#2A2A2A",
              textTransform: "uppercase",
            },
          }}
        />
        <YAxis
          tickLine={false}
          label={{
            value: "Instances",
            angle: -90,
            position: "insideLeft",
            style: {
              textAnchor: "middle",
              fontWeight: "bold",
              fontSize: 12,
              fontWeight: 600,
              color: "#2A2A2A",
              textTransform: "uppercase",
            },
          }}
        />
        <Tooltip />
        <Line type="monotone" dataKey="pv" stroke="#8884d8" dot={false} />
        <Line type="monotone" dataKey="uv" stroke="#82ca9d" dot={false} />
        <Line type="monotone" dataKey="cv" stroke="#E10505" dot={false} />
        <Line type="monotone" dataKey="mv" stroke="#5A8DAA" dot={false} />
        <Line type="monotone" dataKey="amt" stroke="#FE9706" dot={false} />
      </LineChart>
    </ResponsiveContainer>
  );
}
