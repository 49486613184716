import { DownOutlined } from "@ant-design/icons";
import { Dropdown, Menu } from "antd";
import React, { useState } from "react";

const RowSelectBtn = () => {
  const [rows, setRows] = useState(10);

  const handleRowSelect = (value) => {
    setRows(value);
  };
  const RowSelectMenu = (
    <Menu className="rowselect_dropdown">
      {[10, 15, 25, 50, 100].map((key, i) => (
        <Menu.Item key={i} onClick={() => handleRowSelect(key)}>
          <span>{key} Rows</span>
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <Dropdown overlay={RowSelectMenu} trigger={["click"]}>
      <a
        style={{
          textDecoration: "none",
          borderRadius: 6,
          border: "1px solid #D2D8E9",
          padding: "4px 10px",
          background: "#FFF",
          fontSize: 12,
          fontWeight: 600,
          color: "#63637D",
          display: "flex",
          gap: "6px",
          alignItems: "center",
        }}
        className="column_btn"
        onClick={(e) => e.stopPropagation()}
      >
        Show {rows} Rows
        <DownOutlined style={{ color: "#63637D" }} />
      </a>
    </Dropdown>
  );
};

export default RowSelectBtn;
