import { Card, Flex, Table } from 'antd'
import React from 'react'
import './SecurityTabs.css'
import { CloudOutlined } from '@ant-design/icons';
import TableCard from './TableCard';

const SecurityTabs = () => {
    const data = [
        {
          key: "1",
          status: "Open",
          threat_category: "Third Part Vendor Risk",
    
          projectName: "Project 1",
          
          code_issue: [
            {
              mainValue: 400,
              value: 30,
              initial: "C",
              color: "#9494AE",
              bgColor: "#E6E6E6",
            },
            {
              mainValue: 868,
              value: 80,
              initial: "H",
              color: "#F50000",
              bgColor: "#F500001F",
            },
            {
              mainValue: 208,
              value: 90,
              initial: "M",
              color: "#FE9706",
              bgColor: "#FE97061F",
            },

          ],
          version:'2.4.1',
          title: "Weak cipher suites partnerbuddy.hdfcergo.com:443)",
          severity: "High",
          endOfLife: "20/01/2023",
         
          vulnerability_No: 300,
          licence_No: 200,
          malicious_No: 500,
        },
        {
          key: "2",
          status: "Open",
          threat_category: "Cloud Risk",
          projectName: "Project 2",
          code_issue: [
            
            {
              mainValue: 868,
              value: 80,
              initial: "H",
              color: "#F50000",
              bgColor: "#F500001F",
            },
            {
              mainValue: 208,
              value: 90,
              initial: "M",
              color: "#FE9706",
              bgColor: "#FE97061F",
            },
            {
              mainValue: 30,
              value: 30,
              initial: "L",
              color: "#02B583",
              bgColor: "#02B5831F",
            },
          ],
          version:'2.4.1',
          title: "Weak cipher suites partnerbuddy.hdfcergo.com:443)",
          severity: "Medium",
          endOfLife: "20/01/2023",
         
          vulnerability_No: 300,
          licence_No: 200,
          malicious_No: 500,
        },
       
      ];
    const columns = [
      {
        title:"Version",
        dataIndex: "version",
        key: "version",

      },        
        {
          title:"Vulnerabilities",
          dataIndex: "code_issue",
          key: "code_issue",
          render: (event, data) => {
            return (
              <TableCard
                data={event}
                mainvalue={data.codeIssue_No}
              />
            );
          },
        },
        {
            title:"License Risk",
            dataIndex: "code_issue",
            key: "code_issue",

            render: (event, data) => {
              return (
                <TableCard
                  data={event}
                  mainvalue={data.codeIssue_No}
                />
              );
            },
          },
        
      ];
  return (
    <Card className='security-tabs'>
        <Flex justify='space-between'>
        <div className='title'>Security</div>
        <div className='sub-title'>No unknown security issues</div>
        </Flex>
        <Flex justify='space-between'>
            <div className='second-title'>
                <div className='title'>MIT</div>
                <div className='sub-head'>License</div>
            </div>
            <div className='second-title'>
                <div className='title'>Yes</div>
                <div className='sub-head'>Security Policy</div>
            </div>
        </Flex>
        <div className='paragraph'>Security & license risk for significant versions</div>
        <Table
              className="security-table"
              columns={columns}
              dataSource={data}
              pagination={false}
            />
    </Card>
  )
}

export default SecurityTabs