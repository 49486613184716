import { Button, Card, Carousel, Col, Form, Input, Row } from "antd";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import "./LoginPage.css";

const LoginPage = () => {
  const history = useHistory();
  const handleSubmit = () => {
    history.push("/dashboard-option");
  };

  const settings = {
    dots: {
      className: "custom_dots_btn",
    },
  };

  return (
    <div className="login_page_wrapper">
      <Row>
        <Col
          className="d-none d-md-block"
          xs={24}
          sm={24}
          md={24}
          xl={12}
          lg={12}
        >
          <div className="left_content_wrapper">
            <div className="content_container">
              <div className="caousel_wrapper">
                <Carousel autoplay {...settings}>
                  <div className="carousel_slider text-light">
                    <div className="text_wrapper mb-3">
                      <div className="header_text">
                        What is an Attack Surface?
                      </div>
                      <div className="description_text">
                        Your attack surface is all the hardware, software, SaaS,
                        and cloud assets that are accessible from the Internet
                        that process or store your data. Think of it as the
                        total number of attack vectors cybercriminals could use
                        to manipulate a network or system to extract data. Your
                        attack surface includes:
                      </div>
                    </div>
                    <video width="100%" controls>
                      <source src="" type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                    <ul className="mb-2">
                      <li>
                        Known assets: Inventoried and managed assets such as
                        your corporate website, servers, and the dependencies
                        running on them.
                      </li>
                      <li>
                        Unknown assets: Such as Shadow IT or orphaned IT
                        infrastructure that was stood up outside of the purview
                        of your security team such as forgotten development
                        websites or marketing sites.
                      </li>
                      <li>
                        <li>
                          Such as Shadow IT or orphaned IT infrastructure that
                          was stood up outside of the purview of your security
                          team such as forgotten development websites or
                          marketing sites.
                        </li>
                      </li>
                    </ul>
                    <div className="mb-3">
                      Your attack surface is all the hardware, software,Saas &
                      cloud assets that are accessible from the internet that
                      process your store data.
                    </div>
                    <button className="know_more">Know More</button>
                  </div>
                  <div className="carousel_slider text-light">
                    <div className="text_wrapper mb-3">
                      <div className="header_text">
                        What is an Attack Surface?
                      </div>
                      <div className="description_text">
                        Your attack surface is all the hardware, software, SaaS,
                        and cloud assets that are accessible from the Internet
                        that process or store your data. Think of it as the
                        total number of attack vectors cybercriminals could use
                        to manipulate a network or system to extract data. Your
                        attack surface includes:
                      </div>
                    </div>
                    <video width="100%" controls>
                      <source src="" type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                    <ul className="mb-2">
                      <li>
                        Known assets: Inventoried and managed assets such as
                        your corporate website, servers, and the dependencies
                        running on them.
                      </li>
                      <li>
                        Unknown assets: Such as Shadow IT or orphaned IT
                        infrastructure that was stood up outside of the purview
                        of your security team such as forgotten development
                        websites or marketing sites.
                      </li>
                      <li>
                        <li>
                          Such as Shadow IT or orphaned IT infrastructure that
                          was stood up outside of the purview of your security
                          team such as forgotten development websites or
                          marketing sites.
                        </li>
                      </li>
                    </ul>
                    <div className="mb-3">
                      Your attack surface is all the hardware, software,Saas &
                      cloud assets that are accessible from the internet that
                      process your store data.
                    </div>
                    <button className="know_more">Know More</button>
                  </div>
                  <div className="carousel_slider text-light">
                    <div className="text_wrapper mb-3">
                      <div className="header_text">
                        What is an Attack Surface?
                      </div>
                      <div className="description_text">
                        Your attack surface is all the hardware, software, SaaS,
                        and cloud assets that are accessible from the Internet
                        that process or store your data. Think of it as the
                        total number of attack vectors cybercriminals could use
                        to manipulate a network or system to extract data. Your
                        attack surface includes:
                      </div>
                    </div>
                    <video width="100%" controls>
                      <source src="" type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                    <ul className="mb-2">
                      <li>
                        Known assets: Inventoried and managed assets such as
                        your corporate website, servers, and the dependencies
                        running on them.
                      </li>
                      <li>
                        Unknown assets: Such as Shadow IT or orphaned IT
                        infrastructure that was stood up outside of the purview
                        of your security team such as forgotten development
                        websites or marketing sites.
                      </li>
                      <li>
                        <li>
                          Such as Shadow IT or orphaned IT infrastructure that
                          was stood up outside of the purview of your security
                          team such as forgotten development websites or
                          marketing sites.
                        </li>
                      </li>
                    </ul>
                    <div className="mb-3">
                      Your attack surface is all the hardware, software,Saas &
                      cloud assets that are accessible from the internet that
                      process your store data.
                    </div>
                    <button className="know_more">Know More</button>
                  </div>
                </Carousel>
              </div>
            </div>
          </div>
        </Col>
        <Col xs={24} sm={24} md={24} xl={12} lg={12}>
          <div className="login_container">
            <div className="login_container_card">
              <div className="login_content_wrap">
                <div className="logo text-center">
                  <img src="./x_biz_logo_blue.png" alt="logo" />
                </div>
                <div className="header_des text-center">
                  <div className="title">LOGIN</div>
                  <div className="des">
                    Hey, Enter your details to get login to your account
                  </div>
                </div>
                <div className="login_form_content">
                  <Form layout="vertical">
                    <Form.Item
                      name="user_id"
                      label="User ID"
                      style={{ marginBottom: 18 }}
                    >
                      <Input size="large" placeholder="Enter User ID" />
                    </Form.Item>
                    <Form.Item
                      name="password"
                      label="Password"
                      style={{ marginBottom: 18 }}
                    >
                      <Input
                        size="large"
                        type="password"
                        placeholder="Enter Password"
                      />
                      <Form.Item
                        className="text-end"
                        style={{ marginBottom: 0 }}
                      >
                        <a className="login_form_forgot" href="#">
                          Forgot password
                        </a>
                      </Form.Item>
                    </Form.Item>
                    <Form.Item style={{ marginBottom: 18 }}>
                      <Button
                        // onClick={history.push("/dashboard-option")}
                        onClick={handleSubmit}
                        block
                        size="large"
                        type="primary"
                        htmlType="submit"
                        className="login-form-button"
                      >
                        Log in
                      </Button>
                    </Form.Item>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default LoginPage;
