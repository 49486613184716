import React, { useState } from "react";
import { Layout, Menu } from "antd";
import "./SidebarMenu.css";
import { Link } from "react-router-dom";
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  DashboardOutlined,
  UserOutlined,
  SettingOutlined,
  LeftOutlined,
  RightOutlined,
  CloudOutlined,
  AppstoreOutlined,
  CodeOutlined,
  ProfileOutlined,
  SecurityScanOutlined,
  BarChartOutlined,
  SafetyOutlined,
  ApartmentOutlined,
  LockOutlined,
  UsergroupDeleteOutlined,
  BugOutlined,
} from "@ant-design/icons";
import dashboard_icon from "../../images/sidenavIcons/dashboard_icon.svg";
import cloudDashboardIcon from "../../images/sidenavIcons/Cloud_Dashboard_icon.svg";
import scaDashboard from "../../images/sidenavIcons/SCA_Dashboard_icon.svg";
import assetInventory from "../../images/sidenavIcons/Asset_Inventory_icon.svg";
import scaIcon from "../../images/sidenavIcons/SCA_icon.svg";
import attackSurfaceIcon from "../../images/sidenavIcons/Attack_surface_icon.svg";
import benchMarkIcon from "../../images/sidenavIcons/Benchmark_icon.svg";
import enviromentIcon from "../../images/sidenavIcons/Environment_icon.svg";
import integerationIcon from "../../images/sidenavIcons/integration_icon.svg";
import secretsIcon from "../../images/sidenavIcons/Secrets_icon.svg";
import settingsIcon from "../../images/sidenavIcons/Settings_icon.svg";
import advisorieIcon from "../../images/sidenavIcons/Advisories_icon.svg";
import vulnerabilityIcon from "../../images/sidenavIcons/Vulnerability_DB_icon.svg";
import onboardingIcon from "../../images/sidenavIcons/onboardingIcon.png";
import assessmentIcon from "../../images/sidenavIcons/assessmentIcon.png";
import observationIcon from "../../images/sidenavIcons/observationIcon.png";
import auditMasterIcon from "../../images/sidenavIcons/auditMasterIcon.png";
import vector from "../../images/sidenavIcons/Frame_5.png";
import SubMenu from "antd/es/menu/SubMenu";

const { Sider } = Layout;
const assetInventorySubmenu = [
  { key: 5, routesUrl: "/assets-inventory", value: "APIs" },
  { key: 6, routesUrl: "#", value: "Applications" },
  { key: 7, routesUrl: "/mobile", value: "Mobile" },
  { key: 8, routesUrl: "#", value: "Devices" },
  { key: 9, routesUrl: "#", value: "Laptops" },
  { key: 10, routesUrl: "#", value: "Desktops" },
  { key: 11, routesUrl: "#", value: "Containers" },
  { key: 12, routesUrl: "#", value: "Functions" },
  { key: 13, routesUrl: "#", value: "VMs" },
  { key: 14, routesUrl: "#", value: "Software" },
  { key: 15, routesUrl: "#", value: "Storage" },
  { key: 16, routesUrl: "#", value: "Services" },
  { key: 17, routesUrl: "#", value: "Database" },
];
const scaSubmenuList = [
  { key: 19, routesUrl: "/sca-scan-details", value: "Scan Details" },
  { key: 20, routesUrl: "/sca-license", value: "Licenses" },
  { key: 21, routesUrl: "/sca-components", value: "Components" },
  { key: 22, routesUrl: "/sca-vulnerabilities", value: "Vulnerabilities" },
  { key: 23, routesUrl: "/sca-project-list", value: "Project List" },
  { key: 51, routesUrl: "/sca-governance", value: "Governance" },
  { key: 52, routesUrl: "/sca-integerations", value: "Integration" },
];
const attackSurfaceSubmenuList = [
  { key: 25, routesUrl: "#", value: "Scan" },
  { key: 26, routesUrl: "#", value: "Domains & Subdomains" },
  { key: 27, routesUrl: "#", value: "IPs" },
  { key: 28, routesUrl: "#", value: "IP Reputation" },
  { key: 29, routesUrl: "#", value: "ASN" },
  { key: 30, routesUrl: "#", value: "Pages" },
  { key: 31, routesUrl: "#", value: "Threats" },
  { key: 32, routesUrl: "#", value: "Certificates" },
];

const benchMarkSubmenuList = [
  { key: 34, routesUrl: "#", value: "Profiles" },
  { key: 35, routesUrl: "#", value: "Controls" },
  { key: 36, routesUrl: "#", value: "Rules" },
];

const environmentSubMenuList = [
  { key: 38, routesUrl: "#", value: "Cloud" },
  { key: 39, routesUrl: "#", value: "On Prem" },
  { key: 40, routesUrl: "#", value: "Credentials" },
];

const integerationSubmenuList = [
  { key: 43, routesUrl: "#", value: "Scores & Configuration" },
  { key: 44, routesUrl: "#", value: "Category Threat Mapping" },
];
const settingSubmenuList = [
  { key: 47, routesUrl: "#", value: "Scores & Configuration" },
  { key: 48, routesUrl: "#", value: "Category Threat Mapping" },
];

const SidebarComponent = () => {
  const [collapsed, setCollapsed] = useState(true);
  const [theme, settTheme] = useState("light");

  const toggleCollapse = () => {
    setCollapsed(!collapsed);
  };

  return (
    <Sider
      className="sidebar_nav"
      width={200}
      style={{ background: `${theme === "light" ? "#2b318a" : "#000"}` }}
      theme={theme} // You can change the theme to 'dark' if needed
      // collapsible
      collapsed={collapsed}
      onCollapse={toggleCollapse}
    >
      {collapsed ? (
        <div
          className="logo"
          style={{
            height: "32px",
            // background: "rgba(255, 255, 255, 0.2)",
            margin: "16px",
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="13"
            viewBox="0 0 12 13"
            fill="none"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M3.19179 0.812623C3.76355 0.505945 4.58214 0.258553 5.26559 0.1859C6.16381 0.090439 7.12785 0.215396 7.94141 0.532737C8.69875 0.828147 9.4408 1.2969 9.95918 1.80739C10.2188 2.06297 10.6548 2.57701 10.6521 2.62426C10.6512 2.64143 10.3963 2.83922 10.0858 3.0638L9.52121 3.4721L9.32858 3.24279C8.44327 2.18881 7.22743 1.61262 5.88854 1.61262C4.67186 1.61262 3.6028 2.06331 2.73118 2.94364C2.03188 3.64998 1.60992 4.48728 1.46545 5.45536C1.1102 7.83526 2.66176 10.0544 5.03961 10.5676C5.46096 10.6585 6.31655 10.6584 6.73844 10.5674C8.07795 10.2783 9.23573 9.40173 9.84808 8.21294C10.0085 7.9015 10.2319 7.2654 10.2734 7.00191L10.2979 6.84635H8.35593H8.12664V7.47478L5.83133 9.14974L3.34991 7.47478V4.24894H8.12664V5.38229H9.10039H11.7867L11.767 6.17838C11.7501 6.86125 11.7339 7.0317 11.6535 7.37708C11.5202 7.94898 11.3959 8.31126 11.1889 8.73134C10.3341 10.4656 8.85403 11.6098 6.96912 11.9937C6.47027 12.0953 5.25661 12.0875 4.74701 11.9795C2.79209 11.565 1.25787 10.3161 0.484728 8.51001C0.142927 7.71155 0.000363959 7.00689 4.70188e-07 6.11434C-0.000862816 3.86121 1.18709 1.88795 3.19179 0.812623ZM6.02289 6.13832C6.23688 6.06015 6.38965 5.85478 6.38965 5.61372C6.38965 5.30537 6.1397 5.0554 5.83133 5.0554C5.52298 5.0554 5.27301 5.30537 5.27301 5.61372C5.27301 5.83266 5.39904 6.02216 5.58248 6.11365L5.27301 7.72293L5.83133 8.15717L6.32762 7.72293L6.02289 6.13832Z"
              fill="white"
            />
          </svg>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="11"
            height="13"
            viewBox="0 0 11 13"
            fill="none"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M0.786621 12.061V4.22019H2.28274V12.061H0.786621ZM2.28274 1.35773H4.26012C5.87256 1.35773 6.28781 1.37021 6.51114 1.42307C6.95512 1.529 7.35563 1.76899 7.65815 2.11037C7.96067 2.45175 8.1505 2.87794 8.2018 3.33092C8.25309 3.7839 8.16336 4.24166 7.94483 4.64189C7.72629 5.04212 7.38956 5.36537 6.98047 5.56765C6.57337 5.76279 6.29275 5.81013 5.54658 5.81013C5.34289 5.80495 5.13906 5.81029 4.93592 5.8261C4.93592 5.83481 5.03828 6.0227 5.16361 6.24341C6.03191 7.77555 8.0279 11.2998 8.22186 11.6434L8.45449 12.061H9.28267C9.55285 12.0724 9.82353 12.0617 10.0919 12.0287C10.0582 11.9727 8.11252 8.57038 7.53298 7.55253C7.33551 7.22964 7.15865 6.89462 7.00345 6.54949C7.112 6.50427 7.22454 6.46927 7.3396 6.44494C7.86801 6.30829 8.35298 6.0398 8.74901 5.66467C9.14504 5.28954 9.43914 4.82008 9.6037 4.30034C9.68727 4.02702 9.71912 3.74054 9.69763 3.45557C9.71741 3.15824 9.67788 2.85994 9.58131 2.57798C9.43588 2.13218 9.19189 1.72476 8.86742 1.3859C8.3876 0.866897 7.77308 0.490936 7.09214 0.299802L6.70742 0.183642L3.74717 0.168541L0.786621 0.154602V1.3162L2.28274 1.35773Z"
              fill="white"
            />
          </svg>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="10"
            height="13"
            viewBox="0 0 10 13"
            fill="none"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M4.29122 0.201392C1.19301 0.965972 -0.607457 4.51607 0.349635 7.9732C0.764276 9.47094 1.74536 10.766 3.01493 11.4915C4.90365 12.5706 7.32902 12.2105 8.86208 10.6231L9.09229 10.3847L8.64896 9.87631L8.20568 9.3679L7.89339 9.66137C7.50218 10.0291 6.99827 10.3302 6.46571 10.5144C6.09955 10.641 5.9792 10.657 5.38602 10.6578C4.84237 10.6586 4.65006 10.6376 4.3501 10.5447C2.73707 10.045 1.58214 8.509 1.37496 6.58795C1.24052 5.34177 1.65005 3.93264 2.42307 2.98124C2.92409 2.36461 3.52215 1.94496 4.26778 1.6868C4.62354 1.56362 4.75301 1.54636 5.33998 1.54363C6.10858 1.54006 6.42478 1.61272 7.02842 1.93159C7.43703 2.14741 7.74546 2.3729 8.02313 2.65876L8.20066 2.84148L8.64647 2.33061L9.09229 1.81974L8.86208 1.5782C8.32456 1.01434 7.53459 0.534316 6.71631 0.274314C6.34707 0.15701 6.16931 0.133874 5.50112 0.116457C4.84288 0.0992491 4.65052 0.112732 4.29122 0.201392Z"
              fill="white"
            />
          </svg>
        </div>
      ) : (
        <div
          className="logo"
          style={{
            height: "32px",
            // background: "rgba(255, 255, 255, 0.2)",
            margin: "16px",
          }}
        >
          <img src="./xbiz_logo.png" alt="logo" style={{ height: 40 }} />
        </div>
      )}
      <Menu
        className="sidebar_menu"
        mode="inline"
        theme="light"
        defaultSelectedKeys={["1"]}
        inlineCollapsed={collapsed}
      >
        <Menu.Item
          key="1"
          icon={<img src={dashboard_icon} alt="dashboard" />}
          className="custom-menu-item ehsan"
          title="Dashboard"
        >
          <Link to="/dashboard" className="decoration_none">
            Dashboard
          </Link>
        </Menu.Item>
        <Menu.Item
          key="2"
          icon={<img src={cloudDashboardIcon} alt="cloudDash" />}
        >
          <Link to="/cloud-dashboard" className="decoration_none">
            Cloud Dashboard
          </Link>
        </Menu.Item>
        <Menu.Item key="3" icon={<img src={scaDashboard} alt="scadash" />}>
        <Link to="/sca-dashboard" className="decoration_none">
        SCA Dashboard
          </Link>
        </Menu.Item>

        <SubMenu
          key="4"
          icon={<img src={assetInventory} alt="assetsInvetory" />}
          title="Asset Inventory"
        >
          {assetInventorySubmenu.map((item) => (
            <Menu.Item key={item.key}>
              <Link to={item.routesUrl} className="decoration_none">
                {item.value}
              </Link>
            </Menu.Item>
          ))}
        </SubMenu>
        <SubMenu key="18" icon={<img src={scaIcon} alt="sca" />} title="SCA">
          {scaSubmenuList.map((item) => (
            <Menu.Item key={item.key}>
              <Link className="decoration_none" to={item.routesUrl}>
                {item.value}
              </Link>
            </Menu.Item>
          ))}
        </SubMenu>
        <SubMenu
          key="24"
          icon={<img src={attackSurfaceIcon} alt="attack_surface" />}
          title="Attack Surface"
        >
          {attackSurfaceSubmenuList.map((item) => (
            <Menu.Item key={item.key}>
              <Link className="decoration_none" to={item.routesUrl}>
                {item.value}
              </Link>
            </Menu.Item>
          ))}
        </SubMenu>

        <SubMenu
          key="33"
          icon={<img src={benchMarkIcon} alt="bench_mark" />}
          title="BenchMark"
        >
          {benchMarkSubmenuList.map((item) => (
            <Menu.Item key={item.key}>
              <Link className="decoration_none" to={item.routesUrl}>
                {item.value}
              </Link>
            </Menu.Item>
          ))}
        </SubMenu>
        <SubMenu
          key="37"
          icon={<img src={enviromentIcon} alt="environment" />}
          title="Environment"
        >
          {environmentSubMenuList.map((item) => (
            <Menu.Item key={item.key}>
              <Link className="decoration_none" to={item.routesUrl}>
                {item.value}
              </Link>
            </Menu.Item>
          ))}
        </SubMenu>
        <Menu.Item
          key="41"
          icon={<img src={cloudDashboardIcon} alt="cloudThreat" />}
        >
          Cloud Threats
        </Menu.Item>

        <SubMenu
          key="42"
          icon={<img src={integerationIcon} alt="integration" />}
          title="Integration"
        >
          {integerationSubmenuList.map((item) => (
            <Menu.Item key={item.key}>
              <Link className="decoration_none" to={item.routesUrl}>
                {item.value}
              </Link>
            </Menu.Item>
          ))}
        </SubMenu>
        <Menu.Item key="45" icon={<img src={secretsIcon} alt="secrets" />}>
          Secrets
        </Menu.Item>
        <SubMenu
          key="46"
          icon={<img src={settingsIcon} alt="setting" />}
          title="Settings"
        >
          {settingSubmenuList.map((item) => (
            <Menu.Item key={item.key}>
              <Link className="decoration_none" to={item.routesUrl}>
                {item.value}
              </Link>
            </Menu.Item>
          ))}
        </SubMenu>
        <Menu.Item key="49" icon={<img src={advisorieIcon} alt="advisories" />}>
          Advisories
        </Menu.Item>
        <Menu.Item
          key="50"
          icon={<img src={vulnerabilityIcon} alt="vulenerability" />}
        >
          <Link className="text-decoration-none" to="/vulnerability-dashboard" >
          Vulnerability DB
          </Link>
        </Menu.Item>
        <Menu.Item
          key="53"
          icon={<img src={vulnerabilityIcon} alt="vulenerability" />}
        >
          <Link className="text-decoration-none" to="/risk-severty-matrix" >
           Risk Severity Matrix
          </Link>
        </Menu.Item>
        <Menu.Item
          key="54"
          icon={<img src={vulnerabilityIcon} alt="finfing" />}
        >
          <Link className="text-decoration-none" to="/vm-finding" >
           vm finding 
          </Link>
        </Menu.Item>
        {/* TPRM dashboard menu start */}
        <Menu.Item
          key="55"
          icon={<img src={dashboard_icon} alt="dashboard" />}
          className="custom-menu-item ehsan"
          title=" TPRM Dashboard"
        >
          <Link to="/third-party-risk-managemnet-dashboard" className="decoration_none">
            TPRM Dashboard
          </Link>
        </Menu.Item>

        <Menu.Item
          key="56"
          icon={<img src={onboardingIcon} alt="dashboard" />}
          className="custom-menu-item ehsan"
          title=" Onboarding"
        >
          <Link to="/onboarding" className="decoration_none">
          Onboarding
          </Link>
        </Menu.Item>

        <Menu.Item
          key="57"
          icon={<img src={assessmentIcon} alt="dashboard" />}
          className="custom-menu-item ehsan"
          title="Assessment"
        >
          <Link to="#" className="decoration_none">
          Assessment
          </Link>
        </Menu.Item>

        <Menu.Item
          key="58"
          icon={<img src={assessmentIcon} alt="dashboard" />}
          className="custom-menu-item ehsan"
          title="Infosec Assessment"
        >
          <Link to="#" className="decoration_none">
          Infosec Assessment
          </Link>
        </Menu.Item>

        <Menu.Item
          key="59"
          icon={<img src={observationIcon} alt="dashboard" />}
          className="custom-menu-item ehsan"
          title="Observation"
        >
          <Link to="#" className="decoration_none">
          Observation
          </Link>
        </Menu.Item>

        <Menu.Item
          key="60"
          icon={<img src={auditMasterIcon} alt="dashboard" />}
          className="custom-menu-item ehsan"
          title="Auditor Master"
        >
          <Link to="#" className="decoration_none">
          Auditor Master
          </Link>
        </Menu.Item>
        <Menu.Item
          key="61"
          icon={<img src={auditMasterIcon} alt="dashboard" />}
          className="custom-menu-item ehsan"
          title="Auditor Master"
          >
          <Link to="/cloud-security-posture-management" className="decoration_none">
          CSPM Dashboard
          </Link>
        </Menu.Item>
        <Menu.Item
          key="62"
          icon={<img src={vector} alt="Tech Stack" />}
          className="custom-menu-item ehsan"
          title="Auditor Master"
        >
          <Link to="/teck-stack" className="decoration_none">
          Tech Stack
          </Link>
        </Menu.Item>
        <Menu.Item
          key="63"
          icon={<img src={dashboard_icon} alt="TB Category" />}
          className="custom-menu-item ehsan"
          title="TB Category"
        >
          <Link to="/template-builder-categories" className="decoration_none">
          Template Builder Category
          </Link>
        </Menu.Item>
        <Menu.Item
          key="64"
          icon={<img src={dashboard_icon} alt="TB Category" />}
          className="custom-menu-item ehsan"
          title="TB Question Bank"
        >
          <Link to="/template-builder-question-bank" className="decoration_none">
          TB Question Bank
          </Link>
        </Menu.Item>
        <Menu.Item
          key="65"
          icon={<img src={dashboard_icon} alt="TB Category" />}
          className="custom-menu-item ehsan"
          title="TB CheckList"
        >
          <Link to="/template-builder-checklist" className="decoration_none">
          TB CheckList
          </Link>
        </Menu.Item>

        {/* TPRM dashboard menu end */}

      </Menu>
      <div className="toggle_btn_div">
        {collapsed ? (
          <img onClick={toggleCollapse} src="./menu_toggle_btn.svg" />
        ) : (
          // <LeftOutlined
          //   onClick={toggleCollapse}
          //   style={{ fontSize: "16px", color: "#fff" }}
          // />
          // <RightOutlined
          //   onClick={toggleCollapse}
          //   style={{ fontSize: "16px", color: "#fff" }}
          // />
          <img
            style={{ transform: "rotate(180deg)" }}
            onClick={toggleCollapse}
            src="./menu_toggle_btn.svg"
          />
        )}
      </div>
    </Sider>
  );
};

export default SidebarComponent;
