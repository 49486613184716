import React from "react";
import { PieChart, Pie, Cell, ResponsiveContainer, Polygon } from "recharts";

const OverAllRiskRatingChart = ({
  fillPercentage = 40,
  startRange = 0,
  endRange = 100,
  width,
  height,
}) => {
  const data = [
    {
      name: "A",
      value: fillPercentage,
      color: "url(#colorGradient)",
    },
    { name: "B", value: 100 - fillPercentage, color: "#e0e0e0" },
  ];

  const cx = "50%";
  const cy = "50%";
  const iR = 110;
  const oR = 120;

  // Calculate the start angles based on fillPercentage
  const startAngle = 90 - (fillPercentage / 2) * -3.6;

  // Calculate the points for the polygon
  const endAngle = (360 * fillPercentage) / 100 - 90;
  const innerX1 = cx + (iR - 12) * Math.cos((endAngle * Math.PI) / 180);
  const innerY1 = cy + (iR - 12) * Math.sin((endAngle * Math.PI) / 180);
  const outerX1 = cx + oR * Math.cos((endAngle * Math.PI) / 180);
  const outerY1 = cy + oR * Math.sin((endAngle * Math.PI) / 180);

  const innerX2 = cx + (iR - 12) * Math.cos((180 * Math.PI) / 180);
  const innerY2 = cy + (iR - 12) * Math.sin((180 * Math.PI) / 180);
  const outerX2 = cx + oR * Math.cos((180 * Math.PI) / 180);
  const outerY2 = cy + oR * Math.sin((180 * Math.PI) / 180);

  return (
    <ResponsiveContainer
      className="resposive_container"
      width={width ? width : 300}
      height={height ? height : 300}
    >
      <PieChart width={180}>
        {/* <circle cx={outerX1} cy={outerY1} r={4} fill="#fe9706" /> */}

        {/* Second Pie */}
        <Pie
          dataKey="value"
          startAngle={0}
          endAngle={360}
          data={data}
          cx={cx}
          cy={cy}
          innerRadius={80}
          outerRadius={oR - 10}
          fill="#FCF4EF"
          stroke="none"
        >
          <Cell />
        </Pie>

        <Pie
          dataKey="value"
          startAngle={startAngle}
          endAngle={-360}
          data={data}
          cx={cx}
          cy={cy}
          innerRadius={iR - 12}
          outerRadius={oR - 10}
          fill="none"
          cornerRadius="50%"
          stroke="none"
        >
          <Cell fill="url(#colorGradient)" />
        </Pie>
         {/* Definitions for gradient */}
         <defs>
          <linearGradient id="colorGradient" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="3.78%" stopColor="#FF8A00" />
            <stop offset="32.56%" stopColor="#FF4D00" />
            <stop offset="67.69%" stopColor="#FF0000" />
            <stop offset="96.87%" stopColor="#B10202" />
          </linearGradient>
        </defs>

        <defs>
          <linearGradient id="colorGradient" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop
              offset="10%"
              style={{ stopColor: "#0fd850", stopOpacity: 1 }}
            />
            <stop
              offset="20%"
              style={{ stopColor: "#f9f047", stopOpacity: 1 }}
            />
            <stop
              offset="50%"
              style={{ stopColor: "#ff930f", stopOpacity: 1 }}
            />
          </linearGradient>
        </defs>

        {/* Value of the first Pie */}
        <text
          x="50%"
          y="50%"
          textAnchor="middle"
          alignmentBaseline="middle"
          fontSize="50px"
          fontFamily="Open Sans"
          style={{ lineHeight: "87.16px" }}
          fontWeight={600}
          fill="#FF6B00"
        >
          {`${data[0].value / 10}`}
        </text>
        <text
          x={cx}
          y={cy}
          dy={30}
          textAnchor="middle"
          alignmentBaseline="middle"
          fontSize="18"
          fontWeight={600}
          fontFamily="Open Sans"
          fill="#FF6B00"
        >
          {"Medium"}
        </text>
      </PieChart>
    </ResponsiveContainer>
  );
};

export default OverAllRiskRatingChart;
