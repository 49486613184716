import { Button, Form, Input, Select } from "antd";
import React from "react";
import DigitalIcon from "../../../../images/otherIcons/digitalIcon.svg";
import QuestionIcon from "../../../../images/otherIcons/questionnaire.svg";
const Identification = () => {
  return (
    <Form layout="vertical">
      <div className="d-flex gap-3 flex-column">
        <div className="head_text text-center">
          You can setup multiple policies for as many different types as you
          need.
        </div>
        <div className="content d-flex gap-3 flex-column">
          <div className="sub_head_form">
            The vendor name will be shown on your scan results and should be a
            way to quickly identify what the policy protects against, such as
            'Exclude Copyleft Licenses' or 'Commercial Application'
          </div>
          <Form.Item
           style={{marginBottom: 0}}
            name="vendor name"
            label="Vendor Name"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input size="large" placeholder="Enter Vendor Name" />
          </Form.Item>
          <Form.Item
           style={{marginBottom: 0}}
            name="assessment name"
            label="Assessment Name"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input size="large" placeholder="Enter Assessment Name" />
          </Form.Item>
          <Form.Item
           style={{marginBottom: 0}}
            name="assessment type"
            label="Assessment Type"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select size="large" placeholder="Select Tier">
              <Select.Option value="Tier 1">Tier 1</Select.Option>
              <Select.Option value="Tier 2">Tier 2</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
           style={{marginBottom: 0}}
            name="tier"
            label="Tier"
            rules={[
              {
                required: false,
              },
            ]}
          >
            <div className="d-flex flex-start gap-3">
              <Button
                prefix="ab"
                className="d-flex justify-flex-start align-items-center"
                style={{ padding: "24px 16px" }}
                size="large"
                type="default"
                block
                icon={<img src={DigitalIcon} alt="Digital" />}
              >
                Digital
              </Button>
              <Button
                className="d-flex justify-flex-start align-items-center"
                style={{ padding: "24px 16px" }}
                size="large"
                block
                type="default"
                icon={<img src={QuestionIcon} alt="Question" />}
              >
                Questionnaire
              </Button>
            </div>
          </Form.Item>
        </div>
      </div>
    </Form>
  );
};

export default Identification;
