import { Col, Form, Input, Row } from "antd";
import React from "react";
import "./DigitalAssessment.css"

const DigitalAssessment = () => {
  const [form] = Form.useForm();

  const onFinish = (values) => {
    console.log("Received values:", values);
  };

  return (
    <div className="digital_assessment">
      <p className="heading_form_1 mb-0 text-center">
        Select the forms which you want to exclude.
      </p>
      {/* <div className="form_wrapper"> */}
        <Form layout="vertical" form={form} onFinish={onFinish}>
          <div className="form_wrapper">
            <div className="row_label">
              Organization Details
            </div>
            <Row gutter={[16, 16]} className="m-0">
              <Col xs={24} sm={24} md={12} lg={12}>
                <Form.Item
                  style={{ marginBottom: "0px" }}
                  name="Include Organization"
                  label={
                    <span className="custom_label_class">
                      Include Organization
                    </span>
                  }
                  rules={[
                    {
                      required: true,
                      message: "Please enter Include Organization",
                    },
                  ]}
                >
                  <Input size="large" placeholder="Enter" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12}>
                <Form.Item
                  style={{ marginBottom: "0px" }}
                  name="Exclude Organization"
                  label={
                    <span className="custom_label_class">
                      Exclude Organization
                    </span>
                  }
                  rules={[
                    {
                      required: true,
                      message: "Please enter Exclude Organization",
                    },
                  ]}
                >
                  <Input size="large" placeholder="Enter" />
                </Form.Item>
              </Col>
            </Row>

              <div className="row_label">Domain Details</div>
              <Row gutter={[16, 16]} className="m-0">
                <Col xs={24} sm={24} md={12} lg={12}>
                  <Form.Item
                    style={{ marginBottom: "0px" }}
                    name="Include Domains*"
                    label={
                      <span className="custom_label_class">
                        Include Domains*
                      </span>
                    }
                    rules={[
                      {
                        required: true,
                        message: "Please enter Include Domains*",
                      },
                    ]}
                  >
                    <Input size="large" placeholder="Enter" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                  <Form.Item
                    style={{ marginBottom: "0px" }}
                    name="Exclude Domains"
                    label={
                      <span className="custom_label_class">
                        Exclude Domains
                      </span>
                    }
                    rules={[
                      {
                        required: true,
                        message: "Please enter Exclude Domains",
                      },
                    ]}
                  >
                    <Input size="large" placeholder="Enter" />
                  </Form.Item>
                </Col>
              </Row>
              <div className="row_label">Subdomains</div>
              <Row gutter={[16, 16]} className="m-0">
                <Col xs={24} sm={24} md={12} lg={12}>
                  <Form.Item
                    style={{ marginBottom: "0px" }}
                    name="Include subdomains"
                    label={
                      <span className="custom_label_class">
                        Include subdomains
                      </span>
                    }
                    rules={[
                      {
                        required: true,
                        message: "Please enter Include subdomains",
                      },
                    ]}
                  >
                    <Input size="large" placeholder="Enter" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                  <Form.Item
                    style={{ marginBottom: "0px" }}
                    name="Exclude Subdomains"
                    label={
                      <span className="custom_label_class">
                        Exclude Subdomains
                      </span>
                    }
                    rules={[
                      {
                        required: true,
                        message: "Please enter Exclude Subdomains",
                      },
                    ]}
                  >
                    <Input size="large" placeholder="Enter" />
                  </Form.Item>
                </Col>
              </Row>
              <div className="row_label">Hosts</div>
              <Row gutter={[16, 16]} className="m-0">
                <Col xs={24} sm={24} md={12} lg={12}>
                  <Form.Item
                    style={{ marginBottom: "0px" }}
                    name="Include Hosts"
                    label={
                      <span className="custom_label_class">
                        Include Hosts
                      </span>
                    }
                    rules={[
                      {
                        required: true,
                        message: "Please enter Include Hosts",
                      },
                    ]}
                  >
                    <Input size="large" placeholder="Enter" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                  <Form.Item
                    style={{ marginBottom: "0px" }}
                    name="Exclude Hosts"
                    label={
                      <span className="custom_label_class">
                        Exclude Hosts
                      </span>
                    }
                    rules={[
                      {
                        required: true,
                        message: "Please enter Exclude Hosts",
                      },
                    ]}
                  >
                    <Input size="large" placeholder="Enter" />
                  </Form.Item>
                </Col>
              </Row>
              <div className="row_label">IP Address</div>
              <Row gutter={[16, 16]} className="m-0">
                <Col xs={24} sm={24} md={12} lg={12}>
                  <Form.Item
                    style={{ marginBottom: "0px" }}
                    name="Include IP Address"
                    label={
                      <span className="custom_label_class">
                        Include IP Address
                      </span>
                    }
                    rules={[
                      {
                        required: true,
                        message: "Please enter Include IP Address",
                      },
                    ]}
                  >
                    <Input size="large" placeholder="Enter" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                  <Form.Item
                    style={{ marginBottom: "0px" }}
                    name="Exclude IP Address"
                    label={
                      <span className="custom_label_class">
                        Exclude IP Address
                      </span>
                    }
                    rules={[
                      {
                        required: true,
                        message: "Please enter Exclude IP Address",
                      },
                    ]}
                  >
                    <Input size="large" placeholder="Enter" />
                  </Form.Item>
                </Col>
              </Row>
              <div className="row_label">ASNs</div>
              <Row gutter={[16, 16]} className="m-0">
                <Col xs={24} sm={24} md={12} lg={12}>
                  <Form.Item
                    style={{ marginBottom: "0px" }}
                    name="Include ASNs"
                    label={
                      <span className="custom_label_class">
                        Include ASNs
                      </span>
                    }
                    rules={[
                      {
                        required: true,
                        message: "Please enter Include ASNs",
                      },
                    ]}
                  >
                    <Input size="large" placeholder="Enter" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                  <Form.Item
                    style={{ marginBottom: "0px" }}
                    name="Exclude ASNs"
                    label={
                      <span className="custom_label_class">
                        Exclude ASNs
                      </span>
                    }
                    rules={[
                      {
                        required: true,
                        message: "Please enter Exclude ASNs",
                      },
                    ]}
                  >
                    <Input size="large" placeholder="Enter" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                  <Form.Item
                    style={{ marginBottom: "0px" }}
                    name="Include SSL TLS"
                    label={
                      <span className="custom_label_class">
                        Include SSL TLS
                      </span>
                    }
                    rules={[
                      {
                        required: true,
                        message: "Please enter Include SSL TLS",
                      },
                    ]}
                  >
                    <Input size="large" placeholder="Enter" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                  <Form.Item
                    style={{ marginBottom: "0px" }}
                    name="Exclude SSL TLS"
                    label={
                      <span className="custom_label_class">
                        Exclude SSL TLS
                      </span>
                    }
                    rules={[
                      {
                        required: true,
                        message: "Please enter Exclude SSL TLS",
                      },
                    ]}
                  >
                    <Input size="large" placeholder="Enter" />
                  </Form.Item>
                </Col>
              </Row>
          </div>
        </Form>
      {/* </div> */}
    </div>
  );
};

export default DigitalAssessment;
