import React from "react";
import { Card, Col, Dropdown, Row, Select, Space } from "antd";
import {
    ArrowDownOutlined,
    ClockCircleOutlined,
    DeleteOutlined,
    ExpandOutlined,
    EyeOutlined,
    FormOutlined,
    MoreOutlined,
    RiseOutlined,
  } from "@ant-design/icons";
//   import AreaChartDomain from "../../../../components/charts/AreaChartDomain";
  import AreaChartDomain from "../../../../../components/charts/AreaChartCommon";

const CardGraph = ({data,key,style}) =>{
  let uniqueIndex = Math.random()
    console.log('data cardGraph-->',data)
    console.log('data index 2-->', uniqueIndex)
    console.log('data index 2-->', style)
    return(
        <Card className="min-200" style={{ padding: 0, 
       
        }}>
              <div className="area_chat_wrapper" >
                <div className="commo_chat_header p-2 justify-content-between">
                  <span className="text_ellipse"><span className="card-icon">{data.icon}</span> {data.title}</span>
                </div>
                <div className="area_chat_content justify-content-between">
                  <div className="chart_card_wrapper d-flex align-items-center gap-1">
                    <div className="count_content">{data.detail}</div>
                    <div className="percent_with_label">
                      <div className="d-flex">
                        <ArrowDownOutlined style={{ color: "green" }} />
                        <div className="percentage">{data.percentage}%</div>
                      </div>
                      <div className="month_label">Past Month</div>
                    </div>
                  </div>
                </div>
                <AreaChartDomain chartData={data} index={uniqueIndex} />
              </div>
            </Card>
    )
}
export default CardGraph;

