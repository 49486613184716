import React from "react";
import "./HeaderNotation.css";

const HeaderNotation = ({ data, customStyle, popularityStyle }) => {
  return (
    <div className={`notation_header_count ${customStyle || popularityStyle}`}>
      {data
        ? data?.map((el) => (
            <div
              className={`item ${customStyle || popularityStyle}`}
              style={{
                borderColor:
                  el.color
              }}
            >
              <div className={`item_frame ${customStyle || popularityStyle}`}>
                <div
                  className={`count_number ${customStyle || popularityStyle}`}
                  style={{
                    color:
                      el.color
                  }}
                >
                  {el.count}
                </div>
                <div className={`count_label ${customStyle || popularityStyle}`}>{el.category}</div>
              </div>
            </div>
          ))
        : "data is empty please pass data"}
    </div>
  );
};

export default HeaderNotation;
