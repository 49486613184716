// import "./styles.css";
import React, { useCallback, useState } from "react";
import { PieChart, Pie, Sector, Cell } from "recharts";

const SCA_PieChart = (props) => {
  const data = [
    { name: "Group A", value: 3870, fill: "red" },
    { name: "Group B", value: 9860, fill: "green" },
  ];
  const TotalVal = data.reduce((a, b) => a + b.value, 0);
  // const renderActiveShape = (props) => {
  //   const RADIAN = Math.PI / 180;
  //   const {
  //     cx,
  //     cy,
  //     midAngle,
  //     innerRadius,
  //     outerRadius,
  //     startAngle,
  //     endAngle,
  //     fill,
  //     payload,
  //     percent,
  //     value,
  //   } = props;
  //   const sin = Math.sin(-RADIAN * midAngle);
  //   const cos = Math.cos(-RADIAN * midAngle);
  //   const sx = cx + (outerRadius + 10) * cos;
  //   const sy = cy + (outerRadius + 10) * sin;
  //   const mx = cx + (outerRadius + 30) * cos;
  //   const my = cy + (outerRadius + 30) * sin;
  //   const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  //   const ey = my;
  //   const textAnchor = cos >= 0 ? "start" : "end";

  //   const circle = () =>{
  //       return(
  //           <circle cx={ex} cy={ey} r={2} fill={fill} stroke="red" />
  //       )
  //   }

  //   return (
  //     <g>
  //       <text x={cx} y={cy} dy={-8}
  //       fontSize="25"
  //       fontWeight="700"
  //       textAnchor="middle" fill={fill}>
  //         {TotalVal}
  //       </text>
  //       <text x={cx} y={cy} dy={16} fontSize="18"
  //         fontWeight="700" textAnchor="middle" fill={fill}>
  //         Total
  //       </text>

  //       <Sector
  //         cx={cx}
  //         cy={cy}
  //         innerRadius={innerRadius}
  //         outerRadius={outerRadius}
  //         startAngle={startAngle}
  //         endAngle={endAngle}
  //         fill={fill}
  //       />

  //       <text
  //         x={ex + (cos >= 0 ? 1 : -1) * 6}
  //         y={ey}
  //         textAnchor={textAnchor}
  //         fontSize="18"
  //         fontWeight="700"
  //         fill="#333"
  //       >{`${value}`}</text>
  //       {/* <text
  //         x={ex + (cos >= 0 ? 1 : -1) * 12}
  //         y={ey}
  //         dy={18}
  //         textAnchor={textAnchor}
  //         fill="red"
  //       >
  //         {`(Rate ${(percent * 100).toFixed(2)}%)`}
  //       </text> */}

  //     </g>
  //   );
  // };
  const renderActiveShape = (props) => {
    const { cx, cy, fill, payload } = props;
    const x = cx;
    const y = cy;

    return (
      <g>
        <text
          x={x}
          y={y}
          dy={8}
          textAnchor="middle"
          fill={fill}
        >
          {`${payload.name}: ${payload.value}`}
        </text>
      </g>
    );
  };
  const [activeIndex, setActiveIndex] = useState([0, 1]);
  const onPieEnter = useCallback(
    (_, index) => {
      setActiveIndex(index);
    },
    [setActiveIndex]
  );
  const COLORS = ["url(#color1)", "url(#color2)", "url(#color3)"];

  const totalValue = data.reduce((total, entry) => total + entry.value, 0);
  const leftValue = data[0].value;
  const rightValue = data[1].value;

  const innerRadius = 50;
  const outerRadius = 80;

  return (
    <>
    <div className="sca_piechart" style={{ width: "auto", maxWidth: "100%", display: 'flex', justifyContent: 'center' }}>
      {/* <PieChart width={300} height={290}>
      <Pie
        activeIndex={activeIndex}
        activeShape={renderActiveShape}
        data={data}
        cx={180}
        cy={130}
        innerRadius={60}
        outerRadius={80}
        fill="#8884d8"
        dataKey="value"
        onMouseEnter={onPieEnter}
      />
    </PieChart> */}
      <PieChart
        width={325}
        height={215}
      >
        <defs>
          <linearGradient
            id="color2"
            x1="0"
            y1="1"
            x2="0"
            y2="0"
          >
            <stop
              offset="0%"
              stopColor="#FF3043"
            />
            <stop
              offset="44%"
              stopColor="#FF3344"
            />
            <stop
              offset="87%"
              stopColor="#FF7D59"
            />
          </linearGradient>
          <linearGradient
            id="color1"
            x1="0"
            y1="0"
            x2="0"
            y2="1"
          >
            <stop
              offset="0%"
              stopColor="#63E09E"
            />
            <stop
              offset="43%"
              stopColor="#63E09E"
            />
            <stop
              offset="83%"
              stopColor="#1DD3AE"
            />
          </linearGradient>
        </defs>
        <Pie
          data={data}
          cx={155}
          cy={100}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          fill="#8884d8"
          paddingAngle={0}
          dataKey="value"
          startAngle={90}
          endAngle={450}
        >
          {data.map((entry, index) => (
            <Cell
              key={`cell-${index}`}
              fill={COLORS[index % COLORS.length]}
            />
          ))}
        </Pie>
        <text
          className="d-flex"
          x={160}
          y={105}
          textAnchor="middle"
          fill="#2B318A"
          fontWeight={700}
          fontSize="19px"
        >
          {totalValue}
        </text>
        <text
          className="d-flex"
          x={160}
          y={125}
          textAnchor="middle"
          fill="#9494AE"
          fontSize="15px"
          fontWeight={700}
        >
          TOTAL
        </text>
        <text
          x={60}
          y={115}
          textAnchor="end"
          fontSize="15px"
          fontWeight={700}
          fill="#4FDCA2"
        >
          {leftValue}
        </text>
        <text
          x={255}
          y={115}
          textAnchor="start"
          fontSize="15px"
          fontWeight={700}
          fill="#FF3143"

        >
          {rightValue}
        </text>
      </PieChart>
      </div>
      
      <div className="d-flex justify-content-center gap-3 p-2">
        <div className="d-flex align-items-center gap-2">
          <span className="dot"></span>
          <span className="chart-detail">Un-Approved</span>
        </div>

        <div className="d-flex align-items-center gap-2">
          <span
            style={{ background: "#02B583" }}
            className="dot"
          ></span>
          <span className="chart-detail">Approved</span>
        </div>
      </div>
      </>
    
  );
};

export default SCA_PieChart;
