import React, { useEffect } from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4plugins_forceDirected from '@amcharts/amcharts4/plugins/forceDirected';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';

const ForceDirectedTreeChart = () => {
  useEffect(() => {
    am4core.useTheme(am4themes_animated);

    const chart = am4core.create('treechart', am4plugins_forceDirected.ForceDirectedTree);

    chart.data = [
      {
        name: "Core",
        children: [
          {
            name: "First",
            children: [
              { name: "A1", value: 100 },
              { name: "A2", value: 60 }
            ]
          },
          {
            name: "Second",
            children: [
              { name: "B1", value: 135 },
              { name: "B2", value: 98 }
            ]
          },
          {
            name: "Third",
            children: [
              {
                name: "C1",
                children: [
                  { name: "EE1", value: 130 },
                  { name: "EE2", value: 87 },
                  { name: "EE3", value: 55 }
                ]
              },
              { name: "C2", value: 148 },
              {
                name: "C3", children: [
                  { name: "CC1", value: 53 },
                  { name: "CC2", value: 30 }
                ]
              },
              { name: "C4", value: 26 }
            ]
          },
          {
            name: "Fourth",
            children: [
              { name: "D1", value: 415 },
              { name: "D2", value: 148 },
              { name: "D3", value: 89 }
            ]
          },
          {
            name: "Fifth",
            children: [
              {
                name: "E1",
                children: [
                  { name: "EE1", value: 33 },
                  { name: "EE2", value: 40 },
                  { name: "EE3", value: 89 }
                ]
              },
              {
                name: "E2",
                value: 148
              }
            ]
          }
    
        ]
      }
    ];

    // Configure chart series and nodes
    const networkSeries = chart.series.push(new am4plugins_forceDirected.ForceDirectedSeries());
    networkSeries.dataFields.value = 'value';
    networkSeries.dataFields.name = 'name';
    networkSeries.dataFields.id = 'name';
    networkSeries.dataFields.children = 'children';
    networkSeries.nodes.template.tooltipText = '{name}:{value}';
    networkSeries.nodes.template.fillOpacity = 1;
    networkSeries.manyBodyStrength = -20;
    networkSeries.links.template.strength = 0.8;
    networkSeries.minRadius = am4core.percent(2);
    networkSeries.nodes.template.label.text = '{name}';
    networkSeries.fontSize = 10;

    // Function to change node color on button click
    const changeColor = () => {
      const dataItem = networkSeries.getDataItemById(networkSeries.dataItems, 'Fifth');
      dataItem.node.circle.fill = am4core.color('#00ff00');

      // Change color of all children
      dataItem.children.each(function (child) {
        child.node.circle.fill = am4core.color('#00ffff');
      });
    };

    // Add event listener to button
    // const button = document.getElementById('btn');
    // button.addEventListener('click', changeColor);

    // Clean up function
    return () => {
      chart.dispose();
      // button.removeEventListener('click', changeColor);
    };
  }, []); 

  return (
    <div>
      <div id="treechart" style={{ width: '100%', height: '500px' }}></div>
      {/* <input type="button" id="btn" value="Change Color" /> */}
    </div>
  );
};

export default ForceDirectedTreeChart;
