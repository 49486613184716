import { Button, Card, Result, Select } from "antd";
import React, { startTransition, useState } from "react";
import accountTree from '../../../../../images/icons/account_tree.png'
import arming2 from '../../../../../images/icons/arming_countdown2.png'
import workLoad from '../../../../../images/icons/assured_workload2.png'
import "./TopIssuesPage.css";
import TopIssuesCard from "./TopIssuesCard";
import {
  DeleteOutlined,
  EditOutlined,
  EyeTwoTone,
} from "@ant-design/icons";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import CommonBottomDrawer from "../../../../Common/commonBottomDrawer/CommonBottomDrawer";
import TabsWithExtraBtn from "../../../../Common/tabs-with-extra-content/TabsWithExtraBtn";
import CollapseComponent from "../../../../Common/CommanTable/CollapseComponent";
import CollapseTopIssues from "./CollapseTopIssues.js";
import AffectedSoftware from "./AffectedSoftware.js";
import SolutionFix from "./SolutionFix.js";
import References from "./References.js";
import DocumentListTable from "../../../../../components/DocumentList/DocumentListTable";
import Findings from "../../../../../components/findings/Findings";
import warningIcon from "../../../../../images/otherIcons/warningIcon.svg";
import TimeLineComponet from "../../../../../components/timeline/TimeLineComponet";
import SmallModal from "../../../../Common/small-pop-modal/SmallModal";

const TopIssuesPage = () => {
  const history = useHistory();
  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };
  const iconStyle = {
    position: "relative",
    top: "5px",
    fontSize: "18px",
    marginRight: "5px",
    height:'14px'
  };
  const topIssuesCardData = [
    {
      color: "#FE9706",
      icon: <img src={accountTree} alt="Icon" style={{ ...iconStyle, color: "#FE9706" }} />,
      title: "'newtonsoft.json'",
      content: "Policy Violation (newtonsoft.json@12.0.3)",
      discription:
        "First detected 17 days ago, most recently seen 2 hours ago in dotnet-api",
    },
    {
      icon: (
        <img src={arming2} alt="Icon" style={{ ...iconStyle, height:'17px' }} />
      ),
      title: "CVE-2024-21907",
      discription:
        "First detected 17 days ago, most recently seen 2 hours ago in dotnet-api",
      content: "Vulnerability (newtonsoft.json@12.0.3)",
    },
    {
      icon: (
        <img src={workLoad} alt="Icon" style={{ ...iconStyle,height:'17px' }} />
      ),
      title: "'at least 3 releases a year'",
      discription:
        "First detected 17 days ago, most recently seen 2 hours ago in dotnet-api",
      content: "Policy Violation (newtonsoft.json@12.0.3)",
    },
    {
      icon: <img src={accountTree} alt="Icon" style={{ ...iconStyle, color: "#FE9706" }} />,
      title: "CVE-2024-21907",
      discription:
        "First detected 17 days ago, most recently seen 2 hours ago in dotnet-api",
      content: "Vulnerability (newtonsoft.json@12.0.3)",
    },
    {
      icon:<img src={arming2} alt="Icon" style={{...iconStyle, height:'17px'}} />,
      title: "'at least 3 releases a year'",
      discription:
        "First detected 17 days ago, most recently seen 2 hours ago in dotnet-api",
      content: "Policy Violation (newtonsoft.json@12.0.3)",
    },
  ];
  const viewMore = ()=>{
    startTransition(() => {
      console.log("viewMoreBtn--->", history);
      history.push("/sca-vulnerabilities");
    })
  }
  const tabsMenu = [
    {
      key: "Vulnerability_Details",
      label: "Vulnerability Details",
      children: <CollapseTopIssues />,
    },
    { key: "Affected_Software", label: "Affected Software", children: <AffectedSoftware /> },
    {
      key: "Solution_Fix",
      label: "Solution & Fix",
      children: <SolutionFix />,
    },
    {
      key: "References",
      label: "References",
      children: <References  />,
    },
  ];
  const [openSmalModal, setOpenSmalModal] = useState(false);
  const handleCloseSmallModal = () => {
    setOpenSmalModal(false);
  };
  const handleOpenSmallModal = () => {
    setOpenSmalModal(true);
  };
  const operations = (
    <div className="extra_btn">
      <Button
        onClick={handleOpenSmallModal}
        icon={<DeleteOutlined />}
        style={{ borderColor: "#1677ff", color: "#1677ff" }}
      >
        Delete
      </Button>
      <Button
        icon={<EditOutlined />}
        style={{ borderColor: "#1677ff", color: "#FFF", background: "#5A81FA" }}
      >
        Edit
      </Button>
    </div>
  );
  const [activeTabs, setActiveTabs] = useState("Vulnerability Details");
  const callback = (activekey) => {
    setActiveTabs(activekey);
    console.log("active-key ==", activekey);
  };
  const [openDrawer, setOpenDrawer] = useState(false);
  const showDrawerModal = ()=>{
    console.log('onclick drawerModal');
    setOpenDrawer(true);
  }
  return (
    <>
      <div
      className="top-issues"
        style={{
         
        }}
      >
        <div>Issues List</div>
        <div>
          <Select
            defaultValue="Filter By"
            onChange={handleChange}
            options={[
              {
                value: "Critical",
                label: "Critical",
              },
              {
                value: "High",
                label: "High",
              },
              {
                value: "Medium",
                label: "Medium",
              },
              {
                value: "low",
                label: "low",
              },
            ]}
          />
        </div>
      </div>
      <div onClick={()=>showDrawerModal()}>
        <TopIssuesCard
          style={{ marginBottom: "5px" }}
          topIssuesCardData={topIssuesCardData}
          
        />
      </div>
        <div className="view-more" onClick={() => viewMore()} ><EyeTwoTone style={{fontSize:'18px',marginRight:'5px'}} /> View More Details</div>
        {/* below for commondrawer  */}
        <CommonBottomDrawer
        title="Vulnerability"
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
      >
        <Card>
          <TabsWithExtraBtn
            callback={callback}
            menu={tabsMenu}
            extraBtn={operations}
          />
        </Card>
      </CommonBottomDrawer>
      <SmallModal
        open={openSmalModal}
        CloseModal={handleCloseSmallModal}
        // title="small modal"
      >
        <Result
          status="warning"
          icon={<img src={warningIcon} alt="warnig" />}
          title="Are you sure!"
          subTitle="Do you really want to delete these records?"
          extra={
            <div className="d-flex gap-2">
              <Button
                onClick={handleCloseSmallModal}
                type="default"
                block
                key="console"
              >
                Cancel
              </Button>
              <Button block type="primary" key="console">
                Delete
              </Button>
            </div>
          }
        />
      </SmallModal>
    </>
  );
};

export default TopIssuesPage;
