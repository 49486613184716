import React, { Suspense ,useEffect ,useState } from "react";
import logo from "./logo.svg";
import "./App.css";
import MainLayout from "./components/layoutComponents/MainLayout";
import Dashboard from "./components/dashboard/Dashboard";
import SCA_Dashboard from "./Pages/Modules/SC/sca-dashboard/SCA_Dashboard";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import LoginPage from "./components/login-page/LoginPage";
import DashboardOption from "./components/dashboard-options/DashboardOptions";
import DynamicForm from "./components/SelectComponent/DynamicForm";
import { allFieldDataJson } from "../src/components/dashboard-options/Data";
import AssetInventoryList from "./components/assets-inventory/AssetInventoryList";
import PdfDocumnet from "./Pages/Pdf/PdfDocumnet";
import Mobile from "./Pages/Modules/SC/assets-inventory/mobile/Mobile";
import TPRMDashboard from "./Pages/Modules/TPRM/TPRMDashboard";
import Onboarding from "./Pages/Modules/TPRM/onboarding/Onboarding";
import ScanDetails from "./Pages/Modules/SC/sca-dashboard/components/ScanDetails.js";
import ScaLicense from "./Pages/Modules/SC/sca-dashboard/components/ScaLicense.js";
const scaProjectList = React.lazy(() =>
  import("./Pages/Modules/SC/sca-dashboard/components/SCAProjectList.js")
);
const ScaVulnerabilities = React.lazy(() =>
  import("./Pages/Modules/SC/sca-dashboard/components/ScaVulnerabilities.js")
);
const ScaComponents = React.lazy(() =>
  import("./Pages/Modules/SC/sca-dashboard/components/ScaComponents.js")
);
const VMDashboard = React.lazy(()=>import("./Pages/Modules/VM/vulnerability-dashboard/VmDashboard.js"))

const AuditTrackerCal = React.lazy(()=>import('./Pages/Modules/VM/VmComponents/audit-tracker-calendar/AuditCaledarTracker.js'))
const riskServtymatrix = React.lazy(()=>import('./Pages/Modules/VM/VmComponents/risk-severty-matrix/RiskSevertyMatrix.js'))
const VmFinding = React.lazy(()=>import('./Pages/Modules/VM/VmComponents/findings/Finding.js'))
const TechStack = React.lazy(()=>import('./Pages/Modules/VM/VmComponents/TechStack/TechStack.js'))
const ScaGovernace = React.lazy(()=>import('./Pages/Modules/SC/sca-dashboard/components/Governance.js'))
const ScaIntegeration = React.lazy(()=>import("./Pages/Modules/SC/sca-dashboard/components/Integeration.js"))
const VendorOnboardingFlow =React.lazy(()=>import("./Pages/Modules/TPRM/onboarding-flow/QuestionCardByAmol.jsx"))
const AuditorRemarks =React.lazy(()=>import("./Pages/Modules/TPRM/onboarding-flow/auditor-remarks/AuditorRemarks.js"))
const ReviwerRemarks =React.lazy(()=>import("./Pages/Modules/TPRM/onboarding-flow/reviewer-remarks/ReviewerRemarks.js"))
const CSPMDashboard =React.lazy(()=>import("./Pages/Modules/CSPM/CSPMDashboard.js"))
const TemplateBuilderCategory=React.lazy(()=>import("./Pages/Modules/TB/TemplateBuilderDashboard.js"))
const TBQuestionBank = React.lazy(()=>import("./Pages/Modules/TB/question-bank/QuestionBank.js"))
const TBCheckList=React.lazy(()=>import("./Pages/Modules/TB/template-builder-checklist/CheckList.js"))

function App() {
  const [accessGranted, setAccessGranted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [ip, setIp] = useState('');
  console.log("alldata json==", allFieldDataJson);
 
  useEffect(() => {
    const checkAccess = async () => {
      try {
        setLoading(true);
        const response = await fetch('https://api.ipify.org?format=json');
        const data = await response.json();
        setIp(data?.ip);
        if (data.ip === process.env.REACT_APP_IP) {
          setAccessGranted(true);
        } else {
          setAccessGranted(false);
        }
      } catch (error) {
        console.error('Error fetching IP:', error);
        setAccessGranted(false);
      }finally{
        setLoading(false);
      }
    };
    checkAccess();
  }, []);


  if (loading) {
    return <div style={{minHeight:'100vh',display:'flex',justifyContent:'center',alignItems:'center'}}>Loading...</div>;
  }
  if (!accessGranted) {
    return <div style={{minHeight:'100vh',display:'flex',justifyContent:'center',alignItems:'center'}}>Access Denied</div>;
  }

  return (
    <div className="App">
      <Router>
      <Suspense fallback={<div>Loading...</div>}>
        <Switch>
          <Route path="/pdf" component={() => <PdfDocumnet />} />
          <Route path="/vendor-onboarding-flow" component={VendorOnboardingFlow} />
          <Route path="/vendor-onboarding-auditor-remark" component={AuditorRemarks} />
          <Route path="/vendor-onboarding-reviwer-remark" component={ReviwerRemarks} />
          <Route path="/login" component={LoginPage} />
          <Route path="/dashboard-option" component={DashboardOption} />
          <MainLayout className="main_content">
            <Switch>
              <Route exact path="/dashboard" component={Dashboard} />
              <Route exact path="/" component={Dashboard} />
              <Route exact path="/sca-dashboard" component={SCA_Dashboard} />
              <Route
                path="/cloud-dashboard"
                component={() => <DynamicForm data={allFieldDataJson} />}
              />
              <Route
                path="/assets-inventory"
                component={() => <AssetInventoryList />}
              />
              <Route path="/mobile" component={() => <Mobile />} />
              <Route path="/onboarding" component={() => <Onboarding />} />
              <Route
                path="/third-party-risk-managemnet-dashboard"
                component={() => <TPRMDashboard />}
              />
               <Route path="/sca-project-list" component={ scaProjectList } />
               <Route path="/sca-vulnerabilities" component={ ScaVulnerabilities } />
               <Route path="/vulnerability-dashboard" component={ VMDashboard } />
               <Route path="/risk-severty-matrix" component={ riskServtymatrix } />
               <Route path="/vm-finding" component={ VmFinding } />
               <Route path="/audit-tracker-calendar" component={ AuditTrackerCal } />
               <Route path="/sca-scan-details" component={ ScanDetails } />
               <Route path="/sca-license" component={ ScaLicense } />
               <Route path="/sca-components" component={ ScaComponents } />
               <Route path="/sca-governance" component={ ScaGovernace } />
               <Route path="/sca-integerations" component={ ScaIntegeration } />
               <Route path="/cloud-security-posture-management" component={ CSPMDashboard } />
               <Route path="/teck-stack" component={ TechStack } />
               <Route path="/template-builder-categories" component={ TemplateBuilderCategory } />
               <Route path="/template-builder-question-bank" component={ TBQuestionBank } />
               <Route path="/template-builder-checklist" component={ TBCheckList } />
            </Switch>
          </MainLayout>
          <Redirect from="/" to="/login" />
        </Switch>
        </Suspense>
      </Router>
    </div>
  );
}

export default App;
