import React, { useState } from 'react'
import "./VendorDashboard.css"
import { Button, Card } from 'antd'
import TabsWithExtraBtn from '../../../Common/tabs-with-extra-content/TabsWithExtraBtn'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import OverallDetailsTab from '../overall-details-tab/OverallDetailsTab'

const VendorDashboard = () => {
    const [activeKey, setActiveKey]=useState("overall_details")
    const callback = (key)=>{
        setActiveKey(key)
    }

    //tabs menu start
    const tabMenu = [
        {
          key: "overall_details",
          label: "Overall Details",
          children: <OverallDetailsTab />,
        },
        { key: "documnet", label: "Document", children: <h1>Document Tabs</h1> },
        {
          key: "timeline",
          label: "Timeline",
          children: <h1>Timeline Tabs</h1>,
        },
      ];
    //tabs menu end

       // extra btn for tabsWithBtn components
  const operations = (
    <div className="extra_btn">
      <Button
        // onClick={handleOpenSmallModal}
        icon={<DeleteOutlined />}
        style={{ borderColor: "#1677ff", color: "#1677ff" }}
      >
        Delete
      </Button>
      <Button
        icon={<EditOutlined />}
        style={{ borderColor: "#1677ff", color: "#FFF", background: "#5A81FA" }}
      >
        Edit
      </Button>
    </div>
  );
  // end
  return (
    <div className='vendor_dashboard'>
        <Card>
        <TabsWithExtraBtn 
         callback={callback}
         activeKey={activeKey}
         menu={tabMenu}
         extraBtn={operations}
        />
        </Card>
    </div>
  )
}

export default VendorDashboard