import React, { useState } from "react";
import "./Identification.css";
import { CheckCircleFilled } from "@ant-design/icons";
import vulnerabiltyLimitIcon from "../../../../images/otherIcons/vulnerabiltyLimit.png";
import OutedatedPackIcon from "../../../../images/otherIcons/outedatedPackage.png";
import { Col, Form, Input, Row, Select } from "antd";

const { Option } = Select;

const Identification = () => {
  const [form] = Form.useForm();
  const [assetType, setAssetType] = useState(undefined);

  const handleBtnClick = (value) => {
    setAssetType(value);
  };

  const onFinish = (values) => {
    console.log("Received values:", values);
  };

  return (
    <div className="identification">
      <Form layout="vertical" form={form} onFinish={onFinish} >
        <div className="form_wrapper">
       
          <Row gutter={[16, 16]} className="m-0">
          <Col xs={24} sm={24} md={24} lg={24}>
          <p className="heading_form_1 mb-0 text-center">
            You can add Multiple Rules
          </p>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24}>
          <div className="vendor_policy_label">
            The vendor name will be shown on your scan results and should be a
            way to quickly identify what the policy protects against, such as
            'Exclude Copyleft Licenses' or 'Commercial Application'
          </div>
          </Col>
            <Col xs={24} sm={24} md={24} lg={24}>
              <Form.Item
                style={{ marginBottom: "0px" }}
                name="vendor_name"
                label={<span className="custom_label_class">Vendor Name</span>}
                rules={[
                  { required: true, message: "Please enter Vendor Name" },
                ]}
              >
                <Input size="large" placeholder="Enter Vendor Name" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24}>
              <Form.Item
                style={{ marginBottom: "0px" }}
                name="assessment_name"
                label={
                  <span className="custom_label_class">Enter Assessment Name</span>
                }
                rules={[
                  { required: true, message: "Please enter Assessment Name" },
                ]}
              >
                <Input size="large" placeholder="Enter Assessment Name" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24}>
              <Form.Item
                style={{ marginBottom: "0px" }}
                name="tier"
                label={<span className="custom_label_class">Tier</span>}
                rules={[{ required: true, message: "Please select Tier" }]}
              >
                <Select
                  size="large"
                  placeholder="Select"
                  onChange={(value) => setAssetType(value)}
                >
                  {Array(4)
                    .fill()
                    .map((_, i) => (
                      <Option key={i} value={`Tier ${i + 1}`}>
                        {`Tier ${i + 1}`}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24}>
              <Form.Item
                style={{ marginBottom: "0px" }}
                name="digital"
                rules={[{ required: true, message: "Please select Assessment Type" }]}
                label={
                  <span className="custom_label_class">Assessment Type</span>
                }
              >
                <div className="d-flex justify-content-between gap-3">

                  <button
                    size="large"
                    className={`${
                      assetType === "digital"
                        ? "selectable_btn selectable_btn_checked"
                        : "selectable_btn"
                    }`}
                    onClick={() => handleBtnClick("digital")}
                    block
                    type="default"
                  >
                    <span className="btn_img_wrap">
                      <img width={30} src={vulnerabiltyLimitIcon} alt="file" />
                      <span className="btn_inner_text"> Digital</span>
                    </span>
                    {assetType === "digital" ? (
                      <CheckCircleFilled style={{ fontSize: 18, color: "#5A81FA" }} />
                    ) : null}
                  </button>
                  <button
                    size="large"
                    className={`${
                      assetType === "questionnaire"
                        ? "selectable_btn selectable_btn_checked"
                        : "selectable_btn"
                    }`}
                    onClick={() => handleBtnClick("questionnaire")}
                    block
                    type="default"
                  >
                    <span className="btn_img_wrap">
                      <img width={30} src={OutedatedPackIcon} alt="file" />
                      <span className="btn_inner_text"> Questionnaire</span>
                    </span>
                    {assetType === "questionnaire" ? (
                      <CheckCircleFilled style={{ fontSize: 18, color: "#5A81FA" }} />
                    ) : null}
                  </button>
                </div>
              </Form.Item>
            </Col>
          </Row>
        </div>
      </Form>
    </div>
  );
};

export default Identification;
