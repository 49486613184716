import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { Col, Collapse, Flex } from "antd";
import React from "react";
import { ReactComponent as ExclamationIcon } from "../../../../../images/icons/exclamation.svg"
import { ReactComponent as CheckIcon } from "../../../../../images/icons/done.svg"
import { ReactComponent as RemoveIcon } from "../../../../../images/icons/remove.svg"
import { ReactComponent as CrossIcon } from "../../../../../images/icons/close.svg"
import './CollapseDependencyTree.css'

const CollapseDependencyTree = () => {

  const customExpandIcon = (panelProps) => {
    const { isActive } = panelProps;
    return isActive ? <MinusOutlined /> : <PlusOutlined />;
  };
   return (
    <div className="collapse-dependency">
        <Collapse
        defaultActiveKey={"1"}
        expandIconPosition="end"
        expandIcon={customExpandIcon}
        
        >
        <Collapse.Panel
            style={{ background: "#DCDDE7", marginBottom: 8, fontSize:'13px',fontWeight:600 }}
            header="Basic Details"
            key="1"
        >
            <Flex>
            <Col
                xs={24}
                sm={6}
                md={6}
                lg={6}
            >
                <div className="detail">12 years ago</div>
                <div className="sub-detail">First Published</div>
            </Col>
            <Col
                xs={24}
                sm={6}
                md={6}
                lg={6}
            >
                <div className="detail">2 years ago</div>
                <div className="sub-detail">Ver Puublished</div>
            </Col>
            <Col
                xs={24}
                sm={6}
                md={6}
                lg={6}
            >
                <div className="detail">24 days ago</div>
                <div className="sub-detail">Last Push</div>
            </Col>
            <Col
                xs={24}
                sm={6}
                md={6}
                lg={6}
            >
                <div className="detail">4.8 M</div>
                <div className="sub-detail">Last Push</div>
            </Col>
            </Flex>
            <Flex className='py-4'>
            <Col
                xs={24}
                sm={6}
                md={6}
                lg={6}
            >
                <div className="detail">87 M</div>
                <div className="sub-detail">Total Downloads</div>
            </Col>
            <Col
                xs={24}
                sm={6}
                md={6}
                lg={6}
            >
                <div className="detail">1.4 k</div>
                <div className="sub-detail">Forks</div>
            </Col>
            <Col
                xs={24}
                sm={6}
                md={6}
                lg={6}
            >
                <div className="detail">14</div>
                <div className="sub-detail">Branches</div>
            </Col>
            <Col
                xs={24}
                sm={6}
                md={6}
                lg={6}
            >
                <div className="detail">4.43 MB</div>
                <div className="sub-detail">Repository Size</div>
            </Col>
            </Flex>
            <Flex>
                <Col
                xs={24}
                sm={8}
                md={8}
                lg={8}
                >
                    <div className="detail">Usage</div>
                    <div className="sub-detail">Describes how software may be used under this license.</div>
                </Col>   
                <Col
                xs={24}
                sm={8}
                md={8}
                lg={8}
                >
                    <div className="detail">Options</div>
                    <div className="sub-detail">Describes the options that may be specified for software under this license.</div>
                </Col>
                <Col
                xs={24}
                sm={8}
                md={8}
                lg={8}
                >
                    <div className="detail">Requirements</div>
                    <div className="sub-detail">Describes the requirements that must be exercised by using software under this license.</div>
                    
                </Col>
            </Flex>
            <Flex className="icons">
                <Col
                xs={24}
                sm={8}
                md={8}
                lg={8}
                >
                    <Flex>
                        <div className="icon-detail">
                            <ExclamationIcon
                            className="exclamation-icon-style"
                            style={{ color: "#FE9706", backgroundColor: "#ffeacd" }}
                            /> Commercial Use
                        </div>
                        <div className="icon-detail">
                            <CheckIcon
                            className="exclamation-icon-style"
                            style={{color:'#02B583',backgroundColor:'#e6f8f3'}}
                            /> Commercial Use
                        </div>
                    </Flex>
                    <Flex>
                        <div className="icon-detail">
                            <CheckIcon
                            className="exclamation-icon-style"
                            style={{color:'#02B583',backgroundColor:'#e6f8f3'}}
                            /> Commercial Use
                        </div>
                        <div className="icon-detail">
                            <ExclamationIcon
                            className="exclamation-icon-style"
                            style={{ color: "#FE9706", backgroundColor: "#ffeacd" }}
                            /> Commercial Use
                        </div>
                    </Flex>
                    <Flex>
                        <div className="icon-detail">
                            <ExclamationIcon
                            className="exclamation-icon-style"
                            style={{ color: "#FE9706", backgroundColor: "#ffeacd" }}
                            /> Commercial Use
                        </div>
                        <div className="icon-detail">
                            <RemoveIcon
                            className="exclamation-icon-style"
                            style={{ color: "#63637D", backgroundColor: "#e0e0e5" }}
                            /> Commercial Use
                        </div>
                    </Flex>
                </Col>
                <Col
                xs={24}
                sm={8}
                md={8}
                lg={8}
                >
                    <Flex>
                        <div className="icon-detail">
                            <ExclamationIcon
                            className="exclamation-icon-style"
                            style={{ color: "#FE9706", backgroundColor: "#ffeacd" }}
                            /> Commercial Use
                        </div>
                        <div className="icon-detail">
                            <CheckIcon
                            className="exclamation-icon-style"
                            style={{color:'#02B583',backgroundColor:'#e6f8f3'}}
                            /> Commercial Use
                        </div>
                    </Flex>
                    <Flex>
                        <div className="icon-detail">
                            <CheckIcon
                            className="exclamation-icon-style"
                            style={{color:'#02B583',backgroundColor:'#e6f8f3'}}
                            /> Commercial Use
                        </div>
                        <div className="icon-detail">
                            <ExclamationIcon
                            className="exclamation-icon-style"
                            style={{ color: "#FE9706", backgroundColor: "#ffeacd" }}
                            /> Commercial Use
                        </div>
                    </Flex>
                    <Flex>
                        <div className="icon-detail">
                            <ExclamationIcon
                            className="exclamation-icon-style"
                            style={{ color: "#FE9706", backgroundColor: "#ffeacd" }}
                            /> Commercial Use
                        </div>
                        <div className="icon-detail">
                            <RemoveIcon
                            className="exclamation-icon-style"
                            style={{ color: "#63637D", backgroundColor: "#e0e0e5" }}
                            /> Commercial Use
                        </div>
                    </Flex>
                </Col>
                <Col
                xs={24}
                sm={8}
                md={8}
                lg={8}
                >
                    <Flex>
                        <div className="icon-detail">
                            <ExclamationIcon
                            className="exclamation-icon-style"
                            style={{ color: "#FE9706", backgroundColor: "#ffeacd" }}
                            /> Commercial Use
                        </div>
                        <div className="icon-detail">
                            <CheckIcon
                            className="exclamation-icon-style"
                            style={{color:'#02B583',backgroundColor:'#e6f8f3'}}
                            /> Commercial Use
                        </div>
                    </Flex>
                    <Flex>
                        <div className="icon-detail">
                            <CheckIcon
                            className="exclamation-icon-style"
                            style={{color:'#02B583',backgroundColor:'#e6f8f3'}}
                            /> Commercial Use
                        </div>
                        <div className="icon-detail">
                            <ExclamationIcon
                            className="exclamation-icon-style"
                            style={{ color: "#FE9706", backgroundColor: "#ffeacd" }}
                            /> Commercial Use
                        </div>
                    </Flex>
                    <Flex>
                        <div className="icon-detail">
                            <ExclamationIcon
                            className="exclamation-icon-style"
                            style={{ color: "#FE9706", backgroundColor: "#ffeacd" }}
                            /> Commercial Use
                        </div>
                        <div className="icon-detail">
                            <RemoveIcon
                            className="exclamation-icon-style"
                            style={{ color: "#63637D", backgroundColor: "#e0e0e5" }}
                            /> Commercial Use
                        </div>
                    </Flex>
                </Col>
            
            </Flex>
        </Collapse.Panel>
        </Collapse>
    </div>
  );
};

export default CollapseDependencyTree;
