import { CloseOutlined, EyeOutlined } from "@ant-design/icons";
import { Button, Drawer, Table } from "antd";
import "./ViewSummary.css";
import React from "react";
import ViewSummaryChart from "../charts/ViewSummaryChart";

const columns = [
  {
    title: "Category",
    dataIndex: "category",
    render: (text) => <a>{text}</a>,
  },
  {
    title: "Current Instances",
    // className: "current_instances",
    dataIndex: "current_instances",
    render: (text) => (
      <div className="d-flex gap-2 align-items-center">
        <span>{text}</span>
        <svg
          width="20"
          height="10"
          viewBox="0 0 20 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2.49967 0C2.04134 0 1.64898 0.163195 1.32259 0.489583C0.996202 0.815972 0.833008 1.20833 0.833008 1.66667C0.833008 2.125 0.996202 2.51736 1.32259 2.84375C1.64898 3.17014 2.04134 3.33333 2.49967 3.33333H2.71842C2.78092 3.33333 2.8469 3.31944 2.91634 3.29167L6.70801 7.08333C6.68023 7.15278 6.66634 7.21875 6.66634 7.28125V7.5C6.66634 7.95833 6.82954 8.35069 7.15592 8.67708C7.48231 9.00347 7.87467 9.16667 8.33301 9.16667C8.79134 9.16667 9.1837 9.00347 9.51009 8.67708C9.83648 8.35069 9.99967 7.95833 9.99967 7.5C9.99967 7.47222 9.98578 7.33333 9.95801 7.08333L12.083 4.95833C12.1525 4.98611 12.2184 5 12.2809 5H12.7184C12.7809 5 12.8469 4.98611 12.9163 4.95833L15.8747 7.91667C15.8469 7.98611 15.833 8.05208 15.833 8.11458V8.33333C15.833 8.79167 15.9962 9.18403 16.3226 9.51042C16.649 9.83681 17.0413 10 17.4997 10C17.958 10 18.3504 9.83681 18.6768 9.51042C19.0031 9.18403 19.1663 8.79167 19.1663 8.33333C19.1663 7.875 19.0031 7.48264 18.6768 7.15625C18.3504 6.82986 17.958 6.66667 17.4997 6.66667H17.2809C17.2184 6.66667 17.1525 6.68056 17.083 6.70833L14.1247 3.75C14.1525 3.68056 14.1663 3.61458 14.1663 3.55208V3.33333C14.1663 2.875 14.0031 2.48264 13.6768 2.15625C13.3504 1.82986 12.958 1.66667 12.4997 1.66667C12.0413 1.66667 11.649 1.82986 11.3226 2.15625C10.9962 2.48264 10.833 2.875 10.833 3.33333V3.55208C10.833 3.61458 10.8469 3.68056 10.8747 3.75L8.74967 5.875C8.68023 5.84722 8.61426 5.83333 8.55176 5.83333H8.33301C8.30523 5.83333 8.16634 5.84722 7.91634 5.875L4.12467 2.08333C4.15245 2.01389 4.16634 1.94792 4.16634 1.88542V1.66667C4.16634 1.20833 4.00315 0.815972 3.67676 0.489583C3.35037 0.163195 2.95801 0 2.49967 0Z"
            fill="#21C81E"
          />
        </svg>
      </div>
    ),
  },
  {
    title: "Current Instances",
    dataIndex: "current_instances2",
    render: (text, record) => ({
      props: {
        style: {
          background: "#F0F0F3",
        },
      },
      children: <div>{text}</div>,
    }),
  },
];
const data = [
  {
    key: "1",
    category: "Information Leakage",
    current_instances: "100",
    current_instances2: "200",
  },
  {
    key: "2",
    category: "Network Security",
    current_instances: "100",
    current_instances2: "200",
  },
  {
    key: "3",
    category: "Security Misconfigurations",
    current_instances: "100",
    current_instances2: "200",
  },
  {
    key: "4",
    category: "Social Engineering",
    current_instances: "100",
    current_instances2: "200",
  },
  {
    key: "5",
    category: "Information Leakage",
    current_instances: "100",
    current_instances2: "200",
  },
  {
    key: "6",
    category: "Network Security",
    current_instances: "100",
    current_instances2: "200",
  },
  {
    key: "7",
    category: "Security Misconfigurations",
    current_instances: "100",
    current_instances2: "200",
  },
  {
    key: "8",
    category: "Social Engineering",
    current_instances: "100",
    current_instances2: "200",
  },
];



const ViewSummary = ({ open, onClose }) => {
  return (
    <div>
      <Drawer
        title="Cloud Risk Summary"
        placement="right"
        className="view_summary"
        width={585}
        onClose={onClose}
        open={open}
        closable={false}
        headerStyle={{
          borderBottom: "none",
          paddingBottom: "8px",
        }}
        extra={<CloseOutlined onClick={onClose} />}
      >
        <div className="view_summary_drawer_wrapper">
          <div className="chart_summary">
            {/* <TrendSummaryChart /> */}
            <ViewSummaryChart />

          </div>
          <div className="certificate_threat_list">
            <div className="threat_list_head_wrap">
              <p className="label_threat_list">Threat Category List</p>
              <i className="label_desc_threat_list">
                Click on each record to view threat details
              </i>
            </div>
            <Table
              columns={columns}
              dataSource={data}
              bordered
              //   title={() => "Header"}
              //   footer={() => "Footer"}
              pagination={false}
            />
          </div>
          <div className="cta_btn_view_more">
            <button className="btn view_more_btn">
              <EyeOutlined style={{ fontSize: 21, color: "#5A81FA" }} /> View
              More Details
            </button>
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default ViewSummary;
