import React, { useState } from "react";
import { Upload, Button, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";

const CustomFileUpload = ({ multiple, onChange }) => {
  const [fileList, setFileList] = useState([]);

  const handleUploadChange = (info) => {
    let fileList = [...info.fileList];

    // Limit the number of uploaded files if multiple upload is not allowed
    if (!multiple && fileList.length > 1) {
      fileList = fileList.slice(-1);
      message.error("You can only upload one file.");
    }

    setFileList(fileList);

    // Trigger onChange event to pass file list to parent component
    onChange(fileList);
  };

  const uploadProps = {
    fileList,
    beforeUpload: () => false, // Prevent default upload behavior
    onChange: handleUploadChange,
    // multiple,
  };

  return (
    <Upload className="file_upload" {...uploadProps} listType="picture">
      <Button size="large" style={{ width: "100%" }} icon={<UploadOutlined />}>
        Select File
      </Button>
    </Upload>
  );
};

export default CustomFileUpload;
