import {
  Button,
  Card,
  Checkbox,
  Col,
  Collapse,
  Dropdown,
  Menu,
  Pagination,
  Result,
  Row,
  Select,
  Switch,
  Table,
} from "antd";
import React, { useState } from "react";
import FilterListOutlinedIcon, {
  CloseOutlined,
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
  MinusOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import GlobalFilter from "../global-filter/GlobalFilter";
import "./CustomTable.css";
import CommonBottomDrawer from "../commonBottomDrawer/CommonBottomDrawer";

import CardViewIcon from "../../../images/sidenavIcons/view_day.png";
import TabsWithExtraBtn from "../tabs-with-extra-content/TabsWithExtraBtn";
import CollapseComponent from "./CollapseComponent";
import DocumentListTable from "../../../components/DocumentList/DocumentListTable";
import Findings from "../../../components/findings/Findings";
import TimeLineComponet from "../../../components/timeline/TimeLineComponet";
import DataGridCard from "../data-grid-card-view/DataGridCard";
import FormStep from "../steps/FormStep";
import DynamicForm from "../../../components/SelectComponent/DynamicForm";
import dataForm from "../../../components/dashboard-options/Data";
import SmallModal from "../small-pop-modal/SmallModal";
import warningIcon from "../../../../src/images/otherIcons/warningIcon.svg";
import SplitscreenOutlinedIcon from "@mui/icons-material/SplitscreenOutlined";
import FormWidthSection from "../../../components/SelectComponent/FormWidthSection";
import { formWidthSection } from "../../../components/dashboard-options/Data";
import RowSelectBtn from "./RowSelectBtn";

const { Option } = Select;

const CustomTable = ({ data }) => {
  const { applicationInfoFormData } = dataForm;
  console.log("data===42====", formWidthSection);
  const keys = data?.length > 0 ? Object.keys(data[0]) : [];

  const [columnsVisible, setColumnsVisible] = useState(keys);
  const [selectedRow, setSelectedRow] = useState(null);
  const [showGlobalFilter, setShowGlobalFilter] = useState(true);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [tableView, setTableView] = useState(false);
  const [openAddAndriodModal, setOpenAddAndriodModal] = useState(false);

  const openAndriodModal = () => {
    setOpenAddAndriodModal(true);
  };
  const closeAndriodModal = () => {
    setOpenAddAndriodModal(false);
  };
  const toggleTableView = () => {
    setTableView(!tableView);
  };
  const showDrawerModal = () => {
    setOpenDrawer(true);
  };

  const handleSwitchFilter = (checked) => {
    setShowGlobalFilter(checked);
  };

  const toggleColumn = (columnKey) => {
    const updatedVisibleColumns = columnsVisible.includes(columnKey)
      ? columnsVisible.filter((key) => key !== columnKey)
      : [...columnsVisible, columnKey];
    setColumnsVisible(updatedVisibleColumns);
  };

  const menu = (
    <Menu className="column_dropdown">
      {keys.slice(3).map((key) => (
        <Menu.Item className="menu_item" key={key}>
          <Checkbox
            onChange={() => toggleColumn(key)}
            checked={columnsVisible.includes(key)}
            onClick={(e) => e.stopPropagation()}
          >
            {key}
          </Checkbox>
        </Menu.Item>
      ))}
    </Menu>
  );
  const [rows, setRows] = useState(10);

  const handleRowSelect = (value) => {
    setRows(value);
  };
  const RowSelectMenu = (
    <Menu className="column_dropdown">
      {[10, 15, 25, 50, 100].map((key, i) => (
        <Menu.Item key={i} onClick={() => handleRowSelect(key)}>
          <span>{key} Rows</span>
        </Menu.Item>
      ))}
    </Menu>
  );

  const [activeTabs, setActiveTabs] = useState("all_details");
  const callback = (activekey) => {
    setActiveTabs(activekey);
    console.log("active-key ==", activekey);
  };

  // pagination start

  const itemRender = (current, type, originalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }
    return originalElement;
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const [currentPage, setCurrentPage] = useState(1);
  const totalRecords = data ? data.length : 100; // Total number of records in your table
  const pageSize = 10; // Number of records per page

  const startRecord = (currentPage - 1) * pageSize + 1;
  const endRecord = Math.min(currentPage * pageSize, totalRecords);
  // pagination end

  const timelineData = [
    {
      id: 1,
      color: "#FE9706",
      children: {
        status: "Submited",
        name: "Deloitte Security TL",
        remark:
          "It should be used at the top of the container and should override styles. Remark up to 100 character.",
        report_url: "https:/securityheaders.com/",
        file: [
          { id: 1, name: "XbizConnect_Report 1" },
          { id: 2, name: "XbizConnect_Report 2" },
          { id: 1, name: "XbizConnect_Report 3" },
        ],
        created_at: "20/10/2023 @11:03:00s",
        action: false,
      },
    },
    {
      id: 2,
      color: "#02B583",
      children: {
        status: "Confirm Activity",
        name: "Deloitte Security TL",
        remark:
          "It should be used at the top of the container and should override styles. Remark up to 100 character. ",
        report_url: "",
        file: "",
        created_at: "20/10/2023 @11:03:00",
        action: true,
      },
    },
    {
      id: 3,
      color: "#FF3043",
      children: {
        status: "TL Assigned",
        name: "Deloitte Security TL",
        remark:
          "It should be used at the top of the container and should override styles. Remark up to 100 character. ",
        report_url: "https:/securityheaders.com/",
        file: [
          { id: 1, name: "XbizConnect_Report 1" },
          { id: 2, name: "XbizConnect_Report 2" },
          { id: 3, name: "XbizConnect_Report 3" },
          { id: 4, name: "XbizConnect_Report 4" },
        ],
        created_at: "20/10/2023 @11:03:00",
        action: false,
      },
    },
    {
      id: 4,
      color: "#5A81FA",
      children: {
        status: "innitiated",
        name: "Deloitte Security TL",
        remark: "Audit Request initiated by Asset Manager.",
        report_url: "",
        file: "",
        created_at: "20/10/2023 @11:03:00",
        action: false,
      },
    },
  ];

  const tabsMenu = [
    {
      key: "all_details",
      label: "All Details",
      children: <CollapseComponent />,
    },
    { key: "documnet", label: "Document", children: <DocumentListTable /> },
    { key: "findings", label: "Findings", children: <Findings /> },
    {
      key: "timeline",
      label: "Timeline",
      children: <TimeLineComponet data={timelineData} />,
    },
    {
      key: "Finding Form Test",
      label: "Finding Form Test",
      children: <FormWidthSection data={formWidthSection} />,
    },
  ];
  // smalll modalPop for click delete btn on extrabtn tabsWithBtn components start
  const [openSmalModal, setOpenSmalModal] = useState(false);
  const handleCloseSmallModal = () => {
    setOpenSmalModal(false);
  };
  const handleOpenSmallModal = () => {
    setOpenSmalModal(true);
  };
  // smalll modalPop for click delete btn on extrabtn tabsWithBtn components end
  // extra btn for tabsWithBtn components
  const operations = (
    <div className="extra_btn">
      <Button
        onClick={handleOpenSmallModal}
        icon={<DeleteOutlined />}
        style={{ borderColor: "#1677ff", color: "#1677ff" }}
      >
        Delete
      </Button>
      <Button
        icon={<EditOutlined />}
        style={{ borderColor: "#1677ff", color: "#FFF", background: "#5A81FA" }}
      >
        Edit
      </Button>
    </div>
  );
  // end

  // steps menu send to steps FormSteps component start
  const steps = [
    {
      title: "Application Information",
      content: <DynamicForm data={applicationInfoFormData} />,
    },
    {
      title: "Developer Information",
      content: <DynamicForm data={applicationInfoFormData} />,
    },
    {
      title: "Other Information",
      content: <DynamicForm data={applicationInfoFormData} />,
    },
  ];
  // steps menu send to steps FormSteps component end

  return (
    <>
      <div className="common_table">
        <Row gutter={[16, 16]}>
          {showGlobalFilter ? (
            <Col
              xs={showGlobalFilter ? 24 : 0}
              sm={showGlobalFilter ? 24 : 0}
              md={showGlobalFilter ? 5 : 0}
            >
              <GlobalFilter />
            </Col>
          ) : null}
          <Col xs={24} sm={24} md={showGlobalFilter ? 19 : 24}>
            <div className="table_row d-flex justify-content-between align-items-center mb-2">
              <div
                className="wrapper_left"
                style={{
                  display: "inline-flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <Dropdown overlay={menu} trigger={["click"]}>
                  <a
                    className="column-icon"
                    onClick={(e) => e.stopPropagation()}
                  >
                    Columns
                    <SplitscreenOutlinedIcon
                      style={{ color: "#63637D", width: 15, fontSize: 13 }}
                    />
                  </a>
                </Dropdown>
                <RowSelectBtn />
                {!showGlobalFilter ? (
                  <a className="filter_label_btn">
                    <span className="filter_label_btn_btn_text"> Filter</span>
                    <CloseOutlined style={{ color: "#fff" }} />
                  </a>
                ) : null}
              </div>
              <div
                className="right_div"
                style={{
                  display: "inline-flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <div className="filter_switch">
                  <Switch
                    size="small"
                    className="custom_switch"
                    defaultChecked
                    onChange={handleSwitchFilter}
                  />
                  <span className="ms-1">Filter</span>
                </div>
                <div className="card_view">
                  <button className="card_view_btn" onClick={toggleTableView}>
                    <img src={CardViewIcon} alt="card_view" />
                    <span className="card_view_text"> Card View</span>
                  </button>
                </div>
                <div className="download_excel d-flex align-items-center">
                  <button
                    // icon={<DownloadOutlined style={{fontSize: 15}} />}
                    style={{ borderColor: "#5A81FA" }}
                    className="btn download_excel_btn btn-outlined  d-flex align-items-center "
                  >
                    <DownloadOutlined style={{ fontSize: 15 }} />
                    Download Excel
                  </button>
                </div>
                <div className="add_andriod">
                  <button
                    className="add_andriod_btn"
                    onClick={openAndriodModal}
                    // icon={<PlusOutlined />}
                    type="primary"
                  >
                    <PlusOutlined style={{ color: "#FFFFFF", fontSize: 15 }} />
                    Add Andriod
                  </button>
                </div>
              </div>
            </div>
            {!tableView ? (
              <Table
                dataSource={data}
                className="custom_table"
                size="small"
                scroll={{ x: true }}
                columns={keys
                  .filter((key) => columnsVisible.includes(key))
                  .slice(3)
                  .map((key) => ({
                    title: key,
                    dataIndex: key,
                    key: key,
                    ellipsis: true,
                    sorter: (a, b) => {
                      const valueA = a[key] || "";
                      const valueB = b[key] || "";
                      return valueA.localeCompare(valueB);
                    },
                  }))}
                pagination={false}
                onRow={(record) => ({
                  onClick: () => {
                    setSelectedRow(record);
                    showDrawerModal();
                  },
                })}
                rowKey={(record, index) => index}
              />
            ) : (
              <DataGridCard />
            )}
            <div
              style={{
                display: "flex",
                marginTop: "10px",
                justifyContent: "space-between",
                alignItems: "center",
                color: "#63637D",
                padding: "7px",
                fontWeight: 600,
                fontSize: "13px",
              }}
            >
              <div>
                Showing {startRecord}-{endRecord} entries out of {totalRecords}
              </div>
              <Pagination
                style={{
                  color: "#63637D",
                  padding: "5px",
                  fontWeight: 600,
                  fontSize: "12px",
                }}
                current={currentPage}
                pageSize={pageSize}
                total={totalRecords}
                itemRender={itemRender}
                onChange={handlePageChange}
                showSizeChanger={false}
              />
            </div>
          </Col>
        </Row>
      </div>
      <CommonBottomDrawer
        title="Mobile"
        open={openDrawer}
        onClose={() => {
          setOpenDrawer(false);
          setActiveTabs("all_details");
        }}
      >
        <Card>
          <TabsWithExtraBtn
            activeTabs={activeTabs}
            callback={callback}
            menu={tabsMenu}
            extraBtn={operations}
            key={activeTabs}
          />
        </Card>
      </CommonBottomDrawer>
      <CommonBottomDrawer
        title="Mobile"
        open={openAddAndriodModal}
        onClose={closeAndriodModal}
      >
        <Col className="m-auto" span={16}>
          <Card>
            <FormStep onCloseModal={closeAndriodModal} stepsMenu={steps} />
          </Card>
        </Col>
      </CommonBottomDrawer>
      <SmallModal
        open={openSmalModal}
        CloseModal={handleCloseSmallModal}
        // title="small modal"
      >
        <Result
          status="warning"
          icon={<img src={warningIcon} alt="warnig" />}
          title="Are you sure!"
          subTitle="Do you really want to delete these records?"
          extra={
            <div className="d-flex gap-2">
              <Button
                onClick={handleCloseSmallModal}
                type="default"
                block
                key="console"
              >
                Cancel
              </Button>
              <Button block type="primary" key="console">
                Delete
              </Button>
            </div>
          }
        />
      </SmallModal>
    </>
  );
};

export default CustomTable;
