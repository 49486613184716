import { SyncOutlined } from '@ant-design/icons'
import { Card, Col, Flex, Row } from 'antd'
import React from 'react'
import { useHistory } from 'react-router';
import CustomTableVulnerabilities from '../../../../Common/CommanTable/CustomTableVulnerabilities'
import data from "../../../../../components/dashboard-options/Data";
const { LicenseTableData } = data;

const ScaLicense = () => {
    let history= useHistory();
  const handleClick = ()=>{
    history.push("/sca-dashboard");
  }
  return (
  <>
    <Card className="vulnerabilities">
        <div style={{fontSize:'13px', color:'#5A81FA', fontWeight:400, paddingBottom:'10px'}} >
          Dashboard / <span onClick={handleClick} >SCA Dashboard / </span><span style={{color:'#63637D'}}>Licenses</span>
        </div>
        <Flex className='d-flex' justify={'space-between'} >
          <div className="license_summary_title ">Licenses</div>
          <div className="sca-vulnerabilities"> <SyncOutlined style={{marginRight:'5px'}} />Sync Data</div>
          </Flex >
        <Row>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={24}
          >
            <div></div>
            <CustomTableVulnerabilities data={LicenseTableData} applyOnRowHandler={true} />
          </Col>
        </Row>
      </Card>
  </>
  )
}

export default ScaLicense