
import React from 'react';
import { PieChart, Pie, Cell, Tooltip } from 'recharts';
import myImage from '../../../../../images/icons/terminal.png';

const CommanPieChart = ({score, isProjectTable}) => {
    // let score = 80;
  const data = [
    { name: 'Data', value: score, fill: 'url(#colorGradient)' },
    { name: 'Remaining', value: 100 - score, fill: '#F0F0F0' },
  ];
  const cx = "50%";
  const cy = "50%";
  const oR = 60;
  const imageSize = 25;

  return (
    isProjectTable?
      <PieChart width={200} height={200}>
        <defs>
          <linearGradient id="colorGradient" x1="0" y1="0" x2="1" y2="0">
            <stop offset="25.42%" stopColor="#FBA936" />
            <stop offset="74.52%" stopColor="#E11326" />
          </linearGradient>
        </defs>
        <Pie
          data={data}
          dataKey="value"
          cx="50%"
          cy="50%"
          innerRadius={60}
          outerRadius={80}
          paddingAngle={0}
          startAngle={90}
          endAngle={-270}
          cornerRadius={3}
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.fill} />
          ))}
        </Pie>
        <circle cx={cx} cy={cy} r={oR} fill="#ffffff" />
         <image
          x="45%"
          y="25%"
          width={imageSize}
          height={imageSize}
          href={myImage}
         />
        <text
          className="d-flex"
          x={100}
          y={100}
          textAnchor="middle"
          fill="#FF6B00"
          fontSize="30px"
          fontWeight={700}
          alignmentBaseline="middle"
          fontFamily="Open Sans"
          style={{ lineHeight: "87.16px" }}
        >
          {`${score / 10}`}
        </text>
        <text
          className="d-flex"
          x={100}
          y={120}
          textAnchor="middle"
          fill="#FF6B00"
          fontSize="14px"
          fontWeight={600}
          alignmentBaseline="middle"
          fontFamily="Open Sans"
          
        >
         {"Medium"}
        </text>
      </PieChart> 
      :
      <PieChart width={200} height={200}>
        <defs>
          <linearGradient id="colorGradient" x1="0" y1="0" x2="1" y2="0">
            <stop offset="25.42%" stopColor="#FBA936" />
            <stop offset="74.52%" stopColor="#E11326" />
          </linearGradient>
        </defs>
        <Pie
          data={data}
          dataKey="value"
          cx="50%"
          cy="50%"
          innerRadius={60}
          outerRadius={80}
          paddingAngle={0}
          startAngle={90}
          endAngle={-270}
          cornerRadius={3}
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.fill} />
          ))}
        </Pie>
        <text
          className="d-flex"
          x={100}
          y={100}
          textAnchor="middle"
          fill="#232337"
          fontSize="20px"
          fontWeight={700}
        >
          {score}/100
        </text>
        <text
          className="d-flex"
          x={100}
          y={120}
          textAnchor="middle"
          fill="#63637D"
          fontSize="14px"
          fontWeight={400}
        >
         Score
        </text>
      </PieChart> 
  );
};

export default CommanPieChart;
