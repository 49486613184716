import React, { useState } from "react";
import "./GlobalFilter.css";
import {
  Card,
  Checkbox,
  Collapse,
  Input,
  InputNumber,
  Slider,
  DatePicker,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";

const { RangePicker } = DatePicker;

const GlobalFilter = () => {
  const [range, setRange] = useState([2.521356, 99.9999]);
  const [PriceRange, setPriceRange] = useState([1200, 120000]);
  const [currency, setCurrency] = useState([]);
  const [category, setCategory] = useState([]);
  const [updatedDate, setUpdatedDate] = useState([]);
  const [searchText, setSearchText] = useState("");
  const currencyList = ["INR", "USD", "EUR", "GBP", "AFN", "ALL", "AUD", "CAD"];
  const categoryList = ["Business", "Finance", "Education"];
  const onChange = (newValue) => {
    setRange(newValue);
  };
  const currencyChange = (checkedValues) => {
    console.log("checked = ", checkedValues);
    setCurrency(checkedValues);
  };
  const categoryChange = (checkedValues) => {
    setCategory(checkedValues);
    console.log("checked category = ", checkedValues);
  };

  const updatedDateChange = (dateString) => {
    setUpdatedDate(dateString);
    // console.log("data==", updatedDate);
  };
  //   const changeCurrency = (e) => {
  //     console.log(`checked = ${e.target.checked}`);
  //   };
  console.log("data==", updatedDate);

  const resetFilter = () => {
    setSearchText("");
    setRange([]);
    setPriceRange([]);
    setCurrency([]);
    setUpdatedDate([]);
    setCategory([]);
  };

  return (
    <>
      <div className="global_filter">
        <Card width={230}>
          <div className="card_wrapper">
            <div className="header_parent_wrap">
              <div className="header_wrap d-flex justify-content-between">
                <div className="filter_title">Filter</div>
                <button onClick={resetFilter} className="reset_all">
                  Reset All
                </button>
              </div>
              <Input
                prefix={<SearchOutlined style={{ color: "#63637D" }} />}
                onChange={(e) => setSearchText(e.target.value)}
                value={searchText}
                placeholder="Search here"
              />
            </div>
          </div>
          <div className="score_section border-bottom">
            <Collapse bordered={false} defaultActiveKey={["0"]}>
              <Collapse.Panel
                header={
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <span className="score_title">Score</span>
                  </div>
                }
              >
                <Slider
                  range
                  step={0.00001}
                  defaultValue={range}
                  onChange={onChange}
                  // onChangeComplete={onChangeComplete}
                />
                <div className="range_show d-flex justify-content-between align-items-center gap-1">
                  <InputNumber
                    step={0.001}
                    value={parseFloat(range[0]).toFixed(3)}
                    onChange={(value) => setRange([value, range[1]])}
                  />
                  <div>To</div>
                  <InputNumber
                    step={0.00001}
                    value={parseFloat(range[1]).toFixed(3)}
                    onChange={(value) => setRange([range[0], value])}
                  />
                </div>
              </Collapse.Panel>
            </Collapse>
          </div>
          <div className="price_section border-bottom">
            <Collapse bordered={false} defaultActiveKey={["0"]}>
              <Collapse.Panel
                header={
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <span className="price_title">Price</span>
                  </div>
                }
              >
                <Slider
                  range
                  step={10000}
                  min={0}
                  max={300000}
                  defaultValue={PriceRange}
                  onChange={(value) => setPriceRange(value)}
                  // onChangeComplete={onChangeComplete}
                />
                <div className="range_show d-flex justify-content-between align-items-center gap-1">
                  <InputNumber
                    step={10000}
                    value={PriceRange[0]}
                    onChange={(value) => setPriceRange([value, PriceRange[1]])}
                  />
                  <div>To</div>
                  <InputNumber
                    step={10000}
                    value={PriceRange[1]}
                    onChange={(value) => setPriceRange([PriceRange[0], value])}
                  />
                </div>
              </Collapse.Panel>
            </Collapse>
          </div>
          <div className="currency_section border-bottom">
            <Collapse bordered={false} defaultActiveKey={["0"]}>
              <Collapse.Panel
                header={
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <span className="currency_title">Currency</span>
                  </div>
                }
              >
                <div className="currency_list">
                  <Checkbox.Group
                    //   layout="Vertical"
                    style={{
                      width: "100%",
                    }}
                    value={currency}
                    onChange={currencyChange}
                  >
                    {currencyList.map((item) => (
                      <div className="chackbox_item w-100">
                        <Checkbox value={item}>{item}</Checkbox>
                      </div>
                    ))}
                    {/* <div className="chackbox_item w-100">
                    <Checkbox value="USD">USD</Checkbox>
                  </div>
                  <div className="chackbox_item w-100">
                    <Checkbox value="EUR">EUR</Checkbox>
                  </div>
                  <div className="chackbox_item w-100">
                    <Checkbox value="GBP">GBP</Checkbox>
                  </div>
                  <div className="chackbox_item w-100">
                    <Checkbox value="AFN">AFN</Checkbox>
                  </div>
                  <div className="chackbox_item w-100">
                    <Checkbox value="ALL">ALL</Checkbox>
                  </div>
                  <div className="chackbox_item w-100">
                    <Checkbox value="AUD">AUD</Checkbox>
                  </div>
                  <div className="chackbox_item w-100">
                    <Checkbox value="CAD">CAD</Checkbox>
                  </div> */}
                  </Checkbox.Group>
                </div>
              </Collapse.Panel>
            </Collapse>
          </div>
          <div className="category_section">
            <Collapse bordered={false} defaultActiveKey={["0"]}>
              <Collapse.Panel
                header={
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <span className="category_title">Category</span>
                  </div>
                }
              >
                <div className="category_list">
                  <Checkbox.Group
                    layout="Vertical"
                    style={{
                      width: "100%",
                    }}
                    onChange={categoryChange}
                    value={category}
                  >
                    {categoryList.map((item) => (
                      <div className="chackbox_item w-100">
                        <Checkbox value={item}>{item}</Checkbox>
                      </div>
                    ))}
                  </Checkbox.Group>
                </div>
              </Collapse.Panel>
            </Collapse>
          </div>
          <div className="updated_date_section">
            <Collapse bordered={false} defaultActiveKey={["0"]}>
              <Collapse.Panel
                header={
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <span className="category_title">Updated Date</span>
                  </div>
                }
              >
                <div className="updated_date_list">
                  <RangePicker
                    value={updatedDate}
                    onChange={updatedDateChange}
                  />
                </div>
              </Collapse.Panel>
            </Collapse>
          </div>
        </Card>
      </div>
    </>
  );
};

export default GlobalFilter;
