import { FileTextOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Card, Form, Timeline, Input, Dropdown } from "antd";
import React, { useState } from "react";
import "./Timeline.css";
import SmallModal from "../../Pages/Common/small-pop-modal/SmallModal";

const { TextArea } = Input;

const TimeLineComponet = ({ data }) => {

  const [openRemarkModal, setOpenRemarkModal] = useState(false);
  const [openAttachment, setAttachment]=useState(false);
  const [selectedTimelineItemId, setSelectedTimelineItemId] = useState(null);
  const closeAttachmentModal=()=>{
    setAttachment(false)
  }
  const openAttchmentModal = (id)=>{
    setAttachment(true);
    console.log('timeline id==>',id)
    setSelectedTimelineItemId(id)
  }
  const showRemarkModal = () => {
    setOpenRemarkModal(true);
  };
  const closeRemakPopup = () => {
    setOpenRemarkModal(false);
  };
  return (
    <div className="time_line">
      <Timeline>
        {data.map((item, index) => (
          <Timeline.Item key={index} color={item.color} dot={item.dot}>
            <Card
              className="time_line_card"
              style={{ maxWidth: 700 }}
              size="small"
            >
              <div className="timeline_card_header d-flex justify-content-between mb-2">
                <div className="status_wrapper">
                  <span
                    className="status_btn"
                    style={{ background: item.color }}
                  >
                    {item.children.status}
                  </span>
                  <span className="by_notation_name">
                    <i className="by_notation">by</i>
                    {item.children.name}
                  </span>
                </div>
                <Button
                  onClick={showRemarkModal}
                  style={{ border: "none", color: "#5A81FA",boxShadow: 'none' }}
                  className="add_comments_btn"
                  icon={<PlusOutlined />}
                >
                  Add Comment
                </Button>
              </div>
              <div className="time_line_content_body mb-2">
                {item.children.remark && (
                  <div className="remark">
                    <span className="remark_text">{item.children.remark}</span>
                    <span className="remark_label">Remark</span>
                  </div>
                )}
                <div className="report_url_attachments">
                  {item.children.report_url && (
                    <div className="auto_generated_url">
                      <span className="url">{item.children.report_url}</span>
                      <span className="url_label">Auto generated report</span>
                    </div>
                  )}
                  {item.children.file && (
                    <>
                      <div className="attachments">
                        {Array.isArray(item.children.file) &&
                          item.children.file.slice(0,1).map((attachment, index) => (
                            <div
                              key={index}
                              className="attachment_file d-flex flex gap-3"
                            >
                              <div className="d-flex flex gap-1">
                              <FileTextOutlined />
                              <span >
                                {attachment.name}
                              </span>
                              </div>
                              {item.children.file.length > 1 &&
                                <a onClick={() => openAttchmentModal(item.id)} className="remain_count text-decoration-none">+ {item.children.file.length - 1}</a>
                              }
                            </div>
                          ))}
                        <span className="attachment_label">Attachments</span>
                      </div>
                    </>
                  )}
                </div>
                {item.children.action && (
                  <div className="time_activity_action">
                    <button className="confirm_activity_btn">
                      Confirm Activity
                    </button>
                  </div>
                )}
                <div className="timeline_date text-end">
                  {item.children.created_at}
                </div>
              </div>
            </Card>
          </Timeline.Item>
        ))}
      </Timeline>

      <SmallModal
        open={openRemarkModal}
        CloseModal={closeRemakPopup}
        closable={true}
        title="Add Remarks"
      >
        <Form layout="vertical">
          <Form.Item label="Remark">
            <TextArea rows={4} />
          </Form.Item>
          <div className="d-flex gap-3">
            <Button block onClick={closeRemakPopup}>
              Cancel
            </Button>
            <Button block type="primary">
              Submit
            </Button>
          </div>
        </Form>
      </SmallModal>

      <SmallModal
        open={openAttachment}
        CloseModal={closeAttachmentModal}
        closable={true}
        title="Attachment List"
      >
        <ul>
          {data.map((item) => (
            item.id === selectedTimelineItemId && 
            item.children.file &&
             item.children.file.length > 1 && 
             item.children.file.slice(1).map((attachment, index) => (
              <li key={index}>{attachment.name}</li>
            ))
          ))}
        </ul>
      </SmallModal>
    </div>
  );
};

export default TimeLineComponet;
