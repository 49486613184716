import { Table } from "antd";
import React from "react";
import "./LabelValuePairVeticle.css";

const columns = [
  {
    title: "",
    dataIndex: "name",
    key: "name",
    width: "25%",
    render: (text) => <span className="label_column_text">{text}</span>,
    
  },
  {
    title: "",
    dataIndex: "value",
    key: "value",
    render: (text) => (
      <div className={`${text === 'SUBMITTED' ? 'submited_status' : 'value_column_text'}`}>
        {text}
      </div>
    )
  },
];
const data = [
  {
    key: "1",
    name: "Status",
    value: "SUBMITTED",
   
  },
  {
    key: "2",
    name: "Chanege Name",
    value: "Testing exception flow new 1",
  },
  {
    key: "2",
    name: "Application Name",
    value: "x-bizconnect",
  },
];

const LabelValuePairVerticle = () => {
  return (
    <div className="label_value_pair_table_view">
      {/* <Table
        columns={columns}
        dataSource={data}
        pagination={false}
        size="small"
      />
      <h3>another option</h3> */}
      {data.map((el)=>(
      <div className="key_val_pair">
      <span className="status_or_label_col">{el.name}</span>
      <span className={`${el.name === 'Status' ? 'status_btn_label value_col' : 'value_col'}`}>{el.value}</span>
      </div>
      ))}
    </div>
  );
};

export default LabelValuePairVerticle;
