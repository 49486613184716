import React, { useEffect } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4maps from "@amcharts/amcharts4/maps";
import am4geodata_worldLow from "@amcharts/amcharts4-geodata/worldLow";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

am4core.useTheme(am4themes_animated);

const ClusteredMap = ({ markers }) => {
  useEffect(() => {
    let chart = am4core.create("chartdiv", am4maps.MapChart);
    chart.geodata = am4geodata_worldLow;
    chart.projection = new am4maps.projections.Miller();

    let polygonSeries = chart.series.push(new am4maps.MapPolygonSeries());
    polygonSeries.useGeodata = true;
    polygonSeries.exclude = ["AQ"];

    let imageSeries = chart.series.push(new am4maps.MapImageSeries());

    let imageSeriesTemplate = imageSeries.mapImages.template;
    imageSeriesTemplate.propertyFields.longitude = "coordinates[0]";
    imageSeriesTemplate.propertyFields.latitude = "coordinates[1]";
    imageSeriesTemplate.nonScaling = true;

    let circle = imageSeriesTemplate.createChild(am4core.Circle);
    circle.radius = 4;
    circle.fill = am4core.color("#EE1E23");
    circle.stroke = am4core.color("#bf06011c");
    circle.strokeWidth = 8;
    circle.tooltipText = "{name}";

    markers.forEach(marker => {
      let newImage = imageSeries.mapImages.create();
      newImage.latitude = marker.coordinates[1];
      newImage.longitude = marker.coordinates[0];
      newImage.tooltipText = marker.name;
    });

    // chart.zoomControl = new am4maps.ZoomControl();

    return () => {
      chart.dispose();
    };
  }, [markers]);

  return <div id="chartdiv" style={{ width: "100%", height: "350px" }}></div>;
};

export default ClusteredMap;
