import React from 'react'

const TopIssuesCard = (props) => {
  return (
    <div className="list">
        {props.topIssuesCardData.map((item, index) => (
        <div
          className="list_card_item"
          key={index}
          style={{background: item.color}}
        >
          <div className="justify-content-between">
            <div className="d-flex align-items-start">
              {item.icon}
              {/* <item.icon style={{ fontSize: '23px', marginRight: '10px' }} /> */}
              <div style={{cursor:'pointer'}}>
                <p className="pb-0 mb-0 topissue_title">
                  <strong>{item.title}</strong> <span>{item.content}</span>
                </p>
                <p
                  className="mb-0 topissue_para "
                  
                >
                  {item.discription}
                </p>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
    
  )
}

export default TopIssuesCard