import React, { useState } from "react";
import {
  Table,
  Button,
  Menu,
  Checkbox,
  Dropdown,
  Modal,
  Collapse,
  Row,
  Col,
  Switch,
  Pagination,
} from "antd";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import SplitscreenOutlinedIcon from '@mui/icons-material/SplitscreenOutlined';
import "./DynamicTable.css";
import DynamicForm from "./DynamicForm";
import { editResponse } from "../dashboard-options/Data";
import GlobalFilter from "../../Pages/Common/global-filter/GlobalFilter";
import { CloseOutlined, DownloadOutlined } from "@ant-design/icons";
import CardViewIcon from "../../images/sidenavIcons/view_day.png";
import RowSelectBtn from "../../Pages/Common/CommanTable/RowSelectBtn";

const DynamicTable = ({ data }) => {
  const { TableData, AddFormData } = data;
  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [add, setAdd] = useState(false);
  const [showGlobalFilter, setShowGlobalFilter] = useState(true);

  // pagination start

  const itemRender = (current, type, originalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }
    return originalElement;
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const [currentPage, setCurrentPage] = useState(1);
  const totalRecords =TableData ? TableData.length : 100; 
  const pageSize = 10; 
 
  const startRecord = (currentPage - 1) * pageSize + 1;
  const endRecord = Math.min(currentPage * pageSize, totalRecords);
  // pagination end

  const showModal = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleSwitchFilter = (checked) => {
    setShowGlobalFilter(checked);
  };
  const keys = TableData.length > 0 ? Object.keys(TableData[0]) : [];

  const [columnsVisible, setColumnsVisible] = useState(keys);

  const toggleColumn = (columnKey) => {
    const updatedVisibleColumns = columnsVisible.includes(columnKey)
      ? columnsVisible.filter((key) => key !== columnKey)
      : [...columnsVisible, columnKey];
    setColumnsVisible(updatedVisibleColumns);
  };

  const menu = (
    <Menu>
      {keys.slice(3).map((key) => (
        <Menu.Item key={key}>
          <Checkbox
            onChange={() => toggleColumn(key)}
            checked={columnsVisible.includes(key)}
            onClick={(e) => e.stopPropagation()}
          >
            {key}
          </Checkbox>
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <>
      <Row gutter={[16, 16]}>
        {showGlobalFilter ? (
          <Col
            xs={showGlobalFilter ? 24 : 0}
            sm={showGlobalFilter ? 24 : 0}
            md={showGlobalFilter ? 5 : 0}
          >
            <GlobalFilter TableData={TableData} />
          </Col>
        ) : null}
        <Col xs={24} sm={24} md={showGlobalFilter ? 19 : 24}>
          
          {/* row table start */}
          <div className="table_row d-flex justify-content-between align-items-center mb-2">
              <div className="wrapper_left" style={{display: 'inline-flex',alignItems:'center',gap: "10px"}}>
                <Dropdown overlay={menu} trigger={["click"]}>
                  <a
                    style={{
                      textDecoration: "none",
                      borderRadius: 6,
                      border: "1px solid #D2D8E9",
                      padding: "4px 10px",
                      background: "#FFF",
                      fontSize: 14,
                      fontWeight: 600,
                      color: "#63637D",
                      display: "flex",
                      gap: "6px",
                      alignItems: "center",
                    }}
                    className="column_btn"
                    onClick={(e) => e.stopPropagation()}
                  >
                    Columns
                    <SplitscreenOutlinedIcon
                      style={{ color: "#63637D", width: 15 }}
                    />
                  </a>
                </Dropdown>
                <RowSelectBtn />
                {!showGlobalFilter ?  (
                  <a className="filter_label_btn">
                    <span className="filter_label_btn_btn_text"> Filter</span>
                    <CloseOutlined style={{ color: "#fff" }} />
                  </a>
                ) : null}
              </div>
              <div className="right_div" style={{display: 'inline-flex',alignItems:'center',gap: "10px"}}>
                <div className="filter_switch">
                  <Switch
                    size="small"
                    defaultChecked
                    onChange={handleSwitchFilter}
                  />
                  <span className="ms-1">Filter</span>
                </div>
                <div className="card_view">
                  <a className="card_view_btn">
                    <img src={CardViewIcon} alt="card_view" />
                    Card View
                  </a>
                </div>
                <div className="download_excel d-flex align-items-center">
                  <Button
                    icon={<DownloadOutlined style={{fontSize: 15}} />}
                    style={{ borderColor: "#5A81FA" }}
                    className="btn download_excel_btn btn-outlined  d-flex align-items-center "
                  >
                    Download Excel
                  </Button>
                </div>
                <div className="add_andriod">
                <Button
                onClick={() => {
                  setSelectedRow(null);
                  showModal();
                  setAdd(true);
                }}
              >
                Add Form
              </Button>
                </div>
              </div>
            </div>
          {/* row table end */}
         
          <Table
           size="small"
            dataSource={TableData}
            className="dy_namic_table"
            scroll={{ x: true }}
            columns={keys
              .filter((key) => columnsVisible.includes(key))
              .slice(3)
              .map((key) => ({
                title: key,
                dataIndex: key,
                key: key,
                ellipsis: true,
                sorter: (a, b) => {
                  const valueA = a[key] || "";
                  const valueB = b[key] || "";
                  return valueA.localeCompare(valueB);
                },
              }))}
            pagination={false}
            onRow={(record) => ({
              onClick: () => {
                setSelectedRow(record);
                showModal();
              },
            })}
            rowKey={(record, index) => index}
          />
          <div
              style={{
                display: "flex",
                marginTop: "10px",
                justifyContent: "space-between",
                alignItems: "center",
                color: "#63637D",
                padding: "7px",
                fontWeight: 600,
                fontSize: "13px",
              }}
            >
              <div>
                Showing {startRecord}-{endRecord} entries out of {totalRecords}
              </div>
              <Pagination
                style={{
                  color: "#63637D",
                  padding: "5px",
                  fontWeight: 600,
                  fontSize: "12px",
                }}
                current={currentPage}
                pageSize={pageSize}
                total={totalRecords}
                itemRender={itemRender}
                onChange={handlePageChange}
                showSizeChanger={false}
              />
            </div>
        </Col>
      </Row>
      <Modal
        className="dynamic_form_modal"
        visible={open}
        onCancel={() => {
          setOpen(false);
          setSelectedRow(null);
          setEditMode(false);
          // setAdd(false);
        }}
        footer={null}
        width="calc(100% - 210px)"
        title={
          <div className="d-flex justify-content-between align-items-center me-4">
            <div className="add_form border-2 border-bottom border-primary">
              ALL Details
            </div>
            {selectedRow && selectedRow.contextmenujson && (
              <div className="row_details">
                <div className="header_action d-flex gap-2">
                  {selectedRow.contextmenujson.map((el) => (
                    <button
                      onClick={() => {
                        if (el.functionname === "Edit") {
                          setEditMode(true);
                          //   setOpen(true);
                          // console.log("editmode==", editMode);
                        }
                      }}
                      key={el.menuid}
                      className="btn btn-primary"
                    >
                      {el.functionname}
                    </button>
                  ))}
                </div>
              </div>
            )}
          </div>
        }
      >
        <Collapse defaultActiveKey={["1"]}>
          <Collapse.Panel header="Basic Details" key="1">
            {editMode && selectedRow ? (
              <DynamicForm
                data={AddFormData}
                initialValues={selectedRow.txn_no}
                editResponse={editResponse}
              />
            ) : add ? (
              <DynamicForm data={AddFormData} />
            ) : (
              selectedRow && (
                <Row gutter={[16, 16]}>
                  <Col xs={24} sm={24} md={6} lg={8} key={selectedRow.menuid}>
                    <label
                      className="font-bold"
                      style={{ fontSize: "16px", fontWeight: 600 }}
                    >
                      AssetType
                    </label>
                    <div className="font-bold">{selectedRow.AssetType}</div>
                  </Col>
                  <Col xs={24} sm={24} md={6} lg={8} key={selectedRow.menuid}>
                    <label
                      className="font-bold"
                      style={{ fontSize: "16px", fontWeight: 600 }}
                    >
                      AssetGroup
                    </label>
                    <div className="font-bold">{selectedRow.AssetGroup}</div>
                  </Col>
                  <Col xs={24} sm={24} md={6} lg={8} key={selectedRow.menuid}>
                    <label
                      className="font-bold"
                      style={{ fontSize: "16px", fontWeight: 600 }}
                    >
                      Business Head
                    </label>
                    <div className="font-bold">{selectedRow.business_head}</div>
                  </Col>
                  <Col xs={24} sm={24} md={6} lg={8} key={selectedRow.menuid}>
                    <label
                      className="font-bold"
                      style={{ fontSize: "16px", fontWeight: 600 }}
                    >
                      Criticality
                    </label>
                    <div className="font-bold">{selectedRow.Criticality}</div>
                  </Col>
                  <Col xs={24} sm={24} md={6} lg={8} key={selectedRow.menuid}>
                    <label
                      className="font-bold"
                      style={{ fontSize: "16px", fontWeight: 600 }}
                    >
                      Description
                    </label>
                    <div className="font-bold">{selectedRow.Description}</div>
                  </Col>
                  <Col xs={24} sm={24} md={6} lg={8} key={selectedRow.menuid}>
                    <label
                      className="font-bold"
                      style={{ fontSize: "16px", fontWeight: 600 }}
                    >
                      Name
                    </label>
                    <div className="font-bold">{selectedRow.Name}</div>
                  </Col>
                  <Col xs={24} sm={24} md={6} lg={8} key={selectedRow.menuid}>
                    <label
                      className="font-bold"
                      style={{ fontSize: "16px", fontWeight: 600 }}
                    >
                      Owner
                    </label>
                    <div className="font-bold">{selectedRow.Owner}</div>
                  </Col>
                  <Col xs={24} sm={24} md={6} lg={8} key={selectedRow.menuid}>
                    <label
                      className="font-bold"
                      style={{ fontSize: "16px", fontWeight: 600 }}
                    >
                      Vertical{" "}
                    </label>
                    <div className="font-bold">{selectedRow.Vertical}</div>
                  </Col>
                  <Col xs={24} sm={24} md={6} lg={8} key={selectedRow.menuid}>
                    <label
                      className="font-bold"
                      style={{ fontSize: "16px", fontWeight: 600 }}
                    >
                      Module No{" "}
                    </label>
                    <div className="font-bold">{selectedRow.module_no}</div>
                  </Col>
                  <Col xs={24} sm={24} md={6} lg={8} key={selectedRow.menuid}>
                    <label
                      className="font-bold"
                      style={{ fontSize: "16px", fontWeight: 600 }}
                    >
                      Tax No{" "}
                    </label>
                    <div className="font-bold">{selectedRow.txn_no}</div>
                  </Col>
                </Row>
              )
            )}
            <Row justify="end">
              <Col>
                <Button size="large" type="primary" htmlType="submit">
                  Submit
                </Button>
              </Col>
            </Row>
          </Collapse.Panel>
        </Collapse>
      </Modal>
    </>
  );
};

export default DynamicTable;
