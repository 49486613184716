import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { Collapse, Pagination, Table } from 'antd'
import React, { useState } from 'react'
import './AffectedSoftware.css'

const AffectedSoftware = () => {
    const customExpandIcon = (panelProps) => {
        const { isActive } = panelProps;
        return isActive ? <MinusOutlined /> : <PlusOutlined />;
      };
      const dataSource = [
        {
          key: '1',
          CEP_Version: '2.3',
          Part: 'a',
          vendor: 'newtonsoft',
          product: 'json.net',
          version: 'Na',
          update: 'Na',
          edition: 'Na',
          language: 'Na',
          software_edition: 'Na',
          Target_Software:'NA',
          other: 'Na',
        },
        {
          key: '2',
          CEP_Version: '2.3',
          Part: 'a',
          vendor: 'newtonsoft',
          product: 'json.net',
          version: 'Na',
          update: 'Na',
          edition: 'Na',
          language: 'Na',
          software_edition: 'Na',
          Target_Software:'NA',
          other: 'Na',
        },
        {
          key: '3',
          CEP_Version: '2.3',
          Part: 'a',
          vendor: 'newtonsoft',
          product: 'json.net',
          version: 'Na',
          update: 'Na',
          edition: 'Na',
          language: 'Na',
          software_edition: 'Na',
          Target_Software:'NA',
          other: 'Na',
        },
        {
          key: '4',
          CEP_Version: '2.3',
          Part: 'a',
          vendor: 'newtonsoft',
          product: 'json.net',
          version: 'Na',
          update: 'Na',
          edition: 'Na',
          language: 'Na',
          software_edition: 'Na',
          Target_Software:'NA',
          other: 'Na',
        },
        {
          key: '5',
          CEP_Version: '2.3',
          Part: 'a',
          vendor: 'newtonsoft',
          product: 'json.net',
          version: 'Na',
          update: 'Na',
          edition: 'Na',
          language: 'Na',
          software_edition: 'Na',
          Target_Software:'NA',
          other: 'Na',
        },
        {
          key: '6',
          CEP_Version: '2.3',
          Part: 'a',
          vendor: 'newtonsoft',
          product: 'json.net',
          version: 'Na',
          update: 'Na',
          edition: 'Na',
          language: 'Na',
          software_edition: 'Na',
          Target_Software:'NA',
          other: 'Na',
        },
        {
          key: '7',
          CEP_Version: '2.3',
          Part: 'a',
          vendor: 'newtonsoft',
          product: 'json.net',
          version: 'Na',
          update: 'Na',
          edition: 'Na',
          language: 'Na',
          software_edition: 'Na',
          Target_Software:'NA',
          other: 'Na',
        },
        {
          key: '8',
          CEP_Version: '2.3',
          Part: 'a',
          vendor: 'newtonsoft',
          product: 'json.net',
          version: 'Na',
          update: 'Na',
          edition: 'Na',
          language: 'Na',
          software_edition: 'Na',
          Target_Software:'NA',
          other: 'Na',
        },
      ];
      const columns = [
        {
          title: 'CEP Version',
          dataIndex: 'CEP_Version',
          key: 'CEP_Version',
        },
        {
          title: 'Part',
          dataIndex: 'Part',
          key: 'Part',
        },
        {
          title: 'Vendor',
          dataIndex: 'vendor',
          key: 'vendor',
        },
        {
          title: 'Product',
          dataIndex: 'product',
          key: 'product',
        },
        {
          title: 'Version',
          dataIndex: 'version',
          key: 'version',
        },
        {
          title: 'Update',
          dataIndex: 'update',
          key: 'update',
        },
        {
          title: 'Edition',
          dataIndex: 'edition',
          key: 'edition',
        },
        {
          title: 'Language',
          dataIndex: 'language',
          key: 'language',
        },
        {
          title: 'Software Edition',
          dataIndex: 'software_edition',
          key: 'software_edition',
        },
        {
          title: 'Target Software ',
          dataIndex: 'Target_Software',
          key: 'Target_Software',
        },
        {
          title: 'Other',
          dataIndex: 'other',
          key: 'other',
        },
      ];
      const itemRender = (current, type, originalElement) => {
        if (type === "prev") {
          return <a>Previous</a>;
        }
        if (type === "next") {
          return <a>Next</a>;
        }
        return originalElement;
      };
      const [currentPage, setCurrentPage] = useState(1);
      const totalRecords = 100; // Total number of records in your table
      const pageSize = 10; // Number of records per page
  
      const startRecord = (currentPage - 1) * pageSize + 1;
      const endRecord = Math.min(currentPage * pageSize, totalRecords);
  
      // const [open, setOpen] = useState(false);
  
      const handlePageChange = (page) => {
        setCurrentPage(page);
      };
  return (
    <Collapse
    defaultActiveKey={"1"}
    expandIconPosition="end"
    expandIcon={customExpandIcon}
  >
    <Collapse.Panel
      style={{ background: "#DCDDE7", marginBottom: 8, fontSize:'13px',fontWeight:600 }}
      header="Affected Software"
      key="1"
    >
      <div>
        <p className='affected-para' >Known software affected by CVE-2024-21907 as described by Common Platform Enumeration (CPE) URIs. CPE is a structured naming scheme for information technology systems, software, and packages. A NIST CPE URI encodes the following ordered fields:
        cpe</p>
      <Table dataSource={dataSource} columns={columns} pagination={false}  className="affected-custom-table" />
      <div
                  style={{
                    display: "flex",
                    marginTop: "10px",
                    justifyContent: "space-between",
                    alignItems: "center",
                    color: "#63637D",
                    padding: "7px",
                    fontWeight: 600,
                    fontSize: "13px",
                  }}
                >
                <div>
                    Showing {startRecord}-{endRecord} entries out of {totalRecords}
                  </div>
                  <Pagination
                    style={{
                      color: "#63637D",
                      padding: "5px",
                      fontWeight: 600,
                      fontSize: "12px",
                    }}
                    current={currentPage}
                    pageSize={pageSize}
                    total={totalRecords}
                    itemRender={itemRender}
                    onChange={handlePageChange}
                    showSizeChanger={false}
                  />
              </div>
      </div>
    </Collapse.Panel>
  </Collapse>
  )
}

export default AffectedSoftware