import { Col, Modal, Row } from "antd";
import React, { useEffect, useState } from "react";
import "./DashboardOptionModal.css";
import adminPanel from "../../images/dashboardOptionsIcons/admin_panel.png";
import vulneMngmt from "../../images/dashboardOptionsIcons/vulne_mangmnt.png";
import securityCompliance from "../../images/dashboardOptionsIcons/security_complaince.png";
import cloudSecurity from "../../images/dashboardOptionsIcons/cloud_security.png";
import templateBuilder from "../../images/dashboardOptionsIcons/template_builder.png";
import cyberIssuance from "../../images/dashboardOptionsIcons/cyber_issuance.png";
import exceptionMngnt from "../../images/dashboardOptionsIcons/exception_mngmnt.png";
import thirdPartyRisk from "../../images/dashboardOptionsIcons/third_party_risk.png";

const DashboardOptionModal = ({
  className,
  openDashboardOptions,
  onCloseDashboardOption,
}) => {
  const [activeDashboard, setActiveDashboard] = useState("security_compliance");
  const handleDashboardSelect = (e) => {
    setActiveDashboard(e.currentTarget.getAttribute("dataKey"));
  };

  useEffect(() => {
    console.log("active_dashboard===", activeDashboard);
  }, [activeDashboard]);

  return (
    <Modal
      width={400}
      mask={false}
      className={className}
      open={openDashboardOptions}
      closable={false}
      footer={null}
      // onOk={handleProfileOk}
      onCancel={onCloseDashboardOption}
    >
      <div className="dashboard_option_modal_wrapper">
        <div className="dash_option_wrap">
          <Row gutter={[12, 12]}>
            <Col span={12}>
              <div
                onClick={handleDashboardSelect}
                className={`${
                  activeDashboard === "admin_panel"
                    ? "active_dashboard dash_option_card"
                    : "dash_option_card"
                }`}
                datakey="admin_panel"
              >
                <img src={adminPanel} alt="admin" />
                <p className="dash_option_text">Admin Panel</p>
              </div>
            </Col>
            <Col span={12}>
              <div
                onClick={handleDashboardSelect}
                className={`${
                  activeDashboard === "vulnerability_managemnet"
                    ? "active_dashboard dash_option_card"
                    : "dash_option_card"
                }`}
                datakey="vulnerability_managemnet"
              >
                <img src={vulneMngmt} alt="vulnerability" />
                <p className="dash_option_text">Vulnerability Management</p>
              </div>
            </Col>
            <Col span={12}>
              <div
                onClick={handleDashboardSelect}
                className={`${
                  activeDashboard === "security_compliance"
                    ? "active_dashboard dash_option_card"
                    : "dash_option_card"
                }`}
                datakey="security_compliance"
              >
                <img src={securityCompliance} alt="security" />
                <p className="dash_option_text">Security & Compliance</p>
              </div>
            </Col>
            <Col span={12}>
              <div
                onClick={handleDashboardSelect}
                className={`${
                  activeDashboard === "cloud_security_posture_managemnet"
                    ? "active_dashboard dash_option_card"
                    : "dash_option_card"
                }`}
                datakey="cloud_security_posture_managemnet"
              >
                <img src={cloudSecurity} alt="cloud_security" />
                <p className="dash_option_text">
                  Cloud Security Posture Management
                </p>
              </div>
            </Col>
            <Col span={12}>
              <div
                onClick={handleDashboardSelect}
                className={`${
                  activeDashboard === "template_builder"
                    ? "active_dashboard dash_option_card"
                    : "dash_option_card"
                }`}
                datakey="template_builder"
              >
                <img src={templateBuilder} alt="template_builder" />
                <p className="dash_option_text">Template Builder</p>
              </div>
            </Col>
            <Col span={12}>
              <div
                onClick={handleDashboardSelect}
                className={`${
                  activeDashboard === "cyber_issuance"
                    ? "active_dashboard dash_option_card"
                    : "dash_option_card"
                }`}
                datakey="cyber_issuance"
              >
                <img src={cyberIssuance} alt="cyber_issuance" />
                <p className="dash_option_text">Cyber Insurance</p>
              </div>
            </Col>
            <Col span={12}>
              <div
                onClick={handleDashboardSelect}
                className={`${
                  activeDashboard === "exception_management"
                    ? "active_dashboard dash_option_card"
                    : "dash_option_card"
                }`}
                datakey="exception_management"
              >
                <img src={exceptionMngnt} alt="exception_mng" />
                <p className="dash_option_text">Exception Management</p>
              </div>
            </Col>
            <Col span={12}>
              <div
                onClick={handleDashboardSelect}
                className={`${
                  activeDashboard === "third_party_risk_management"
                    ? "active_dashboard dash_option_card"
                    : "dash_option_card"
                }`}
                datakey="third_party_risk_management"
              >
                <img src={thirdPartyRisk} alt="third_party" />
                <p className="dash_option_text">Third Party Risk Management</p>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </Modal>
  );
};

export default DashboardOptionModal;
