
import React, { useState } from "react";
import "./AssignAssessment.css"
import { Col, Form, Row, Table } from "antd";
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';

const AssignAssessment = () => {
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

    const columns = [
        {
          title: 'Type To Search',
          dataIndex: 'type_to_search',
          key: 'type_to_search',
          render: (text)=>(<span style={{color: '#9494AE', fontSize: 16, fontWeight: 600, fontFamily:'Open Sans',lineHeight:"21.79px"}}>{text || "-"}</span>)
        },
        {
          title: '',
          dataIndex: '',
          key: '',
          align: 'right',
          render: ()=>(<span style={{padding: '0 20px'}}><OpenInNewOutlinedIcon style={{color: '#9494AE', fontSize: 16, fontWeight: 400, fontFamily:'Open Sans'}} /></span>)
        },
        
      ];
      const data =[
        {
            id:1,
            type_to_search: "Form 1"
        },
        {
            id:2,
            type_to_search: "Form 2"
        },
        {
            id:3,
            type_to_search: "Form 3"
        },
        {
            id:4,
            type_to_search: "Form 4"
        },
        {
            id:5,
            type_to_search: "Form 5"
        },
        {
            id:6,
            type_to_search: "Form 6 "
        },
        {
            id:7,
            type_to_search: "Form 7"
        },
        {
            id:8,
            type_to_search: "Form 8"
        },
        {
            id:9,
            type_to_search: "Form 9"
        },
        {
            id:10,
            type_to_search: "Form 10"
        },
        {
            id:11,
            type_to_search: "Form 11"
        },
        {
            id:12,
            type_to_search: "Form 12"
        },
        {
            id:13,
            type_to_search: "Form 13"
        },
        {
            id:14,
            type_to_search: "Form 14"
        },
        {
            id:15,
            type_to_search: "Form 15"
        },
       
      ]
  return (
    <div className="assign_assessment mt-3">
      <Form layout="vertical">
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={24} lg={24}>
            <p className="heading_form_1 mb-0 text-center">
              Select the license identifiers which you want to exclude.
            </p>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24}>
            <Form.Item
              style={{ marginBottom: "12px" }}
              name="type_to_search"
              rules={[{ required: false }]}
            >
             <div className="type_to_search_table">
              <Table
                rowSelection={rowSelection}
                size="small"
                columns={columns}
                dataSource={data}
                pagination={false}
                scroll={{
                  y: 260,
                }}
                rowKey="id"
              />
              </div>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default AssignAssessment;
