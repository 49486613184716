import React from 'react'
import {
  CheckOutlined,
  DeleteOutlined,
  EditOutlined,
  EllipsisOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { Avatar, Card, Flex, Tag } from "antd";

const { Meta } = Card;

const TableCard = (props) => {
  // console.log('props14=====>',props)
  return (
    // <Card style={{ width: 170 }} bordered={false}>
    <div className='flex'  >
      <h1 style={{
        color: ' #63637D',
        fontWeight:700,
        fontSize: '18px',
        cursor:'pointer'
      }}>{props.mainvalue}</h1>
      <Flex>
        {props?.data?.map((item, index) => (
          <Tag
            key={index}
            style={{
              display: 'flex',
              alignItems: 'center', // Align items vertically in the center
              justifyContent: 'center', 
              fontWeight: 600,
              fontSize: '10px',
              width: '25px',
              height: '15px',
              borderRadius: '1px',
              borderColor:'transparent',
              backgroundColor: item.bgColor,
              color: item.color,
              cursor: 'pointer',
              padding: '5px',
              marginRight: '8px',
            }}
          >
            {item.value}{item.initial}
          </Tag>
        ))}
      </Flex>
    </div>
    // </Card>
  )
}

export default TableCard;