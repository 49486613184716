import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Label,
} from "recharts";

const data = [
  {
    name: "JAN",
    close: 15,
    open: 25,
    amt: 2400,
  },
  {
    name: "FEB",
    close: 25,
    open: 75,
    amt: 2400,
  },
  {
    name: "MAR",
    close: 10,
    open: 80,
    amt: 2400,
  },
  {
    name: "APR",
    close: 30,
    open: 40,
    amt: 2400,
  },
  {
    name: "MAY",
    close: 60,
    open: 60,
    amt: 2400,
  },
  {
    name: "JUN",
    close: 35,
    open: 35,
    amt: 2400,
  },
  {
    name: "JUL",
    close: 75,
    open: 75,
    amt: 2400,
  },
  {
    name: "AUG",
    close: 15,
    open: 20,
    amt: 2400,
  },
  {
    name: "SEP",
    close: 20,
    open: 40,
    amt: 2400,
  },
  {
    name: "OCT",
    close: 110,
    open: 10,
    amt: 2400,
  },
  {
    name: "NOV",
    close: 120,
    open: 15,
    amt: 2400,
  },
  {
    name: "DEC",
    close: 120,
    open: 40,
    amt: 2400,
  },
];

const TrendSummaryChart = ({ width, height }) => {
  return (
    <BarChart
      width={width ? width : 480}
      height={height ? height : 400}
      data={data}
      barSize={18}
      margin={{
        top: 20,
        right: 20,
        left: 20,
        bottom: 20,
      }}
    >
      <defs>
        <linearGradient id="openGradient" x1="0" y1="0" x2="0" y2="1">
          <stop offset="5%" stopColor="#63E09E" />
          <stop offset="95%" stopColor="#1DD3AE" />
        </linearGradient>
        <linearGradient id="closeGradient" x1="0" y1="0" x2="0" y2="1">
          <stop offset="5%" stopColor="#FF3043" />
          <stop offset="95%" stopColor="#FF7D59" />
        </linearGradient>
      </defs>
      <CartesianGrid stroke="none" strokeWidth={18} />
      <XAxis
        dataKey="name"
        interval={0}
        label={{
          value: "Months",
          offset: 0,
          position: "bottom",
          style: { textAnchor: "middle", fontWeight: "bold" },
        }}
      />

      <YAxis
        label={{
          value: "CERTIFICATE COUNT",
          style: { textAnchor: "middle", fontWeight: "bold" },
          angle: -90,
          position: "left",
          offset: 0,
        }}
      />

      <Tooltip />
      <Legend
        iconType="circle"
        iconSize="12"
        align="center"
        wrapperStyle={{ bottom: 0 }}
      />
      <Bar
        dataKey="open"
        stackId="a"
        fill="url(#openGradient)"
        strokeWidth={10}
      />
      <Bar
        dataKey="close"
        stackId="a"
        fill="url(#closeGradient)"
        strokeWidth={10}
        radius={[4, 4, 0, 0]} 
      />
    </BarChart>
  );
};

export default TrendSummaryChart;
