import React, { useState } from "react";
import {
  AccountBookOutlined,
  BugOutlined,
  CloseOutlined,
  CloudOutlined,
  DownOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Avatar,
  Row,
  Space,
  Table,
  Tabs,
  Flex,
  Modal,
  Dropdown,
  Pagination,
  Drawer,
} from "antd";
import "./ProjectTable.css";
import ScaBarGhraph from "../charts/SCA_BarGraph";
import TableCard from "../../Pages/Modules/SC/sca-dashboard/components/TableCard";
import CodeIssueTab from "../../Pages/Modules/SC/sca-dashboard/components/CodeIssueTab.js";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  EditOutlined,
  EllipsisOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import ProjectNameCard from "../../Pages/Modules/SC/sca-dashboard/components/ProjectNameCard";
import arming from "../../images/icons/arming_countdown.png";
import cloud from "../../images/icons/cloud.png";
import license from "../../images/icons/warning.png";
import malicious from "../../images/icons/pest_control.png";
import amend from "../../images/icons/amend.png";
import share from "../../images/icons/share_windows.png";
import eye from "../../images/icons/visibility.png";
import { useHistory } from "react-router-dom";
import Icon from "@ant-design/icons/lib/components/Icon";
import TabsWithExtraBtn from "../../Pages/Common/tabs-with-extra-content/TabsWithExtraBtn";
import TopIssuesCard from "../../Pages/Modules/SC/sca-dashboard/components/TopIssuesCard";
import TopIssuesPage from "../../Pages/Modules/SC/sca-dashboard/components/TopIssuesPage";
import DependencyTree from "../../Pages/Modules/SC/sca-dashboard/components/DependencyTree";
import DiscoveredLicense from "../../Pages/Modules/SC/sca-dashboard/components/DiscoveredLicense.js";
import HistoryList from "../../Pages/Modules/SC/sca-dashboard/components/HistoryList.js";
import CommonBottomDrawer from "../../Pages/Common/commonBottomDrawer/CommonBottomDrawer.js";
import CollapseTopIssues from "../../Pages/Modules/SC/sca-dashboard/components/CollapseTopIssues.js";
import LicenseViolationTabs from "../../Pages/Modules/SC/sca-dashboard/components/LicenseViolationTabs.js";
import AffectedSoftware from "../../Pages/Modules/SC/sca-dashboard/components/AffectedSoftware.js";
import SolutionFix from "../../Pages/Modules/SC/sca-dashboard/components/SolutionFix.js";
import References from "../../Pages/Modules/SC/sca-dashboard/components/References.js";

const { Meta } = Card;

const ProjectTable = ({tableColumn, tableData}) => {
  const history = useHistory();

  const onChange = (key) => {
    console.log(key);
  };
  const items = [
    {
      key: "1",
      label: "All(100)",
      //   children: 'Content of Tab Pane 1',
    },
    {
      key: "2",
      label: "Cloud Risk(25)",
      //   children: 'Content of Tab Pane 2',
    },
    {
      key: "3",
      label: "Third Party Vendor Risk(20)",
      //   children: 'Content of Tab Pane 3',
    },
    {
      key: "4",
      label: "SCA Risk(30)",
      //   children: 'Content of Tab Pane 3',
    },
    {
      key: "5",
      label: "Infrastructural Risk(25)",
      //   children: 'Content of Tab Pane 3',
    },
  ];

  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});
  const handleChange = (pagination, filters, sorter) => {
    console.log("Various parameters", pagination, filters, sorter);
    setFilteredInfo(filters);
    setSortedInfo(sorter);
  };
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const handlePopUp = () => {
    setIsPopupVisible(true);
  };
  const [openDrawer, setOpenDrawer] = useState(false);
  const [drawerTitle, setTitle] = useState('');
  const [TabsTitleKey, setTabsTitleKey] = useState('');
  const [TabsTitle, setTabsTitle] = useState('');
  const [Components, setComponent] = useState('');

  const data = [
    {
      key: "1",
      status: "Open",
      threat_category: "Third Part Vendor Risk",

      projectName: "Project 1",
      projectData: [
        {
          value: 30,
          initial: "C",
          color: "#9494AE",
          bgColor: "#E6E6E6",
        },
        {
          projectName: "Project 2",
          value: 80,
          initial: "H",
          color: "#F50000",
          bgColor: "#F500001F",
        },
        {
          projectName: "Project 3",
          value: 90,
          initial: "M",
          color: "#FE9706",
          bgColor: "#FE97061F",
        },
        {
          projectName: "Project 4",
          value: 30,
          initial: "L",
          color: "#02B583",
          bgColor: "#02B5831F",
        },
      ],
      code_issue: [
        {
          mainValue: 400,
          value: 30,
          initial: "C",
          color: "#9494AE",
          bgColor: "#E6E6E6",
        },
        {
          mainValue: 868,
          value: 80,
          initial: "H",
          color: "#F50000",
          bgColor: "#F500001F",
        },
        {
          mainValue: 208,
          value: 90,
          initial: "M",
          color: "#FE9706",
          bgColor: "#FE97061F",
        },
        {
          mainValue: 30,
          value: 30,
          initial: "L",
          color: "#02B583",
          bgColor: "#02B5831F",
        },
      ],
      title: "",
      severity: "High",
      endOfLife: "20/01/2023",
      codeIssue_No: 400,
      vulnerability_No: 300,
      licence_No: 200,
      malicious_No: 500,
    },
    {
      key: "2",
      status: "Open",
      threat_category: "Cloud Risk",
      projectName: "Project 2",
      projectData: [
        {
          projectName: "Project 1",
          value: 30,
          initial: "C",
          color: "#9494AE",
          bgColor: "#E6E6E6",
        },
        {
          projectName: "Project 2",
          value: 80,
          initial: "H",
          color: "#F50000",
          bgColor: "#F500001F",
        },
        {
          projectName: "Project 3",
          value: 90,
          initial: "M",
          color: "#FE9706",
          bgColor: "#FE97061F",
        },
        {
          projectName: "Project 4",
          value: 30,
          initial: "L",
          color: "#02B583",
          bgColor: "#02B5831F",
        },
      ],
      code_issue: [
        {
          mainValue: 400,
          value: 30,
          initial: "C",
          color: "#9494AE",
          bgColor: "#E6E6E6",
        },
        {
          mainValue: 868,
          value: 80,
          initial: "H",
          color: "#F50000",
          bgColor: "#F500001F",
        },
        {
          mainValue: 208,
          value: 90,
          initial: "M",
          color: "#FE9706",
          bgColor: "#FE97061F",
        },
        {
          mainValue: 30,
          value: 30,
          initial: "L",
          color: "#02B583",
          bgColor: "#02B5831F",
        },
      ],
      title: "code_issue",
      severity: "Medium",
      endOfLife: "20/01/2023",
      codeIssue_No: 400,
      vulnerability_No: 300,
      licence_No: 200,
      malicious_No: 500,
    },
    {
      key: "3",
      status: "Open",
      threat_category: "SCA Risk",
      projectName: "Project 3",
      projectData: [
        {
          projectName: "Project 1",
          value: 30,
          initial: "C",
          color: "#9494AE",
          bgColor: "#E6E6E6",
        },
        {
          projectName: "Project 2",
          value: 80,
          initial: "H",
          color: "#F50000",
          bgColor: "#F500001F",
        },
        {
          projectName: "Project 3",
          value: 90,
          initial: "M",
          color: "#FE9706",
          bgColor: "#FE97061F",
        },
        {
          projectName: "Project 4",
          value: 30,
          initial: "L",
          color: "#02B583",
          bgColor: "#02B5831F",
        },
      ],
      code_issue: [
        {
          mainValue: 400,
          value: 30,
          initial: "C",
          color: "#9494AE",
          bgColor: "#E6E6E6",
        },
        {
          mainValue: 868,
          value: 80,
          initial: "H",
          color: "#F50000",
          bgColor: "#F500001F",
        },
        {
          mainValue: 208,
          value: 90,
          initial: "M",
          color: "#FE9706",
          bgColor: "#FE97061F",
        },
        {
          mainValue: 30,
          value: 30,
          initial: "L",
          color: "#02B583",
          bgColor: "#02B5831F",
        },
      ],
      title: "vulnerabilities",
      // severity: <Button>Critical</Button>,
      severity: "Critical",
      identification_date: "20/01/2023",
      codeIssue_No: 400,
      vulnerability_No: 300,
      licence_No: 200,
      malicious_No: 500,
    },
    {
      key: "4",
      status: "Open",
      threat_category: "Infrastructural Risk",
      projectName: "Project 4",
      projectData: [
        {
          projectName: "Project 1",
          value: 30,
          initial: "C",
          color: "#9494AE",
          bgColor: "#E6E6E6",
        },
        {
          projectName: "Project 2",
          value: 80,
          initial: "H",
          color: "#F50000",
          bgColor: "#F500001F",
        },
        {
          projectName: "Project 3",
          value: 90,
          initial: "M",
          color: "#FE9706",
          bgColor: "#FE97061F",
        },
        {
          projectName: "Project 4",
          value: 30,
          initial: "L",
          color: "#02B583",
          bgColor: "#02B5831F",
        },
      ],
      code_issue: [
        {
          mainValue: 400,
          value: 30,
          initial: "C",
          color: "#9494AE",
          bgColor: "#E6E6E6",
        },
        {
          mainValue: 868,
          value: 80,
          initial: "H",
          color: "#F50000",
          bgColor: "#F500001F",
        },
        {
          mainValue: 208,
          value: 90,
          initial: "M",
          color: "#FE9706",
          bgColor: "#FE97061F",
        },
        {
          mainValue: 30,
          value: 30,
          initial: "L",
          color: "#02B583",
          bgColor: "#02B5831F",
        },
      ],
      title: "license_violations",
      // severity: <Button>Low</Button>,
      severity: "Low",
      identification_date: "20/01/2023",
      codeIssue_No: 400,
      vulnerability_No: 300,
      licence_No: 200,
      malicious_No: 500,
    },
    {
      key: "5",
      status: "Open",
      threat_category: "Third Part Vendor Risk",
      projectName: "Project 5",
      projectData: [
        {
          projectName: "Project 1",
          value: 30,
          initial: "C",
          color: "#9494AE",
          bgColor: "#E6E6E6",
        },
        {
          projectName: "Project 2",
          value: 80,
          initial: "H",
          color: "#F50000",
          bgColor: "#F500001F",
        },
        {
          projectName: "Project 3",
          value: 90,
          initial: "M",
          color: "#FE9706",
          bgColor: "#FE97061F",
        },
        {
          projectName: "Project 4",
          value: 30,
          initial: "L",
          color: "#02B583",
          bgColor: "#02B5831F",
        },
      ],
      code_issue: [
        {
          mainValue: 400,
          value: 30,
          initial: "C",
          color: "#9494AE",
          bgColor: "#E6E6E6",
        },
        {
          mainValue: 868,
          value: 80,
          initial: "H",
          color: "#F50000",
          bgColor: "#F500001F",
        },
        {
          mainValue: 208,
          value: 90,
          initial: "M",
          color: "#FE9706",
          bgColor: "#FE97061F",
        },
        {
          mainValue: 30,
          value: 30,
          initial: "L",
          color: "#02B583",
          bgColor: "#02B5831F",
        },
      ],
      title: "malicious_packages",
      // severity: <Button>High</Button>,
      severity: "High",
      identification_date: "20/01/2023",
      codeIssue_No: 400,
      vulnerability_No: 300,
      licence_No: 200,
      malicious_No: 500,
    },
    {
      key: "6",
      status: "Open",
      threat_category: "Cloud Risk",
      projectName: "Project 6",
      projectData: [
        {
          projectName: "Project 1",
          value: 30,
          initial: "C",
          color: "#9494AE",
          bgColor: "#E6E6E6",
        },
        {
          projectName: "Project 2",
          value: 80,
          initial: "H",
          color: "#F50000",
          bgColor: "#F500001F",
        },
        {
          projectName: "Project 3",
          value: 90,
          initial: "M",
          color: "#FE9706",
          bgColor: "#FE97061F",
        },
        {
          projectName: "Project 4",
          value: 30,
          initial: "L",
          color: "#02B583",
          bgColor: "#02B5831F",
        },
      ],
      code_issue: [
        {
          mainValue: 400,
          value: 30,
          initial: "C",
          color: "#9494AE",
          bgColor: "#E6E6E6",
        },
        {
          mainValue: 868,
          value: 80,
          initial: "H",
          color: "#F50000",
          bgColor: "#F500001F",
        },
        {
          mainValue: 208,
          value: 90,
          initial: "M",
          color: "#FE9706",
          bgColor: "#FE97061F",
        },
        {
          mainValue: 30,
          value: 30,
          initial: "L",
          color: "#02B583",
          bgColor: "#02B5831F",
        },
      ],
      title: "end_of_life",
      // severity: <Button>Medium</Button>,
      severity: "Medium",
      identification_date: "20/01/2023",
      codeIssue_No: 768,
      vulnerability_No: 300,
      licence_No: 200,
      malicious_No: 500,
    },
    {
      key: "7",
      status: "Open",
      threat_category: "Third Part Vendor Risk",
      projectName: "Project 7",
      projectData: [
        {
          projectName: "Project 1",
          value: 30,
          initial: "C",
          color: "#9494AE",
          bgColor: "#E6E6E6",
        },
        {
          projectName: "Project 2",
          value: 80,
          initial: "H",
          color: "#F50000",
          bgColor: "#F500001F",
        },
        {
          projectName: "Project 3",
          value: 90,
          initial: "M",
          color: "#FE9706",
          bgColor: "#FE97061F",
        },
        {
          projectName: "Project 4",
          value: 30,
          initial: "L",
          color: "#02B583",
          bgColor: "#02B5831F",
        },
      ],
      code_issue: [
        {
          mainValue: 400,
          value: 30,
          initial: "C",
          color: "#9494AE",
          bgColor: "#E6E6E6",
        },
        {
          mainValue: 868,
          value: 80,
          initial: "H",
          color: "#F50000",
          bgColor: "#F500001F",
        },
        {
          mainValue: 208,
          value: 90,
          initial: "M",
          color: "#FE9706",
          bgColor: "#FE97061F",
        },
        {
          mainValue: 30,
          value: 30,
          initial: "L",
          color: "#02B583",
          bgColor: "#02B5831F",
        },
      ],
      title: "",
      // severity: <Button>Low</Button>,
      severity: "Low",
      identification_date: "20/01/2023",
      codeIssue_No: 120,
      vulnerability_No: 300,
      licence_No: 200,
      malicious_No: 500,
    },
    {
      key: "8",
      status: "Open",
      threat_category: "Third Part Vendor Risk",
      projectName: "Project 7",
      projectData: [
        {
          projectName: "Project 1",
          value: 30,
          initial: "C",
          color: "#9494AE",
          bgColor: "#E6E6E6",
        },
        {
          projectName: "Project 2",
          value: 80,
          initial: "H",
          color: "#F50000",
          bgColor: "#F500001F",
        },
        {
          projectName: "Project 3",
          value: 90,
          initial: "M",
          color: "#FE9706",
          bgColor: "#FE97061F",
        },
        {
          projectName: "Project 4",
          value: 30,
          initial: "L",
          color: "#02B583",
          bgColor: "#02B5831F",
        },
      ],
      code_issue: [
        {
          mainValue: 400,
          value: 30,
          initial: "C",
          color: "#9494AE",
          bgColor: "#E6E6E6",
        },
        {
          mainValue: 868,
          value: 80,
          initial: "H",
          color: "#F50000",
          bgColor: "#F500001F",
        },
        {
          mainValue: 208,
          value: 90,
          initial: "M",
          color: "#FE9706",
          bgColor: "#FE97061F",
        },
        {
          mainValue: 30,
          value: 30,
          initial: "L",
          color: "#02B583",
          bgColor: "#02B5831F",
        },
      ],
      title: "",
      // severity: <Button>Low</Button>,
      severity: "Low",
      identification_date: "20/01/2023",
      codeIssue_No: 400,
      vulnerability_No: 300,
      licence_No: 200,
      malicious_No: 500,
    },
  ];

  const columns = [
    {
      title: "Project Name",
      dataIndex: "projectData",
      key: "projectData",
      responsive: ["lg"],
      width: "20%",
      filteredValue: filteredInfo.projectData || null,
      ellipsis: true,
      render: (event, data) => {
        return (
          <ProjectNameCard
            data={event}
            projectName={data.projectName}
          />
        );
      },
    },
    {
      title: (
        <span style={{ whiteSpace: "nowrap" }}>
          {/* <CloudOutlined style={{ marginRight: "4px", color: "#5A81FA" }} /> */}
          <img
            src={cloud}
            alt="Icon"
            style={{
              width: "16px",
              height: "13px",
              position: "relative",
              top: "-2px",
              marginRight: '5px',
              left: "-2px",
            }}
          />
          Code issue
        </span>
      ),
      dataIndex: "code_issue",
      key: "code_issue",
      responsive: ["lg"],
      width: "20%",
      render: (event, data, index) => {
        return (
          <div onClick={() => handleColumnClick(event, data,  index,'code_issue')}>
          <TableCard
            data={event}
            mainvalue={data.codeIssue_No}
          />
          </div>
        );
      },
    },
    {
      title: (
        <span style={{ whiteSpace: "nowrap" }}>
          <img
            src={arming}
            alt="Icon"
            style={{
              width: "14px",
              height: "14px",
              position: "relative",
              top: "-2px",
              marginRight: '5px',
              left: "-2px",
            }}
          />
          Vulnerabilities
        </span>
      ),
      dataIndex: "code_issue",
      key: "code_issue",
      responsive: ["lg"],
      width: "20%",
      render: (event, data, index) => {
        return (
          <div onClick={() => handleColumnClick(event, data,index,'vulnerability')}>
          <TableCard
            data={event}
            mainvalue={data.codeIssue_No}
          />
          </div>
        );
      },
    },
    {
      title: (
        <span style={{ whiteSpace: "nowrap" }}>
          <img
            src={license}
            alt="Icon"
            style={{
              width: "16px",
              height: "13px",
              position: "relative",
              top: "-2px",
              marginRight: '5px',
              left: "-2px",
            }}
          />
          License Violations
        </span>
      ),
      dataIndex: "code_issue",
      key: "code_issue",

      responsive: ["lg"],
      width: "20%",
      render: (event, data, index) => {
        return (
          <div onClick={() => handleColumnClick(event, data,index,'license_violation')}>
          <TableCard
            data={event}
            mainvalue={data.licence_No}
          />
          </div>
        );
      },
    },
    {
      title: (
        <span style={{ whiteSpace: "nowrap" }}>
          <img
            src={malicious}
            alt="Icon"
            style={{
              width: "16px",
              height: "15px",
              position: "relative",
              top: "-2px",
              marginRight: '5px',
              left: "-2px",
            }}
          />
          Malicious packages
        </span>
      ),
      dataIndex: "code_issue",
      key: "code_issue",
      responsive: ["lg"],
      width: "20%",

      render: (event, data, index) => {
        return (
          <div onClick={() => handleColumnClick(event, data,index,'malicious')}>
          <TableCard
            data={event}
            mainvalue={data.malicious_No}
          />
          </div>
        );
      },
    },
    {
      title: (
        <span style={{ whiteSpace: "nowrap" }}>
          <img
            src={amend}
            alt="Icon"
            style={{
              width: "16px",
              height: "15px",
              position: "relative",
              top: "-2px",
              marginRight: '5px',
              left: "-2px",
            }}
          />
          End of Life
        </span>
      ),
      dataIndex: "code_issue",
      key: "code_issue",
      responsive: ["lg"],
      width: "20%",
      render: (event, data,index) => {
        
        return (
          <div onClick={() => handleColumnClick(event, data,index,'end_of_life')}>
          <TableCard
            data={event}
            mainvalue={data.codeIssue_No}
          />
          </div>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "endOfLife",
      key: "endOfLife",
      responsive: ["lg"],
      width: "20%",
      render: (event, data) => {
        return (
          <Row className="">
            <Col span={12}>
              {/* <span>  */}
              <Flex style={{ color: "#5A81FA" }}>
                <span style={{ fontWeight: 600,fontSize:'13px', display:'flex',alignItems:'center',cursor:'pointer' }} onClick={handlePopUp}>
                  {/* <ExitToAppIcon
                    style={{
                      fontSize: "16px",
                      color: "#5A81FA",
                    }}
                  /> */}
                  <img
                    src={share}
                    alt="Icon"
                    style={{
                      width: "16px",
                      height: "15px",
                      marginRight: '5px',
                      
                    }}
                  />
                  SBOM
                </span>
              </Flex>
              {/* </span>  */}
            </Col>
            <Col span={12}>
              <Flex style={{ color: "#5A81FA" }}>
                <span style={{ fontWeight: 600,fontSize:'13px', display:'flex',alignItems:'center',cursor:'pointer' }} onClick={handleDrawer}>
                  {/* <VisibilityIcon
                    style={{
                      fontSize: "16px",
                      color: "#5A81FA",
                    }}
                  /> */}
                  <img
                    src={eye}
                    alt="Icon"
                    style={{
                      width: "16px",
                      height: "13px",
                      marginRight: '5px',
                      cursor:'pointer'
                    }}
                  />
                  Summary
                </span>
              </Flex>
            </Col>
          </Row>
        );
      },
    },
  ];
  let handleColumnClick = (event,data,index,string) =>{
    console.log("data.event--->", event);
    console.log("data.code_issue--->", data);
    console.log("index--->", index);

    if(string === 'code_issue'){
      console.log('code_issue');
      setTitle('Code Issue')
      setOpenDrawer(true)
      setTabsTitleKey('code_issue')
      setTabsTitle('Code Issue Details')
      setComponent(<CodeIssueTab />)
    }
    else if(string === 'vulnerability'){
      console.log('inside vulnerability');
      setTitle('Vulnerability')
      setOpenDrawer(true)
      setTabsTitleKey('Vulnerability')
      setTabsTitle('Vulnerability Details')
      setComponent(<CollapseTopIssues />)
      
    }
    else if(string === 'license_violation'){
      console.log('inside license');
      setTitle('License Violation')
      setOpenDrawer(true)
      setTabsTitleKey('license_violation')
      setTabsTitle('License Violation Details')
      setComponent(<LicenseViolationTabs />)

      
    } 
    else if(string === 'malicious'){
      console.log('inside malicious');
      setTitle('Malicious Package')
      setOpenDrawer(true)
      setTabsTitleKey('malicious')
      setTabsTitle('Malicious Details')
    } 
    else if(string === 'end_of_life'){
      console.log('inside end_of_life');
      setTitle('End Of Life')
      setOpenDrawer(true)
      setTabsTitleKey('end_of_life')
      setTabsTitle('End Of Life')
    } 
  }

  const itemRender = (current, type, originalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }
    return originalElement;
  };
  const [currentPage, setCurrentPage] = useState(1);
  const totalRecords = 100; // Total number of records in your table
  const pageSize = 10; // Number of records per page

  const startRecord = (currentPage - 1) * pageSize + 1;
  const endRecord = Math.min(currentPage * pageSize, totalRecords);

  // const [open, setOpen] = useState(false);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [open, setOpen] = useState(false);
  const handleDrawer = () => {
    console.log("Inside drawer method");
    // setIsDrawerVisible(true)
    setOpen(true);
  };

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  const [activeTabs, setActiveTabs] = useState("Issues");
  const [codeIssueActiveTabs, setCodeIssueTabs] = useState('');
  const callback = (codeIssueActiveTabs) => {
    setActiveTabs(codeIssueActiveTabs);
    console.log("active-key ==", codeIssueActiveTabs);
  };  
  const callbackCodeIssue = (codeIssueActiveTabs) => {
    setCodeIssueTabs(codeIssueActiveTabs);
  };

  const treeData = [
    {
      title: 'conan-library@5.0.2',
      key: '0-0',
      children: [
        {
          title: 'fmt@9.1.0',
          key: '0-0-0',
          children: [
            {
              title: 'brotili@5.0.2',
              key: '0-0-0-0',
            },
            {
              title: 'bzip2@2.0.0',
              key: '0-0-0-1',
            },
            {
              title: 'zalib@3.0.1',
              key: '0-0-0-2',
            },
            {
              title: 'conan-library@5.0.2',
              key: '0-0-0-3',
            },
            {
              title: 'conan-library@5.0.2',
              key: '0-0-0-4',
            },
          ],
        },
        {
          title: 'conan-library@5.0.2',
          key: '0-0-1',
          children: [
            {
              title: 'leaf',
              key: '0-0-1-0',
            },
          ],
        },
        {
          title: 'fmt@9.1.0',
          key: '0-0-2',
          children: [
            {
              title: 'brotili@5.0.2',
              key: '0-0-2-0',
            },
            {
              title: 'brotili@5.0.2',
              key: '0-0-2-1',
            },
          ],
        },
      ],
    },
  ];
  const projectData = [
    {
      value: 30,
      initial: "C",
      color: "#9494AE",
      bgColor: "#E6E6E6",
    },
    {
      projectName: "Project 2",
      value: 80,
      initial: "H",
      color: "#F50000",
      bgColor: "#F500001F",
    },
    {
      projectName: "Project 3",
      value: 90,
      initial: "M",
      color: "#FE9706",
      bgColor: "#FE97061F",
    },
    {
      projectName: "Project 4",
      value: 30,
      initial: "L",
      color: "#02B583",
      bgColor: "#02B5831F",
    },
  ];
  const timeLineData  = [
    {
        title:'Latest, 1st Feb 2024 by recruting test',
        dependencies:120,
        issues:80
    },
    {
        title:'3 days ago, by recruting test',
        dependencies:120,
        issues:80
    },
    {
        title:'3 days ago, by recruting test',
        dependencies:120,
        issues:80
    },
    {
        title:'3 days ago, by recruting test',
        dependencies:120,
        issues:80
    },
    {
        title:'3 days ago, by recruting test',
        dependencies:120,
        issues:80
    },
    {
        title:'3 days ago, by recruting test',
        dependencies:120,
        issues:80
    },
    {
        title:'3 days ago, by recruting test',
        dependencies:120,
        issues:80
    },
  ]
  const tabsMenu = [
    {
      key: "Issues",
      label: "Issues",
      children:<TopIssuesPage />,
    },
    {
      key: "Dependency",
      label: "Dependency",
       children: <DependencyTree treeData={treeData} />
    },
    {
      key: "Licenses",
      label: "Licenses",
      children: <DiscoveredLicense />
    },
    {
      key: "History",
      label: "History",
      children: <HistoryList timeLineData={timeLineData} projectData={projectData} style={{ width: '100%', backgroundColor: 'rgb(236, 236, 246)'}}/>,
    },
  ];
  const codeIssueTabs = [ 
    {
      key: TabsTitleKey,
      label: TabsTitle,
      children: Components,
    },
    {
      key: "Affected_Software",
      label: "Affected Software",
      children: <AffectedSoftware />,
      },
    {
      key: "Solution_Fix",
      label: "Solution & Fix",
      children: <SolutionFix />,
      },
    {
      key: "References",
      label: "References",
      children: <References  />,
      },
  ];
  return (
    <div className="project_chart_wrapper">
      <Row gutter={[16, { xs: 16, sm: 16, md: 8, lg: 8 }]}>
        {/* <Col xs={24} sm={24} md={24} lg={7}>
              <ScaBarGhraph />
                
              </Col> */}
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={24}
        >
          {/* <Tabs defaultActiveKey="1" items={items} onChange={onChange} /> */}
          <Card>
            <Table
              className="summary_chart"
              columns={tableColumn ? tableColumn : columns}
              dataSource={tableData ? tableData :  data}
              style={{ width: "100%" }}
              scroll={{ x: 1200 }}
              pagination={false}
            />
            <div
              style={{
                display: "flex",
                marginTop: "10px",
                justifyContent: "space-between",
                alignItems: "center",
                color: "#63637D",
                padding: "7px",
                fontWeight: 600,
                fontSize: "13px",
              }}
            >
              <div>
                Showing {startRecord}-{endRecord} entries out of {totalRecords}
              </div>
              <Pagination
                style={{
                  color: "#63637D",
                  padding: "5px",
                  fontWeight: 600,
                  fontSize: "12px",
                }}
                current={currentPage}
                pageSize={pageSize}
                total={totalRecords}
                itemRender={itemRender}
                onChange={handlePageChange}
                showSizeChanger={false}
              />
            </div>
            {/* project table bottom drawer */}
            {openDrawer && (
              <CommonBottomDrawer
              title={drawerTitle}
              open={openDrawer}
              onClose={() => {setOpenDrawer(false);setCodeIssueTabs('')}
              }>
                <Card>
                <TabsWithExtraBtn
                 activeTabs={codeIssueActiveTabs}
                 key={codeIssueActiveTabs}
                 callback={callbackCodeIssue}
                 menu={codeIssueTabs}
               />
                </Card>

              </CommonBottomDrawer>
            )}
          </Card>
          {isPopupVisible && (
            <Modal
              title="Export SBOM"
              centered
              open={isPopupVisible}
              onOk={() => setIsPopupVisible(false)}
              onCancel={() => setIsPopupVisible(false)}
              className={isPopupVisible ? "popup-slide-up" : "popup-slide-down"}
              transitionName=""
              width={400}
            >
              <div>
                <p>Select the format below to download SBOM.</p>
                <Dropdown
                  menu={{
                    items,
                  }}
                  trigger={["click"]}
                >
                  <a
                    style={{
                      display: "block",
                      padding: " 5px",
                      color: "#63637D",
                      borderRadius: "5px",
                      border: "1px solid #1F214529",
                      width: "350px",
                    }}
                    onClick={(e) => e.preventDefault()}
                  >
                    <Space>Select</Space>
                    <DownOutlined
                      style={{
                        float: "inline-end",
                        position: "relative",
                        top: "4px",
                        fontSize: "12px",
                      }}
                    />
                  </a>
                </Dropdown>
              </div>
            </Modal>
          )}
          {open && (
            <Drawer
              title="Project Summary"
              className="side-drawer"
              // placement={placement}
              width={500}
              onClose={onClose}
              closeIcon={null}
              
              open={open}
              extra={
                <Space>
                  <span
                  style={{cursor:'pointer'}}
                    onClick={onClose}
                  >
                    <CloseOutlined />
                  </span>
                  {/* <Button type="primary" onClick={onClose}>
                  OK
                </Button> */}
                </Space>
              }
            >
              <TabsWithExtraBtn
                callback={callback}
                menu={tabsMenu}
                // extraBtn={operations}
              />
            </Drawer>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default ProjectTable;
