import React from "react";
import {
  BarChart,
  Bar,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Label,
  CartesianGrid
} from "recharts";
const data = [
  {
    name: "POLICY & PEOPLE",
    uv: 25,
    pv: 2400,
    amt: 2400,
    gradient: "linear-gradient(180deg, #FFAF00 0%, #FF9900 92.19%)",
  },
  {
    name: "RISK MANAGEMENT",
    uv: 35,
    pv: 1398,
    amt: 2210,
    gradient: "linear-gradient(180deg, #C0C900 0%, #A0C800 100%)",
  },
  {
    name: "INCIDENT MANAGEMENT",
    uv: 60,
    pv: 9800,
    amt: 2290,
    gradient: "linear-gradient(180deg, #00A1DE 0%, #0066DE 100%)",
  },
  {
    name: "PHYSICAL",
    uv: 80,
    pv: 3908,
    amt: 2000,
    gradient: "linear-gradient(180deg, #42C375 0%, #64C342 100%)",
  },
  {
    name: "Infrastructure",
    uv: 90,
    pv: 4800,
    amt: 2181,
    gradient: "linear-gradient(180deg, #00C1B9 0%, #009EC1 100%)",
  },
  {
    name: "access control",
    uv: 50,
    pv: 3800,
    amt: 2500,
    gradient: "linear-gradient(180deg, #0032B4 0%, #0064B4 100%)",
  },
  {
    name: "application security",
    uv: 45,
    pv: 4300,
    amt: 2100,
    gradient: "linear-gradient(180deg, #3301C0 0%, #6C4ACD 100%)",
  },
  {
    name: "BCM",
    uv: 30,
    pv: 4300,
    amt: 2100,
    gradient: "linear-gradient(180deg, #629DB0 0%, #527CA2 100%)",
  },
];

// custom shape for bar with radius start
const customShape = (props) => {
  const { x, y, width, height, fill, index } = props;
  const radius = 8;
  const path = `M${x},${y + height}
    L${x},${y + radius}
    Q${x},${y} ${x + radius},${y}
    L${x + width - radius},${y}
    Q${x + width},${y} ${x + width},${y + radius}
    L${x + width},${y + height}
    Z`;

  // Get gradient color from data object
  const gradientColor = data[index].gradient;

  return (
    <g>
      <defs>
        <linearGradient
          id={`gradient-${index}`}
          x1="0%"
          y1="0%"
          x2="100%"
          y2="0%"
        >
          <stop offset="0%" stopColor={gradientColor.split(" ")[1]} />
          <stop offset="100%" stopColor={gradientColor.split(" ")[3]} />
        </linearGradient>
      </defs>
      <path d={path} stroke="none" fill={`url(#gradient-${index})`} />
    </g>
  );
};

// custom shape for bar with radius end

const CategoryWiseIssueBarChart = () => {
  return (
    <>
      <ResponsiveContainer  width="100%" height={300}>
        <BarChart
          width={300}
          height={300}
          data={data}
          barSize={26}
          margin={{
            top: 20,
            right: 10,
            left: 0,
            bottom: 15,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" horizontal={true} vertical={false} />
          <XAxis
            dataKey="name"
            tickLine={false}
            fontSize={10}
            interval={1}
            width={40}
            height={60}
            minTickGap={4}
            tick={{
              width: 40,
              height: 40,
              wordSpacing: 'break-word',
              whiteSpace: 'wrap',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              
           }}
            label={{
              value: "Category",
              offset: 0,
              position: "bottom",
              style: { textAnchor: "middle", fontWeight: "bold" },
            }}
          />
          {/* <Label value="Category"  offset={0} position="bottom",  style:{ textAnchor: "middle", fontWeight: "bold" }, />
          </XAxis> */}
          <YAxis
           tickLine={false}
            label={{
              value: "POINT",
              style: { textAnchor: "middle", fontWeight: "bold" },
              angle: -90,
              position: "left",
              offset: -15,
            }}
          />
          <Bar dataKey="uv" fill="#8884d8" shape={customShape} />
        </BarChart>
      </ResponsiveContainer>
    </>
  );
};

export default CategoryWiseIssueBarChart;
