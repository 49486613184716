import React, { useState } from "react";
import "./AdditionalRulesForm.css";
import { Button, Col, Collapse, Form, Input, Row, Select, Switch } from "antd";
import {
  DeleteOutlined,
  PlusOutlined,
  UpOutlined,
  DownOutlined,
} from "@ant-design/icons";

const { Option } = Select;

const AdditionalRulesForm = () => {
  const [panelCount, setPanelCount] = useState([1]);

  const addNewPane = () => {
    if (panelCount.length > 0) {
      const newKey = panelCount.length + 1;
      setPanelCount([...panelCount, newKey]);
    }
  };

  const deletePanel = (keyId) => {
    if (panelCount.length > 1) {
      const filteredPanel = panelCount.filter((el) => el !== keyId);
      setPanelCount(filteredPanel);
    }
  };

  const optionsSelect = [
    { id: 1, label: "AWS Codebuild", value: "AWS Codebuild" },
    { id: 2, label: "Bitbucket", value: "Bitbucket" },
    { id: 3, label: "CircleCI", value: "CircleCI" },
  ];

  const customExpandIcon = ({ isActive }) => {
    return isActive ? (
      <UpOutlined style={{ fontSize: 16, color: "#D9D9D9" }} />
    ) : (
      <DownOutlined style={{ fontSize: 16, color: "#D9D9D9" }} />
    );
  };
  return (
    <div className="additional_rules_form">
      <p className="heading_form_1 mb-0 text-center">
        You can add Multiple Rules
      </p>

      <Collapse
        style={{ background: "#F6F6FF" }}
        defaultActiveKey={["1"]}
        onChange={(keys) => console.log(keys)}
        expandIconPosition="right"
        expandIcon={customExpandIcon}
      >
        {panelCount.map((el) => (
          <Collapse.Panel
            header={
              <div className="d-flex justify-content-between align-items-center">
                <div className="left_header_item">Rules {el}</div>
                <div className="right_header_item d-flex gap-2">
                  <Switch
                    size="small"
                    onChange={(checked) => console.log(checked)}
                  />
                  <DeleteOutlined
                    style={{ fontSize: 16, color: "#E10505" }}
                    onClick={(e) => {
                      e.stopPropagation();
                      deletePanel(el);
                    }}
                  />
                </div>
              </div>
            }
            showArrow={true}
            key={el.toString()}
          >
            <div className="panel_content">
              <Form layout="vertical">
                <Form.Item
                  style={{ marginBottom: "12px" }}
                  name="rule_type"
                  label={<span className="custom_label_class">Rule Type</span>}
                  rules={[{ required: false }]}
                >
                  <Select size="large" placeholder="Select Rule Type">
                    {Array(4)
                      .fill()
                      .map((_, i) => (
                        <Option key={i} value={`Rule ${i + 1}`}>
                          {`Rule ${i + 1}`}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  style={{ marginBottom: "12px" }}
                  name="description"
                  label={
                    <span className="custom_label_class">Description</span>
                  }
                  rules={[{ required: false }]}
                >
                  <Input.TextArea
                    placeholder="write description here..."
                    showCount
                    maxLength={150}
                  />
                </Form.Item>
                <Form.Item
                  style={{ marginBottom: "12px" }}
                  name="app"
                  label={
                    <div className="custom_label_class">
                      App
                      <span className="text-danger">
                        (min one app is required)
                      </span>
                    </div>
                  }
                  rules={[{ required: false }]}
                >
                  <Select
                    size="large"
                    mode="multiple"
                    style={{ width: "100%", fontSize: 16, height: "40px" }}
                    placeholder="Please select"
                    defaultValue={["AWS Codebuild"]}
                    options={optionsSelect}
                  />
                </Form.Item>
                <div className="d-flex justify-content-between">
                  <button className="cancel_btn">Cancel</button>
                  <button className="save_btn">Save</button>
                </div>
              </Form>
            </div>
          </Collapse.Panel>
        ))}
        <div className="add_btn_wrapper d-flex justify-content-center mt-3">
          <button onClick={addNewPane} className="add_rule_btn" icon="">
            <PlusOutlined />
            <span className="add_rule_btn_text">Add Rules</span>
          </button>
        </div>
      </Collapse>
    </div>
  );
};

export default AdditionalRulesForm;
