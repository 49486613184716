import React from "react";
import {
  Radar,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  Legend,
  Line,
} from "recharts";

const data = [
  {
    subject: "Information Leakage (30)",
    A: 160,
    B: 120,
    fullMark: 150,
  },
  {
    subject: "Network Security",
    A: 160,
    B: 143,
    fullMark: 150,
  },
  {
    subject: "Security Misconfigurations",
    A: 160,
    B: 130,
    fullMark: 150,
  },
  {
    subject: "Social Engineering",
    A: 140,
    B: 100,
    fullMark: 150,
  },
  {
    subject: "Information Leakage",
    A: 160,
    B: 130,
    fullMark: 150,
  },
  {
    subject: "Security Misconfigurations2",
    A: 60,
    B: 130,
    fullMark: 150,
  },
  {
    subject: "Network Security2",
    A: 70,
    B: 140,
    fullMark: 150,
  },
  {
    subject: "Social Engineering2",
    A: 160,
    B: 70,
    fullMark: 150,
  },
];

const renderSmoothShape = (props) => {
  const { points } = props;

  // Construct a smooth curve using cubic Bézier interpolation
  const path = points
    .reduce(
      (acc, point, index, array) => {
        const nextPoint = array[(index + 1) % array.length];
        const controlPoint1 = [
          (point.x + nextPoint.x) / 2,
          (point.y + nextPoint.y) / 2,
        ];
        const prevPoint = array[(index - 1 + array.length) % array.length];
        const controlPoint2 = [
          (point.x + prevPoint.x) / 2,
          (point.y + prevPoint.y) / 2,
        ];

        return acc.concat([
          `C${controlPoint1.join(",")},${controlPoint2.join(",")},${point.x},${
            point.y
          }`,
        ]);
      },
      [`M${points[0].x},${points[0].y}`]
    )
    .join("");

  return <path d={path} fill="#CDDEFF" fillOpacity={0.6} />;
};

const CustomLegend = () => (
  <div style={{ display: "flex", gap: "10px", justifyContent: "flex-end" }}>
    <div style={{ color: "#F50000" }}>
      <span
        style={{
          display: "inline-block",
          width: 10,
          height: 10,
          borderRadius: "50%",
          backgroundColor: "#F50000",
          marginRight: 8,
        }}
      ></span>
      <span
        style={{
          fontSize: 12,
          fontWeight: 600,
          fontFamily: "Open Sans",
          color: "#232337",
        }}
      >
        Current Threat
      </span>
    </div>
    <div style={{ color: "#82ca9d" }}>
      <span
        style={{
          display: "inline-block",
          width: 10,
          height: 10,
          borderRadius: "50%",
          backgroundColor: "#5A81FA",
          marginRight: 8,
        }}
      ></span>
      <span
        style={{
          fontSize: 12,
          fontWeight: 600,
          fontFamily: "Open Sans",
          color: "#232337",
        }}
      >
        Previous Threat
      </span>
    </div>
  </div>
);

const ViewSummaryChart = ({ width, height }) => {
  return (
    <div>
      <RadarChart
        cx="50%"
        cy="50%"
        outerRadius={100}
        width={width ? width : 500}
        height={height ? height : 370}
        data={data}
        defaultShowTooltip={false}
      >
        <PolarGrid gridType="circle" />
        <PolarAngleAxis fontSize={12} dataKey="subject" />
        <PolarRadiusAxis />
        <Radar
          name="Current Threat"
          dataKey="A"
          stroke="#CDDEFF"
          fill="#CDDEFF"
          fillOpacity={0.6}
          shape={renderSmoothShape}
          dot={{ fill: "#F50000", strokeWidth: 0, r: 5 }}
        />
        <Radar
          name="Previous Threat"
          dataKey="B"
          stroke="#5A81FA"
          fill="none"
          fillOpacity={0.6}
          shape="circle"
          strokeDasharray="4 4"
          dot={{ fill: "#5A81FA", strokeWidth: 0, r: 5 }}
        />
        <Legend
          content={CustomLegend}
          iconType="circle"
          iconSize={10}
          verticalAlign="bottom"
          align="right"
          height={36}
        />
      </RadarChart>
    </div>
  );
};

export default ViewSummaryChart;
