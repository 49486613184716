import React from "react";
import { Drawer, Button, Space } from "antd";
import "./commonBottomDrawer.css";
import { CloseOutlined } from "@ant-design/icons";

const CommonBottomDrawer = ({ children, onClose, open, title, className, bodyPadding }) => {
  return (
    <Drawer
      className={`${className} common_bottom_drawer`}
      title={title}
      placement={"bottom"}
      height={"100vh"}
      width={"100%"}
      footer={null}
      onClose={onClose}
      bodyStyle={{background: "#ECECF6", padding: bodyPadding}}
      visible={open}
      closeIcon={<CloseOutlined style={{ color: "#fff" }} />}
    >
      <div
        className="children_wrapper_container"
        // style={{ height: "calc(100% - 30px)", overflowY: "auto" }}
      >
        {children}
      </div>
    </Drawer>
  );
};

export default CommonBottomDrawer;
