import React, { useState } from "react";
import { Row, Col, Card, Collapse } from "antd";
// import { flag } from "country-flag-icons/react/3x2";
import Flags from "country-flag-icons/react/3x2";
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import ArrowDropUpOutlinedIcon from '@mui/icons-material/ArrowDropUpOutlined';
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
} from "react-simple-maps";
import TinyFlag from "tiny-flag";
import ReactFlagsSelect from "react-flags-select";
import "./Threats.css";
import FlagIcon from "../charts/FlagIcon";
import ClusteredMap from "./ClusteredMap";

const ThreatsAcc = ({ threadTitle }) => {
  const [selectedCountries, setSelectedCountries] = useState();
  const [flag, setFlag] = useState({ code: "US" });
  // console.log("us flag====", US);

  const customExpandIcon = (panelProps) => {
    const { isActive } = panelProps;
    return isActive ? (
      <ArrowDropUpOutlinedIcon style={{ fontSize: 24, color: '#D9D9D9' }} />
    ) : (
      <ArrowDropDownOutlinedIcon style={{ fontSize: 24, color: '#D9D9D9' }} />
    );
  };
  const markers = [
    {
      markerOffset: -15,
      name: "CANADA",
      coordinates: [-106.3468, 56.1304],
      code: "CA",
      paneldatalist: [
        { id: 1, ip: "34.212.534.88", domain: "apifactory.hdfcargo.com" },
        { id: 2, ip: "34.212.534.88", domain: "apifactory.hdfcargo.com" },
        { id: 3, ip: "34.212.534.88", domain: "apifactory.hdfcargo.com" },
        { id: 4, ip: "34.212.534.88", domain: "apifactory.hdfcargo.com" },
        { id: 5, ip: "34.212.534.88", domain: "apifactory.hdfcargo.com" },
        { id: 6, ip: "34.212.534.88", domain: "apifactory.hdfcargo.com" },
        { id: 7, ip: "34.212.534.88", domain: "apifactory.hdfcargo.com" },
      ],
    },
    {
      markerOffset: -15,
      name: "UNITED STATE",
      coordinates:[-95.7129,37.0902],
      code: "US",
      paneldatalist: [
        { id: 1, ip: "34.212.534.88", domain: "apifactory.hdfcargo.com" },
        { id: 2, ip: "34.212.534.88", domain: "apifactory.hdfcargo.com" },
        { id: 3, ip: "34.212.534.88", domain: "apifactory.hdfcargo.com" },
        { id: 4, ip: "34.212.534.88", domain: "apifactory.hdfcargo.com" },
        { id: 5, ip: "34.212.534.88", domain: "apifactory.hdfcargo.com" },
        { id: 6, ip: "34.212.534.88", domain: "apifactory.hdfcargo.com" },
        { id: 7, ip: "34.212.534.88", domain: "apifactory.hdfcargo.com" },
      ],
    },

    {
      markerOffset: 25,
      name: "AFGANISTAN",
      coordinates: [67.709953, 33.93911],
      code: "AF",
      paneldatalist: [
        { id: 1, ip: "34.212.534.88", domain: "apifactory.hdfcargo.com" },
        { id: 2, ip: "34.212.534.88", domain: "apifactory.hdfcargo.com" },
        { id: 3, ip: "34.212.534.88", domain: "apifactory.hdfcargo.com" },
        { id: 4, ip: "34.212.534.88", domain: "apifactory.hdfcargo.com" },
        { id: 5, ip: "34.212.534.88", domain: "apifactory.hdfcargo.com" },
        { id: 6, ip: "34.212.534.88", domain: "apifactory.hdfcargo.com" },
        { id: 7, ip: "34.212.534.88", domain: "apifactory.hdfcargo.com" },
      ],
    },
    {
      markerOffset: 25,
      name: "ALBANIA",
      coordinates: [20.168331, 41.153332],
      code: "AL",
      paneldatalist: [
        { id: 1, ip: "34.212.534.88", domain: "apifactory.hdfcargo.com" },
        { id: 2, ip: "34.212.534.88", domain: "apifactory.hdfcargo.com" },
        { id: 3, ip: "34.212.534.88", domain: "apifactory.hdfcargo.com" },
        { id: 4, ip: "34.212.534.88", domain: "apifactory.hdfcargo.com" },
        { id: 5, ip: "34.212.534.88", domain: "apifactory.hdfcargo.com" },
        { id: 6, ip: "34.212.534.88", domain: "apifactory.hdfcargo.com" },
        { id: 7, ip: "34.212.534.88", domain: "apifactory.hdfcargo.com" },
      ],
    },
    {
      markerOffset: 25,
      name: "AUSTRALIA",
      coordinates: [133.775136, -25.274398],
      code: "AU",
      paneldatalist: [
        { id: 1, ip: "34.212.534.88", domain: "apifactory.hdfcargo.com" },
        { id: 2, ip: "34.212.534.88", domain: "apifactory.hdfcargo.com" },
        { id: 3, ip: "34.212.534.88", domain: "apifactory.hdfcargo.com" },
        { id: 4, ip: "34.212.534.88", domain: "apifactory.hdfcargo.com" },
        { id: 5, ip: "34.212.534.88", domain: "apifactory.hdfcargo.com" },
        { id: 6, ip: "34.212.534.88", domain: "apifactory.hdfcargo.com" },
        { id: 7, ip: "34.212.534.88", domain: "apifactory.hdfcargo.com" },
      ],
    },
    {
      markerOffset: 25,
      name: "ANDORRA",
      coordinates: [1.601554, 42.546245],
      code: "AD",
      paneldatalist: [
        { id: 1, ip: "34.212.534.88", domain: "apifactory.hdfcargo.com" },
        { id: 2, ip: "34.212.534.88", domain: "apifactory.hdfcargo.com" },
        { id: 3, ip: "34.212.534.88", domain: "apifactory.hdfcargo.com" },
        { id: 4, ip: "34.212.534.88", domain: "apifactory.hdfcargo.com" },
        { id: 5, ip: "34.212.534.88", domain: "apifactory.hdfcargo.com" },
        { id: 6, ip: "34.212.534.88", domain: "apifactory.hdfcargo.com" },
        { id: 7, ip: "34.212.534.88", domain: "apifactory.hdfcargo.com" },
      ],
    },
    {
      markerOffset: 25,
      name: "ARMENIA",
      coordinates: [45.038189, 40.069099],
      code: "AM",
      paneldatalist: [
        { id: 1, ip: "34.212.534.88", domain: "apifactory.hdfcargo.com" },
        { id: 2, ip: "34.212.534.88", domain: "apifactory.hdfcargo.com" },
        { id: 3, ip: "34.212.534.88", domain: "apifactory.hdfcargo.com" },
        { id: 4, ip: "34.212.534.88", domain: "apifactory.hdfcargo.com" },
        { id: 5, ip: "34.212.534.88", domain: "apifactory.hdfcargo.com" },
        { id: 6, ip: "34.212.534.88", domain: "apifactory.hdfcargo.com" },
        { id: 7, ip: "34.212.534.88", domain: "apifactory.hdfcargo.com" },
      ],
    },
    {
      markerOffset: 25,
      name: "AUSTRIA",
      coordinates: [14.550072, 47.516231],
      code: "AT",
      paneldatalist: [
        { id: 1, ip: "34.212.534.88", domain: "apifactory.hdfcargo.com" },
        { id: 2, ip: "34.212.534.88", domain: "apifactory.hdfcargo.com" },
        { id: 3, ip: "34.212.534.88", domain: "apifactory.hdfcargo.com" },
        { id: 4, ip: "34.212.534.88", domain: "apifactory.hdfcargo.com" },
        { id: 5, ip: "34.212.534.88", domain: "apifactory.hdfcargo.com" },
        { id: 6, ip: "34.212.534.88", domain: "apifactory.hdfcargo.com" },
        { id: 7, ip: "34.212.534.88", domain: "apifactory.hdfcargo.com" },
      ],
    },
    {
      markerOffset: 25,
      name: "BELGIUM",
      coordinates: [4.469936, 50.503887],
      code: "BE",
      paneldatalist: [
        { id: 1, ip: "34.212.534.88", domain: "apifactory.hdfcargo.com" },
        { id: 2, ip: "34.212.534.88", domain: "apifactory.hdfcargo.com" },
        { id: 3, ip: "34.212.534.88", domain: "apifactory.hdfcargo.com" },
        { id: 4, ip: "34.212.534.88", domain: "apifactory.hdfcargo.com" },
        { id: 5, ip: "34.212.534.88", domain: "apifactory.hdfcargo.com" },
        { id: 6, ip: "34.212.534.88", domain: "apifactory.hdfcargo.com" },
        { id: 7, ip: "34.212.534.88", domain: "apifactory.hdfcargo.com" },
      ],
    },
    {
      markerOffset: 25,
      name: "BAHRAIN",
      coordinates: [50.637772, 25.930414],
      code: "BH",
      paneldatalist: [
        { id: 1, ip: "34.212.534.88", domain: "apifactory.hdfcargo.com" },
        { id: 2, ip: "34.212.534.88", domain: "apifactory.hdfcargo.com" },
        { id: 3, ip: "34.212.534.88", domain: "apifactory.hdfcargo.com" },
        { id: 4, ip: "34.212.534.88", domain: "apifactory.hdfcargo.com" },
        { id: 5, ip: "34.212.534.88", domain: "apifactory.hdfcargo.com" },
        { id: 6, ip: "34.212.534.88", domain: "apifactory.hdfcargo.com" },
        { id: 7, ip: "34.212.534.88", domain: "apifactory.hdfcargo.com" },
      ],
    },
    {
      markerOffset: 25,
      name: "BELARUS",
      coordinates: [27.953389, 53.709807],
      code: "BY",
      paneldatalist: [
        { id: 1, ip: "34.212.534.88", domain: "apifactory.hdfcargo.com" },
        { id: 2, ip: "34.212.534.88", domain: "apifactory.hdfcargo.com" },
        { id: 3, ip: "34.212.534.88", domain: "apifactory.hdfcargo.com" },
        { id: 4, ip: "34.212.534.88", domain: "apifactory.hdfcargo.com" },
        { id: 5, ip: "34.212.534.88", domain: "apifactory.hdfcargo.com" },
        { id: 6, ip: "34.212.534.88", domain: "apifactory.hdfcargo.com" },
        { id: 7, ip: "34.212.534.88", domain: "apifactory.hdfcargo.com" },
      ],
    },
  ];

  return (
    <div className="top-threats-parent">
      <Row className="w-100">
        <Col xs={24} sm={24} md={8} lg={6}>
          <div className="top-threats">
            {threadTitle ? threadTitle : "Top Threats"}
          </div>
          <div className="country_selector">
            <Collapse
              accordion
              bordered={false}
              style={{ background: "#fff" }}
              expandIconPosition="right"
              expandIcon={customExpandIcon}
            >
              {markers?.map((el, index) => (
                <Collapse.Panel
                  header={
                    <div className="d-flex justify-content-between align-items-center">
                      <div style={{ display: "flex", gap: 4,  }}>
                        <FlagIcon className="ehsan" flagNationCode={el.code} />
                        <div className="country"> {el.name}</div>
                      </div>
                      <div>23</div>
                    </div>
                  }
                  key={index}
                >
                  {el.paneldatalist?.map((item) => (
                    <div className="panel_list_item">{`${item.ip}(${item.domain})`}</div>
                  ))}
                </Collapse.Panel>
              ))}
            </Collapse>
          </div>
        </Col>
        <Col xs={24} sm={24} md={16} lg={18}>
          <div className="asset-distribution-map">
            {/* <ComposableMap>
              <Geographies geography="/features.json">
                {({ geographies }) =>
                  geographies.map((geo) => (
                    <Geography key={geo.rsmKey} geography={geo} />
                  ))
                }
              </Geographies>
              {markers.map(({ name, coordinates, markerOffset }) => (
                <Marker key={name} coordinates={coordinates}>
                  <circle r={10} fill="#F00" stroke="#fff" strokeWidth={2} />
                  <text
                    textAnchor="middle"
                    y={markerOffset}
                    style={{ fontFamily: "system-ui", fill: "#5D5A6D" }}
                  >
                    {name}
                  </text>
                </Marker>
              ))}
            </ComposableMap> */}
            <ClusteredMap markers={markers} />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ThreatsAcc;
