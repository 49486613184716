import React from "react";
import { Button, Col, Collapse, Form, Input, Radio, Row, Select, Upload } from "antd";
import { Editor } from "@tinymce/tinymce-react";
import { UploadOutlined } from "@ant-design/icons";

const { Option } = Select;

const FormWidthSection = ({ data }) => {
    const [form] = Form.useForm();
    const submitHandle = (values) =>{
       console.log('form-value==',values)
    }
  return (
    <div>
      <Form form={form} layout="vertical" onFinish={submitHandle} >
        <Collapse defaultActiveKey={['1','2','3']} expandIconPosition="end">
          <Collapse.Panel
            style={{ background: "#DCDDE7", marginBottom: 10 }}
            header="Finding Details"
            key="1"
          >
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={24} md={8} lg={8}>
                <Form.Item
                  label="Audit"
                  name="audit"
                  rules={[
                    {
                      required: true,
                      message: "Please select an option",
                    },
                  ]}
                >
                  <Select
                    size="large"
                    placeholder="Select Audit"
                    options={[{ value: "sample", label: <span>sample</span> }]}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8}>
                <Form.Item
                  label="Priority"
                  name="priority"
                  rules={[
                    {
                      required: false,
                      message: "Please select priority",
                    },
                  ]}
                >
                  <Select
                    size="large"
                    placeholder="Select Priority"
                    options={[
                      { value: "High", label: <span>High</span> },
                      { value: "Low", label: <span>Low</span> },
                    ]}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Collapse.Panel>
          <Collapse.Panel
            style={{ background: "#DCDDE7", marginBottom: 10 }}
            header="Issue Details"
            key="2"
          >
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={24} md={8} lg={8}>
                <Form.Item
                  label="Title"
                  name="title"
                  rules={[
                    {
                      required: true,
                      message: "Please enter an title",
                    },
                  ]}
                >
                  <Input size="large" placeholder="enter title" type="text" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8}>
                <Form.Item
                  label="Vulnerability List"
                  name="vulnerability_list"
                  rules={[
                    {
                      required: false,
                      message: "Please select Vulnerability",
                    },
                  ]}
                >
                  <Select
                    size="large"
                    placeholder="Select Vulnerability"
                    options={[
                      { value: "High", label: <span>High</span> },
                      { value: "Low", label: <span>Low</span> },
                    ]}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8}>
                <Form.Item
                  label="Type"
                  name="type"
                  rules={[
                    {
                      required: true,
                      message: "Please select type",
                    },
                  ]}
                >
                  <Select
                    size="large"
                    placeholder="Select Type"
                    options={[
                      { value: "a", label: <span>A</span> },
                      { value: "b", label: <span>B</span> },
                    ]}
                  />
                </Form.Item>
              </Col>
              
              <Col xs={24} sm={24} md={24} lg={24} >
              <Form.Item
                label="Severity"
                name="severity"
                rules={[
                  {
                    required: true,
                    message: "Please select severity",
                  },
                ]}
              >
                <Radio.Group>
                  <Radio defaultChecked="standard" value="standard">
                    Standard
                  </Radio>
                  {/* <Radio value="high">High</Radio>
                      <Radio value="low">Low</Radio> */}
                </Radio.Group>
              </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8}>
                <Form.Item
                  label="Severity "
                  name="severity"
                  rules={[
                    {
                      required: true,
                      message: "Please select severity",
                    },
                  ]}
                >
                  <Select
                    size="large"
                    placeholder="Select Seerity"
                    options={[
                      { value: "serverity1", label: <span>Serverity 1</span> },
                      { value: "serverity2", label: <span>Serverity 2</span> },
                    ]}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8}>
                <Form.Item
                  label="Severity Status "
                  name="severity_status"
                  rules={[
                    {
                      required: true,
                      message: "Please select severity status",
                    },
                  ]}
                >
                  <Select
                    size="large"
                    placeholder="Select"
                    options={[
                      { value: "up", label: <span>UP</span> },
                      { value: "low", label: <span>LOW</span> },
                    ]}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8}>
                <Form.Item
                  label="Affected URL"
                  name="affected_url"
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <Input size="large" placeholder="Enter" type="text" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24}>
              <Form.Item label="Description" name="description"
              rules={[
                {required: true}
            ]}
               >
                  <Editor
                    // apiKey="2o2tdzsy4xcqv64tb1o74pvnsv4jh33xwpnqyxs2bty99f61"
                    init={{
                      plugins:
                        "anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount checklist mediaembed casechange export formatpainter pageembed linkchecker a11ychecker tinymcespellchecker permanentpen powerpaste advtable advcode editimage advtemplate ai mentions tinycomments tableofcontents footnotes mergetags autocorrect typography inlinecss",
                      toolbar:
                        "undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat",
                      tinycomments_mode: "embedded",
                      tinycomments_author: "Author name",
                      mergetags_list: [
                        { value: "First.Name", title: "First Name" },
                        { value: "Email", title: "Email" },
                      ],
                      ai_request: (request, respondWith) =>
                        respondWith.string(() =>
                          Promise.reject("See docs to implement AI Assistant")
                        ),
                    }}
                    initialValue="Enter"
                  />
                </Form.Item>
              </Col>
            </Row>
           

          </Collapse.Panel>
          <Collapse.Panel
            style={{ background: "#DCDDE7", marginBottom: 10 }}
            header="Upload Document"
            key="3"
          >
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={24} md={8} lg={8}>
                <Form.Item
                  label="Upload Documnet"
                  name="upload_document"
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <Upload
                    className="file_upload"
                    action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
                    listType="picture"
                    maxCount={1}
                  >
                    <Button
                      size="large"
                      style={{ width: "100%" }}
                      icon={<UploadOutlined />}
                    >
                      Upload
                    </Button>
                  </Upload>
                </Form.Item>
              </Col>
            
            </Row>
          </Collapse.Panel>
        </Collapse>
        <Row justify={"space-between"}>
                    <Col md={12} className="text-start">
                        <Button size="large">Clear</Button>
                    </Col>
                    <Col md={12} className="text-end">
                        <Button size="large" type="primary" onClick={submitHandle} htmlType="submit">Submit</Button>
                    </Col>
                </Row>
      </Form>
    </div>
  );
};

export default FormWidthSection;