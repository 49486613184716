import React from "react";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Collapse,
  Dropdown,
  Menu,
  Row,
  Switch,
  Table,
} from "antd";
import FilterListOutlinedIcon, {
  DownloadOutlined,
  MinusOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import CardView from "../../../components/accordianCardOptions/CardView";
import TableView from "../../../components/accordianCardOptions/TableView";
import LabelValuePairVerticle from "../../../components/accordianCardOptions/LabelValuePairVeticle";
import CardOption1 from "../../../components/accordianCardOptions/card-option/CardOption1";
import CardOption2 from "../../../components/accordianCardOptions/card-option/CardOption2";
import CardOption3 from "../../../components/accordianCardOptions/card-option/CardOption3";
import CardOption4 from "../../../components/accordianCardOptions/card-option/CardOption4";
import QuestionDetails from "../../../components/accordianCardOptions/QuestionDetails";
const CollapseComponent = () => {
  const customExpandIcon = (panelProps) => {
    const { isActive } = panelProps;
    return isActive ? <MinusOutlined /> : <PlusOutlined />;
  };
  return (
    <Collapse defaultActiveKey={"1"} expandIconPosition="end" expandIcon={customExpandIcon}>
      <Collapse.Panel
        style={{ background: "#DCDDE7", marginBottom: 10 }}
        header="Basic Details"
        key="1"
      >
        <CardView />
      </Collapse.Panel>
      <Collapse.Panel
        style={{ background: "#DCDDE7", marginBottom: 10 }}
        header="Component Details"
        key="2"
      >
        <TableView />
      </Collapse.Panel>
      <Collapse.Panel
        style={{ background: "#DCDDE7", marginBottom: 10 }}
        header="Basic Details(Option 2)"
        key="3"
      >
        <LabelValuePairVerticle />
      </Collapse.Panel>
      <Collapse.Panel
        style={{ background: "#DCDDE7", marginBottom: 10 }}
        header="Basic Details(card option)"
        key="4"
      >
        <div className="d-flex flex-wrap gap-2">
          <CardOption1 />
          <CardOption2 />
          <CardOption3 />
          <CardOption4 />
        </div>
      </Collapse.Panel>
      <Collapse.Panel
        style={{ background: "#DCDDE7", marginBottom: 10 }}
        header="Question Details"
        key="5"
      >
        <div className="d-flex flex-wrap gap-2">
          <QuestionDetails />
        </div>
      </Collapse.Panel>
    </Collapse>
  );
};

export default CollapseComponent;
