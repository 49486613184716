import React, { useEffect } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

// Apply amCharts themes
am4core.useTheme(am4themes_animated);

const HeatmapChart = () => {
  useEffect(() => {
    // Create chart instance
    let chart = am4core.create("heatmapChart", am4charts.XYChart);

    // Add data
    chart.data = [
      {
        categoryY: "F (200-400)",
        categoryX: "TIER 1",
        value: 12,
        color: am4core.color("#F40000"),
      },
      {
        categoryY: "D (400-600)",
        categoryX: "TIER 1",
        value: 4,
        color: am4core.color("#FF6B01"),
      },
      {
        categoryY: "C (600-800)",
        categoryX: "TIER 1",
        value: 3,
        color: am4core.color("#FDC106"),
      },
      {
        categoryY: "B (800-1000)",
        categoryX: "TIER 1",
        value: 10,
        color: am4core.color("#90CA2E"),
      },
      {
        categoryY: "A (0-200)",
        categoryX: "TIER 1",
        value: 1,
        color: am4core.color("#20AF5D"),
      },

      {
        categoryY: "F (200-400)",
        categoryX: "TIER 2",
        value: null,
        color: am4core.color("#F0F0F3"),
      },
      {
        categoryY: "D (400-600)",
        categoryX: "TIER 2",
        value: 5,
        color: am4core.color("#FF8B3E"),
      },
      {
        categoryY: "C (600-800)",
        categoryX: "TIER 2",
        value: 9,
        color: am4core.color("#FDCE43"),
      },
      {
        categoryY: "B (800-1000)",
        categoryX: "TIER 2",
        value: 12,
        color: am4core.color("#A7D55F"),
      },
      {
        categoryY: "A (0-200)",
        categoryX: "TIER 2",
        value: 3,
        color: am4core.color("#55BF80"),
      },

      {
        categoryY: "F (200-400)",
        categoryX: "TIER 3",
        value: null,
        color: am4core.color("#F0F0F3"),
      },
      {
        categoryY: "D (400-600)",
        categoryX: "TIER 3",
        value: 2,
        color: am4core.color("#FFAA73"),
      },
      {
        categoryY: "C (600-800)",
        categoryX: "TIER 3",
        value: 6,
        color: am4core.color("#FEDA77"),
      },
      {
        categoryY: "B (800-1000)",
        categoryX: "TIER 3",
        value: 8,
        color: am4core.color("#BEE08A"),
      },
      {
        categoryY: "A (0-200)",
        categoryX: "TIER 3",
        value: 5,
        color: am4core.color("#83CFA2"),
      },
      {
        categoryY: "A (0-200)",
        categoryX: "UNTIERED",
        value: 1,
        color: am4core.color("#F0F0F3"),
      },
      {
        categoryY: "F (200-400)",
        categoryX: "UNTIERED",
        value: 12,
        color: am4core.color("#F0F0F3"),
      },
      {
        categoryY: "D (400-600)",
        categoryX: "UNTIERED",
        value: 4,
        color: am4core.color("#F0F0F3"),
      },
      {
        categoryY: "C (600-800)",
        categoryX: "UNTIERED",
        value: 8,
        color: am4core.color("#F0F0F3"),
      },
      {
        categoryY: "B (800-1000)",
        categoryX: "UNTIERED",
        value: 10,
        color: am4core.color("#F0F0F3"),
      },
     
    ];

    // Create axes
    let categoryXAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryXAxis.dataFields.category = "categoryX";
    categoryXAxis.renderer.minGridDistance = 40;
    categoryXAxis.title.text = "Business Impact";
    // Set title text and styles
    categoryXAxis.title.text = "Business Impact";
    categoryXAxis.title.fontSize = 12;
    categoryXAxis.title.fontWeight = 400;
    categoryXAxis.title.fontFamily = "Open Sans";
    categoryXAxis.title.fill = am4core.color("#232337");
    // Transform text to uppercase
    categoryXAxis.title.adapter.add("textOutput", function (text) {
      return text.toUpperCase();
    });

    categoryXAxis.renderer.labels.template.fontSize = 12;

    let categoryYAxis = chart.yAxes.push(new am4charts.CategoryAxis());
    categoryYAxis.dataFields.category = "categoryY";
    categoryYAxis.renderer.minGridDistance = 40;
    // Set title text and styles
    categoryYAxis.title.text = "Risk Score";
    categoryYAxis.title.fontSize = 12;
    categoryYAxis.title.fontWeight = 400;
    categoryYAxis.title.fontFamily = "Open Sans";
    categoryYAxis.title.fill = am4core.color("#232337");
    // Transform text to uppercase
    categoryYAxis.title.adapter.add("textOutput", function (text) {
      return text.toUpperCase();
    });

   

    categoryYAxis.renderer.labels.template.fontSize = 12;

    // Create series
    let series = chart.series.push(new am4charts.ColumnSeries());
    series.dataFields.categoryX = "categoryX";
    series.dataFields.categoryY = "categoryY";
    series.dataFields.value = "value";
    series.columns.template.width = am4core.percent(100);
    series.columns.template.height = am4core.percent(100);

    // Configure colors for individual cells
    series.columns.template.propertyFields.fill = "color"; // Use custom color from data
    series.columns.template.stroke = am4core.color("#FFFFFF"); // White border
   
   
    // Configure tooltip to display value
    series.columns.template.tooltipText = "{categoryX}, {categoryY}: {value}";

    // Show only column 5 and row 5
    categoryXAxis.startIndex = 4;
    categoryXAxis.endIndex = 4;
    categoryYAxis.startIndex = 4;
    categoryYAxis.endIndex = 4;

    // Add LabelBullet to display value in cell
    let labelBullet = series.bullets.push(new am4charts.LabelBullet());
    labelBullet.label.text = "{value}";
    labelBullet.label.fill = am4core.color("#000"); // White text
    labelBullet.label.fontSize = 12;
    labelBullet.label.horizontalCenter = "middle";
    labelBullet.label.verticalCenter = "middle";
    labelBullet.label.truncate = false;
   
    // Set label text based on value
labelBullet.label.adapter.add("text", function (text, target) {
    let value = target.dataItem.dataContext.value;
    return value !== null ? value : "-"; // Show dash if value is null
  });


  
    // Set label color based on value
    labelBullet.label.adapter.add("fill", function (fill, target) {
      let color = target.dataItem.dataContext.color;
      if (color === "#F0F0F3") {
        return am4core.color("#000"); // Set label color to black if cell color is #F0F0F3
      } else {
        return fill; // Use the default label color
      }
    });

    // Create custom template for columns
    let columnTemplate = series.columns.template;
    columnTemplate.width = am4core.percent(100);
    columnTemplate.height = am4core.percent(100);
    columnTemplate.fill = am4core.color("#FFFFFF"); // Default color
    columnTemplate.propertyFields.fill = "color"; // Set column fill based on data

    // Set corner radius directly for the custom template
    columnTemplate.column.cornerRadiusTopLeft = 5;
    columnTemplate.column.cornerRadiusTopRight = 5;
    columnTemplate.column.cornerRadiusBottomLeft = 5;
    columnTemplate.column.cornerRadiusBottomRight = 5;

    // Clean up function
    return () => {
      chart.dispose();
    };
  }, []);

  return <div id="heatmapChart" style={{ width: "100%", height: "370px" }} />;
};

export default HeatmapChart;
