import { Table } from "antd";
import React, { useState } from "react";
import "./TableSelectable.css";
import { DownloadOutlined } from "@ant-design/icons";

const columns = [
  {
    title: "Name",
    dataIndex: "name",
    render: (text) => <a style={{ color: "#5A81FA" }}>{text}</a>,
  },
  {
    title: "Size",
    dataIndex: "size",
    render:(text)=>(
      <span style={{fontFamily: 'Open Sans', fontWeight: 400, fontSize: 12,lineHeight: '19.07px',color: '#9494AE'}}>{text}</span>
    )
  },
  {
    title: "Action",
    dataIndex: "action",
    align: "center",
    render: (text, record) => (
      <a>
        <DownloadOutlined style={{ color: "#5A81FA" }} />
      </a>
    ),
  },
];
const data = [];
for (let i = 0; i < 46; i++) {
  data.push({
    key: i,
    name: `file-sample_200kb.Doc`,
    size: `20 kb`,
  });
}

const TableSelectable = ({
  size,
  rowSelect,
  TableData,
  TableClomun,
  pagination,
}) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  return (
    <div className="selectable_table_row">
      <Table
        size={size}
        rowSelection={rowSelect ? rowSelection : ""}
        columns={TableClomun ? TableClomun : columns}
        dataSource={TableData ? TableData : data}
        pagination={pagination ? pagination : false}
      />
    </div>
  );
};

export default TableSelectable;
