import { Button, Tabs } from "antd";
import React from "react";
import "./TabsWithExtraBtn.css";
// import { DeleteOutlined, EditOutlined } from "@ant-design/icons";

const TabsWithExtraBtn = ({ menu, callback, extraBtn, activeTabs }) => {
  const handleActiveTabs = (e) => {
    // console.log("active tabs ===", e);
    callback(e);
  };

  return (
    <Tabs
      defaultActiveKey={activeTabs}
      className="tabs_with_btn"
      onChange={handleActiveTabs}
      tabBarExtraContent={extraBtn}
      items={menu}
    />
  );
};

export default TabsWithExtraBtn;
