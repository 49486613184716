import React, { useEffect, useRef, useState } from "react";
import {
  Form,
  Row,
  Col,
  Input,
  Select,
  Checkbox,
  Radio,
  Button,
  Space,
  Tooltip,
  Tag,
  TimePicker,
  InputNumber,
  DatePicker,
  Upload,
  message,
} from "antd";
import DynamicRationGroup from "./DynamicRationGroup";
import "./DynamicForm.css";
import CustomFileUpload from "./CustomFileUpload";
import AutoCompleteChips from "./AutoCompleteChips";
import { InboxOutlined, UploadOutlined } from "@ant-design/icons";
import { Editor } from "@tinymce/tinymce-react";
// import { editResponse } from "../dashboard-options/Data";
const { Option } = Select;
const { Dragger } = Upload;

const DynamicForm = ({ data, initialValues, editResponse, editMode }) => {
  // console.log("initialValue=====", initialValues, editResponse);

  // time picker start
  const [openTimePicker, setOpenTimePicker] = useState(false);
  // time picker end
  const handleFieldChange = (fieldName, value) => {
    // Handle field value change here
    console.log(`Field '${fieldName}' value changed to:`, value);
  };
  // handle file upload
  const [form] = Form.useForm();
  const [uploadedFiles, setUploadedFiles] = useState({});

  const handleFileUpload = (fieldId, fileList) => {
    setUploadedFiles({
      ...uploadedFiles,
      [fieldId]: fileList,
    });
  };
  useEffect(() => {
    if (initialValues) {
      const fieldValues = {};
      editResponse.forEach((el) => {
        fieldValues[el.field_id] = el.control_value;
      });
      // console.log("fieldValue=======", fieldValues);
      form.setFieldsValue(fieldValues);
    } else {
      form.resetFields();
    }
  }, [initialValues, form, editResponse]);

  const onFinish = (values) => {
    console.log("Form values:", values);
  };

  // drag and drop
  const [fileList, setFileList] = useState([]);
  const handleChange = (info) => {
    let newFileList = [...info.fileList];

    // 1. Limit the number of uploaded files to only one
    newFileList = newFileList.slice(-1);

    // 2. Read from response and show file link
    newFileList = newFileList.map((file) => {
      if (file.response) {
        // Component will show file.url as link
        file.url = file.response.url;
      }
      return file;
    });

    setFileList(newFileList);
  };

  const props = {
    action: "https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188",
    fileList: fileList,
    onChange: handleChange,
    multiple: false, // Allow only one file to be uploaded
  };
  // end drag and drop
  return (
    <Form form={form} layout="vertical" onFinish={onFinish}>
      <Row gutter={[16, 16]}>
        {data &&
          data?.length &&
          data?.map((field) => (
            <Col key={field.id} xs={24} sm={24} md={12} lg={8}>
              {field.control_type === "AUTOCOMPLETE_WITH_CHIPS" && (
                <Form.Item label={field.field_name} name={field.field_id}>
                  <AutoCompleteChips
                    value={form.getFieldValue(field.field_id)}
                    data={field}
                    onChange={handleFieldChange}
                  />
                </Form.Item>
              )}

              {field.control_type === "TEXTAREA" && (
                <Form.Item label={field.field_name} name={field.field_id}>
                  <Input.TextArea />
                </Form.Item>
              )}
              {field.control_type === "TEXT_INPUT" && (
                <Form.Item label={field.field_name} name={field.field_id}>
                  <Input size="large" />
                </Form.Item>
              )}
              {field.control_type === "SELECT2" ||
                (field.control_type === "SELECT" && (
                  <Form.Item label={field.field_name} name={field.field_id}>
                    <Select size="large">
                      {field?.optionvalues?.map((option) => (
                        <Option key={option.key} value={option.value}>
                          {option.value}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                ))}
              {field.control_type === "MULTIPLE_SELECT" && (
                <Form.Item label={field.field_name} name={field.field_id}>
                  <Select mode="multiple" size="large">
                    {field?.dropdownvalues?.map((option) => (
                      <Option key={option.key} value={option.value}>
                        {option.value}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              )}
              {field.control_type === "CHECKBOX" && (
                <Form.Item
                  name={field.field_id}
                  label={field.field_name}
                  valuePropName="checked"
                  rules={[
                    {
                      required: field.required === 1,
                      message: `Please select ${field.field_id}`,
                    },
                  ]}
                >
                  <Checkbox.Group>
                    {field?.optionvalues?.map(({ id, value }) => (
                      <Checkbox key={id} value={value}>
                        {value}
                      </Checkbox>
                    ))}
                  </Checkbox.Group>
                </Form.Item>
              )}
              {field.control_type === "RADIO" && (
                <Form.Item label={field.field_name} name={field.field_id}>
                  <Radio.Group size="large">
                    {field.optionvalues &&
                      field.optionvalues.map(({ id, value }) => (
                        <Radio key={id} value={value}>
                          {value}
                        </Radio>
                      ))}
                  </Radio.Group>
                </Form.Item>
              )}
              {field.control_type === "MULTIPLE_FILE_WITH_ADD_REMOVE" && (
                <Form.Item
                  style={{ width: "100%" }}
                  label={field.field_name}
                  name={field.field_id}
                >
                  <CustomFileUpload
                    multiple={field.file_length > 1}
                    onChange={(fileList) =>
                      handleFileUpload(field.field_id, fileList)
                    }
                  />
                </Form.Item>
              )}
              {field.control_type === "TIME_PICKER" && (
                <Form.Item label={field.field_name} name={field.field_id}>
                  <TimePicker
                    size="large"
                    style={{ width: "100%" }}
                    open={openTimePicker}
                    onOpenChange={setOpenTimePicker}
                    renderExtraFooter={() => (
                      <Button
                        size="small"
                        type="primary"
                        onClick={() => setOpenTimePicker(false)}
                      >
                        OK
                      </Button>
                    )}
                  />
                </Form.Item>
              )}
              {field.control_type === "DATE_PICKER" && (
                <Form.Item label={field.field_name} name={field.field_id}>
                  <DatePicker
                    style={{ width: "100%" }}
                    size="large"
                    onChange={handleFieldChange}
                  />
                </Form.Item>
              )}
              {field.control_type === "NUMBER" && (
                <Form.Item label={field.field_name} name={field.field_id}>
                  <InputNumber
                    size="large"
                    type="number"
                    style={{ width: "100%" }}
                    min={0}
                    placeholder={`Enter ${field.field_id}`}
                    max={200}
                    defaultValue={field.default_value}
                    controls={true}
                  />
                </Form.Item>
              )}

              {field.control_type === "DRAG_AND_DROP" && (
                <Form.Item label={field.field_name} name={field.field_id}>
                  <Dragger {...props} maxCount={1}>
                    <p className="ant-upload-drag-icon">
                      <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">
                      Click or drag file to upload
                    </p>
                  </Dragger>
                </Form.Item>
              )}
              {field.control_type === "FILE" && (
                <Form.Item label={field.field_name} name={field.field_id}>
                  <Upload
                    className="file_upload"
                    action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
                    listType="picture"
                    maxCount={1}
                  >
                    <Button
                      size="large"
                      style={{ width: "100%" }}
                      icon={<UploadOutlined />}
                    >
                      Upload
                    </Button>
                  </Upload>
                </Form.Item>
              )}
              {field.control_type === "WYSIWYG" && (
                <Form.Item label={field.field_name} name={field.field_id}>
                  <Editor
                    apiKey="2o2tdzsy4xcqv64tb1o74pvnsv4jh33xwpnqyxs2bty99f61"
                    init={{
                      plugins:
                        "anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount checklist mediaembed casechange export formatpainter pageembed linkchecker a11ychecker tinymcespellchecker permanentpen powerpaste advtable advcode editimage advtemplate ai mentions tinycomments tableofcontents footnotes mergetags autocorrect typography inlinecss",
                      toolbar:
                        "undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat",
                      tinycomments_mode: "embedded",
                      tinycomments_author: "Author name",
                      mergetags_list: [
                        { value: "First.Name", title: "First Name" },
                        { value: "Email", title: "Email" },
                      ],
                      ai_request: (request, respondWith) =>
                        respondWith.string(() =>
                          Promise.reject("See docs to implement AI Assistant")
                        ),
                    }}
                    initialValue="Welcome to TinyMCE!"
                  />
                </Form.Item>
              )}
            </Col>
          ))}
      </Row>
      {/* <Row justify="end">
        <Col>
          <Button size="large" type="primary" htmlType="submit">
            Submit
          </Button>
        </Col>
      </Row> */}
    </Form>
  );
};

export default DynamicForm;
