import React from "react";
import { AreaChart, Area, ResponsiveContainer, defs, linearGradient, stop } from "recharts";

export default function ScoreTrendChart({ chartBg, strokeColor, data,height }) {
  // Generate a unique ID for the linear gradient
  const gradientId = `chartFill-${Math.random().toString(36).substr(2, 9)}`;

  return (
    <ResponsiveContainer width="100%" height={height ? height : 60}>
      <AreaChart
        width={100}
        height={60}
        data={data}
        margin={{
          top: 5,
          right: 0,
          left: 0,
          bottom: 5,
        }}
      >
        <defs>
          <linearGradient id={gradientId} x1="0" y1="0" x2="0" y2="1">
            <stop offset="20%" stopColor={chartBg} stopOpacity={0.8} />
            <stop offset="80%" stopColor={chartBg} stopOpacity={0.1} />
          </linearGradient>
        </defs>
        <Area
          type="monotone"
          dataKey="uv"
          stroke={strokeColor}
          fill={`url(#${gradientId})`}
        />
      </AreaChart>
    </ResponsiveContainer>
  );
}
